import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Zoom } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { PuffSpinner } from '../components/spinner/puff/puff';

export const ConfirmationWrapper = ({
    sx,
    open,
    successFunc,
    cancelFunc,
    successText,
    cancelText,
    title,
    description,
    children,
    renderContent,
    maxWidth,
    fullWidth,
    inverse,
    loading,
    onClose
}) => {
    return (
        <>
            <Dialog

                {...(maxWidth && { maxWidth: maxWidth })}
                {...(fullWidth && { fullWidth: fullWidth })}
                open={open}
                onClose={(_, reason) => {
                    if (reason !== `backdropClick`) {
                        cancelFunc && cancelFunc();
                    }
                }}
                aria-describedby="alert-dialog-slide-description"
                {...(sx ? sx : { ...sx } : { })}
            >
            {` `}
            {loading && <PuffSpinner />}
            <DialogTitle >{title}
                {onClose ? (
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            position: `absolute`,
                            right: 8,
                            top: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                ) : null}</DialogTitle>
            <DialogContent>
                {description && <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>}
                <Zoom in={renderContent ? true : false} mountOnEnter unmountOnExit><Box sx={{ mt: .5 }}>{renderContent}</Box></Zoom>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        backgroundColor: inverse ? `red` : `#1699C5`,
                        textTransform: `none`,
                        color: `white`,
                        '&:hover': {
                            backgroundColor: `darkred`,
                        },
                    }}
                    onClick={() => inverse ? cancelFunc() : successFunc()}
                >
                    {inverse ? cancelText : successText}
                </Button>
                {cancelFunc &&
                    <Button
                        sx={{
                            backgroundColor: inverse ? `green` : `#03b403`,
                            color: `white`,
                            textTransform: `none`,
                            '&:hover': {
                                backgroundColor: inverse ? `darkgreen` : `green`,
                            },
                        }}
                        onClick={() => inverse ? successFunc() : cancelFunc()}
                    >
                        {inverse ? successText : cancelText}
                    </Button>
                }
            </DialogActions>
        </Dialog >
            { children }
        </>
    )
}
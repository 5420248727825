import React from 'react';
import { Grid, MenuItem, TextField, ListSubheader } from '@mui/material';
import { resmedDeviceDetail, respironicsDeviceType, reactHealthDeviceType } from 'src/utils';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

export const Filters = ({ filtersObj, setFilters, validate, setFilterFlag, setPagination }) => {
  const onChangeHandler = (e, key) => {
    let { value } = e.target;
    setFilters((p) => ({ ...p, [key]: value }));
  };

  React.useEffect(() => {
    return () => {
      setFilters({
        search: ``,
        manufacturer: ``,
        isAdherent: ``,
        status: ``,
      });
    };
  }, []);

  return (
    <Grid container spacing={1.5} sx={{ p: 1.5 }}>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          onChange={(e) => onChangeHandler(e, `manufacturer`)}
          label="Manufacturer"
          fullWidth
          type="text"
          id="manufacturer"
          name="manufacturer"
          select
          value={filtersObj?.manufacturer}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value="resmed">ResMed</MenuItem>
          <MenuItem value="respironics">Respironics</MenuItem>
          <MenuItem value="reactHealth">React Health</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          select
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          onChange={(e) => onChangeHandler(e, `search`)}
          label="Device Type"
          fullWidth
          type="text"
          id="search"
          name="search"
          value={filtersObj?.search}
        >
          <MenuItem value="">Select</MenuItem>
          <ListSubheader>ResMed</ListSubheader>
          {resmedDeviceDetail.map((e) => (
            <MenuItem disabled={filtersObj?.manufacturer === `respironics` || filtersObj?.manufacturer === `reactHealth` ? true : false} key={e} value={e}>
              {e}
            </MenuItem>
          ))}
          <ListSubheader>Respironics</ListSubheader>
          {respironicsDeviceType.map((e) => (
            <MenuItem disabled={filtersObj?.manufacturer === `resmed` || filtersObj?.manufacturer === `reactHealth` ? true : false} key={e} value={e}>
              {e}
            </MenuItem>
          ))}
          <ListSubheader>React Health</ListSubheader>
          {reactHealthDeviceType.map((mappedDeviceType) => (
            <MenuItem disabled={filtersObj?.manufacturer === `respironics` || filtersObj?.manufacturer === `resmed` ? true : false} key={mappedDeviceType} value={mappedDeviceType}>
              {mappedDeviceType}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          label="Patient Status"
          fullWidth
          select
          id="status"
          name="status"
          value={filtersObj?.status}
          onChange={(e) => onChangeHandler(e, `status`)}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="inactive">Inactive</MenuItem>
          <MenuItem value="both">{`Active & Inactive`}</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          label="30 Day Adherence"
          fullWidth
          select
          id="isAdherent"
          name="isAdherent"
          value={filtersObj?.isAdherent || ``}
          onChange={(e) => onChangeHandler(e, `isAdherent`)}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value={`Adherent`}>Adherent</MenuItem>
          <MenuItem value={`Non-Adherent`}>Non-Adherent</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
        <TextField
          InputLabelProps={{
            style: { left: `-2%` },
          }}
          size="small"
          label="3 Day Usage Rate"
          fullWidth
          select
          id="isAdherent3Days"
          name="isAdherent3Days"
          value={filtersObj?.isAdherent3Days || ``}
          onChange={(e) => onChangeHandler(e, `isAdherent3Days`)}
        >
          <MenuItem value="">Select</MenuItem>
          <MenuItem value={`0`}>0%</MenuItem>
          <MenuItem value={`33`}>33%</MenuItem>
          <MenuItem value={`67`}>67%</MenuItem>
          <MenuItem value={`100`}>100%</MenuItem>
        </TextField>
      </Grid>
      <Grid item xs={4} sm={4} md={4} lg={4} xl={4} >
        <MuiSelect
          controlProps={{ fullWidth: true, id: `alertInAdherence`, size: `small` }}
          label="Alert"
          isSimple={true}
          options={[`Yes`, `No`]}
          selectProps={{
            label: `Alert`,
            id: `alertInAdherence`,
            name: `alertInAdherence`,
            onChange: (e) => onChangeHandler(e, `alert`),
            value: filtersObj?.alert,
          }}
        />
      </Grid>
      <Grid sx={{ textAlign: `end` }} item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ToolTipProvider
          toolTipProps={{
            title: `Filter`,
            placement: `top`,
            arrow: true,
          }}

          element={
            <button
              style={{
                width: `70px`,
              }}
              onClick={() => {
                let hasValidated = validate();
                if (hasValidated) {
                  setFilterFlag((p) => p + 1);
                  setPagination((p) => ({ ...p, sortField: `lastName`, sortOrder: `ASC`, currentPage: 1, rowsPerPage: 25 }));
                }
              }}
              className={`btn btn-sm btn-outline-primary`}
            >
              Filter
            </button>
          }

        />
      </Grid>
    </Grid>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { useSelector } from 'react-redux';
import { ADD_COMMENT_TO_SPECIFIC_TICKET, getRequest, GET_TICKET_DETAIL, postRequest } from '../../../../../../../../crud/crud';
import { PuffSpinner } from '../../../../../../../common/components/spinner/puff/puff';
import { momentWrapper } from 'src/momentWrapper';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { CommentsList } from '../common/CommentsList';
import { WordsFormatter } from 'src/pages/common/components/WordsFormatter/WordsFormatter';
import { Box } from '@mui/material';
import { HtmlFormatter } from '../../../../../HtmlFormatter/index';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { cleanWordsFormatterVal } from 'src/utils';

export const ViewIssue = ({ selectedIssueModalHandler, setSelectedIssueModalHandler, selectedIssueId, setIssueListFlag }) => {
  const [issueDetail, setIssueDetail] = useState({});
  const [loading, setLoading] = useState(false);
  const [commentsList, setCommentsList] = useState([]);
  const [comment, setComment] = useState(``);
  const [commentEmptyOnApiSuccess, setCommentEmptyOnApiSuccess] = useState(false);
  const commentBox = useRef(null);
  const user = useSelector((state) => state?.user?.user);
  useEffect(() => {
    if (commentBox.current) commentBox.current.scrollTop = commentBox.current.scrollHeight;
  }, [commentsList.length]);

  useEffect(() => {
    // API call to get specific issue details
    setLoading(true);
    selectedTicketDetails(selectedIssueId);
  }, [selectedIssueId]);

  const selectedTicketDetails = (selectedIssueId) => {
    getRequest(GET_TICKET_DETAIL + selectedIssueId)
      .then((response) => {
        setLoading(false);
        setIssueDetail(response?.data?.ticket);
        setCommentsList(response?.data?.ticket?.comments);
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
      });
  };

  // SAVING COMMENT HANDLER
  const savingCommentHandler = (ticketId) => {
    let filterValue = cleanWordsFormatterVal(comment);
    if (!filterValue) {
      errorToast(`Comment text is empty.`);
      return;
    }
    setLoading(true);
    postRequest(ADD_COMMENT_TO_SPECIFIC_TICKET + ticketId, { comment: comment })
      .then(() => {
        setLoading(false);
        setCommentEmptyOnApiSuccess(true);
        selectedTicketDetails(ticketId);
        // Red dot emit event
        newSocket.emit(`getAllTicketsOfUser`, {
          userId: user._id,
        });
      })
      .catch((err) => {
        setLoading(false);
        errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
      });
  };
  const closeModal = () => {
    setSelectedIssueModalHandler((selectedIssueModalHandler) => !selectedIssueModalHandler);
    setIssueListFlag((p) => !p); // Refresh the issues list
    // Red dot emit event
    newSocket.emit(`getAllTicketsOfUser`, {
      userId: user._id,
    });
  };

  return (
    <>
      <CustomizedDialogs
        title="Issue Detail"
        open={selectedIssueModalHandler}
        setOpen={() => closeModal()}
        size="sm"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <>
          <div className="d-flex justify-content-between">
            <div>
              {(issueDetail.issueStatus === `opened` || issueDetail.issueStatus === `closed`) && (
                <div className="text-success" style={{ fontSize: `0.7em` }}>
                  Opened : {momentWrapper(issueDetail.openedTicketDate).format(`MM/DD/YYYY hh:mm A`)}
                </div>
              )}
              {issueDetail.issueStatus === `closed` && (
                <div className="text-danger" style={{ fontSize: `0.7em` }}>
                  Closed : {momentWrapper(issueDetail.closedTicketDate).format(`MM/DD/YYYY hh:mm A`)}
                </div>
              )}
              {issueDetail._created_by && (
                <>
                  <div className="text-secondary" style={{ fontSize: `0.7em` }}>
                    Email : {issueDetail._created_by.email}
                  </div>
                  <div className="text-secondary" style={{ fontSize: `0.7em` }}>
                    Phone : {issueDetail._created_by.phoneNumber}
                  </div>
                </>
              )}
            </div>
            <div>
              {issueDetail.issueType === `other` && <span className="badge badge-primary">Other</span>}
              {issueDetail.issueType === `payment` && <span className="badge badge-secondary">Payment</span>}
              {issueDetail.issueType === `feature` && <span className="badge badge-info">Feature</span>}
              {issueDetail.issueType === `technical` && <span className="badge badge-dark">Technical</span>}
              <span className="ml-1">
                {issueDetail.issuePriority === `normal` && <span className="badge badge-primary">Normal</span>}
                {issueDetail.issuePriority === `urgent` && <span className="badge badge-success">Urgent</span>}
                <span className="ml-1">
                  {issueDetail.issueStatus === `opened` && <span className="badge badge-info">Open</span>}
                  {issueDetail.issueStatus === `closed` && <span className="badge badge-danger">Closed</span>}
                </span>
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="description" className="ml-0">
                  Description
                </label>
                <ToolTipProvider
                  toolTipProps={{
                    arrow: true,
                    placement: `top`,
                    title: cleanWordsFormatterVal(issueDetail?.issueTitle),
                  }}
                  element={
                    <div>
                      <HtmlFormatter title={issueDetail?.issueTitle} />
                    </div>
                  }
                />
              </div>
            </div>
          </div>
          <hr />
          <h6>Comments</h6>
          <div
            className="p-2"
            style={{
              border: `1px solid #ececec`,
              backgroundColor: `white`,
              overflowY: `auto`,
              borderRadius: `5px`,
              height: `35vh`,
            }}
            ref={commentBox}
          >
            {commentsList.map((comment) => (
              <CommentsList comment={comment} />
              // <div
              //   key={comment._id}
              //   className={`mb-1 p-1 rounded-right ${
              //     comment?._created_by?.isProvider || comment?._created_by?.isClinicalStaff ? 'border border-primary' : 'border border-success'
              //   }`}
              // >
              //   <div style={{ display: 'flex', alignItems: 'center' }}>
              //     <Avatar name={`${comment?._created_by?.firstName} ${comment?._created_by?.lastName}`} size="30" round="40px" />
              //     <p className="ml-2">{comment?.comment}</p>
              //   </div>
              //   <p style={{ textAlign: 'end' }}>{moment(comment?.createdAt).format('MM/DD hh:mm A')}</p>
              // </div>
            ))}
          </div>
          <div>
            {issueDetail.issueStatus !== `closed` ? (
              <>
                <div className="form-group">
                  <WordsFormatter
                    placeHolder={`Add Comment`}
                    setKeyValue={setComment}
                    commentEmptyOnApiSuccess={commentEmptyOnApiSuccess}
                    setCommentEmptyOnApiSuccess={setCommentEmptyOnApiSuccess}
                    readyToSet={true}
                  />
                </div>
                <div style={{ display: `flex`, justifyContent: `flex-end` }}>
                  <Box sx={{ display: `flex`, justifyContent: `flex-end`, mt: { xs: `30%`, sm: `15%`, md: `15%`, lg: `15%`, xl: `15%` } }}>
                    <button
                      className="btn btn-success"
                      type="button"
                      size="sm"
                      disabled={!comment.length}
                      onClick={() => {
                        savingCommentHandler(issueDetail._id);
                      }}
                    >
                      Comment
                    </button>
                  </Box>
                </div>
              </>
            ) : null}
          </div>
        </>
        {loading && <PuffSpinner />}
      </CustomizedDialogs>
    </>
  );
};

import { Skeleton, Zoom } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getRequest, PRACTICE_INFO_URL } from 'src/crud/crud.js'
import { Image } from '../Image/Image.js'


// ${user?.practiceLogoPath}

export const PracticeLogo = ({ logoProps }) => {
    const { user } = useSelector((state) => state?.user)
    const [logoPath, setLogoPath] = useState(``)

    useEffect(() => {
        getRequest(PRACTICE_INFO_URL)
            .then((res) => {
                const practiceLogoPath = res?.data?.practice?.practiceLogoPath;
                setLogoPath(practiceLogoPath)
            }).catch(() => {

            })
    }, [user?.practiceLogoPath])

    return (
        !logoPath ? <Skeleton {...logoProps} />
            : <Zoom in={logoPath ? true : false} mountOnEnter unmountOnExit>
                <div>
                    <Image imgProps={{
                        ...logoProps, src: logoPath
                    }} />
                </div>
            </Zoom>
    )
}

import React from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logoutHandlerS } from 'src/redux/action/logoutHandler';

export const Logout = () => {
  const dispatch = useDispatch();
  return (
    <div>
      {dispatch(logoutHandlerS())}
      <Redirect to="/login" />
    </div>
  );
}



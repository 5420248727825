import React from 'react';
import { useSelector } from 'react-redux';
import { BlockStatsWidget } from '../systemAdminStatsWidget/BlockStatsWidget';
import { Box, Typography } from '@mui/material';
import { ListAllGroups } from 'src/pages/home/modules/practices/PracticesGroup/ListAllGroups';

export const SystemAdminDashboard = () => {
  const user = useSelector((state) => state?.user?.user);

  return (
    <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, overflowX: `hidden`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
      <Box sx={{ display: `flex`, justifyContent: `space-between`, flexDirection: { xs: `column`, sm: `row` }, gap: `10px` }}>
        <Typography
          variant="h1"
          component="h1"
          gutterBottom
          sx={{ fontWeight: `normal`, textTransform: `capitalize`, fontSize: 24, textAlign: { xs: `center`, sm: `left` } }}
        >
          Welcome,{` `}
          <span style={{ fontWeight: `600` }}>
            {user?.firstName} {user?.lastName}
          </span>
        </Typography>
      </Box>
      {/* <div className="custom-control custom-switch">
        <input
          type="checkbox"
          className="custom-control-input"
          id="customSwitch1"
          checked={realTimeDataAvailableFlag}
          onChange={() => liveDataToggleBtn()}
        />
        <label className="custom-control-label" htmlFor="customSwitch1">
          REAL TIME DATA OFF/ON
        </label>
      </div> */}
      <BlockStatsWidget isGroupView={false} />
      <ListAllGroups />
    </Box>
  );
}

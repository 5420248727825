/* eslint-disable no-case-declarations */
import {
  UPDATE_TIME_OBJECT,
  SET_TIMER_TOGGLE_BUTTON,
  PATIENT_REPORT_TIME_IN_SECONDS,
  RESET_TIMER_VALUE,
  START_AUDIO_CALL,
  END_AUDIO_CALL,
  START_VIDEO_CALL,
  END_VIDEO_CALL,
  NO_OF_SECONDS_FOR_PATIENT,
  UPDATE_INDIVIDUAL_PATIENT_ACCUMULATED_TIME,
  SPECIAL_TIMER_FLAG_CHECK_BEFORE_MAKE_ADDENDUM,
} from './timerTypes';

const initialState = {
  autoTimer: false,
  audioCall: false,
  videoCall: false,
  toggleButton: false,
  startTime: null,
  logout: false,
};

export const timerReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TIME_OBJECT:
      return { ...state, ...action.payload };
    case SPECIAL_TIMER_FLAG_CHECK_BEFORE_MAKE_ADDENDUM:
      return {
        ...state,
        specialTimerFlagCheckBeforeMakeAddendum: action.payload,
      };
    case SET_TIMER_TOGGLE_BUTTON:
      return {
        ...state,
        toggleButton: false, // OFF
      };
    case RESET_TIMER_VALUE:
      return {
        ...state,
        toggleButton: true, // ON
      };
    case START_VIDEO_CALL: {
      return {
        ...state,
        videoCall: true,
      };
    }
    case END_VIDEO_CALL:
      return {
        ...state,
        videoCall: false,
      };

    case START_AUDIO_CALL:
      return {
        ...state,
        audioCall: true,
      };
    case END_AUDIO_CALL:
      return {
        ...state,
        audioCall: false,
      };
    case NO_OF_SECONDS_FOR_PATIENT:
      return {
        ...state,
        individualPatientTimeInSeconds: { ...action.payload },
      };
    case PATIENT_REPORT_TIME_IN_SECONDS:
      return {
        ...state,
        patientReportTimeInSeconds: { ...action.payload },
      };
    case `HANDLE_LOGOUT_MODAL`:
      return {
        ...state,
        logout: action.payLoad,
      };

    // case BASIC_DETAIL_TIMER_REFRESH_FLAG:
    //   return {
    //     ...state,
    //     patientBasicInfoTimer: !action.payload,
    //   };
    // case REFRESH_MANUAL_TIMELOG_COMPONENT:
    //   return {
    //     ...state,
    //     refreshManualTimeLogComponent: !action.payload,
    //   };
    // case TIMER_REFRESH_FLAG:
    //   return {
    //     ...state,
    //     timerRefreshFlag: !action.payload,
    //   };
    case UPDATE_INDIVIDUAL_PATIENT_ACCUMULATED_TIME:
      let patients = state.individualPatientTimeInSeconds;
      if (patients[`${action.payload.patientId}`]) {
        patients[`${action.payload.patientId}`].numberOfSecondsForPatient = action.payload.accumulatedTime;
      }
      return {
        ...state,
        individualPatientTimeInSeconds: { ...patients },
      };
    // case TURN_ON_PATIENT_TIMER:
    //   return {
    //     ...state,
    //     reStartPatientTimer: true,
    //   };
    // case TURN_OFF_PATIENT_TIMER:
    //   return {
    //     ...state,
    //     reStartPatientTimer: false,
    //   };
    default:
      return state;
  }
};

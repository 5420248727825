import React from 'react';
import InputMask from 'react-input-mask';

export const FieldMask = ({ maskProps, child }) => {
  return (
    <InputMask
      {...maskProps}
    >
      {() => {
        return child;
      }}
    </InputMask>
  );
};
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard } from '../pages/home/dashboard/commonDashboard/dashboard';
import { GroupAdminUsers } from 'src/pages/home/dashboard/commonDashboard/components/Group admin/GroupAdminusers';
import { AnalyticsSystemAdmin } from '../pages/home/modules/settings/Analytics/Analytics';
import { SettingsSystemAdmin } from '../pages/home/modules/settings/settings';
import { SmartPhrase } from '../pages/home/modules/administration/smartPhrases/smartPhrases';
import { Faqs } from 'src/pages/common/components/wrapper/components/help/faqs/Faqs';
import { Resources } from 'src/pages/common/components/wrapper/components/help/resources/resources';
import { ListView } from 'src/pages/common/components/listview/ListView';
import { AdministrationPayment } from 'src/pages/home/modules/administration/payment';

export const GroupAdmin = () => {
  return (
    <Switch>
      <Route exact path="/list/view/:id?">
        <ListView />
      </Route>
      <Route exact path="/admin/payments">
        <AdministrationPayment />
      </Route>
      <Route exact path="/smart/phrases">
        <SmartPhrase />
      </Route>
      <Route exact path="/analytics">
        <AnalyticsSystemAdmin />
      </Route>
      <Route exact path="/settings">
        <SettingsSystemAdmin />
      </Route>
      <Route exact path={`/groupAdmin/administration/user`}>
        <GroupAdminUsers />
      </Route>
      <Route exact path="/faqs">
        <Faqs />
      </Route>
      <Route exact path="/resources">
        <Resources />
      </Route>
      <Route path="/">
        <Redirect to="/" />
        <Dashboard />
      </Route>
    </Switch>
  );
}

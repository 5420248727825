import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import MainRouter from './router/main';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { PulseSpinner } from './pages/common/components/spinner/spinner';
import { CachesBusterWrapper } from './pages/common/HOC/CachesBusterWrapper';



export const MonitAirApp = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor} loading={<PulseSpinner />}>
        <React.StrictMode>
          <CachesBusterWrapper>
            <BrowserRouter>
              <MainRouter />
            </BrowserRouter>
          </CachesBusterWrapper>
        </React.StrictMode>
      </PersistGate>
    </Provider >
  );
}




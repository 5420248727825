import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Compose } from '../Compose/compose';
import Toolbar from '../Toolbar';
import CircularProgress from '@mui/material/CircularProgress';
import css from './MessageList.module.css';
import { Message } from '../Message/Message';
import Spinner from 'react-spinners/PuffLoader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { _recentMessage, _updateUserOnlineStatus, _updateMessageReadStatus } from '../../../../../../redux/messenger/messengerActions';
import { capitalizeFirstLetter } from 'src/utils';

export const MessageList = ({ isFromView, user, patientName, patientId }) => {
  const individualUser = useSelector((state) => state.messenger.individualUser);
  const selectedUserRedux = useSelector((state) => state.messenger.selectedUser);
  const selectedUserReduxRef = useRef(selectedUserRedux);
  const listOfPatients = useSelector((state) => state.messenger.listOfPatients);
  const [messages, setMessages] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const pageNumberRef = useRef(pageNumber);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const paginationLoaderRef = useRef(paginationLoader);
  const [conversationAvailable, setConversationAvailable] = useState(false);
  const conversationAvailableRef = useRef(conversationAvailable);
  const dispatch = useDispatch();
  const { conversationList, selectedUser } = useSelector((state) => state?.messenger);
  const userInfoNotify = useMemo(() => {
    let info = {};
    let foundUser = conversationList?.find((user) => {
      let condition = user?._id === selectedUser && user?.isProvider;
      if (condition) {
        let capitalLastName = capitalizeFirstLetter(user?.lastName)
        let capitalFirstName = capitalizeFirstLetter(user?.firstName)
        info = {
          isProvider: true,
          messageNotificationStatus: user?.messageNotificationStatus,
          receiverPhoneNumber: user?.phoneNumber,
          emailNotificationStatus: user?.emailNotificationStatus,
          receiverEmail: user?.email,
          receiverFirstName: capitalFirstName,
          receiverSecretToken: user?.secretToken,
          receiverLastName: capitalLastName
        };
      }
      return condition;
    });
    if (foundUser) {
      foundUser = {
        practicePhoneNumber: user?.practiceNumber,
        twilioSubAccountSid: user?.twilioSubAccountSid,
        twilioSubAccountAuth: user?.twilioSubAccountAuth,
        ...info,
      };
    }
    return foundUser;
  }, [conversationList, selectedUser, user]);



  useEffect(() => {
    selectedUserReduxRef.current = selectedUserRedux;
    if (selectedUserRedux.length > 0) {
      setSpinner(true);
      newSocket.emit(`getAllMessages`, {
        senderId: individualUser[selectedUserRedux].senderId,
        practiceId: individualUser[selectedUserRedux].practiceId,
        receiverId: individualUser[selectedUserRedux].receiverId,
        socketId: individualUser[selectedUserRedux].socketId,
        chatId: individualUser[selectedUserRedux]._chatRoomId,
        pageNumber: 1,
      });
    }

    listOfPatients.map((patient) => {
      patient[`id`] = patient._id;
      patient[`display`] = `${patient.firstName} ${patient.lastName}`;
    });

    newSocket.on(`getAllMessages`, (data) => {
      setSpinner(false);
      if (data.length > 0) {

        if (data && data[0]?._chatRoomId && data[0]?._chatRoomId == individualUser[selectedUserRedux]?._chatRoomId) {
          setMessages((prevState) => {
            return [...prevState, ...data];
          });
        }
      }
      if (pageNumberRef.current > 1 && data.length === 0) {
        setConversationAvailable(true);
        conversationAvailableRef.current = true;
      } else {
        setConversationAvailable(false);
        conversationAvailableRef.current = false;
      }
      setPaginationLoader(false);
      paginationLoaderRef.current = false;
    });

    return () => {
      setMessages([]);
      setPageNumber(1);
      setPaginationLoader(false);
      setConversationAvailable(false);
      pageNumberRef.current = 1;
      newSocket.off(`getAllMessages`);
    };
  }, [selectedUserRedux]);

  useEffect(() => {
    newSocket.on(`inboundMessage`, (data) => {
      updateMessages(data);
    });
  }, []);

  useEffect(() => {
    // SET USER MESSAGE READ STATUS THROUGH SOCKET
    newSocket.on(`isReadStatus`, (data) => {
      dispatch(_updateMessageReadStatus(data));
    });
  }, []);

  const newMessages = (msg) => {
    const targetChatRoomIdObj = individualUser[selectedUserRedux];
    dispatch(
      _recentMessage({
        message: msg[0].message,
        id: msg[0]._receiverId,
        _chatRoomId: targetChatRoomIdObj?._chatRoomId,
      })
    );
    let capitalLastName = capitalizeFirstLetter(user?.lastName)
    let capitalFirstName = capitalizeFirstLetter(user?.firstName)
    const msgSend = [
      {
        ...msg[0],
        _chatRoomId: targetChatRoomIdObj?._chatRoomId,
        ...(userInfoNotify ? {
          ...userInfoNotify, senderFirstName: capitalFirstName,
          senderLastName: capitalLastName
        } : {}),
      },
    ];
    setMessages((prevState) => [...msg, ...prevState]);

    newSocket.emit(`chatMessage`, msgSend);
    // SET USER ONLINE AND OFFLINE STATUS THROUGH SOCKET
    newSocket.on(`userOnlineStatus`, (data) => {
      dispatch(_updateUserOnlineStatus(data));
    });
  };

  const updateMessages = (data) => {
    if (data && data.length > 0 && data[0]._senderId && data[0]._senderId == selectedUserReduxRef.current) {
      setMessages((prevState) => {
        return [...data, ...prevState];
      });
    }
  };

  const loadMore = () => {
    if (!conversationAvailable) {
      setPageNumber((prevState) => {
        pageNumberRef.current = prevState + 1;
        return pageNumberRef.current;
      });
      setPaginationLoader(true);
      newSocket.emit(`getAllMessages`, {
        senderId: individualUser[selectedUserRedux].senderId,
        practiceId: individualUser[selectedUserRedux].practiceId,
        receiverId: individualUser[selectedUserRedux].receiverId,
        socketId: individualUser[selectedUserRedux].socketId,
        chatId: individualUser[selectedUserRedux]._chatRoomId,
        pageNumber: pageNumberRef.current,
      });
    }
  };
  return (
    <div className={isFromView ? css.viewMessagesList : css.messageList}>
      <div style={{ height: `10vh`, backgroundColor: `white` }}>
        <Toolbar />
      </div>

      <div
        id="scrollableDiv"
        style={{
          height: isFromView ? `38vh` : `68vh`,
          overflow: `auto`,
          display: `flex`,
          flexDirection: `column-reverse`,
          padding: `10px`,
        }}
      >
        {spinner ? (
          <div
            style={{
              display: `flex`,
              height: `100%`,
              justifyContent: `center`,
              width: `100%`,
              alignItems: `center`,
            }}
          >
            <Spinner size={150} color={`#6dd0f2`} loading={true} />
          </div>
        ) : (
          <>
            <InfiniteScroll
              dataLength={messages.length}
              next={loadMore}
              style={{
                display: `flex`,
                flexDirection: `column-reverse`,
                overflow: `hidden`,
              }} //To put endMessage and loader to the top.
              inverse={true}
              hasMore={true}
              loader={
                paginationLoader && (
                  <div style={{ display: `flex`, width: `100%`, justifyContent: `center` }}>
                    <CircularProgress style={{ height: `25px`, width: `25px` }} />
                  </div>
                )
              }
              scrollableTarget="scrollableDiv"
            >
              {messages?.length > 0 &&
                messages.map((message, index) => {
                  let isMine = message._receiverId === selectedUserRedux;
                  let timeStamp = message.createdAt;
                  let startsSequence = true;
                  let endsSequence = true;
                  let showTimestamp = true;

                  return (
                    // <div>
                    <Message
                      last="no"
                      key={index}
                      isMine={isMine}
                      startsSequence={startsSequence}
                      endsSequence={endsSequence}
                      showTimestamp={showTimestamp}
                      data={message.message}
                      timeStamp={timeStamp}
                    />
                    // </div>
                  );
                  // }
                })}
              {conversationAvailable && (
                <center>
                  <p>No more Conversation Available</p>
                </center>
              )}
            </InfiniteScroll>
          </>
        )}
      </div>
      <div style={{ height: `10vh` }}>
        {!spinner && individualUser[selectedUserRedux]?.practiceId ? (
          <Compose
            patientName={patientName}
            newMsgHandler={newMessages}
            selectedUser={individualUser[selectedUserRedux].receiverId}
            practiceId={individualUser[selectedUserRedux].practiceId}
            senderId={individualUser[selectedUserRedux].senderId}
            socketId={individualUser[selectedUserRedux].socketId}
            listOfPatients={listOfPatients}
            chatId={individualUser[selectedUserRedux]._chatRoomId}
            isFromView={isFromView}
            {...(patientId ? { patientId: patientId } : {})}
          />
        ) : null}
      </div>
    </div>
  );
}


import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import css from '../addNewUser.module.css';
import { ADD_NEW_ROLE, CHECK_EMAIL_USERNAME_AVAILABILITY_URL, CREATE_NEW_USER_URL, postRequest } from 'src/crud/crud';
import { PulseSpinner } from '../../../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { ToastContainer } from 'react-toastify';
import { addNewUserMountFlag } from 'src/redux/resmed/resmedActions';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import {
  capitalizeFirstLetter,
  disableAdditionalInfoInput,
  dynamicObjCreator,
  emailRegex,
  extractExactRoles,
  faxNumberMask,
  npiRegex,
  phoneRegex,
  providerType,
  runDefalutValidationAssignRole,
  usStateName,
} from 'src/utils';
import { saveUserInforRegistration } from 'src/redux/action/setUserHandler';
import { Box, Fade, Collapse, Grid } from '@mui/material';
import moment from 'moment';
import { MuiCheckBox } from 'src/pages/common/components/MuiCheckBox/MuiCheckBox';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

export const AddNewUser = ({ setAddUserModal, isNew }) => {
  // auth: state.user.auth,
  // practice: state?.user?.,

  const [firstName, setFirstName] = useState(``);
  const [firstNameError, setFirstNameError] = useState({
    status: false,
    msg: ``,
  });
  //
  const [values, setValues] = useState({
    isSuperAdmin: false,
    isProvider: false,
    isClinicalStaff: false,
    isTransportationAdmin: false,
    type: ``,
    npi: ``,
    license: ``,
    licenseState: ``,
  });
  const [errors, setErrors] = useState({
    roleError: { status: false, msg: `` },
    typeError: { status: false, msg: `` },
    npiError: { status: false, msg: `` },
    licenseError: { status: false, msg: `` },
    licenseStateError: { status: false, msg: `` },
  });
  const [lastName, setLastName] = useState(``);
  const [lastNameError, setLastNameError] = useState({
    status: false,
    msg: ``,
  });
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [phone, setPhone] = useState(``);
  const [phoneExtension, setphoneExtension] = useState(``);
  const [phoneError, setPhoneError] = useState({ status: false, msg: `` });
  const [email, setEmail] = useState(``);
  const [emailError, setEmailError] = useState({ status: false, msg: `` });
  const [fax, setFax] = useState(``);
  const [title, setTitle] = useState(``);
  const [runValidation, setrunValidation] = useState(false);
  const [loading, setLoading] = useState(false);
  const asignRoleValues = useRef(null);
  const user = useSelector((state) => state.user);
  const existing = useSelector((state) => state.user?.existingUser);
  const dispatch = useDispatch();
  const hasAlreadyUser = useMemo(() => {
    if ((existing?.additionalValues && Object.keys(existing?.additionalValues)?.length) || values?.isTransportationAdmin) {
      return true;
    } else {
      return false;
    }
  }, [existing]);

  const emptyExistingUser = () => {
    if (existing) {
      dispatch(saveUserInforRegistration(``));
    }
  };

  const selectedPractice = useSelector((state) => state?.practice);
  const groupId = useSelector((state) => state?.user?.user?._practiceGroupId);
  const _practiceGroupId = useSelector((state) => state?.practiceGroup?.groupId);
  let practice = user?.user?._practiceId;
  const specialHandler = useCallback(
    (e) => {
      if (existing) {
        dispatch(saveUserInforRegistration(``));
      }
    },
    [dispatch, existing]
  );
  const errorHandler = (key, value) => {
    setErrors((p) => ({ ...p, [key]: value }));
  };
  const containerRef = useRef(null);
  useEffect(() => {
    window.addEventListener(`beforeunload`, specialHandler);
    if (existing) {
      setFirstName(capitalizeFirstLetter(existing?.firstName));
      setLastName(capitalizeFirstLetter(existing?.lastName));
      setPhone(existing?.phoneNumber);
      setEmail(existing?.email);
      if (existing?.faxNumber) {
        setFax(existing?.faxNumber);
      }

      if (existing?.title) {
        setTitle(existing?.title);
      }
      if (existing?.dateOfBirth) {
        setDateOfBirth(existing?.dateOfBirth);
      }
      if (existing?.ext) {
        setphoneExtension(existing?.ext);
      }
    }
    return () => {
      window.removeEventListener(`beforeunload`, specialHandler);
    };
  }, [existing, specialHandler]);

  const runValidationForRole = async (values, errorHandler) => {
    return runDefalutValidationAssignRole(values, errorHandler, npiRegex, runValidation, setrunValidation, asignRoleValues);
  };

  const defaultValidator = async (value, temp, setError, key) => {
    if (key === `firstName`) {
      setrunValidation(true);
    }
    let returnValue = ``;
    if (key === `phone`) {
      if (!value) {
        temp.status = true;
        temp.msg = `*Required`;
        setError(temp);
        returnValue = false;
      } else {
        if (!phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setError(temp);
          returnValue = false;
        } else {
          setError(temp);
          returnValue = true;
        }
      }
    } else if (key === `email`) {
      if (!email) {
        temp.status = true;
        temp.msg = `*Required`;
        setError(temp);
        returnValue = false;
      } else if (!emailRegex.test(email)) {
        temp.status = true;
        temp.msg = `Invalid Email Address`;
        setError(temp);
        returnValue = false;
      } else {
        returnValue = true;
      }
    } else {
      if (!value) {
        temp.status = true;
        temp.msg = `*Required`;
        setError(temp);
        returnValue = false;
      } else {
        returnValue = true;
      }
    }

    return returnValue;
  };

  const setExistingProviderClinicallStaff = () => {
    if (existing?.additionalValues && Object.keys(existing?.additionalValues)?.length) {
      if (existing?.additionalValues?.NPI) {
        onChangeHandler(`npi`, existing?.additionalValues?.NPI);
      }
      if (existing?.additionalValues?.licenseState) {
        onChangeHandler(`licenseState`, existing?.additionalValues?.licenseState);
      }
      if (existing?.additionalValues?.medicalLicense) {
        onChangeHandler(`license`, existing?.additionalValues?.medicalLicense);
      }
      if (existing?.additionalValues?.providerType) {
        onChangeHandler(`type`, existing?.additionalValues?.providerType);
      }
    }
  };

  const validateInput = async (field) => {
    let temp = { status: false, msg: `` };

    switch (field) {
      case `firstName`:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
        } else {
          setFirstNameError(temp);
        }
        break;
      case `lastName`:
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
        } else {
          setLastNameError(temp);
        }
        break;

      case `phone`:
        if (!phone?.length) {
          temp.status = true;
          temp.msg = `*Required`;
          setPhoneError(temp);
        }
        if (phone?.length > 0 && !phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setPhoneError(temp);
        } else {
          setPhoneError(temp);
        }

        break;
      case `email`:
        if (!email) {
          temp.status = true;
          temp.msg = `*Required`;
          setEmailError(temp);
        } else if (!emailRegex.test(email)) {
          temp.status = true;
          temp.msg = `Invalid Email Address`;
          setEmailError(temp);
        } else {
          setEmailError(temp);
          isAvailable(email, `email`);
        }
        break;
      case `role`:
        if (!values?.isSuperAdmin && !values?.isProvider && !values?.isClinicalStaff && !values?.isTransportationAdmin) {
          temp.status = true;
          temp.msg = `*Required`;
          errorHandler(`roleError`, temp);
        } else {
          errorHandler(`roleError`, temp);
        }
        break;
      case `type`:
        if (values?.isProvider && !values?.type) {
          temp.status = true;
          temp.msg = `*Required`;

          errorHandler(`typeError`, temp);
        } else {
          errorHandler(`typeError`, temp);
        }
        break;
      case `npi`:
        if (values?.isProvider && !values?.npi) {
          temp.status = true;
          temp.msg = `*Required`;
          errorHandler(`npiError`, temp);
        } else if (values?.npi.length > 0 && values?.npi.length < 10) {
          temp.status = true;
          temp.msg = `Invalid NPI`;
          errorHandler(`npiError`, temp);
        } else {
          errorHandler(`npiError`, temp);
        }
        break;
      case `license`:
        if (values?.isProvider && !values?.license) {
          temp.status = true;
          temp.msg = `*Required`;

          errorHandler(`licenseError`, temp);
        } else {
          errorHandler(`licenseError`, temp);
        }
        break;
      case `state`:
        if (values?.isProvider && !values?.licenseState) {
          temp.status = true;
          temp.msg = `*Required`;

          errorHandler(`licenseStateError`, temp);
        } else {
          errorHandler(`licenseStateError`, temp);
        }
        break;
      default:
        let firstNameCheck = await defaultValidator(firstName, temp, setFirstNameError, `firstName`);
        let lastNameCheck = await defaultValidator(lastName, temp, setLastNameError, false);
        let PhoneCheck = await defaultValidator(phone, temp, setPhoneError, `phone`);
        let emailCHeck = await defaultValidator(email, temp, setEmailError, `email`);
        let roles = await runValidationForRole(values, errorHandler);
        if (!firstNameCheck || !lastNameCheck || !PhoneCheck || !emailCHeck || !roles) {
          return false;
        } else {
          return true;
        }
    }
  };

  // VALIDATE FIRST NAME
  const validateFirstName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setFirstName(val);
    }
  };

  // VALIDATE LAST NAME
  const validateLastName = (val) => {
    if (val.match(`^[a-zA-Z ]*$`) != null) {
      setLastName(val);
    }
  };

  // PHONE MASK REGEX
  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setPhone(temp);
  };

  // FAX MASK REGEX

  // NPI MAST REGEX

  // RESET FORM
  const resetForm = () => {
    setFirstName(``);
    setFirstNameError({ status: false, msg: `` });
    setLastName(``);
    setLastNameError({ status: false, msg: `` });
    setDateOfBirth(null);
    setPhone(``);
    setPhoneError({ status: false, msg: `` });
    setEmail(``);
    setEmailError({ status: false, msg: `` });
    setFax(``);
  };

  // FORM SUBMIT HANDLER
  const submitHandler = async () => {
    try {
      const temp = await validateInput();
      let userRoles = await extractExactRoles({ ...values }, true);
      let dob = dateOfBirth?.toString()?.length && moment(dateOfBirth).format(`MM/DD/YYYY`);
      let providerKeys = { NPI: values?.npi, providerType: values?.type, medicalLicense: values?.license, licenseState: values?.licenseState };
      const dynamicProviderKeys = dynamicObjCreator({ ...providerKeys });
      let dynamicObject = dynamicObjCreator({
        ...providerKeys,
        firstName: firstName,
        lastName: lastName,
        email: email,
        title,
        phoneNumber: phone,
        ...(dob?.length > 0 && { dateOfBirth: dob }),
      });

      let payLoad = {
        ...dynamicObject,
        systemRoles: [
          {
            ...userRoles,
            _practiceId: user.user?.isSystemAdministrator || user?.user?.isGroupAdmin ? selectedPractice?._id : practice,
            _practiceGroupId: user?.user?.isSystemAdministrator ? _practiceGroupId : groupId,
          },
        ],
      };

      const suspenser = (success) => {
        if (success) {
          setLoading(false);
          setAddUserModal(false);
          dispatch(addNewUserMountFlag()); // NEW USER ADDED, RESET REFRESH FLAG
          successToast(`New User Added !`);
          resetForm();
        } else {
          setLoading(false);
        }
      };
      if (temp) {
        setLoading(true);
        if (isNew) {
          postRequest(CREATE_NEW_USER_URL, payLoad)
            .then(() => {
              suspenser(true);
            })
            .catch((err) => {
              suspenser(false);
              errorToast(err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data?.error);
            });
        } else {
          if (userRoles) {
            postRequest(ADD_NEW_ROLE + existing?._id, {
              ...(hasAlreadyUser ? {} : { ...dynamicProviderKeys, isNewProviderOrClinicalStaff: true }),
              systemRoles: [
                {
                  ...userRoles,
                  _practiceId: user?.user?.isSystemAdministrator || user?.user?.isGroupAdmin ? selectedPractice?._id : user?.user?._practiceId,
                  _practiceGroupId: user?.user?.isSystemAdministrator ? _practiceGroupId : groupId,
                },
              ],
            })
              .then(() => {
                emptyExistingUser();
                suspenser(true);
              })
              .catch((e) => {
                errorToast(e?.response?.data?.message ? e?.response?.data?.message : e?.response?.data?.error);
                suspenser(false);
              });
          }
        }
      } else {
        errorToast(`Please fill up the entries!`);
      }
    } catch (error) {
      errorToast(`Failed`);
    }
  };

  const isAvailable = (val, key) => {
    setLoading(true);
    postRequest(CHECK_EMAIL_USERNAME_AVAILABILITY_URL, { userName: val })
      .then((res) => {
        setLoading(false);
        successToast(`Available`);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response) {
          if (err.response.status === 409 && key === `email`) setEmailError({ status: true, msg: `Email already exists.` });
        } else errorToast(`Network Error`);
      });
  };
  const onChangeHandler = (key, value) => {
    setValues((p) => ({ ...p, [key]: value }));
  };

  const npiMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,10})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);

    onChangeHandler(`npi`, temp);
  };
  const checkAndAssignRoleHandler = (e) => {
    const { name } = e.target;

    //admin flag
    if (name === `admin`) {
      if (!values?.isTransportationAdmin) {
        onChangeHandler(`isSuperAdmin`, !values?.isSuperAdmin);
        return;
      } else {
        errorToast(`Admin role cannot assign with Clinical Staff or Biller`);
      }
    }
    //Provider flag
    if (name === `provider`) {
      if (!values?.isClinicalStaff && !values?.isTransportationAdmin) {
        onChangeHandler(`isProvider`, !values?.isProvider);
        return;
      } else {
        errorToast(`Provider role cannot assign with Clinical Staff or Biller`);
      }
    }
    //Clinical Staff flag
    if (name === `clinicalStaff`) {
      if (!values?.isProvider && !values?.isTransportationAdmin) {
        onChangeHandler(`isClinicalStaff`, !values?.isClinicalStaff);
        return;
      } else {
        errorToast(`Clinical Staff role cannot assign with Provider,Biller or Admin`);
      }
    }
    //Clinical Staff flag
    if (name === `transportationAdmin`) {
      if (!values?.isProvider && !values?.isSuperAdmin && !values?.isClinicalStaff) {
        onChangeHandler(`isTransportationAdmin`, !values?.isTransportationAdmin);
        return;
      } else {
        errorToast(`TransportAdmin role cannot assign with Provider,Clinical Staff or Admin`);
      }
    }
  };
  let hasMount = true;
  return (
    <>
      <div>
        <>
          <ToastContainer />
          <div>
            <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
              Basic Information
            </p>
            <hr style={{ margin: `0px` }} />

            <Box sx={{ mt: 2 }}>
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <InputField
                    size="small"
                    disabled={!isNew && existing}
                    error={firstNameError.status && !firstName ? true : false}
                    // className={firstNameError.status && !firstName ? css.errorCustom : null}
                    onBlur={() => validateInput(`firstName`)}
                    autoComplete="off"
                    onChange={(event) => {
                      validateFirstName(event.target.value);
                      validateInput(`firstName`);
                    }}
                    value={firstName}
                    type="text"
                    id="firstName"
                    style={{
                      width: `100%`,

                      textTransform: `capitalize`,
                    }}
                    name="firstName"
                    label="First Name*"
                  />
                  <p className={firstNameError.status && !firstName ? `d-block text-danger` : `d-none`}>{firstNameError.msg}</p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <InputField
                    size="small"
                    error={lastNameError.status && !lastName ? true : false}
                    disabled={!isNew && existing}
                    // className={lastNameError.status && !lastName ? css.errorCustom : null}
                    onBlur={() => validateInput(`lastName`)}
                    autoComplete="off"
                    onChange={(event) => {
                      validateLastName(event.target.value);
                      validateInput(`lastName`);
                    }}
                    value={lastName}
                    type="text"
                    id="lastName"
                    style={{
                      width: `100%`,

                      textTransform: `capitalize`,
                    }}
                    name="lastName"
                    label="Last Name*"
                  />
                  <p className={lastNameError.status && !lastName ? `d-block text-danger` : `d-none`}>{lastNameError.msg}</p>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <DatePickerCustomize
                    dateInputValue={dateOfBirth || null}
                    setDateInputValue={setDateOfBirth}
                    disabled={!isNew && existing ? true : false}
                    pickerProps={{
                      size: `small`,
                      label: `DOB`,
                    }}
                  />
                </Grid>
                <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                  <InputField
                    size="small"
                    disabled={!isNew && existing}
                    // className={phoneError.status ? css.errorCustom : null}
                    onBlur={() => validateInput(`phone`)}
                    autoComplete="off"
                    onChange={(event) => {
                      setphoneExtension(event.target.value);
                    }}
                    value={phoneExtension}
                    type="text"
                    id="phoneExtension"
                    style={{ width: `100%` }}
                    name="phoneExtension"
                    label="Extension"
                  />
                  {/* <p className={phoneError.status ? 'd-block text-danger' : 'd-none'}>{phoneError.msg}</p> */}
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                  <InputField
                    size="small"
                    error={phoneError.status ? true : false}
                    disabled={!isNew && existing}
                    // className={phoneError.status ? css.errorCustom : null}
                    onBlur={() => validateInput(`phone`)}
                    autoComplete="off"
                    onChange={(event) => {
                      phoneMask(event.target.value);
                      validateInput(`phone`);
                    }}
                    value={phone}
                    type="text"
                    id="phone"
                    style={{ width: `100%` }}
                    name="phone"
                    label="Phone Number*"
                  />
                  <p className={phoneError.status ? `d-block text-danger` : `d-none`}>{phoneError.msg}</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputField
                    size="small"
                    disabled={!isNew && existing}
                    error={emailError.status ? true : false}
                    // className={emailError.status ? css.errorCustom : null}
                    onBlur={() => validateInput(`email`)}
                    autoComplete="off"
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    type="email"
                    id="email"
                    style={{ width: `100%` }}
                    name="email"
                    label="Email*"
                  />
                  <p className={emailError.status ? `d-block text-danger` : `d-none`}>{emailError.msg}</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputField
                    size="small"
                    disabled={!isNew && existing}
                    autoComplete="off"
                    onChange={(event) => faxNumberMask({ value: event.target.value, setValue: setFax })}
                    value={fax}
                    type="text"
                    id="fax"
                    style={{ width: `100%` }}
                    name="fax"
                    label="Fax"
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputField
                    size="small"
                    disabled={!isNew && existing}
                    // className={titleError.status && !title ? css.errorCustom : null}
                    // onBlur={() => validateInput('title')}
                    autoComplete="off"
                    onChange={(event) => {
                      setTitle(event.target.value);
                      // validateInput('title');
                    }}
                    value={title}
                    type="text"
                    id="title"
                    style={{ width: `100%` }}
                    name="title"
                    label="Title"
                  />
                  {/* <p className={titleError.status && !title ? 'd-block text-danger' : 'd-none'}>{titleError.msg}</p> */}
                </Grid>
              </Grid>
            </Box>

            <Fade in={hasMount} mountOnEnter unmountOnExit>
              <Box ref={containerRef} sx={{ width: `100%`, overflow: `hidden`, mt: 2 }}>
                <div className={`d-flex align-items-center 'w-100 justify-content-center'}`} id={css.additionalRole}>
                  <label
                    className={css.assingRoleLabel}
                    style={
                      errors?.roleError.status &&
                        !values?.isSuperAdmin &&
                        !values?.isProvider &&
                        !values?.isClinicalStaff &&
                        !values?.isTransportationAdmin
                        ? { color: `red` }
                        : null
                    }
                  >
                    Assign Role(s)*:{` `}
                  </label>
                  <MuiCheckBox
                    size="small"
                    type="checkbox"
                    id="adminaddUser"
                    name="admin"
                    checked={values?.isSuperAdmin}
                    onChange={(e) => {
                      // setisSuperAdmin(e.target.checked);
                      // setisSuperAdmin(!isSuperAdmin);
                      checkAndAssignRoleHandler(e);
                    }}
                    onBlur={() => validateInput(`role`)}
                  />
                  <label htmlFor="admin"> Admin</label>
                  <MuiCheckBox
                    size="small"
                    type="checkbox"
                    id="providerAddUser"
                    name="provider"
                    checked={values?.isProvider}
                    onChange={(e) => {
                      checkAndAssignRoleHandler(e);
                      setExistingProviderClinicallStaff();
                    }}
                    onBlur={() => validateInput(`role`)}
                  />
                  <label htmlFor="provider"> Provider</label>
                  <MuiCheckBox
                    size="small"
                    type="checkbox"
                    id="clinicalStaffAddUser"
                    name="clinicalStaff"
                    checked={values?.isClinicalStaff}
                    onChange={(e) => {
                      let errosObj = {
                        ...errors,
                      };
                      delete errosObj.roleError;
                      let reset = { status: false, msg: `` };
                      for (const iterator in errosObj) {
                        const { msg } = errosObj[iterator];
                        if (msg?.length) {
                          errorHandler(`${iterator}`, { ...reset });
                        }
                      }
                      checkAndAssignRoleHandler(e);
                      setExistingProviderClinicallStaff();
                    }}
                    onBlur={() => validateInput(`role`)}
                  />
                  <label htmlFor="clinicalStaff"> Clinical Staff</label>
                  <MuiCheckBox
                    size="small"
                    type="checkbox"
                    id="transportationAdminAddUser"
                    name="transportationAdmin"
                    checked={values?.isTransportationAdmin}
                    onChange={(e) => {
                      checkAndAssignRoleHandler(e);
                      // setisTransportationAdmin(!isTransportationAdmin);
                    }}
                    onBlur={() => validateInput(`role`)}
                  />
                  <label htmlFor="biller">Transportation Admin</label>
                </div>

                <Collapse
                  container={containerRef.current}
                  direction="left"
                  in={values?.isProvider || values?.isClinicalStaff}
                  mountOnEnter
                  unmountOnExit
                  timeout={900}
                >
                  <div>
                    <p className={css.sectionHeadingText}>{`${values?.isClinicalStaff ? `Clinical User` : `Provider`} Additional Information`}</p>
                    <hr style={{ margin: `0px` }} />
                    <Box sx={{ mt: 2, mb: 2 }}>
                      <Grid container columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <MuiSelect
                            isSimple={false}
                            controlProps={{
                              size: `small`,
                              fullWidth: true,
                              id: `providerType`,
                            }}
                            label={`Provider Type${values?.isClinicalStaff ? `` : `*`}`}
                            options={[...providerType]}
                            selectProps={{
                              error: errors?.typeError.status && !values?.type ? true : false,
                              label: `Provider Type${values?.isClinicalStaff ? `` : `*`}`,
                              disabled: disableAdditionalInfoInput({ ...existing?.additionalValues }, `providerType`),
                              onBlur: () => validateInput(`type`),
                              name: `type`,
                              onChange: (event) => {
                                onChangeHandler(`type`, event.target.value);
                                validateInput(`type`);
                              },
                              value: values?.type,
                            }}
                            optionKey={`name`}
                            optionValue={`name`}
                          />
                          <p className={errors?.typeError.status && !values?.type ? `d-block text-danger` : `d-none`}>{errors?.typeError.msg}</p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <InputField
                            fullWidth={true}
                            size="small"
                            disabled={disableAdditionalInfoInput({ ...existing?.additionalValues }, `medicalLicense`)}
                            error={errors?.licenseError.status && !values?.license ? true : false}
                            onBlur={() => validateInput(`license`)}
                            autoComplete="off"
                            onChange={(event) => {
                              onChangeHandler(`license`, event.target.value);
                              validateInput(`license`);
                            }}
                            value={values?.license}
                            type="text"
                            id="licenseAddUser"
                            name="license"
                            label={`License No.${values?.isClinicalStaff ? `` : `*`}`}
                          />
                          <p className={errors?.licenseError.status && !values?.license ? `d-block text-danger` : `d-none`}>
                            {errors?.licenseError.msg}
                          </p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <MuiSelect
                            isSimple={false}
                            controlProps={{
                              size: `small`,
                              fullWidth: true,
                              id: `licenseState`,
                            }}
                            label={`License State${values?.isClinicalStaff ? `` : `*`}`}
                            options={[...usStateName]}
                            selectProps={{
                              error: errors?.licenseStateError.status && !values?.licenseState ? true : false,
                              id: `licenseState`,
                              label: `License State${values?.isClinicalStaff ? `` : `*`}`,
                              disabled: disableAdditionalInfoInput({ ...existing?.additionalValues }, `licenseState`),
                              onBlur: () => validateInput(`state`),
                              name: `type`,
                              onChange: (event) => {
                                onChangeHandler(`licenseState`, event.target.value);
                                validateInput(`state`);
                              },
                              value: values?.licenseState,
                            }}
                            optionKey={`name`}
                            optionValue={`name`}
                          />
                          <p className={errors?.licenseStateError.status && !values?.licenseState ? `d-block text-danger` : `d-none`}>
                            {errors?.licenseStateError.msg}
                          </p>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                          <InputField
                            size="small"
                            fullWidth={true}
                            disabled={disableAdditionalInfoInput({ ...existing?.additionalValues }, `NPI`)}
                            error={errors?.npiError.status ? true : false}
                            onBlur={() => validateInput(`npi`)}
                            autoComplete="off"
                            onChange={(event) => {
                              npiMask(event.target.value);
                              validateInput(`npi`);
                            }}
                            value={values?.npi}
                            id="npiAddUser"
                            name="npi"
                            label={`NPI${values?.isClinicalStaff ? `` : `*`}`}
                            placeholder={`NPI${values?.isProvider ? `*` : ``}`}
                          />
                          <p className={errors?.npiError.status ? `d-block text-danger` : `d-none`}>{errors?.npiError.msg}</p>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                </Collapse>
              </Box>
            </Fade>
            {/* <AssignRole
              isNew={isNew}
              runValidation={runValidation}
              setrunValidation={setrunValidation}
              runValidationForRole={(values, errorHandler) => runValidationForRole(values, errorHandler)}
            /> */}
          </div>
          <div className="d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-outline-info"
              id={css.addNewUserModalButton}
              onClick={submitHandler}
              disabled={values?.npi.length > 1 && values?.npi.length < 10 && true}
            >
              ADD USER
            </button>
          </div>
          {loading && <PulseSpinner />}
        </>
      </div>
    </>
  );
};

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { Link, useHistory } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { Wrapper } from 'src/pages/common/components/wrapper/wrapper';
import iDashboard from '../../../../../../assets/icons/dashboard.svg';
import iPatients from '../../../../../../assets/icons/patients.svg';
import iAnalytics from '../../../../../../assets/icons/analytics.svg';
import iSettings from '../../../../../../assets/icons/settings.svg';
import iPhrase from '../../../../../../assets/icons/smartphrase.svg';
import iLogOut from '../../../../../../assets/icons/log-out.svg';
import messenger from '../../../../../../assets/icons/messenger.svg';
import ticket from '../../../../../../assets/icons/ticket.svg';
import logo from '../../../../../../assets/monitair-logo.svg';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import css from './sidebar.module.css';
import { createTheme, Grid, ThemeProvider } from '@mui/material';
import { Device } from 'twilio-client';
import { endAudioCall } from 'src/redux/timer/timerActions';
import { BootstrapSpinner } from '../../../Bootstrapspinner/BootstrapSpinner';
import { doLogOut, timeLogAddOnSwitchPatientAndSorting } from 'src/utils';
import { useConfirm } from 'src/pages/common/hooks/useConfirm';
import taskManager from '../../../../../../assets/icons/taskManager.svg';
import { SkeletonWrapper } from 'src/pages/common/HOC/SkeletonWrapper';

const drawerWidth = 190;

export const ResponsiveDrawer = ({ children }) => {
  const [groupLogoConfirmation, setgroupLOgoConfirmation] = useState({
    open: false,
    renderEDitComp: false,
    showEditBtn: false,
  });
  const [groupLogoPath, setGroupLogoPath] = useState({ loading: false, error: false, src: `` });
  const { src: groupLogoSrc, loading: loadingGroupLogo, error: errorGroupLogo } = groupLogoPath;
  const history = useHistory();
  const timer = useSelector((state) => state.patient.timer);
  const user = useSelector((state) => state.user.user);
  const auth = useSelector((state) => state.user.auth);
  const [isLoggingOut, setLogout] = useState(false);
  const audioCall = useSelector((state) => state.timer.audioCall);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [multipleListItem, setMultipleListItem] = useState({ admin: false, patient: false });
  const logoPath = useSelector((state) => state?.practiceGroup?.logoPath);
  const logOutModal = useSelector((state) => state.timer.logout);
  const isSubProCli = (user.isProvider || user.isClinicalStaff) && user.stripeSubscriptionId;
  useEffect(() => {
    return () => {
      setgroupLOgoConfirmation({
        open: false,
        renderEDitComp: false,
      });
    };
  }, []);

  const dispatch = useDispatch();
  // responsive mobile screen show or close
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  // Multiple nesting menu handle
  const handleClick = (key) => {
    setMultipleListItem((p) => ({ ...p, [key]: !multipleListItem?.[key] }));
  };
  let confirmationForTimerOrCall = useConfirm({
    open: (audioCall && logOutModal) || (timer?.toggleButton && logOutModal) ? true : false,
    successFunc: () => {
      dispatch({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
      if (timer?.toggleButton) {
        timeLogAddOnSwitchPatientAndSorting();
      }
      if (audioCall && logOutModal) {
        Device.disconnectAll();
        dispatch(endAudioCall);
      }

      if (newSocket) {
        newSocket.close();
      }
      doLogOut(auth, setLogout, history);
      setLogout(true);
    },
    cancelFunc: () => {
      dispatch({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
    },
    successText: audioCall && logOutModal ? `End call in progress and logout!` : timer?.toggleButton && logOutModal && `Stop Timer and logout!`,
    cancelText: `Cancel`,
    title: audioCall && logOutModal ? `End call in progress?` : timer?.toggleButton && logOutModal && `Stop Timer`,
    description:
      audioCall && logOutModal
        ? `In order to logout you must end call first !`
        : timer?.toggleButton && logOutModal && `In order to logout you must stop timer first !`,
  });
  const logOutHandlerFunction = () => {
    if (!audioCall && !timer?.toggleButton) {
      if (newSocket) {
        newSocket.close(); //  CLOSING THE SOCKET, WHEN LOGOUT
      }
      doLogOut(auth, setLogout, history); // API CALL - IN CASE OF USER LOGOUT
    } else {
      dispatch({ type: `HANDLE_LOGOUT_MODAL`, payLoad: true });
    }
  };

  const drawer = (
    <>
      {confirmationForTimerOrCall}
      <SkeletonWrapper
        loading={{ loading: loadingGroupLogo, error: errorGroupLogo }}
        skeletonProps={{ type: `rectangular`, height: 67, sx: { transform: `scale(1, 1)`, borderRadius: `0px` } }}
      >
        <div
          style={{ padding: user?.groupLogo ? `0px` : logo ? `15px` : `21px`, height: `67px`, ...(user?.isGroupAdmin ? { position: `relative` } : {}) }}
        >
          <img src={user?.groupLogo ? user?.groupLogo : logo} alt="Logo" width={`100%`} height={`100%`} />
        </div>
      </SkeletonWrapper>

      <List
        sx={{
          backgroundColor: `#1699c5`,
          height: `100%`,
          color: `white`,
          width: `100%`,
          display: `flex`,
          flexDirection: `column`,
          justifyContent: `space-between`,
          // pointerEvents: videoCallStatus || audioCallStatus ? 'none' : null,
          paddingBottom: `0`,
        }}
      >
        <div>
          {(isSubProCli || user.isSystemAdministrator || user.isTransportationAdmin || user?.isGroupAdmin || user?.isSuperAdmin) && (
            <ListItem
              disabled={audioCall ? true : false}
              style={{
                backgroundColor: window?.location?.pathname === `/` ? `rgba(0,0,0,.4)` : null,
                height: `3.5rem`,
                marginTop: -8,
              }}
              button
              component={Link}
              to="/"
            >
              <ListItemIcon>
                <img src={iDashboard} alt="dashboard" width="24px" height="24px" />
                <h6
                  style={{
                    color: `#fff`,
                    marginLeft: 15,
                    marginTop: 3,
                    fontSize: `small`,
                  }}
                >
                  Dashboard
                </h6>
              </ListItemIcon>
            </ListItem>
          )}
          {user?.isTransportationAdmin ? (
            <ListItem
              disabled={audioCall ? true : false}
              style={{
                height: `3.5rem`,
                backgroundColor:
                  window?.location?.pathname === `/patient` || window?.location?.pathname === `/patients/overview` ? `rgba(0,0,0,.4)` : null,
                color: `white`,
              }}
              button
              component={Link}
              to={`/patients/overview`}
            >
              <ListItemIcon>
                <img src={iPatients} alt="patient" width="24px" height="24px" />
                <h6
                  style={{
                    color: `#fff`,
                    marginLeft: 15,
                    marginTop: 3,
                    fontSize: `small`,
                  }}
                >
                  <p>Patient Overview</p>
                </h6>
              </ListItemIcon>
            </ListItem>
          ) : null}
          {isSubProCli ? (
            <>
              <ListItem
                disabled={audioCall ? true : false}
                style={{
                  height: `3.5rem`,
                  backgroundColor:
                    window?.location?.pathname === `/patient` || window?.location?.pathname === `/patients/overview` ? `rgba(0,0,0,.4)` : null,
                  color: `white`,
                }}
                button
                onClick={() => {
                  handleClick(`patient`);
                }}
                component={isSubProCli ? `div` : Link}
                to={`${!isSubProCli ? `/patient` : `/`}`}
              >
                <ListItemIcon>
                  <img src={iPatients} alt="patient" width="24px" height="24px" />
                  <h6
                    style={{
                      color: `#fff`,
                      marginLeft: 15,
                      marginTop: 3,
                      fontSize: `small`,
                    }}
                  >
                    <p>RPM</p>
                  </h6>
                </ListItemIcon>
                {isSubProCli ? (
                  multipleListItem?.patient ? (
                    <ExpandLess
                      sx={{
                        marginLeft: `auto`,
                        '&:hover': {
                          color: `white !important`,
                        },
                      }}
                    />
                  ) : (
                    <ExpandMore
                      sx={{
                        marginLeft: `auto`,
                        '&:hover': {
                          color: `white !important`,
                        },
                      }}
                    />
                  )
                ) : null}
              </ListItem>
              {isSubProCli && (
                <Collapse in={multipleListItem?.patient} timeout="auto" unmountOnExit>
                  <List aria-disabled={audioCall ? true : false} component="div" disablePadding>
                    <ul className="nav" style={{ padding: `5% 0%`, backgroundColor: `rgb(22, 153, 197)` }}>
                      <li
                        className="nav-item d-flex w-100"
                        style={{
                          color: `white`,
                          backgroundColor: window?.location?.pathname === `/patient` ? `rgba(0,0,0,.4)` : null,
                          justifyContent: `center`,
                        }}
                      >
                        <Link to="/patient">
                          <p style={{ color: `rgb(255, 255, 255)`, padding: `0px 0px 5px 0px` }}>Patient Detail</p>
                        </Link>
                      </li>
                      <li
                        className="nav-item d-flex w-100"
                        style={{
                          color: `white`,
                          justifyContent: `center`,
                          backgroundColor: window?.location?.pathname === `/patients/overview` ? `rgba(0,0,0,.4)` : null,
                        }}
                      >
                        <Link to="/patients/overview">
                          <p style={{ color: `rgb(255, 255, 255)`, padding: `0px 0px 5px 24px` }}>Patient Overview</p>
                        </Link>
                      </li>
                    </ul>
                  </List>
                </Collapse>
              )}
            </>
          ) : null}

          {(isSubProCli || user.isTransportationAdmin) && (
            <ListItem
              disabled={audioCall ? true : false}
              style={{
                height: `3.5rem`,
                backgroundColor: window?.location?.pathname === `/messenger` ? `rgba(0,0,0,.4)` : null,
              }}
              button
              component={Link}
              to="/messenger"
            >
              <ListItemIcon>
                <img src={messenger} alt="patient" width="24px" height="24px" />
                <h6
                  style={{
                    color: `#fff`,
                    marginLeft: 15,
                    marginTop: 3,
                    fontSize: `small`,
                  }}
                >
                  Messenger
                </h6>
              </ListItemIcon>
            </ListItem>
          )}
          {(isSubProCli || user.isSystemAdministrator || user.isTransportationAdmin || user.isSuperAdmin) && (
            <ListItem
              disabled={audioCall ? true : false}
              style={{
                height: `3.5rem`,
                backgroundColor: window?.location?.pathname === `/ticketing` ? `rgba(0,0,0,.4)` : null,
              }}
              button
              component={Link}
              to="/ticketing"
            >
              <ListItemIcon>
                <img src={ticket} alt="analytics" width="24px" height="24px" />
                <h6
                  style={{
                    color: `#fff`,
                    marginLeft: 15,
                    marginTop: 3,
                    fontSize: `small`,
                  }}
                >
                  Ticket Center
                </h6>
              </ListItemIcon>
            </ListItem>
          )}
          {isSubProCli || user?.isTransportationAdmin || user?.isSuperAdmin ? (
            <ListItem
              disabled={audioCall ? true : false}
              style={{
                height: `3.5rem`,
                backgroundColor: window?.location?.pathname === `/taskmanagement` ? `rgba(0,0,0,.4)` : null,
              }}
              button
              component={Link}
              to="/taskmanagement"
            >
              <ListItemIcon>
                <img src={taskManager} alt="analytics" width="24px" height="24px" />
                <h6
                  style={{
                    color: `#fff`,
                    marginLeft: 15,
                    marginTop: 3,
                    fontSize: `small`,
                  }}
                >
                  Task Management
                </h6>
              </ListItemIcon>
            </ListItem>
          ) : null}
          {(isSubProCli || user.isSystemAdministrator || user?.isGroupAdmin || user.isSuperAdmin) && (
            <ListItem
              disabled={audioCall ? true : false}
              style={{
                height: `3.5rem`,
                backgroundColor: window?.location?.pathname === `/analytics` ? `rgba(0,0,0,.4)` : null,
              }}
              button
              component={Link}
              to="/analytics"
            >
              <ListItemIcon>
                <img src={iAnalytics} alt="analytics" width="24px" height="24px" />
                <h6
                  style={{
                    color: `#fff`,
                    marginLeft: 15,
                    marginTop: 3,
                    fontSize: `small`,
                  }}
                >
                  Analytics
                </h6>
              </ListItemIcon>
            </ListItem>
          )}
          {user.isSuperAdmin && (
            <>
              <ListItem
                disabled={audioCall ? true : false}
                style={{
                  height: `3.5rem`,
                  backgroundColor:
                    window?.location?.pathname === `/administration/practice` ||
                      window?.location?.pathname === `/administration/user` ||
                      window?.location?.pathname === `/administration/payment`
                      ? `rgba(0,0,0,.4)`
                      : null,
                }}
                button
                onClick={() => handleClick(`admin`)}
              >
                <ListItemIcon>
                  <img src={iSettings} alt="Administration" width="24px" height="24px" />
                  <h6
                    style={{
                      color: `#fff`,
                      marginLeft: 15,
                      marginTop: 3,
                      fontSize: `small`,
                    }}
                  >
                    Administration
                  </h6>
                </ListItemIcon>
                {multipleListItem?.admin ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={multipleListItem?.admin} timeout="auto" unmountOnExit>
                {/* Multiple Nested Items inside the administration */}
                <List aria-disabled={audioCall ? true : false} component="div" disablePadding>
                  <ul className="nav" style={{ padding: `5% 0%`, backgroundColor: `rgb(22, 153, 197)` }}>
                    <li className="nav-item d-flex w-100" style={{ color: `white`, marginLeft: `30%` }}>
                      <Link to="/administration/practice">
                        <p style={{ color: `#fff` }}>Practice</p>
                      </Link>
                    </li>
                    <li className="nav-item d-flex w-100" style={{ color: `white`, marginLeft: `30%` }}>
                      <Link to="/administration/user">
                        <p style={{ color: `#fff` }}>Users</p>
                      </Link>
                    </li>
                    <li className="nav-item d-flex w-100" style={{ color: `white`, marginLeft: `30%` }}>
                      <Link to="/administration/payment">
                        <p style={{ color: `#fff` }}>Payment</p>
                      </Link>
                    </li>
                  </ul>

                  {/* End multiple items tag */}
                </List>
              </Collapse>
            </>
          )}
          {(user.isSystemAdministrator || user?.isGroupAdmin) && (
            <>
              <ListItem
                disabled={audioCall ? true : false}
                style={{
                  height: `3.5rem`,
                  backgroundColor: window?.location?.pathname === `/settings` ? `rgba(0,0,0,.4)` : null,
                }}
                button
                component={Link}
                to="/settings"
              >
                <ListItemIcon>
                  <img src={iSettings} alt="settings" width="24px" height="24px" />
                  <h6
                    style={{
                      color: `#fff`,
                      marginLeft: 15,
                      marginTop: 3,
                      fontSize: `small`,
                    }}
                  >
                    Settings
                  </h6>
                </ListItemIcon>
              </ListItem>
              {user?.isSystemAdministrator && (
                <ListItem
                  disabled={audioCall ? true : false}
                  style={{
                    height: `3.5rem`,
                    backgroundColor: window?.location?.pathname === `/list/view` ? `rgba(0,0,0,.4)` : null,
                  }}
                  button
                  component={Link}
                  to="/list/view"
                >
                  <ListItemIcon>
                    <img src={iPhrase} alt="settings" width="24px" height="24px" />
                    <h6
                      style={{
                        color: `#fff`,
                        marginLeft: 15,
                        marginTop: 3,
                        fontSize: `small`,
                      }}
                    >
                      Smart Phrases
                    </h6>
                  </ListItemIcon>
                </ListItem>
              )}

              {user.isSystemAdministrator && (
                <ListItem
                  disabled={audioCall ? true : false}
                  style={{
                    height: `3.5rem`,
                    backgroundColor: window?.location?.pathname === `/credentials` ? `rgba(0,0,0,.4)` : null,
                  }}
                  button
                  component={Link}
                  to="/credentials"
                >
                  <ListItemIcon>
                    <img src={iSettings} alt="credentials" width="24px" height="24px" />
                    <h6
                      style={{
                        color: `#fff`,
                        marginLeft: 15,
                        marginTop: 3,
                        fontSize: `small`,
                      }}
                    >
                      Credentials
                    </h6>
                  </ListItemIcon>
                </ListItem>
              )}
            </>
          )}
        </div>
        <div>
          <ListItem style={{ backgroundColor: `rgba(0,0,0,.4)`, p: 0.5, height: `3.8rem` }} button onClick={() => logOutHandlerFunction()}>
            <Grid container sx={{ pt: 2 }}>
              <Grid sx={{ textAlign: `right` }} item xs={6} sm={6} md={6} lg={6} xl={6}>
                {` `}
                <ListItemIcon sx={{ marginRight: -1 }}>
                  {isLoggingOut ? (
                    <BootstrapSpinner
                      spinnerProps={{
                        className: `spinner-border spinner-border-sm text-light`,
                      }}
                    />
                  ) : (
                    <img src={iLogOut} alt="log-out" width="18px" height="18px" />
                  )}
                </ListItemIcon>
              </Grid>
              <Grid sx={{ textAlign: `left` }} item xs={6} sm={6} md={6} lg={6} xl={6}>
                {` `}
                <Box
                  sx={{
                    color: `#fff`,
                    fontSize: `small`,
                    fontWeight: 500,
                    marginLeft: -1.5,
                  }}
                >
                  Log out
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        </div>
        {/* </div> */}
      </List>
      {/* {logout && <Redirect to="/logout" />} */}
    </>
  );
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box className={css.mainGridContainer}>
        {/* <CssBaseline /> */}

        {/* sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders" */}
        <Box component="nav" className="sidebarContainer" sx={{ display: { xs: `none`, md: `block` } }}>
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Drawer
            className="mobileSidebar"
            //   container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: `block`, md: `none` },
              '& .MuiDrawer-paper': { boxSizing: `border-box`, width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: `none`, md: `block` },
              '& .MuiDrawer-paper': { boxSizing: `border-box`, width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <AppBar sx={{ position: `sticky`, backgroundColor: `#fff`, display: `block`, boxShadow: `0 0px 8px #9c909085`, height: `max-content` }}>
          <Toolbar sx={{ minHeight: { sm: 67 }, padding: { xs: `0 15px`, sm: `0 30px` } }}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: `none` }, color: `#1699c5` }}
            >
              <MenuIcon />
            </IconButton>
            <Wrapper setGroupLogoPath={(key, val) => setGroupLogoPath((p) => ({ ...p, [key]: val }))} />
          </Toolbar>
        </AppBar>

        {/* sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} */}
        <Box component="main" sx={{ overflow: `auto`, backgroundColor: `#f4fcfd`, height: `93vh` }}>
          {/* All children content main app /routes */}

          {children}
        </Box>
      </Box>
    </ThemeProvider>
  );
};

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

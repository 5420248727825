import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import { ListAllPatients } from './components/listAllPatients/filteredAllPatientsList';
import { PatientBasicDetails } from './components/patientDetails/basicDetails/basicDetails';
import { PatientMessages } from './components/patientCommunication/message/message';
import { PatientLogs } from './components/patientCommunication/logs/logs';
import { PatientsViewData } from './components/patientDetails/moreDetails/viewData/viewData';
import { useDispatch, useSelector } from 'react-redux';
import { VideoWindow } from './components/patientCommunication/videoCall/components/videoWindow/videoWindow';
import {
  setAllPatients, handleTimer, emptyPatientsList, emptyPatientData,
  setSelectedPatientReport, setAllMessages, communcicationWithPatient, setPatientsFilters,
} from '../../../../redux/patient/patientActions';
import { store } from 'src/redux/store';
import { PatientTasks } from './components/patientCommunication/tasks/tasks';
import css from './patient.module.css';

const blankPatientModule = (isSimpleUnmount) => {
  store.dispatch(setPatientsFilters({
    sortField: `lastName`,
    sortOrder: `ASC`,
    pageNumber: 1,
    pageSize: 30,
    searchFilters: {
      monitoringTime: [],
      tier: [],
      isAdherent: [],
      isAdherent3Days: [],
      online: [],
      status: [`active`],
    },
  }))

  store.dispatch(emptyPatientData(null));
  store.dispatch(emptyPatientsList(null));
  store.dispatch(setSelectedPatientReport(null));
  store.dispatch(handleTimer({ status: false }));
  store.dispatch(setAllMessages([]));
  store.dispatch({ type: `ISSENT`, payLoad: false });
  if (!isSimpleUnmount) {
    store.dispatch(communcicationWithPatient({ chatting: false, text: `` }));
  } else {
    return null;
  }
};

const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, `\\$&`);
  var regex = new RegExp(`[?&]` + name + `(=([^&#]*)|&|#|$)`),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return ``;
  return decodeURIComponent(results[2].replace(/\+/g, ` `));
}

export const Patients = () => {
  const user = useSelector((state) => state.user.user);
  const [taskToggle, setTaskToggle] = useState(false);
  const [currentTab, setCurrentTab] = useState(`msg`);
  // VERY UNIQUE FLAG, TO AVOID USER TO CLICK ON MULTIPLE PATIENTS simultaneously
  const [refreshFlag, setRefreshFlag] = useState(false);
  // Special flags
  const [messageToggle, setMessageToggle] = useState(true);
  const [chartingToggle, setChartingToggle] = useState(false);
  // INCOMPLETE OR UNSIGNED REPORT
  const [reportId] = useState(null);
  const location = useLocation();
  const dispatchRedux = useDispatch();
  const selectedPatientIdRedux = useSelector((state) => state.patient?.patientData?.data?._id);
  const twilioNotify = useSelector((state) => state.twilio.smsArrayForNotification);

  useEffect(() => {
    let adherent = getParameterByName(`Adherence`);
    let _risk = getParameterByName(`risk`);
    let _monitoring = getParameterByName(`monitoring`);
    let _status = getParameterByName(`status`);
    if (!(adherent || _risk || _monitoring || _status || location.patientId !== ``)) {
      setAllPatients();
    }
  }, []);

  useEffect(() => {
    if (location.pathname === `/patient` && twilioNotify[0]?.from === selectedPatientIdRedux) {
      dispatchRedux({ type: `REMOVE_CURRENT_MESSAGE_PATIENT`, payLoad: [] });
    }
  }, [selectedPatientIdRedux]);

  // When we change patient module we have to save time and stop time in case timer is on
  useEffect(() => {
    return () => {
      if (window.location.pathname !== `/patient`) {
        //Empty the patient module e.g PatientList,selected patient,patient report
        blankPatientModule(true);
      }
    };
    // }
  }, [selectedPatientIdRedux]);

  const handleChartingToggle = () => {
    if (messageToggle) {
      setChartingToggle(!chartingToggle);
      setMessageToggle(!messageToggle);
    } else {
      setChartingToggle(!chartingToggle);
    }
    setTaskToggle(false);
  };
  const handleTaskToggle = () => {
    setChartingToggle(false);
    setMessageToggle(false);
    setTaskToggle((p) => !p);


  };
  const handleMessageToggle = () => {
    if (chartingToggle) {
      setChartingToggle(!chartingToggle);
      setMessageToggle(!messageToggle);
    } else {
      setMessageToggle(!messageToggle);
    }
    setTaskToggle(false);
  };
  return (
    <VideoWindow>
      <Box
        sx={{
          display: `grid`,
          paddingRight: `1px`,
          gridTemplateColumns: user?.isTransportationAdmin ? `minmax(auto, 330px) 1fr` : `minmax(auto, 26%) 1fr minmax(auto, 24%)`,
        }}
      >
        {/* Implementing the patients list with backend filter and pagination */}
        <ListAllPatients setCurrentTab={setCurrentTab} />
        <div id={css.detailContainer}>
          <PatientBasicDetails />
          <PatientsViewData reportId={false} />
        </div>
        {user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isTransportationAdmin ? (
          <div
            id="msgNLogContainer"
            style={{
              boxShadow: `0 1px 2px 0 #0000001a, 0 10px 10px 0 rgba(68, 68, 68, 0.19)`,
            }}
          >
            <PatientMessages
              reportId={reportId}
              refreshFlag={refreshFlag}
              setRefreshFlag={setRefreshFlag}
              handleMessageToggle={handleMessageToggle}
              toggle={messageToggle}
              setCurrentTab={setCurrentTab}
              currentTab={currentTab}
              isTel={false}
            />
            <PatientLogs reportId={reportId} handleChartingToggle={handleChartingToggle} toggle={chartingToggle} />
            <PatientTasks reportId={reportId} handleChartingToggle={handleTaskToggle} toggle={taskToggle} />
          </div>
        ) : null}
      </Box>
    </VideoWindow>
  );
}
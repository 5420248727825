import { Tooltip } from '@mui/material';
import React from 'react';

export const ToolTipProvider = ({ toolTipProps, sx, element }) => {
  return (
    <Tooltip {...toolTipProps} sx={{ ...(sx ? { ...sx } : {}) }}>
      {element}
    </Tooltip>
  );
}

import React, { useState, useEffect } from 'react';
import css from './dialer.module.css';
import BackspaceIcon from '../../../../../../../../../assets/icons/backspace.svg';
import { useSelector } from 'react-redux';

export const TelemedicineDialer = ({ patientNumber, readyFlag, callHandler }) => {
  const videoCallStatus = useSelector((state) => state.videoCall);
  const [dialer, setDialer] = useState(patientNumber);
  useEffect(() => {
    if (patientNumber) {
      setDialer(patientNumber);
    }
  }, [patientNumber]);

  const dialerHandler = (key) => {
    let temp = dialer;
    temp = temp + key;
    phoneMask(temp);
  };

  const backspaceHandler = () => {
    let temp = dialer;
    if (temp.length) temp = temp.substr(0, temp.length - 1);
    phoneMask(temp);
  };

  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setDialer(temp);
  };

  return (
    <div id={css.telemedicineDialer} style={{ height: `88%` }}>
      <div className={css.rightAddon} style={{ height: `13%` }}>
        <input
          id={css.inputDialer}
          type="text"
          placeholder="Enter/Type Phone Number"
          style={{ width: `100%`, padding: `10px` }}
          value={dialer}
          onChange={(e) => phoneMask(e.target.value)}
        />
        <img src={BackspaceIcon} alt="icon" onClick={() => backspaceHandler()} />
      </div>
      <table className="table table-borderless w-100 mb-0" style={{ height: `87%` }}>
        <tbody id={css.dialPad} style={{ lineHeight: `1.20` }}>
          <tr>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`1`)}>
              1
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`2`)}>
              2
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`3`)}>
              3
            </td>
          </tr>
          <tr>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`4`)}>
              4
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`5`)}>
              5
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`6`)}>
              6
            </td>
          </tr>
          <tr>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`7`)}>
              7
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`8`)}>
              8
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`9`)}>
              9
            </td>
          </tr>
          <tr>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`*`)}>
              *
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`0`)}>
              0
            </td>
            <td style={{ width: `33%`, verticalAlign: `middle` }} onClick={() => dialerHandler(`#`)}>
              #
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr id={css.dialPadBtnContainer}>
            <td colSpan="3">
              <button
                disabled={!readyFlag}
                id={css.dialPadButton}
                className="btn"
                style={{ pointerEvents: videoCallStatus?.startFlag ? `none` : null }}
                onClick={() => callHandler(dialer)}
              >
                {!readyFlag ? `Connecting...` : <i className="fa fa-phone" aria-hidden="true"></i>}
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
}


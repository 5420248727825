import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { PracticeMessageCard } from 'src/pages/home/modules/patient2/components/patientCommunication/message/card';
import { Box, Fade } from '@mui/material';
import { SendMsg } from 'src/pages/home/modules/patient2/components/patientCommunication/message/SendMsg';
import { _twilioOutGoingSMS } from 'src/redux/twilio/twilioActions';
import { messagesApiCall, setAllMessages } from 'src/redux/patient/patientActions';
import { chatSkelton } from 'src/pages/home/modules/patient2/components/patientCommunication/message/message';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';

export const PatientMessenger = () => {
  const { readyToSign: { patientId }, user: { user }, patient: { messageLoadingFlag, messages } } = useSelector((state) => state);
  const chatBox = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (chatBox.current && messages?.length > 1) chatBox.current.scrollTop = chatBox.current.scrollHeight;
  }, [messages]);
  useEffect(() => {
    dispatch(messagesApiCall(patientId));
    return () => {
      dispatch(setAllMessages([]));
    };
  }, []);
  return (
    <Box sx={{ border: `1px solid #2299c5`, borderRadius: `5px` }}>
      <Box
        sx={{
          backgroundColor: `rgb(22, 153, 197)`,
          color: `white`,
          padding: `10px`,
          fontFamily: `sans-serif`,
          textAlign: `start`,
          borderTopLeftRadius: `5px`,
          borderTopRightRadius: `5px`,
        }}
      >
        Patient Communication
      </Box>
      {messageLoadingFlag.error && (
        <Box sx={{ width: `100%`, textAlign: `center` }}>
          <ReportProblemIcon sx={{ color: `red` }} />
        </Box>
      )}
      {messageLoadingFlag.loading && <Box sx={{ height: user?.isTransportationAdmin ? `54vh` : `47.3vh`, overflow: `auto` }}>{chatSkelton}</Box>}
      {!messageLoadingFlag.loading && !messageLoadingFlag.error && (
        <Box ref={chatBox} sx={{ height: user?.isTransportationAdmin ? `54vh` : `47.3vh`, overflowY: `scroll` }}>
          {messages?.map((m, i) => {
            return (
              <Fade in={m} key={i + m + `key`}>
                <Box key={i + m + `key1`}>
                  <PracticeMessageCard i={i} msg={m} />
                </Box>
              </Fade>
            );
          })}
        </Box>
      )}
      {!user?.isTransportationAdmin && <Box sx={{ mt: 1 }}>
        <SendMsg
          patientId={patientId}
          twilioOutGoingSMS={(payLoad) => dispatch(_twilioOutGoingSMS(payLoad))}
          overView={true}
          conditionalStyles={{ bottom: `132px`, width: `24%`, left: `1.6%`, position: `absolute` }}
        />
      </Box>}

    </Box>
  );
}




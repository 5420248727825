import { Button, TextField } from '@mui/material';
import React from 'react';

export const InputField = (props) => {
  const { InputLabelProps } = props || false
  return (
    <TextField {...props}
      {...(InputLabelProps ? { ...InputLabelProps } : {
        InputLabelProps: {
          style: { left: `-2%` }
        }
      })}
    />
  );
};

export const FileInput = ({ inputProps, fileBtnProps }) => {

  return (
    <>
      <input
        style={{ display: `none` }}
        type="file"
        {...inputProps}
      />
      <label htmlFor={inputProps?.id}>
        <Button {...fileBtnProps} >
          Upload Image
        </Button>
      </label>
    </>

  )
}
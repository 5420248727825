import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Device } from 'twilio-client';
import { getRequest, PATIENT_TWILIO_VOICE_CALL_TOKEN_VERIFY_URL } from '../../../../../../../crud/crud';
import { TelemedicineDialer } from './components/dialer/dialer';
import { startAudioCall, endAudioCall } from '../../../../../../../redux/timer/timerActions';
import { newSocket as socket } from 'src/pages/common/HOC/SocketWrapper';
import { Zoom } from '@mui/material';

export const PatientVoiceCall = ({ reportId }) => {
  const patient = useSelector((state) => state?.patient?.patientData);
  const { auth, user: { _id: userID } } = useSelector((state) => state?.user)
  const patientId = patient?.data?._id
  let interactiveTalk = patient?.data?.interactiveCommunication
  const patientNumber = useSelector((state) => state?.patient?.patientData?.data?.phoneNumber);
  const audioCallStatus = useSelector((state) => state?.timer?.audioCall);
  const [muted, setMuted] = useState(false);
  const [onPhone, setOnPhone] = useState(false);
  const [ready, setReady] = useState(false);
  const [latestPatientPhoneNumber, setLatestPatientPhoneNumber] = useState(``);
  const [audioSocketData, setAudioSocketData] = useState({});
  const [userIdBe, setuserIdBe] = useState(false)
  let enter = true;
  const dispatchRedux = useDispatch();

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = ``;
  };
  useEffect(() => {
    if (audioCallStatus) {
      window.addEventListener(`beforeunload`, alertUser);
    }

    if (patientId) {
      getRequest(`${PATIENT_TWILIO_VOICE_CALL_TOKEN_VERIFY_URL}${patientId}`)
        .then((res) => {
          let encryptedUserId = res.data.token.slice(-24);
          const getLastFourWords = () => {
            let arr = []
            for (let i = 1; i < 5; i++) {
              arr.push(encryptedUserId.charAt(encryptedUserId.length - i))
            }
            return arr.reverse().join(``)

          }
          const convertAsciiChar = (words) => {

            let arr = []
            for (let i = 0; i < 4; i++) {
              let char = String.fromCharCode(words.charCodeAt(i) - 1)
              arr.push(char)

            }
            return arr.join(``)
          }
          let lastfourWords = getLastFourWords()
          let convertedWords = convertAsciiChar(lastfourWords)
          let finalencryptedUserId = `${encryptedUserId.slice(0, encryptedUserId.length - 4)}${convertedWords}`
          if (finalencryptedUserId === userID) {
            let idRemovedToken = res?.data?.token.slice(0, res.data?.token.length - 24)
            setuserIdBe(true)
            Device.setup(idRemovedToken);
            setLatestPatientPhoneNumber(res.data?.patientPhoneNumber);
            const deviceStatus = Device.status();
            //  according to new logics
            if (deviceStatus === `ready`) {
              setReady(true);
            }
          }

        })
        .catch(() => {

        });

      Device.disconnect(function () {
        dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
        setOnPhone(false);
        dispatchRedux(endAudioCall());
        Device.disconnectAll();
      });

      Device.ready(function () {
        setReady(true);
      });
    }
    return () => {
      window.removeEventListener(`beforeunload`, alertUser);
    };
  }, [patientId, auth, audioCallStatus, dispatchRedux]);

  useEffect(() => {
    socket.on(`audioCallLogs`, (data) => {
      if (interactiveTalk) {
        // not someThing Special.
      } else {
        setAudioSocketData(data);
      }
    });
    return () => {
      socket.off(`audioCallLogs`);
    };
  }, []);

  const handleToggleMute = () => {
    let muteTemp = !muted;

    setMuted(muteTemp);
    Device.activeConnection().mute(muteTemp);
  };

  // Make an outbound call with the current number,
  // or hang up the current call

  const handleToggleCall = async () => {
    let isReady = await ready;
    if (isReady && userIdBe) {
      if (!onPhone && !audioCallStatus && ready) {
        // STOP PATIENT TIME, BEFORE CALLING

        dispatchRedux(startAudioCall());
        setMuted(false);
        setOnPhone(true);
        Device.connect({
          patientId,
          number: patientNumber,
          token: auth,
          userId: userID,
        });
      } else {
        // hang up call in progress
        dispatchRedux(endAudioCall());
        Device.disconnectAll();

        setOnPhone(false);
      }
    } else {
      dispatchRedux(endAudioCall());
      Device.disconnectAll();

      setOnPhone(false);
      const deviceStatus = Device.status();
      //  according to new logics
      if (deviceStatus === `ready`) {
        setReady(true);
      }
      return false;
    }
  };

  return (
    <Zoom in={enter} mountOnEnter unmountOnExit>
      <div
        // className="p-3"
        style={{
          backgroundColor: `white`,
          flex: 1,
          verticalAlign: `middle`,
          height: `100%`,
        }}
      >
        {onPhone || audioCallStatus ? (
          <>
            {/* Still facing calling null issue */}
            <h5 className="text-center pt-3 mb-3">{`Calling ...`}</h5>
            <div className="d-flex justify-content-center" style={{ height: `15%` }}>
              <button style={{ outline: `none` }} onClick={handleToggleMute}>
                <i
                  className="fa fa-microphone-slash pt-2 pb-2 pl-4 pr-4"
                  style={
                    muted
                      ? {
                        fontSize: 28,
                        color: `black`,
                        borderRadius: `32px`,
                      }
                      : {
                        fontSize: 28,
                        color: `rgb(230, 230, 230)`,
                        borderRadius: `32px`,
                      }
                  }
                  aria-hidden="true"
                ></i>
              </button>
              <button style={{ outline: `none` }} onClick={handleToggleCall}>
                <i
                  className="fa fa-phone pt-2 pb-2 pl-4 pr-4 ml-3"
                  style={{
                    fontSize: 28,
                    backgroundColor: `red`,
                    color: `white`,
                    borderRadius: `32px`,
                  }}
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </>
        ) : (
          <TelemedicineDialer
            patientNumber={latestPatientPhoneNumber ? latestPatientPhoneNumber : patientNumber}
            readyFlag={ready}
            callHandler={handleToggleCall}
          />
        )}
      </div>
    </Zoom>
  );
}







import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Ticketing } from '../pages/common/components/wrapper/components/help/ticketing/Ticketing';
import { Dashboard } from '../pages/home/dashboard/commonDashboard/dashboard';
import { AdministrationUser } from '../pages/home/modules/administration/user/user';
import { Faqs } from '../pages/common/components/wrapper/components/help/faqs/Faqs';
import { Resources } from '../pages/common/components/wrapper/components/help/resources/resources';
import { SettingsSystemAdmin } from '../pages/home/modules/settings/settings';
import { SystemAdminCredentials } from 'src/pages/home/modules/reactHealthCredentials/forSystemAdminCredentials.jsx';
import { AnalyticsSystemAdmin } from '../pages/home/modules/settings/Analytics/Analytics';
import { ListAllPracticeWithCount } from 'src/pages/home/dashboard/commonDashboard/components/Systemadmin/ListAllPracticeWithCount';
import { SmartPhrase } from '../pages/home/modules/administration/smartPhrases/smartPhrases';
import { ListView } from 'src/pages/common/components/listview/ListView';
import { AdministrationPayment } from 'src/pages/home/modules/administration/payment';


export const SystemAdminRouter = () => {
  return (
    <Switch>
      <Route exact path="/settings">
        <SettingsSystemAdmin />
      </Route>
      <Route exact path="/credentials">
        <SystemAdminCredentials />
      </Route>
      <Route exact path="/admin/payments">
        <AdministrationPayment />
      </Route>
      <Route exact path="/list/view/:id?">
        <ListView />
      </Route>
      <Route exact path="/ticketing">
        <Ticketing />
      </Route>
      <Route exact path="/analytics">
        <AnalyticsSystemAdmin />
      </Route>
      <Route exact path="/SystemAdmin/Group/Practices">
        <ListAllPracticeWithCount />
      </Route>
      <Route exact path="/systemAdmin/administration/user">
        <AdministrationUser />
      </Route>
      <Route exact path="/faqs">
        <Faqs />
      </Route>
      <Route exact path="/resources">
        <Resources />
      </Route>
      <Route exact path="/smart/phrases">
        <SmartPhrase />
      </Route>
      <Route path="/">
        <Redirect to="/" />
        <Dashboard />
      </Route>
    </Switch>
  );
}


import React from 'react';

export const Bootspinner = (props) => {
  return (
    <div className={`spinner-border text-center ${props.type}`} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  );
}




import { useSelector } from 'react-redux'

export const useUserConditionalRendering = ({ superAdminAndProvider,
    superAdminAndClinicalStaff,
    superAdmin,
    provider,
    clinicalStaff,
    transporationAdmin,
    systemAdmin,
    groupAdmin }) => {
    const { user: { user: { userRole } } } = useSelector((state) => state)
    let renderContent = {
        superAdminAndProvider: superAdminAndProvider ? superAdminAndProvider : null,
        superAdminAndClinicalStaff: superAdminAndClinicalStaff ? superAdminAndClinicalStaff : null,
        superAdmin: superAdmin ? superAdmin : null,
        provider: provider ? provider : null,
        clinicalStaff: clinicalStaff ? clinicalStaff : null,
        transporationAdmin: transporationAdmin ? transporationAdmin : null,
        systemAdmin: systemAdmin ? systemAdmin : null,
        groupAdmin: groupAdmin ? groupAdmin : null
    }
    return renderContent[userRole]
}
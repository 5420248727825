import React from 'react';
import { Fade, IconButton, } from '@mui/material';
import { capitalizeFirstLetter, returnNull, handleAHI, REACT_HEALTH } from 'src/utils';
import ContactMailRoundedIcon from '@mui/icons-material/ContactMailRounded';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { PressureDecider } from '../components/PressureDecider';
import { useDispatch } from 'react-redux';
import { RoundDot } from 'src/pages/common/components/RoundDot/RoundDot';
import css from './tableRows.module.css'
import { useSelector } from 'react-redux';

export const TableRows = ({ moveToPatientModule, patientOverViewMappingData, index }) => {
  // The ColorCodedAHI function is store in variable.
  const { user: { user } } = useSelector((state) => state)
  let showColorCodedAHI = handleAHI(patientOverViewMappingData?.AHI)
  const dispatch = useDispatch()

  return (
    <Fade key={index} mountOnEnter unmountOnExit in={patientOverViewMappingData ? true : false} style={{ transitionDelay: patientOverViewMappingData ? `${index}00ms` : `0ms` }}>
      <tr key={index} style={{ textAlign: `center`, verticalAlign: `top` }}>
        {!user?.isTransportationAdmin ? (
          <td
            className={css.verticallyCenter}
            style={{
              cursor: `pointer`,

            }}
            onClick={() => moveToPatientModule()}
          >
            <ToolTipProvider
              toolTipProps={{
                title: `Click to get details`,
                placement: `top`,
                arrow: true,
              }}
              element={
                <span> {
                  capitalizeFirstLetter(patientOverViewMappingData?.lastName)
                }
                </span>
              }
            />
          </td>
        ) : (
          <td
            className={css.verticallyCenter}
          >
            {capitalizeFirstLetter(patientOverViewMappingData?.lastName)}
          </td>
        )}
        {!user?.isTransportationAdmin ? (
          <td
            className={css.verticallyCenter}
            style={{
              cursor: `pointer`,
            }}
            onClick={() => moveToPatientModule()}
          >
            <ToolTipProvider
              toolTipProps={{
                title: `Click to get details`,
                placement: `top`,
                arrow: true,
              }}
              element={
                <span> {
                  capitalizeFirstLetter(patientOverViewMappingData?.firstName)
                }
                </span>
              }
            />
          </td>
        ) : (
          <td
            className={css.verticallyCenter}
          >
            {capitalizeFirstLetter(patientOverViewMappingData?.firstName)}
          </td>
        )}

        <td
          className={css.verticallyCenter}>
          {returnNull({
            key: patientOverViewMappingData?.daysDeviceSetup,
            successRetrun: patientOverViewMappingData?.daysDeviceSetup,
            faliure: `0`,
          })}
        </td>

        <td
          className={css.verticallyCenter}>
          {returnNull({
            key: patientOverViewMappingData?.deviceManufacturer,
            successRetrun: patientOverViewMappingData?.deviceManufacturer,
            faliure: `-`,
          })}
        </td>
        <td
          className={css.verticallyCenter}>
          {patientOverViewMappingData?.device?.manufacturer === `respironics` || patientOverViewMappingData?.device?.manufacturer === REACT_HEALTH
            ? returnNull({
              key: patientOverViewMappingData?.device?.type,
              successRetrun: patientOverViewMappingData?.device?.type,
              faliure: `-`,
            })
            : returnNull({
              key: patientOverViewMappingData?.device?.detail,
              successRetrun: patientOverViewMappingData?.device?.detail,
              faliure: `-`,
            })}
        </td>
        <td
          className={css.verticallyCenter}
        >
          {capitalizeFirstLetter(patientOverViewMappingData?.status)}
        </td>
        <td
          className={css.verticallyCenter}>{patientOverViewMappingData?.adherenceAlerts?.last3Days || patientOverViewMappingData?.adherenceAlerts?.last30Days ? <RoundDot style={{
            width: `7px`, height: `7px`, backgroundColor: `red`
          }} /> : `-`}</td>
        <td
          className={css.verticallyCenter} > {patientOverViewMappingData?.adherencePercentage?.last30Days ? `${patientOverViewMappingData?.adherencePercentage?.last30Days}% ` : `0%`}</td>
        <td
          className={css.verticallyCenter} >{patientOverViewMappingData?.adherencePercentage?.prior3Days ? `${patientOverViewMappingData?.adherencePercentage?.prior3Days}% ` : `0%`}</td>
        <td
          className={css.verticallyCenter} >{patientOverViewMappingData?.adherencePercentage?.last3Days ? `${patientOverViewMappingData?.adherencePercentage?.last3Days}% ` : `0%`}</td>
        <td
          className={css.verticallyCenter} >{patientOverViewMappingData?.adherencePercentage?.lastDay ? `${patientOverViewMappingData?.adherencePercentage?.lastDay}%` : `0%`}</td>
        <td
          className={css.verticallyCenter}>
          <PressureDecider deviceMenuFacturer={patientOverViewMappingData?.device?.manufacturer} pressure={patientOverViewMappingData?.pressure} />
        </td>

        <td
          className={css.verticallyCenter}>
          <ToolTipProvider
            toolTipProps={{
              title: returnNull({
                key: patientOverViewMappingData?.AHI !== `-` ? true : false,
                successRetrun: `Events/hour`,
                faliure: `-`,
              }),
              placement: `top`,
              arrow: true,
            }}
            element={
              <span style={{ color: showColorCodedAHI }}>
                {patientOverViewMappingData?.AHI}
              </span>
            }
          />
        </td>
        <td
          className={css.verticallyCenter}>
          {
            <ToolTipProvider
              toolTipProps={{
                title: returnNull({
                  key: patientOverViewMappingData?.leak !== `-` ? true : false,
                  successRetrun: `L/min`,
                  faliure: `-`,
                }),
                placement: `top`,
                arrow: true,
              }}
              element={
                <div>{patientOverViewMappingData?.leak}</div>
              }
            />
          }
        </td>
        <td
          className={css.verticallyCenter}>
          <ToolTipProvider
            toolTipProps={{
              arrow: true,
              placement: `top`,
              title: `Want to  ${user?.isTransportationAdmin ? `review  ` : `communicate`} with the patient click me to open!`
            }} element={<IconButton onClick={() => dispatch({
              type: `OPENINFOMODAL`, payLoad: {
                rootKeys: {
                  open: true,
                  startDate: patientOverViewMappingData?.startDate,
                  endDate: patientOverViewMappingData?.endDate,
                  patientId: patientOverViewMappingData?._id,
                  patientNumber: patientOverViewMappingData?.phoneNumber,
                },
                patientData: {
                  ...patientOverViewMappingData, adherencePercentage: {
                    last30Days: `${patientOverViewMappingData?.adherencePercentage?.last30Days ? patientOverViewMappingData?.adherencePercentage?.last30Days : 0}%`,
                    prior3Days: `${patientOverViewMappingData?.adherencePercentage?.prior3Days ? patientOverViewMappingData?.adherencePercentage?.prior3Days : 0}%`,
                    last3Days: `${patientOverViewMappingData?.adherencePercentage?.last3Days ? patientOverViewMappingData?.adherencePercentage?.last3Days : 0}%`,
                    lastDay: `${patientOverViewMappingData?.adherencePercentage?.lastDay ? patientOverViewMappingData?.adherencePercentage?.lastDay : 0}%`,
                  },
                }
              }
            })} sx={{ color: `#1699C5` }}>
              <ContactMailRoundedIcon />
            </IconButton>} />

        </td>
      </tr>
    </Fade >
  );
}

import moment from 'moment';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postRequest, REFRESH_AUTH_TOKEN } from 'src/crud/crud';
import { setAuth } from 'src/redux/action/authTokenHandler';
import { setUserHandler } from 'src/redux/action/setUserHandler';
import { store } from 'src/redux/store';
import { covertEpochTime } from 'src/utils';


let timer;


export const JwtExpWrapper = ({ children }) => {
  const { user: { user } } = useSelector((state) => state)
  const { exp } = user || false
  const dispatch = useDispatch()

  useEffect(() => {
    if (exp) {
      let date = moment(covertEpochTime(exp, `YYYY-MM-DD HH:mm:ss`)).subtract(1, `minute`)
      const calculateDifference = (date) => {
        const duration = moment.duration(moment(date).diff(moment(new Date())));
        const durationInSeconds = duration.asSeconds();
        return durationInSeconds * 1000
      }
      let milliseconds = calculateDifference(date)
      const { user: { user } } = store.getState()
      const { refreshToken } = user
      timer = setTimeout(() => {
        postRequest(REFRESH_AUTH_TOKEN, {
          refreshToken: refreshToken
        }).then((res) => {
          const { data = {
            freshToken: ``,
            resExp: 0
          } } = res
          const { token: freshToken, exp: resExp } = data
          if (freshToken) {
            dispatch(setAuth(freshToken))
            if (resExp && !moment(covertEpochTime(exp, `YYYY-MM-DD HH:mm:ss`)).isSame(moment(covertEpochTime(resExp, `YYYY-MM-DD HH:mm:ss`)))) dispatch(setUserHandler({ ...user, exp: resExp }))
          }



        }).catch()
      }, milliseconds)
    }
  }, [exp])

  useEffect(() => {
    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <>{children}</>
  )
}
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Collapse, Fade, IconButton } from '@mui/material';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import css from './PatientOver.module.css';
import SearchIcon from '@mui/icons-material/Search';
import { useFilters } from './UseFilters/useFilters';
import { TableRows } from './TableRows/TableRows';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import { ReportModal } from './ReportModal/ReportModal';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import { useSelector } from 'react-redux';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { useHistory } from 'react-router-dom';
import { momentWrapper } from 'src/momentWrapper';
import { patientOverviewDownloadCsv } from 'src/utils';
import DownloadIcon from '@mui/icons-material/Download';
import { GET_ALL_PATIENTS_CSV, getRequest } from 'src/crud/crud';
import { BootstrapSpinner } from 'src/pages/common/components/Bootstrapspinner/BootstrapSpinner';

export const sortIconHandler = (pagination, field) => {
  return (
    <>
      {pagination?.sortField === field && pagination?.sortOrder === `ASC` && <i className="fa fa-sort-asc ml-2" />}
      {pagination?.sortField === field && pagination?.sortOrder === `DSC` && <i className="fa fa-sort-desc ml-2" />}
      {pagination?.sortOrder === `` || pagination?.sortField !== field ? <i className="fa fa-sort ml-2" /> : null}
    </>
  );
};

export const sortKeyGiver = (pagination, key) => {
  let { sortOrder: order, sortField: field } = pagination;
  let giver = ``;
  if ((order === `ASC` && key === field) || (order === `ASC` && key !== field)) {
    giver = `DSC`;
  } else if (order === `DSC` && key === field) {
    giver = `ASC`;
  } else if ((order === `DSC` && key === field) || (order === `DSC` && key !== field)) {
    giver = `DSC`;
  }
  return giver;
};
export const PatientOverView = () => {
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsPerPage: 25,
    totalRows: 0,
    totalPage: 0,
    sortOrder: `ASC`,
    sortField: `lastName`,
  });
  const [fetchingcsvData, setfetchingcsvData] = useState({ loading: false, error: false })
  const [openFilters, setOpenFilters] = useState(false);
  const alertClick = useRef(0)
  const [patientOverViewData, setPatientOverViewData] = useState([]);
  const daysOnDeviceClick = useRef(0)
  const { readyToSign: { open }, user: { user } } = useSelector((state) => state);
  let { data, filters, defaultButton } = useFilters(pagination, setPagination, alertClick, daysOnDeviceClick);
  const { loading: loadingCsv, error: errorCsv } = fetchingcsvData
  const fetchingcsvDataHandler = (key, value, isWhole) => {
    setfetchingcsvData(isWhole ? value : (p) => ({ ...p, [key]: value }))
  }

  useEffect(() => {
    if (typeof data === `object`) {
      setPatientOverViewData(data);
    }
  }, [data]);

  const matches = useMediaQuery(`(max-width:768px)`);
  const matchesMedium = useMediaQuery(`(max-width:1050px)`);
  const matchesLarge = useMediaQuery(`(max-width:1460px)`);
  const history = useHistory();
  const sortHandler = (key, order) => {
    setPagination((p) => ({
      ...p,
      sortField: key,
      sortOrder: order,
    }));
  };

  const moveToPatientModule = (patientId) => {
    history.push({ pathname: `/patient`, patientId });
  };

  const handleDefaultSorting = (key) => {
    const { current: alertClickCount } = alertClick
    const { current: daysOnDeviceCount } = daysOnDeviceClick
    if (key === `daysDeviceSetup` ? daysOnDeviceCount === 0 : alertClickCount === 0) sortHandler(key, `ASC`)
    else sortHandler(key, sortKeyGiver(pagination, key))
    key === `daysDeviceSetup` ? daysOnDeviceClick.current = daysOnDeviceClick.current + 1 : alertClick.current = alertClick.current + 1
  }

  let dates = {
    endDate: momentWrapper(new Date()).endOf(`day`).subtract(1, `days`),
    startDate: momentWrapper(new Date()).startOf(`day`).subtract(30, `days`),
  };
  const { endDate, startDate } = dates;
  return (
    <div className="p-3">
      {open && <ReportModal />}
      <div className={`${css.table} card`}>
        <Box>
          <Collapse in={openFilters} mountOnEnter unmountOnExit>
            {filters}
          </Collapse>
        </Box>
        <Box sx={{ width: `100%`, textAlign: `end` }}>
          <Box sx={{ display: `inline-block`, p: 1.2 }}>
            {user?.isProvider || user?.isClinicalStaff ? <ToolTipProvider
              toolTipProps={{
                title: loadingCsv ? `Wait we are preparing the file for you!` : `Download data in Csv File!`,
                arrow: true,
                placement: `top`,
              }}
              element={
                <Button
                  size="large"
                  {...(loadingCsv ? {} : { endIcon: <DownloadIcon /> })}
                  variant="contained"
                  sx={{ backgroundColor: errorCsv ? `red` : `#1699c5`, textTransform: `none`, fontSize: `large` }}

                  onClick={() => {
                    if (!loadingCsv) {
                      if (errorCsv) fetchingcsvDataHandler(`error`, false, false)
                      fetchingcsvDataHandler(`loading`, true, false)
                      const endDate = momentWrapper(new Date()).subtract(1, `days`).set({ hours: 23, minutes: 59, seconds: 59 }).toISOString();
                      const startDate = momentWrapper(new Date(endDate)).subtract(29, `days`).set({ hours: 0, minutes: 0, seconds: 0 }).toISOString();
                      let query = `${GET_ALL_PATIENTS_CSV}?endDate=${JSON.stringify(endDate)}&startDate=${JSON.stringify(startDate)}`
                      getRequest(query).then(({ data: { data } = { data: {} } }) => {
                        fetchingcsvDataHandler(`loading`, false, false)
                        patientOverviewDownloadCsv(data, `PatientReviewPeriod:${momentWrapper(startDate).format(`MM/DD/YYYY`) + ` - ` + momentWrapper(endDate).format(`MM/DD/YYYY`)}.csv`)
                      }).catch(() => {
                        fetchingcsvDataHandler(`loading`, { loading: false, error: true }, true)
                      })
                    }
                  }}
                >
                  {loadingCsv && <BootstrapSpinner spinnerProps={{
                    className: `spinner-border spinner-border-sm text-light`
                  }} />}
                  {errorCsv && <GppMaybeIcon sx={{ color: `white` }} />}
                  {!loadingCsv &&
                    !errorCsv &&
                    `Review Period:
               ${momentWrapper(startDate).format(`MM/DD/YYYY`) + ` - ` + momentWrapper(endDate).format(`MM/DD/YYYY`)}`}
                </Button>
              }
            /> :
              <>
                Review Period:
                <Box
                  sx={{
                    padding: `8px 14px`,
                    marginLeft: `5px`,
                    backgroundColor: `white`,
                    display: `inline-block`,
                    borderRadius: `7px`,
                    border: `1px solid #2299c5`,
                  }}
                >
                  {momentWrapper(startDate).format(`MM/DD/YYYY`) + ` - ` + momentWrapper(endDate).format(`MM/DD/YYYY`)}
                </Box>
              </>}

          </Box>
          <Box sx={{ display: `inline-block` }}>{defaultButton}</Box>
          <Fade in={!openFilters || openFilters} mountOnEnter unmountOnExit>
            <Box sx={{ display: `inline-block` }}>
              <ToolTipProvider
                toolTipProps={{
                  title: `${openFilters ? `Close` : `Search`}`,
                  placement: `top`,
                  arrow: true,
                }}
                element={
                  <IconButton sx={{ p: 2 }} onClick={() => setOpenFilters((p) => !p)}>
                    {openFilters ? (
                      <CloseSharpIcon sx={{ color: `red`, fontSize: `34px` }} />
                    ) : (
                      <SearchIcon sx={{ color: `rgb(22, 153, 197)`, fontSize: `34px` }} />
                    )}
                  </IconButton>
                }
              />
            </Box>
          </Fade>
        </Box>
        <div
          className="table-responsive"
          style={{ width: matches || matchesMedium ? `200% !important` : matchesLarge ? `150% !important` : `100% !important` }}
        >
          <table
            style={{ width: matches || matchesMedium ? `200%` : matchesLarge ? `150%` : `100%` }}
            className={`table  table-lg table-borderless table-striped ${css.tableInner}`}
          >
            <thead>
              <tr style={{ textAlign: `center`, verticalAlign: `middle` }}>
                <td className={css.tdLess} onClick={() => sortHandler(`lastName`, sortKeyGiver(pagination, `lastName`))}>
                  Last Name
                  {sortIconHandler(pagination, `lastName`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`firstName`, sortKeyGiver(pagination, `firstName`))}>
                  First Name
                  {sortIconHandler(pagination, `firstName`)}
                </td>
                <td className={css.tdLess} onClick={() => handleDefaultSorting(`daysDeviceSetup`)}>
                  Days On Device
                  {sortIconHandler(pagination, `daysDeviceSetup`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`manufacturer`, sortKeyGiver(pagination, `manufacturer`))}>
                  MFG
                  {sortIconHandler(pagination, `manufacturer`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`mode`, sortKeyGiver(pagination, `mode`))}>
                  Device Type
                  {sortIconHandler(pagination, `mode`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`status`, sortKeyGiver(pagination, `status`))}>
                  Status
                  {sortIconHandler(pagination, `status`)}
                </td>
                <td className={css.tdLess} onClick={() => handleDefaultSorting(`isAlertOff`)}>
                  Alert
                  {sortIconHandler(pagination, `isAlertOff`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`last30Days`, sortKeyGiver(pagination, `last30Days`))}>
                  30 Day
                  {sortIconHandler(pagination, `last30Days`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`prior3Days`, sortKeyGiver(pagination, `prior3Days`))}>
                  Prior 3 Day
                  {sortIconHandler(pagination, `prior3Days`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`last3Days`, sortKeyGiver(pagination, `last3Days`))}>
                  Last 3 Day
                  {sortIconHandler(pagination, `last3Days`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`lastDay`, sortKeyGiver(pagination, `lastDay`))}>
                  Last Day
                  {sortIconHandler(pagination, `lastDay`)}
                </td>
                <td className={css.tdModerate} onClick={() => sortHandler(`pressureSortingData`, sortKeyGiver(pagination, `pressureSortingData`))}>
                  Pressure 90th/95th %{sortIconHandler(pagination, `pressureSortingData`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`AHI`, sortKeyGiver(pagination, `AHI`))}>
                  AHI
                  {sortIconHandler(pagination, `AHI`)}
                </td>
                <td className={css.tdLess} onClick={() => sortHandler(`leak`, sortKeyGiver(pagination, `leak`))}>
                  Leak
                  {sortIconHandler(pagination, `leak`)}
                </td>
                <td className={css.td}>
                  Communication
                </td>
              </tr>
            </thead>
            <tbody>
              {data === `loading` && <PuffSpinner />}

              {data === `error` && (
                <tr>
                  <td colSpan={14} className={css.fullWidth}>
                    <GppMaybeIcon sx={{ color: `red` }} />
                  </td>
                </tr>
              )}

              {patientOverViewData?.length > 0 &&
                patientOverViewData?.map((patientOverViewMappingData, index) => {
                  return (
                    <TableRows
                      patientOverViewMappingData={patientOverViewMappingData}
                      index={index}
                      moveToPatientModule={() => moveToPatientModule(patientOverViewMappingData?._id)}
                    />
                  );
                })}

              {typeof data === `object` && data?.length === 0 && (
                <tr>
                  <td className={css.fullWidth} colSpan={14}>
                    No Records Found
                  </td>
                  {` `}
                </tr>
              )}
            </tbody>
            <TableFooter pagination={pagination} setPagination={setPagination} isPatientView={true} data={patientOverViewData} />
          </table>
        </div>
      </div>
    </div >
  );
};
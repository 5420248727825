import React, { useState, useEffect } from 'react';
import { getRequest, TOTAL_STATS_URL } from '../../../../../../crud/crud';
import { useDispatch, useSelector } from 'react-redux';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import { Box, Container, Grid, Grow, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CircularProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import { moneyFormatter } from 'src/utils';
import { ConfirmationWrapper } from 'src/pages/common/HOC/ConfirmationWrapper';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { EditGroupLogo } from 'src/pages/home/modules/practices/PracticesGroup/EditGroupLogo';
import logo from 'src/assets/monitair-logo.svg';
import { getPracticeLogoPath } from 'src/redux/PracticeGroups/Actions';

const Block = ({ children, loading }) => {
  return (
    <Box
      component={Paper}
      sx={{
        height: `130px`,
        width: { xs: `100%`, sm: `90%`, md: `70%`, lg: `50%`, xl: `50%` },
        backgroundColor: `${loading.error ? `#ff0c0cbd` : `white`}`,
        boxShadow: `0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)`,
        display: `flex`,
        justifyContent: `center`,
      }}
    >
      {children}
    </Box>
  );
};

const GridItem = ({ children }) => {
  return (
    <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
      {children}
    </Grid>
  );
};

export const BlockStatsWidget = ({ isGroupView }) => {
  const { addNewPractice } = useSelector((state) => state?.resmed);
  const [data, setData] = useState({
    PatientsCount: 0,
    PracticesCount: 0,
    ProvidersCount: 0,
    OpenTicketsCout: 0,
  });
  const dispatch = useDispatch();
  const [loading, setloading] = useState({ loading: false, error: false });
  const theme = useTheme();
  const matchesUp = useMediaQuery(theme.breakpoints.up(`lg`));
  const counts = useSelector((state) => state?.practiceGroup?.counts);
  const refreshGroups = useSelector((state) => state?.practiceGroup?.isRegistered);
  const logoPath = useSelector((state) => state?.practiceGroup?.PracticeLogoPath);

  const [groupLogoConfirmation, setGroupLogoConfirmation] = useState({
    open: false,
    renderEDitComp: false,
    showEditBtn: false,
  });

  const { open, renderEditComp } = groupLogoConfirmation;

  useEffect(() => {
    if (!isGroupView || refreshGroups) {
      let queryParams = `/?pageName=all`;
      setloading({ loading: true, error: false });
      getRequest(TOTAL_STATS_URL + queryParams)
        .then((res) => {
          setloading({ loading: false, error: false });

          setData(res.data);
        })
        .catch(() => {
          setloading({ loading: false, error: true });
        });
    }
  }, [addNewPractice, isGroupView]);

  useEffect(() => {
    return () => {
      setGroupLogoConfirmation({
        open: false,
        renderEDitComp: false,
      });
    };
  }, []);

  let iconColor = `${loading.error ? `white` : `#009cc6`}`;
  let iconGroups = <LibraryBooksIcon sx={{ color: iconColor }} />;
  let iconHospital = <LocalHospitalIcon sx={{ color: iconColor }} />;
  let iconPatients = <AccessibilityIcon sx={{ color: iconColor }} />;
  let iconTickets = <ConfirmationNumberIcon sx={{ color: iconColor }} />;
  let iconIncome = <RequestQuoteIcon sx={{ color: iconColor }} />;
  let errorIcon = <ErrorIcon sx={{ color: iconColor }} fontSize="small" />;

  return (
    <Box sx={{ px: 5, width: `100%` }}>
      <Container>
        {loading.loading ? (
          <Grid container columnSpacing={2} rowSpacing={4} sx={{ mt: 3, mb: 4, ...(matchesUp && { ml: 5 }) }}>
            <GridItem>
              <Block loading={loading}>
                <Box sx={{ textAlign: `center`, mt: 1 }}>
                  {iconGroups}
                  <h6>{!isGroupView ? <span>Groups</span> : <span>Practices</span>}</h6>
                  <CircularProgress size={30} thickness={6} />
                </Box>
              </Block>
            </GridItem>
            {!isGroupView && (
              <GridItem>
                <Block loading={loading}>
                  <Box sx={{ textAlign: `center`, py: 2 }}>
                    {iconHospital}
                    <h6> Practices</h6>
                    <CircularProgress size={30} thickness={6} />
                  </Box>
                </Block>
              </GridItem>
            )}

            <GridItem>
              <Block loading={loading}>
                <Box sx={{ textAlign: `center`, py: 2 }}>
                  {iconHospital}
                  <h6>Providers</h6>
                  <CircularProgress size={30} thickness={6} />
                </Box>
              </Block>
            </GridItem>
            <GridItem>
              <Block loading={loading}>
                <Box sx={{ textAlign: `center`, py: 2 }}>
                  {iconPatients}
                  <h6>Patients</h6>
                  <CircularProgress size={30} thickness={6} />
                </Box>
              </Block>
            </GridItem>
            <GridItem>
              <Block loading={loading}>
                <Box sx={{ textAlign: `center`, py: 2 }}>
                  {iconTickets}
                  <h6>Open Tickets</h6>
                  <CircularProgress size={30} thickness={6} />
                </Box>
              </Block>
            </GridItem>
            {isGroupView && (
              <GridItem>
                <Block loading={loading}>
                  <Box sx={{ textAlign: `center`, py: 2 }}>
                    {iconIncome}
                    <h6>Net Income</h6>
                    <CircularProgress size={30} thickness={6} />
                  </Box>
                </Block>
              </GridItem>
            )}
          </Grid>
        ) : (
          <Grid container columnSpacing={2} rowSpacing={4} sx={{ mt: 3, mb: 4, ...(matchesUp && { ml: 5 }) }}>
            <GridItem>
              <Grow in={!loading.loading}>
                <div>
                  <Block loading={loading}>
                    <Box sx={{ textAlign: `center`, py: 2 }}>
                      {iconGroups}
                      <h6>
                        {!isGroupView ? (
                          <span style={{ color: `${loading.error ? `white` : `#009cc6`}` }}>Groups</span>
                        ) : (
                          <span style={{ color: `${loading.error ? `white` : `#009cc6`}` }}>Practices</span>
                        )}
                      </h6>
                      {loading.error ? errorIcon : <h3>{!isGroupView ? <span>{data.groupsCount}</span> : <span>{counts?.activePractices}</span>}</h3>}
                    </Box>
                  </Block>
                </div>
              </Grow>
            </GridItem>

            {!isGroupView && (
              <GridItem>
                <Grow in={!loading.loading} {...(!loading.loading ? { timeout: 300 } : {})}>
                  <div>
                    <Block loading={loading}>
                      <Box
                        sx={{
                          textAlign: `center`,
                          py: 2,
                        }}
                      >
                        {iconHospital}
                        <h6 style={{ color: `${loading.error ? `white` : `#009cc6`}` }}>Practices</h6>
                        {loading.error ? (
                          errorIcon
                        ) : (
                          <h3>{!isGroupView ? <span>{data?.practicesCount}</span> : <span>{counts?.activeProviders}</span>}</h3>
                        )}
                      </Box>
                    </Block>
                  </div>
                </Grow>
              </GridItem>
            )}

            <GridItem>
              <Grow in={!loading.loading} {...(!loading.loading ? { timeout: 300 } : {})}>
                <div>
                  <Block loading={loading}>
                    <Box
                      sx={{
                        textAlign: `center`,
                        py: 2,
                      }}
                    >
                      {iconHospital}
                      <h6 style={{ color: `${loading.error ? `white` : `#009cc6`}` }}>Providers</h6>
                      {loading.error ? (
                        errorIcon
                      ) : (
                        <h3>{!isGroupView ? <span>{data?.providersCount}</span> : <span>{counts?.activeProviders}</span>}</h3>
                      )}
                    </Box>
                  </Block>
                </div>
              </Grow>
            </GridItem>

            <GridItem>
              <Grow in={!loading.loading} {...(!loading.loading ? { timeout: 500 } : {})}>
                <div>
                  <Block loading={loading}>
                    <Box
                      sx={{
                        textAlign: `center`,
                        py: 2,
                      }}
                    >
                      {iconPatients}
                      <h6 style={{ color: `${loading.error ? `white` : `#009cc6`}` }}>Patients</h6>
                      {loading.error ? (
                        errorIcon
                      ) : (
                        <h3>{!isGroupView ? <span>{data?.patientsCount} </span> : <span>{counts?.activePatients}</span>}</h3>
                      )}
                    </Box>
                  </Block>
                </div>
              </Grow>
            </GridItem>

            <GridItem>
              <Grow in={!loading.loading} {...(!loading.loading ? { timeout: 700 } : {})}>
                <div>
                  <Block loading={loading}>
                    <Box
                      sx={{
                        textAlign: `center`,
                        py: 2,
                      }}
                    >
                      {iconTickets}

                      <h6 style={{ color: `${loading.error ? `white` : `#009cc6`}` }}>Open Tickets</h6>

                      {loading.error ? (
                        errorIcon
                      ) : (
                        <h3>{!isGroupView ? <span> {data.openTicketsCout}</span> : <span>{counts?.openedTickets}</span>}</h3>
                      )}
                    </Box>
                  </Block>
                </div>
              </Grow>
            </GridItem>
            {isGroupView && (
              <GridItem>
                <Grow in={!loading.loading} {...(!loading.loading ? { timeout: 700 } : {})}>
                  <div>
                    <Block loading={loading}>
                      <Box
                        sx={{
                          textAlign: `center`,
                          py: 2,
                        }}
                      >
                        {iconIncome}
                        <h6 style={{ color: `${loading.error ? `white` : `#009cc6`}` }}>Net Income</h6>
                        {loading.error ? (
                          errorIcon
                        ) : (
                          <h3>
                            {' '}
                            <span>{counts?.totalincomByGroup ? moneyFormatter(counts?.totalincomByGroup) : `-`}</span>
                          </h3>
                        )}
                      </Box>
                    </Block>
                  </div>
                </Grow>
              </GridItem>
            )}
            {isGroupView && (
              <GridItem>
                <Grow in={!loading.loading} {...(!loading.loading ? { timeout: 700 } : {})}>
                  <div>
                    <Block loading={loading}>
                      <ToolTipProvider
                        toolTipProps={{
                          arrow: true,
                          placement: `top`,
                          title: `Edit Logo`,
                        }}
                        element={
                          <Box
                            sx={{
                              textAlign: `center`,
                              py: 2,
                              cursor: 'pointer',
                            }}
                            onClick={() => setGroupLogoConfirmation((p) => ({ ...p, open: !p[`open`] }))}
                          >
                            <img src={logoPath ? logoPath : logo} alt="Logo" width={`100%`} style={{ cursor: 'pointer' }} />
                          </Box>
                        }
                      />
                      <ConfirmationWrapper
                        inverse={true}
                        open={open}
                        successFunc={() => setGroupLogoConfirmation((p) => ({ ...p, renderEditComp: !p[`renderEditComp`] }))}
                        cancelFunc={() => setGroupLogoConfirmation((p) => ({ renderEditComp: false, open: !p[`open`] }))}
                        successText={`Edit`}
                        cancelText={`Cancel`}
                        title={`Group Logo Confirmation`}
                        description={`Do You Really Want To Edit Group Logo`}
                        renderContent={
                          renderEditComp && (
                            <EditGroupLogo
                              perFormAction={(res) => {
                                const { path } = res.data;
                                dispatch(getPracticeLogoPath(path));
                                setGroupLogoConfirmation((p) => ({ renderEditComp: false, open: !p[`open`] }));
                              }}
                            />
                          )
                        }
                      ></ConfirmationWrapper>
                    </Block>
                  </div>
                </Grow>
              </GridItem>
            )}
          </Grid>
        )}
      </Container>
    </Box>
  );
};

import React from 'react';
import Skeleton from '@mui/material/Skeleton';

export const Skelton = ({ isGreater, isAdmin }) => {
  let dummyData = [
    {
      type: `text`,
    },
    {
      type: `text`,
    },
    {
      type: `text`,
    },
    {
      type: `text`,
    },
    {
      type: `text`,
    },
    {
      type: `text`,
    },
    {
      type: `text`,
    },

  ];
  return (
    <>
      {dummyData.map((e, index) => {
        return (
          <tr key={index + e}>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="rectangular" />
            </td>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="rectangular" />
            </td>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="rectangular" />
            </td>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="rectangular" />
            </td>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="rectangular" />
            </td>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="rectangular" />
            </td>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="rectangular" />
            </td>
            <td>
              <Skeleton sx={{ mt: 0.4 }} type="text" />
            </td>
            {isAdmin && <td>
              <Skeleton sx={{ mt: 0.4 }} type="text" />
            </td>}
            {isGreater && (
              <>
                <td>
                  <Skeleton sx={{ mt: 0.4 }} type="text" />
                </td>
                <td>
                  <Skeleton sx={{ mt: 0.4 }} type="text" />
                </td>
              </>

            )}
          </tr>
        );
      })}
    </>
  );
}

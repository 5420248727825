import { LIST_OF_TICKETS, postRequest } from 'src/crud/crud';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { SET_ALL_TICKETS, UPDATE_ALL_TICKETS, NOTIFY_ALL_TICKETS } from './ticketsCenterTypes';

export const setTotalTickets = (payload) => {
  return {
    type: SET_ALL_TICKETS,
    payload,
  };
};
export const notifyAllTickets = (payload) => {
  return {
    type: NOTIFY_ALL_TICKETS,
    payload,
  };
};

export const updateAllTickets = (payload) => {
  return {
    type: UPDATE_ALL_TICKETS,
    payload,
  };
};

export const getTicketsSysAdmin = (rowsPerPage, currentPage, payLoad) => {

  return (dispatch) => {
    dispatch({ type: `SET_LOADING`, payLoad: { loading: true, error: false } });
    postRequest(LIST_OF_TICKETS, {
      page: currentPage,
      recordsPerPage: rowsPerPage,
      ...(payLoad ? { ...payLoad } : {})
    })
      .then((res) => {
        dispatch({ type: `SET_LOADING`, payLoad: { loading: false, error: false } });
        dispatch({ type: `SET_DATA`, payLoad: res.data?.tickets });
        dispatch({ type: `SET_TOTAL_ROWS`, payLoad: res?.data?.pager?.totalRecords });
        if (Math.ceil(res?.data?.pager?.totalRecords / res?.data?.pager?.recordsPerPage)) {
          dispatch({ type: `SET_TOTAL_PAGE`, payLoad: Math.ceil(res?.data?.pager?.totalRecords / res?.data?.pager?.recordsPerPage) });
        } else {
          return null;
        }
      })
      .catch((err) => {
        dispatch({ type: `SET_LOADING`, payLoad: { loading: false, error: true } });
        if (err.response) {
          errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
        }
      });
  };
};

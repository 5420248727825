import React from 'react';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';

import Iframe from 'react-iframe';

export const ReportPdfViewer = ({ show, setShow, fileURL }) => {
  const handleClose = () => setShow(false);

  return (
    <div>
      <CustomizedDialogs noTitle={true} open={show} setOpen={handleClose} size="md" fullWidth={true} showCloseButton={true}>
        <div style={{ height: `80vh` }}>
          <Iframe
            url={fileURL}
            width="100%"
            height="100%"
            id="myId"
            style={{ border: `none` }}
            className="myClassname"
            display="initial"
            position="relative"
          />
        </div>
      </CustomizedDialogs>
    </div>
  );
}



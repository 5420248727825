import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserHandler } from 'src/redux/action/setUserHandler';
import { Asktwillio } from '../practices/components/addNewPractice/Asktwillio';
import { Paper } from '@mui/material';

export const PurchasePracticeNumberWithStripSubscription = () => {
    const {
        user: { user },
        practice: { _id },
    } = useSelector((state) => state);
    const { _practiceId = `` } = user;
    const dispatch = useDispatch();
    return (
        <>
            <Paper sx={{ pt: 1, pb: 3, pl: 2, pr: 2 }}>
                <Asktwillio
                    practiceId={_id || _practiceId}
                    onClose={(res) => {
                        dispatch(setUserHandler({ ...user, practiceNumber: res?.data?.practiceNumber }));
                    }}
                />
            </Paper>
        </>
    );
};
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Tooltip, MenuItem, Button, Zoom, Stack, Fade, Select, InputLabel, FormControl } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { AnalyticsSuperAdmin } from '../../../modules/analytics/analytics';
import { AskForAnalytics } from './Components/AskForAnalytics';
import { groupIdSaver, practiceIdSaver, viewGiver, providerIdSaver } from '../../../../../redux/systemadminAnalytics/Redux/Actions/Analytics.actions';
import { FILTER_PRACTICE_GROUP_URL, GET_ALL_GROUP_OPTIONS, getRequest } from 'src/crud/crud';
import LinearProgress from '@mui/material/LinearProgress';
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import PlayForWorkRoundedIcon from '@mui/icons-material/PlayForWorkRounded';
import css from './analytics.module.css';
import { capitalizeFirstLetter, dynamicObjCreator, returnNull } from 'src/utils';
import { NavigatePractices } from './Components/NavigatePractices';
import { SearchInput } from 'src/pages/common/components/LIstSubheader/SearchInput';

export const AnalyticsSystemAdmin = () => {
  let openCheck = true;
  const [groupsArray, setGroupsArray] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    rowsPerPage: 10,
    totalRows: 0,
    totalPage: 0,
    filterFlag: 0,
  });

  const [loadingState, setLoadingState] = useState({
    groups: {
      loading: false,
      error: false,
    },
    practice: {
      loading: false,
      error: false,
    },
  });
  const [practicesArray, setPracticesArray] = useState([]);
  const [searchValue, setSearchValue] = useState({
    groups: ``,
    practices: ``,
  });
  const { view, groupId, practiceId, providerId } = useSelector((state) => state?.analytics);
  const user = useSelector((state) => state?.user);
  const dispatch = useDispatch();
  const defaultDispatcher = () => {
    if (!view?.includes(`W.R.Tpractice`)) {
      user?.user?.isSystemAdministrator ? dispatch(viewGiver(`systemaAdminW.R.Tpractice`)) : dispatch(viewGiver(`groupAdminW.R.Tpractice`));
    }
  };
  const defaultAnalytics = (empty) => {
    empty && dispatch(viewGiver(``));
    if (practiceId || providerId || groupId) {
      defaultDispatcher();
      dispatch(practiceIdSaver(``));
      dispatch(groupIdSaver(``));
      dispatch(providerIdSaver(``));
    }
  };
  //practice api call to get practice
  const getPractices = useCallback(() => {
    let filtersObj = dynamicObjCreator({ practiceName: searchValue?.practices });
    let query = `?page=${pagination.currentPage}&recordsPerPage=${pagination?.rowsPerPage}&sort={"createdAt":"1"}&filters=${JSON.stringify(
      filtersObj
    )}&_practiceGroupId=${user?.user?.isSystemAdministrator ? groupId : user?.user?._practiceGroupId}`;
    if (pagination.filterFlag > 0 || searchValue?.practices || user?.auth) {
      if (user?.user?._practiceGroupId || groupId) {
        loadingHandler(`practice`, true, false);
        getRequest(FILTER_PRACTICE_GROUP_URL + query)
          .then((res) => {
            loadingHandler(`practice`, false, false);
            setPagination((p) => ({ ...p, totalRows: res.data.data.pager.totalRecords }));
            if (Math.ceil(res.data.data.pager.totalRecords / res.data.data.pager.recordsPerPage)) {
              setPagination((p) => ({ ...p, totalPage: Math.ceil(res.data.data.pager.totalRecords / res.data.data.pager.recordsPerPage) }));
            }

            setPracticesArray(res?.data?.data?.practiceDataArrayWithNumberOfDays);
          })
          .catch(() => loadingHandler(`practice`, false, true));
      }
    }
  }, [
    user.auth,
    groupId,
    user.user._practiceGroupId,
    user.user.isSystemAdministrator,
    pagination.currentPage,
    searchValue.practices,
    pagination.rowsPerPage,
    pagination.filterFlag,
  ]);
  useEffect(() => {
    defaultDispatcher();
    return () => {
      defaultAnalytics(true);
    };
  }, []);
  useEffect(() => {
    getPractices();
  }, [getPractices]);

  //helper function to use for loading states of both inputs
  const loadingHandler = (key, loading, error) => {
    setLoadingState((p) => ({
      ...p,
      [key]: {
        loading: loading,
        error: error,
      },
    }));
  };

  // helper function which will return options list after determining the role and screen on the base of redux for inputs options
  const optionsViewer = (key) => {
    let loadingElement = <LinearProgress sx={{ width: `100%` }} />;
    let errorElement = <>Error</>;
    if (key === `groups`) {
      if (loadingState?.groups?.loading) {
        return loadingElement;
      } else if (loadingState?.groups?.error) {
        return errorElement;
      } else if (!loadingState?.groups?.loading && !loadingState?.groups?.error) {
        return (
          groupsArray &&
          filteredGroups?.map((e, i) => (
            <MenuItem
              sx={{ fontWeight: 600 }}
              key={i}
              value={e._id}
              onClick={() => {
                if (practiceId || providerId) {
                  dispatch(providerIdSaver(``));
                  dispatch(practiceIdSaver(``));
                }
                dispatch(viewGiver(`groups/${e._id}`));
                dispatch(groupIdSaver(e._id));
              }}
            >
              {capitalizeFirstLetter(e.name)}
            </MenuItem>
          ))
        );
      }
    } else if (key === `practices`) {
      if (loadingState?.practice?.loading) {
        return loadingElement;
      } else if (loadingState?.practice?.error) {
        return errorElement;
      } else if (!loadingState?.practice?.loading && !loadingState?.practice?.error) {
        return (
          practicesArray?.length > 0 &&
          practicesArray.map((e, i) => {
            return (
              <MenuItem
                sx={{ fontWeight: 600 }}
                key={i}
                value={e._id}
                onClick={() => {
                  if (providerId) {
                    dispatch(providerIdSaver(``));
                  }
                  dispatch(viewGiver(`practice/${e._id}`));
                  dispatch(practiceIdSaver(e._id));
                }}
              >
                {capitalizeFirstLetter(e.practiceName)}
              </MenuItem>
            );
          })
        );
      }
    }
  };

  //step color to determine on what stage the user is
  const colorShower = (tab) => {
    let color = `#dbe2e3`;
    if (openCheck) {
      color = `#dbe2e3`;
    }
    if (tab === `groups`) {
      if (groupId || practiceId) {
        color = `#1975cf`;
      }
    } else if (tab === `practices` && practiceId) {
      color = `#1975cf`;
    }
    return color;
  };
  const getGRoups = useCallback(() => {
    loadingHandler(`groups`, true, false);
    getRequest(GET_ALL_GROUP_OPTIONS)
      .then((res) => {
        loadingHandler(`groups`, false, false);
        setGroupsArray(res?.data?.data);
      })
      .catch(() => {
        loadingHandler(`groups`, false, true);
      });
  });

  let filteredGroups = useMemo(() => {
    return groupsArray.filter((e) => e?.name?.toLowerCase()?.includes(searchValue.groups?.toLocaleLowerCase()));
  }, [searchValue.groups, groupsArray]);

  return (
    <Box sx={{ p: 3 }}>
      <Stack sx={{ width: { xs: `100%`, sm: `100%`, md: `80%`, lg: `70%`, xl: `70%` } }} direction="row" alignItems={`center`}>
        <Box sx={{ marginTop: `6px` }}>
          <Tooltip
            placement="top"
            title={
              !view?.includes(`W.R.Tpractice`)
                ? user?.user?.isSystemAdministrator
                  ? `Click to get default analytics of your App!`
                  : `Click to get default analytics of your group!`
                : `Done !`
            }
            arrow
          >
            <Box
              sx={{
                '&:hover': {
                  cursor: `pointer`,
                },
                width: `100%`,
              }}
              style={{ color: `rgb(22, 153, 197)`, fontFamily: `sans-serif` }}
            >
              <Button
                onClick={() => defaultAnalytics(false)}
                variant="outlined"
                sx={{
                  textTransform: `none`,
                  fontSize: { xs: `x-small`, sm: `small`, md: `medium`, lg: `large`, xl: `large` },
                  fontFamily: `system-ui`,
                }}
                size="small"
              >
                {/* as component is only been using for systemadmin and group admin */}
                {user?.user?.isSystemAdministrator ? `System Admin` : `${capitalizeFirstLetter(user?.user?.firstName + ` ` + user?.user?.lastName)}`}
              </Button>
            </Box>
          </Tooltip>
        </Box>
        <Zoom in={openCheck} style={{ transitionDelay: openCheck ? `100ms` : `0ms` }}>
          <Box sx={{ width: `10%`, textAlign: `center` }}>
            <HorizontalRuleIcon
              sx={{
                fontSize: `60px`,
                padding: `0px !important`,
                color: colorShower(user?.user?.isSystemAdministrator ? `groups` : `practices`),
              }}
            />
          </Box>
        </Zoom>
        {/* mix use of ternary operators to determine the screen view on the base of user role */}
        <Zoom in={openCheck} style={{ transitionDelay: openCheck ? `200ms` : `0ms` }}>
          <Box sx={{ marginTop: `5px`, width: `20%` }}>
            <FormControl fullWidth size="small">
              <InputLabel>
                {returnNull({
                  key: user?.user?.isSystemAdministrator,
                  successRetrun: `Groups`,
                  faliure: `Practice`,
                })}
              </InputLabel>
              <Select
                size="small"
                label={returnNull({
                  key: user?.user?.isSystemAdministrator,
                  successRetrun: `Groups`,
                  faliure: `Practice`,
                })}
                MenuProps={{
                  autoFocus: false,
                  PaperProps: {
                    style: {
                      maxHeight: `300px`,
                    },
                  },
                }}
                name={`groups`}
                onChange={() => {
                  return null;
                }}
                id={user?.user?.isSystemAdministrator ? `groupsAnalyticsSearch` : `practicesAnalyticsSearch`}
                value={user?.user?.isSystemAdministrator ? groupId : practiceId}
                inputProps={{
                  onFocus: () => {
                    let { groups, practices } = searchValue;
                    if (user?.user?.isSystemAdministrator) {
                      groups && setSearchValue((p) => ({ ...p, groups: `` }));
                    } else {
                      practices && setSearchValue((p) => ({ ...p, practices: `` }));
                    }

                    if (user?.user?.isSystemAdministrator) {
                      if (!groupId) {
                        getGRoups();
                      }
                    } else if (user?.user?.isGroupAdmin) {
                      if (!practiceId) {
                        getPractices();
                      }
                    }
                  },
                }}
              >
                {returnNull({
                  key: user?.user?.isSystemAdministrator,
                  successRetrun: <SearchInput label={false} setSearchValue={setSearchValue} keyValue={`groups`} isObj={true} />,
                  faliure: <NavigatePractices pagination={pagination} setPagination={setPagination} />,
                })}
                {optionsViewer(user?.user?.isSystemAdministrator ? `groups` : `practices`)}
              </Select>
            </FormControl>
          </Box>
        </Zoom>
        {/* screen will only be shown when the user is system admin  */}
        {user?.user?.isSystemAdministrator && (
          <>
            <Box
              sx={{
                visibility: view?.includes(`groups/`) || view?.includes(`practice/`) ? `visible` : `hidden`,
                width: `10%`,
                textAlign: `center`,
              }}
            >
              <HorizontalRuleIcon sx={{ fontSize: `60px`, padding: `0px !important`, color: colorShower(`practices`) }} />
            </Box>
            <Zoom direction="right" timeout={200} in={view?.includes(`groups/`) || view?.includes(`practice/`)}>
              <Box sx={{ width: `20%` }}>
                <FormControl fullWidth size="small">
                  <InputLabel>Practice</InputLabel>
                  <Select
                    MenuProps={{
                      autoFocus: false,
                      PaperProps: {
                        style: {
                          maxHeight: `300px`,
                        },
                      },
                    }}
                    size="small"
                    name="Practice"
                    label="Practice"
                    value={practiceId}
                    id="practicesAnalyticsSearch"
                    inputProps={{
                      onFocus: () => {
                        let { practices } = searchValue;
                        practices && setSearchValue((p) => ({ ...p, practices: `` }));

                        if (!practiceId) {
                          getPractices();
                        }
                      },
                    }}
                  >
                    <SearchInput label={`Search a practice name`} setSearchValue={setSearchValue} keyValue="practices" isObj={true} />
                    <NavigatePractices pagination={pagination} setPagination={setPagination} />
                    {optionsViewer(`practices`)}
                  </Select>
                </FormControl>
              </Box>
            </Zoom>
          </>
        )}
      </Stack>
      <Fade in={openCheck} mountOnEnter unmountOnExit>
        <PlayForWorkRoundedIcon
          id="providersIcon"
          className={css.transition}
          sx={{
            pt: 0.2,
            color: () => {
              if (providerId) {
                return `#1975cf`;
              } else {
                return `#dbe2e3`;
              }
            },
            fontSize: `56px`,
            marginLeft: `28px`,
          }}
        />
      </Fade>

      <Box sx={{ pt: 1.3, width: `100%` }}>
        <AskForAnalytics />
      </Box>
      {view && <AnalyticsSuperAdmin />}
    </Box>
  );
};

import { SET_ALL_PRACTICES, GET_SMART_PHRASE } from './practiceTypes.js';
const initialState = {
  smartPhrases: []
};

export const practiceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALL_PRACTICES:
      return { ...state, ...action.payload }
    case GET_SMART_PHRASE:
      return {
        ...state,
        smartPhrases: action.payload
      };
    default:
      return state
  }
};

import React, { useState, useEffect } from 'react';
import {
  postRequest,
  PATIENT_VIEW_DATA_RESMED_URL,
  PATIENT_VIEW_DATA_RESPIRONICS_URL,
  PATIENT_VIEW_DATA_REACT_HEALTH,
  getRequest,
} from '../../../../../../../../crud/crud';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { momentWrapper } from 'src/momentWrapper';
import momentOriginal from 'moment';
import css from './viewData.module.css';
import { PuffSpinner } from '../../../../../../../common/components/spinner/puff/puff';
import { ViewDataSummary } from './tabComponents/summary';
import { ViewDataDetail } from './tabComponents/detail';
import { ViewDataMonitAir } from './tabComponents/monitAir';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker, defaultInputRanges } from 'react-date-range';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { predefinedRanges } from 'src/utils';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { updatePatientDataFlag } from 'src/redux/patient/patientActions';

export const PatientsViewData = () => {
  const location = useLocation();

  const [showModal, setModal] = useState(false);

  const [dateRangeSelection, setDateRangeSelection] = useState([
    {
      startDate: momentWrapper().startOf(`day`).subtract(29, `days`).format(`MM/DD/YYYY`),
      endDate: momentWrapper().format(`MM/DD/YYYY`),
      key: `selection`,
    },
  ]);
  const [persistDateRangeSelection, setPersistDateRangeSelection] = useState([
    {
      startDate: momentWrapper().startOf(`day`).subtract(30, `days`).format(`MM/DD/YYYY`),
      endDate: momentWrapper().endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
      key: `selection`,
    },
  ]);

  const selectedPatient = useSelector((state) => state.patient?.patientData?.data);
  const selectedPatientReport = useSelector((state) => state?.patient?.patientReport?.selectedPatientReport?.data);
  const auth = useSelector((state) => state.user.auth);
  const [data, setData] = useState(``);
  const [currentTab, setCurrentTab] = useState(`summary`);
  const [visibleFlag, setVisibleFlag] = useState(true);
  const [loading, setLoading] = useState(false);
  const setIframeRefreshFlag = false;
  const dispatchRedux = useDispatch();
  const selectedPatientIdFromReport = useSelector((state) => state.patient.patientReport?.patient?._id);
  /*
    Initializing default date ranges separately
  */
  useEffect(() => {
    let { startDateOfReport, endDateOfReport } = location;

    /*
        if report if of previous month and, is being signed in the current month,
        then end date of the report should be end of that month.
      */
    if (location && location.startDateOfReport && location.endDateOfReport) {
      const currentMonth = parseInt(momentWrapper().format(`YYYYMM`));

      const reportMonth = parseInt(momentWrapper(endDateOfReport).format(`YYYYMM`));

      if (currentMonth > reportMonth) {
        endDateOfReport = momentWrapper(endDateOfReport).endOf(`month`).toDate();
      }
    }

    let startDate = startDateOfReport
      ? momentWrapper(startDateOfReport).startOf(`day`).toDate()
      : momentWrapper().startOf(`day`).subtract(30, `days`).toDate();

    let endDate = endDateOfReport ? momentWrapper(endDateOfReport).endOf(`day`).toDate() : momentWrapper().subtract(1, `day`).endOf(`day`).toDate();

    // setting default date range for "dateRangeSelection"
    setDateRangeSelection([
      {
        startDate: momentWrapper(startDate).format(`MM/DD/YYYY`),
        endDate: momentWrapper(endDate).format(`MM/DD/YYYY`),
        key: `selection`,
      },
    ]);

    // setting default date range for "dateRangeSelection"
    setPersistDateRangeSelection([
      {
        startDate: momentWrapper(startDate).format(`MM/DD/YYYY`),
        endDate: momentWrapper(endDate).format(`MM/DD/YYYY`),
        key: `selection`,
      },
    ]);
  }, [location, selectedPatient]);

  useEffect(() => {
    let startDate = momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toISOString();
    let endDate = momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).endOf(`day`).toISOString();
    if (selectedPatient?.device?.manufacturer === `resmed` && selectedPatient?._id) {
      setLoading(true);
      let url = PATIENT_VIEW_DATA_RESMED_URL + selectedPatient?._id;
      postRequest(url, { startDate, endDate })
        .then((res) => {
          dispatchRedux(updatePatientDataFlag(true));
          setData(res.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setData(``);
        });
    } else if (selectedPatient?.device?.manufacturer === `reactHealth` && typeof selectedPatient?._id === `string`) {
      setLoading(true);
      let url =
        PATIENT_VIEW_DATA_REACT_HEALTH +
        selectedPatient?._id +
        `/?reportId=${location.reportId ? location.reportId : null}&startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(
          endDate
        ).toISOString()}`;
      getRequest(url)
        .then((response) => {
          setData(response?.data);
          setLoading(false);
        })
        .catch(() => {
          setData(``);
          setLoading(false);
        });
    } else if (selectedPatient?.device?.manufacturer === `respironics` && selectedPatient?._id) {
      setLoading(true);
      let url =
        PATIENT_VIEW_DATA_RESPIRONICS_URL +
        selectedPatient?._id +
        `/?startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(endDate).toISOString()}`;
      getRequest(url)
        .then((res) => {
          setData(res.data);
          setLoading(false);
        })
        .catch(() => {
          setData(``);
          setLoading(false);
        });
    }
  }, [auth, location, selectedPatient, currentTab]);

  const getDataHandler = (picker) => {
    let { startDate, endDate } = picker.selection;
    setDateRangeSelection([
      {
        startDate: momentOriginal(startDate).format(`MM/DD/YYYY`),
        endDate: momentOriginal(endDate).format(`MM / DD / YYYY`),
        key: `selection`,
      },
    ]);
  };

  const applyDateRangeApiCall = () => {
    setModal(false);
    setPersistDateRangeSelection(dateRangeSelection);

    let startDate = momentWrapper(dateRangeSelection[0].startDate, `MM / DD / YYYY`).startOf(`day`).toDate();
    let endDate = momentWrapper(dateRangeSelection[0].endDate, `MM / DD / YYYY`).endOf(`day`).toDate();

    if (selectedPatient && startDate && endDate) {
      let url = PATIENT_VIEW_DATA_RESMED_URL + selectedPatient?._id;
      setLoading(true);
      setData(``);
      if (selectedPatient?.device?.manufacturer === `resmed`) {
        postRequest(url, {
          startDate,
          endDate,
        })
          .then((res) => {
            //
            dispatchRedux(updatePatientDataFlag(true));
            setData(res.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else if (selectedPatient?.device?.manufacturer === `reactHealth`) {
        let url =
          PATIENT_VIEW_DATA_REACT_HEALTH +
          selectedPatient?._id +
          `/?reportId=${location.reportId ? location.reportId : null}&startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(
            endDate
          ).toISOString()}`;
        getRequest(url, {
          startDate,
          endDate,
        })
          .then((response) => {
            setData(response?.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      } else {
        let url =
          PATIENT_VIEW_DATA_RESPIRONICS_URL +
          selectedPatient?._id +
          `/?startDate=${momentWrapper(startDate).toISOString()}&endDate=${momentWrapper(endDate).toISOString()}`;
        getRequest(url)
          .then((res) => {
            setData(res.data);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
          });
      }
    }
  };

  const closeDateRangeModal = () => {
    setModal(false);
    setDateRangeSelection(persistDateRangeSelection);
  };

  return (
    <div id={css.viewDataMain} className="mb-4">
      {/* {user && */}
      <>
        <div className="mb-3 pt-1 w-100 d-flex">
          <InputField
            sx={{ width: `60 % ` }}
            type="text"
            name="from"
            id="from-date"
            disabled={location?.reportId || !selectedPatientIdFromReport ? true : false}
            onClick={() => setModal(true)}
            value={`${persistDateRangeSelection[0]?.startDate} - ${persistDateRangeSelection[0]?.endDate} `}
          />
          <CustomizedDialogs
            noTitle={true}
            open={showModal}
            setOpen={() => closeDateRangeModal()}
            size="md"
            fullWidth={true}
            showCloseButton={false}
            customButton={true}
            customButtonText="Apply"
            customButtonAction={() => applyDateRangeApiCall()}
          >
            <div style={{ margin: -7 }}>
              <DateRangePicker
                startDatePlaceholder="From"
                endDatePlaceholder="to"
                onChange={(item) => getDataHandler(item)}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[
                  {
                    startDate: momentWrapper(dateRangeSelection[0].startDate, `MM / DD / YYYY`).startOf(`day`).toDate(),
                    endDate: momentWrapper(dateRangeSelection[0].endDate, `MM / DD / YYYY`).toDate(),
                    key: `selection`,
                  },
                ]}
                staticRanges={predefinedRanges}
                inputRanges={[defaultInputRanges[0]]}
                direction="horizontal"
              />
            </div>
          </CustomizedDialogs>
        </div>

        <div className="d-flex justify-content-between">
          <div id={css.customViewDataHead}>
            <div id={currentTab === `summary` ? css.menuItemSelected : css.menuItem} onClick={() => setCurrentTab(`summary`)} className="p-3 mr-1">
              Summary Trend
            </div>
            <div id={currentTab === `detail` ? css.menuItemSelected : css.menuItem} onClick={() => setCurrentTab(`detail`)} className="p-3 mr-1">
              Detailed Trend
            </div>
            <div id={currentTab === `monitAir` ? css.menuItemSelected : css.menuItem} onClick={() => setCurrentTab(`monitAir`)} className="p-3">
              MonitAir Trend
            </div>
          </div>
          <button
            className="btn"
            id={css.collapseButton}
            onClick={() => setVisibleFlag(!visibleFlag)}
            type="button"
            data-toggle="collapse"
            data-target="#patientViewDataComponent"
            aria-expanded="true"
            aria-controls="patientBasicInfo"
          >
            {visibleFlag ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-right" aria-hidden="true"></i>}
          </button>
        </div>
        <div className="collapse show" id="patientViewDataComponent" style={{ position: `relative` }}>
          {selectedPatient ? (
            currentTab === `detail` ? (
              <div id={css.patientViewData} className="w-100 pl-3 pr-3 pb-2">
                <ViewDataDetail
                  user={selectedPatient}
                  data={data}
                  setIframeRefreshFlag={setIframeRefreshFlag}
                  start={momentWrapper(dateRangeSelection[0].startDate, `MM/DD/YYYY`).startOf(`day`).toDate()}
                  end={momentWrapper(dateRangeSelection[0].endDate, `MM/DD/YYYY`).startOf(`day`).toDate()}
                  // start={momentWrapper(dateRangeSelection[0].startDate, `MM / DD / YYYY`).startOf(`day`).toDate()}
                  // end={momentWrapper(dateRangeSelection[0].endDate, `MM / DD / YYYY`).endOf(`day`).toDate()}
                  userId={selectedPatient?._id}
                  reportId={selectedPatientReport?._id}
                  dateHandler={getDataHandler}
                  selectedPatient={selectedPatient}
                />
              </div>
            ) : currentTab === `monitAir` ? (
              <div id={css.patientViewData} className="w-100 pl-3 pr-3 pb-2">
                <ViewDataMonitAir user={selectedPatient} />
              </div>
            ) : (
              <div id={css.patientViewData} className="w-100 pl-3 pr-3 pb-2">
                <ViewDataSummary
                  setIframeRefreshFlag={setIframeRefreshFlag}
                  data={data}
                  start={momentWrapper(dateRangeSelection[0].startDate, `MM / DD / YYYY`).startOf(`day`).toDate()}
                  end={momentWrapper(dateRangeSelection[0].endDate, `MM / DD / YYYY`).endOf(`day`).toDate()}
                  userId={selectedPatient?._id}
                  reportId={selectedPatientReport?._id}
                  dateHandler={getDataHandler}
                  selectedPatient={selectedPatient}
                />
              </div>
            )
          ) : (
            <div style={{ color: `#c1c1c1` }} id={css.patientViewData} className="d-flex justify-content-center align-items-center">
              Click patient from list to show its summary.
            </div>
          )}
          {loading && <PuffSpinner />}
        </div>
      </>
    </div>
  );
};

import { IconButton, InputAdornment, TextField } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { useSelector } from 'react-redux';
import { errorToast, successToast } from '../components/snackBar/toast';
import { ToastContainer } from 'react-toastify';

export const useSearchInput = (filedProps, action, secParam) => {
  const [value, setValue] = useState('');
  const { auth } = useSelector((state) => state?.user);
  return (
    <>
      {action && <ToastContainer />}
      <TextField
        {...filedProps}
        onChange={(e) => setValue(e.target.value)}
        {...(action && {
          InputProps: {
            endAdornment: (
              <InputAdornment>
                <IconButton
                  onClick={() =>
                    action(auth, secParam(value))
                      .then((res) => {
                        successToast('Success');
                      })
                      .catch((e) => errorToast(e?.response?.message))
                  }
                >
                  <SearchIcon sx={{ color: 'rgb(22, 153, 197)' }} />
                </IconButton>
              </InputAdornment>
            ),
          },
        })}
      />
    </>
  );
};

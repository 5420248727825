import React from 'react';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker, LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { InputField } from '../InputField/InputField';

export const DatePickerCustomize = ({ maxDate, onChange, dateInputValue, setDateInputValue,
  name, serial, step1RespironicsNumber, pickerProps,
  disabled, isFutureSelection, setDateInputError, dateInputError }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        {...(pickerProps && { ...pickerProps })}
        disabled={(step1RespironicsNumber?.length && (step1RespironicsNumber?.length > 0 || serial === `hasSerial` ? true : false)) || disabled}
        value={dateInputValue}
        onChange={(newValue) => {
          if (onChange) {
            onChange(newValue?.$d)
          } else {
            setDateInputValue(newValue?.$d);
          }

        }}
        {...(!maxDate && !isFutureSelection ? { maxDate: new Date() } : {})}

        renderInput={(params) => {
          if (dateInputError !== undefined) {
            if (params.error) {
              setDateInputError(true)
            } else if (dateInputError) {
              setDateInputError(false)
            }
          }
          return <TextField {...(pickerProps?.size && { size: pickerProps?.size })} {...(pickerProps?.fullWidth && { fullWidth: true })}   {...(name && { name: name })}  {...params} />
        }}
      />
    </LocalizationProvider>
  );
}
export const DateTimePickerMui = (props) => {


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DateTimePicker
        {...props}
        {...(props?.onAccept && {
          onAccept: (value) => props.onAccept(value?.$d)
        })}
        onChange={(value) => props.onChange ? props.onChange(value) : null}
        renderInput={(params) => {
          return <InputField {...(props?.onClick ? { onClick: props.onClick } : {})} {...(props.onBlur && {
            onBlur: (e) => {
              const { target: { value } } = e
              props.onBlur(value)
            }
          })} size={`small`} fullWidth={true}   {...params} {...(props?.error && { error: true })} />
        }}

      />
    </LocalizationProvider>
  )
}
export const DatePickerCustomizeForFilter = ({ dateInputValue, setDateInputValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        value={dateInputValue ? dateInputValue : null}
        onChange={(event) => setDateInputValue(event?.$d)}
        pickerProps={{ size: `small` }}

        renderInput={(params) =>
          <TextField {...params}
            fullWidth
            size='small'
            placeholder='Start Date'
          />
        }
      />
    </LocalizationProvider>
  );
}

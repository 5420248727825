import React, { useEffect } from 'react'
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { getRequest, LOG_OUT } from 'src/crud/crud';
import { errorToast } from '../components/snackBar/toast';
import { store } from 'src/redux/store';
import { logoutHandlerS } from 'src/redux/action/logoutHandler';







let time = null
const restartTimerIdleUSer = () => {
    if (time) clearTimeout(time)
    time = setTimeout(logout, 780000)
}
const logout = () => {
    const { timer } = store.getState()
    const { videoCall, audioCall } = timer || false
    if (!videoCall && !audioCall) {
        newSocket.close()
        getRequest(LOG_OUT)
            .then(() => {
                document.removeEventListener(`click`, restartTimerIdleUSer)
                document.removeEventListener(`keydown`, restartTimerIdleUSer)
                document.removeEventListener(`mousemove`, restartTimerIdleUSer)
                if (time) clearTimeout(time)
                store.dispatch(logoutHandlerS())
            })
            .catch(() => {
                errorToast(`Some error has occured please try again later!`);
            });

    }

}



export const IdleUserWrapper = ({ children }) => {

    useEffect(() => {
        restartTimerIdleUSer()
        document.addEventListener(`click`, restartTimerIdleUSer);
        document.addEventListener(`keydown`, restartTimerIdleUSer)
        document.addEventListener(`mousemove`, restartTimerIdleUSer)


        return () => {
            document.removeEventListener(`click`, restartTimerIdleUSer)
            document.removeEventListener(`keydown`, restartTimerIdleUSer)
            document.removeEventListener(`mousemove`, restartTimerIdleUSer)
        }
    }, [])
    return (
        <>{children}</>
    )
}





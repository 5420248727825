import { BLANK_PRACTICE_GROUP, GET_COUNTS, GROUP_ID, HAS_REGISTERED, PRACTICE_LOGO_PATH } from './Types';

const initialState = {
  groupId: ``,
  PracticeLogoPath: ``,
  isRegistered: false,
};
export const practiceGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case GROUP_ID:
      return { ...state, groupId: action.payLoad };
    case PRACTICE_LOGO_PATH:
      return { ...state, PracticeLogoPath: action.payLoad };
    case BLANK_PRACTICE_GROUP:
      return { ...state, groupId: `` };
    case HAS_REGISTERED:
      return { ...state, isRegistered: action.payLoad };
    case GET_COUNTS:
      return { ...state, counts: action.payLoad };
    default:
      return state;
  }
};

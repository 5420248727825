const initialState = {
  open: false,
  startDate: ``,
  endDate: ``,
  patientId: ``,
  patientNumber: ``,
  patientData: {}
};
export const readyToSignModal = (state = initialState, action) => {
  switch (action.type) {
    case `OPENINFOMODAL`:
      return {
        ...action?.payLoad?.rootKeys,
        patientData: { ...action?.payLoad?.patientData }
      };

    default:
      return state;
  }
};

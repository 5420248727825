import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard } from '../pages/home/dashboard/commonDashboard/dashboard';
import { Patient } from '../pages/home/modules/patient2/patient';
import { Messenger } from '../pages/home/modules/messenger/messenger';
import { SettingsSystemAdmin } from '../pages/home/modules/settings/settings';
import { AnalyticsSuperAdmin } from '../pages/home/modules/analytics/analytics';
import { Ticketing } from '../pages/common/components/wrapper/components/help/ticketing/Ticketing';
import { Faqs } from '../pages/common/components/wrapper/components/help/faqs/Faqs';
import { Resources } from '../pages/common/components/wrapper/components/help/resources/resources';
import { SmartPhrase } from 'src/pages/home/modules/administration/smartPhrases/smartPhrases';
import { PatientOverView } from 'src/pages/home/modules/patient2/PatientOverview/PatientOverView';
import TaskManagement from 'src/pages/home/modules/TaskManagement/TaskManagement';
export const ProviderRouter = () => {
  return (
    <Switch>
      <Route exact path="/taskmanagement">
        <TaskManagement />
      </Route>
      <Route exact path="/patient">
        <Patient />
      </Route>
      <Route exact path={`/patients/overview`}>
        <PatientOverView />
      </Route>
      <Route exact path="/messenger">
        <Messenger isFromView={false} />
      </Route>
      <Route exact path="/settings">
        <SettingsSystemAdmin />
      </Route>
      <Route exact path="/analytics">
        <AnalyticsSuperAdmin />
      </Route>
      <Route exact path="/ticketing">
        <Ticketing />
      </Route>
      <Route exact path="/faqs">
        <Faqs />
      </Route>
      <Route exact path="/resources">
        <Resources />
      </Route>
      <Route exact path="/smart/phrases">
        <SmartPhrase />
      </Route>
      <Route path="/">
        <Redirect to="/" />
        <Dashboard />
      </Route>
    </Switch>
  );
}


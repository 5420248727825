import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Dashboard } from '../pages/home/dashboard/commonDashboard/dashboard';
import { AnalyticsSuperAdmin } from '../pages/home/modules/analytics/analytics';
import { AdministrationPractice } from '../pages/home/modules/administration/practice/practice';
import { AdministrationUser } from '../pages/home/modules/administration/user/user';
import { AdministrationPayment } from '../pages/home/modules/administration/payment';
import { Patient } from '../pages/home/modules/patient2/patient';
import { Faqs } from '../pages/common/components/wrapper/components/help/faqs/Faqs';
import { Resources } from '../pages/common/components/wrapper/components/help/resources/resources';
import { Messenger } from '../pages/home/modules/messenger/messenger';
import { Ticketing } from '../pages/common/components/wrapper/components/help/ticketing/Ticketing';
import { SmartPhrase } from '../pages/home/modules/administration/smartPhrases/smartPhrases';
import { PatientOverView } from 'src/pages/home/modules/patient2/PatientOverview/PatientOverView';
import TaskManagement from 'src/pages/home/modules/TaskManagement/TaskManagement';

export const SuperAdminRouter = ({ user }) => {
  return user.stripeSubscriptionId ? (
    <Switch>
      {(user.isProvider || user.isClinicalStaff || user.isTransportationAdmin) && (
        <Route exact path="/patient">
          <Patient />
        </Route>
      )}
      {(user.isProvider || user.isClinicalStaff || user.isTransportationAdmin || user.isSuperAdmin) && (
        <Route exact path="/messenger">
          <Messenger isFromView={false} />
        </Route>
      )}
      {user.isSuperAdmin && (
        <Route exact path={`/patients/overview`}>
          <PatientOverView />
        </Route>
      )}

      {(user.isProvider || user.isClinicalStaff || user.isSuperAdmin) && (
        <Route exact path="/analytics">
          <AnalyticsSuperAdmin />
        </Route>
      )}
      {(user.isProvider || user.isClinicalStaff || user.isTransportationAdmin || user.isSuperAdmin) && (
        <Route exact path="/ticketing">
          <Ticketing />
        </Route>
      )}

      {(user.isProvider || user.isClinicalStaff || user.isSuperAdmin) && (
        <Route exact path="/faqs">
          <Faqs />
        </Route>
      )}
      {(user.isProvider || user.isClinicalStaff || user.isSuperAdmin) && (
        <Route exact path="/resources">
          <Resources />
        </Route>
      )}

      <Route exact path="/administration/practice">
        <AdministrationPractice />
      </Route>
      {/* on hold */}
      {/* <Route exact path={'/analytics'}>
        <AnalyticsSuperAdmin />
      </Route> */}
      <Route exact path="/administration/user">
        <AdministrationUser />
      </Route>
      {(user.isProvider || user.isClinicalStaff || user.isSuperAdmin) && (
        <Route exact path="/taskmanagement">
          <TaskManagement />
        </Route>
      )}
      <Route exact path="/administration/payment">
        <AdministrationPayment />
      </Route>
      <Route exact path="/smart/phrases">
        <SmartPhrase />
      </Route>
      <Route path="/">
        <Redirect to="/" />
        <Dashboard />
      </Route>
    </Switch>
  ) : (
    <Switch>
      <Route exact path="/administration/payment">
        <AdministrationPayment />
      </Route>

      <Route path="/">
        <Redirect to="/administration/payment" />
      </Route>
    </Switch>
  );
}

import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import css from './importPatient.module.css';
import momentOriginal from 'moment';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { resmedGroupedOptions, respironicsGroupedOptions, fisherAndPaykelGroupedOptions } from './data';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import {
  getRequest, GET_ALL_MD_BY_PRACTICE_ID, GET_ALL_QHCP_BY_PRACTICE_ID, postRequest,
  GET_RESMED_PATIENT_URL, GET_RESPIRONICS_PATIENT_URL, IMPORT_PATIENT_URL_FROM_URL, IMPORT_PATIENT_URL,
  EXTERNAL_DEVICE_OPTION, IMPORT_REACT_HEALTH_PATIENT, IMPORT_PATIENT_FROM_REACT_HEALTH
} from '../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../common/components/spinner/spinner';
import { newImportPatientAddedSuccessfully } from '../../../../../../redux/resmed/resmedActions';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { toast, ToastContainer } from 'react-toastify';
import {
  appDevices, genderOPtions, getNIHPrimaryDiagnosis, setUseState,
  maskDevices,
} from 'src/utils';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { Box, Grid, InputAdornment, ListSubheader, MenuItem, TextField } from '@mui/material';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { InputSpinner } from 'src/pages/common/components/InputSpinner/InputSpinner';
import { resmedMaskTypes, respironicsMaskTypes, fisherMaskTypes, capitalizeFirstLetter, emailRegex, phoneRegex } from 'src/utils';
import Downshift from 'downshift';
import Swal from 'sweetalert2';

export const ImportPatient = ({ importPatientModal, setImportPatientModal }) => {
  const [step, setStep] = useState(1);
  const { user } = useSelector((state) => state?.user);
  const [secondaryDiagnosisLoading, setSecondaryDiagnosisLoading] = useState(false);
  const _practiceGroupId = useSelector((state) => state?.user?.user?._practiceGroupId);
  const [step1Manufacturer, setStep1Manufacturer] = useState(``);
  const [step1ManufacturerError, setStep1ManufacturerError] = useState({
    status: false,
    msg: ``,
  });
  const [isRegistered, setIsRegistered] = useState(false);
  const [step1Number, setStep1Number] = useState(``);
  const [step1NumberError, setStep1NumberError] = useState({
    status: false,
    msg: ``,
  });
  const [step1RespironicsNumber, setStep1RespironicsNumber] = useState(``);
  const [step1RespironicsNumberError] = useState({
    status: false,
    msg: ``,
  });
  const [step1FirstName, setStep1FirstName] = useState(``);
  const [step1FirstNameForReactHealth, setStep1FirstNameForReactHealth] = useState(``);
  const [step1LastNameForReactHealth, setStep1LastNameForReactHealth] = useState(``);
  const [step1FirstNameError, setStep1FirstNameError] = useState({
    status: false,
    msg: ``,
  });
  const [step1FirstNameErrorForReactHealth, setStep1FirstNameErrorForReactHealth] = useState({
    status: false,
    msg: ``,
  });
  const [step1LastNameNameErrorForReactHealth, setStep1LastNameErrorForReactHealth] = useState({
    status: false,
    msg: ``,
  });
  const [step1LastName, setStep1LastName] = useState(``);
  const [step1LastNameError, setStep1LastNameError] = useState({
    status: false,
    msg: ``,
  });
  const [step1DateOfBirth, setStep1DateOfBirth] = useState(null);
  const [step1DateOfBirthError, setStep1DateOfBirthError] = useState({
    status: false,
    msg: ``,
  });

  const [step1RespironicsDateOfBirth, setStep1RespironicsDateOfBirth] = useState(null);
  const [step1ReactHealthDateOfBirth, setStep1ReactHealthDateOfBirth] = useState(null);
  const [step1RespironicsDateOfBirthError, setStep1RespironicsDateOfBirthError] = useState({
    status: false,
    msg: ``,
  });
  const [step1ReactHealthDateOfBirthError, setStep1ReactHealthDateOfBirthError] = useState({
    status: false,
    msg: ``,
  });

  const [firstName, setFirstName] = useState(``);
  const [firstNameError, setFirstNameError] = useState({
    status: false,
    msg: ``,
  });
  const [lastName, setLastName] = useState(``);
  const [lastNameError, setLastNameError] = useState({
    status: false,
    msg: ``,
  });
  const [dateOfBirth, setDateOfBirth] = useState(``);
  const [gender, setGender] = useState(``);
  const [genderError, setGenderError] = useState({ status: false, msg: `` });
  const [externalDeviceValue, setExternalDeviceValue] = useState(``);
  // const [externalDeviceError, setExternalDeviceError] = useState({ status: false, msg: `` })
  const [patientDeviceIdValue, setPatientDeviceIdValue] = useState(``);
  const [patientDeviceError, setPatientDeviceError] = useState({ status: false, msg: `` });
  const [_idFromDevice, set_idFromDevice] = useState(``);
  const [phone, setPhone] = useState(``);
  const [phoneError, setPhoneError] = useState({ status: false, msg: `` });
  const [email, setEmail] = useState(``);
  const [dateError, setDateError] = useState(false)
  const [emailError, setEmailError] = useState({ status: false, msg: `` });
  const [insuranceCompany, setInsuranceCompany] = useState(``);
  const [, setPrimaryDiagnosis] = useState(``);
  const [primaryDiagnosisError, setPrimaryDiagnosisError] = useState({
    status: false,
    msg: ``,
  });
  const [, setSecondaryDiagnosis] = useState(``);
  const [manufacturer, setManufacturer] = useState(``);
  const [deviceInfo, setDeviceInfo] = useState({});
  const [deviceType, setDeviceType] = useState(``);
  const [maskType, setMaskType] = useState(``);
  const [maskSize, setMaskSize] = useState(``);
  const [maskCompany, setMaskCompany] = useState(``);
  const [deviceNumber, setDeviceNumber] = useState(``);
  const [MDData, setMDData] = useState([]);
  const [QhCPUser, setQhCPUser] = useState([]);
  const [assignMD, setAssignMD] = useState(``);
  const [assignMDError, setAssignMDError] = useState({
    status: false,
    msg: ``,
  });
  const [assignQHCP, setAssignQHCP] = useState(``);
  const [assignQHCPError, setAssignQHCPError] = useState({
    status: false,
    msg: ``,
  });
  const [note, setNote] = useState(``);
  const [MRN, setMRN] = useState(``);
  const [_orgId, setOrgId] = useState(``);
  const [orgName, setOrgName] = useState(``);
  const [patientId, setPatientId] = useState(``);
  const [setupDate, setSetupDate] = useState(``);
  const [loading, setLoading] = useState(false);
  const close = useRef(null);

  /// IMPLEMENTING PRIMARY & SECONDARY DIAGNOSIS COMBOS ///
  /// PRIMARY DIAGNOSIS
  const [primaryDiagnosisListItems, setPrimDiagnosisListItems] = useState([]);
  const [primaryDiagnosisQuery, setPrimaryDiagnosisQuery] = useState(``);
  const [primaryDiagnosisQueryId, setPrimaryDiagnosisQueryId] = useState(``);
  const [isPrimaryOpenDropDown, setIsPrimaryOpenDropDown] = useState(false);
  const [primaryDiagnosisIsLoading, setPrimaryDiagnosisLoading] = useState(false);
  const [baseLineAHI, setBaseLineAHI] = useState(``);
  const inputPDiagnosis = useRef(null);

  /// SECONDARY DIAGNOSIS
  const [secDiagnosisListItems, setSecDiagnosisListItems] = useState([]);
  const [secondaryDiagnosisQuery, setSecondaryDiagnosisQuery] = useState(``);
  const [secondaryDiagnosisQueryId, setSecondaryDiagnosisQueryId] = useState(``);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const dispatch = useDispatch();
  const [isSerial, setIsSerial] = useState(``);
  const [isSerialDeviceId, setIsSerialDeviceId] = useState(``);
  const [, setAnchorElemDiagnosis] = useState({
    primary: null,
    secondary: null,
  });
  // getOptionsForExternalDevice
  const [optionsForExternalDevice, setoptionsForExternalDevice] = useState([]);

  const comBosPrimaryDiagnosisOnChange = (selectedDiagnosis) => {
    setPrimaryDiagnosisQuery(selectedDiagnosis.target.value);
    getNIHPrimaryDiagnosis({
      query: selectedDiagnosis.target.value,
      setPrimaryDiagnosisLoading: setPrimaryDiagnosisLoading,
      setIsPrimaryOpenDropDown: setIsPrimaryOpenDropDown,
      setPrimDiagnosisListItems: setPrimDiagnosisListItems,
    });
  };

  const primaryDiagnosisDropdownValueOnChange = (selectedDiagnosis) => {
    setIsPrimaryOpenDropDown((pre) => !pre);
    setPrimaryDiagnosisQuery(selectedDiagnosis?.Description);
    setPrimaryDiagnosisQueryId(selectedDiagnosis?.Code);
  };

  const comBosSecondaryDiagnosisOnChange = (event) => {
    setSecondaryDiagnosisQuery(event?.target?.value);
    getNIHPrimaryDiagnosis({
      query: event.target.value,
      setPrimaryDiagnosisLoading: setSecondaryDiagnosisLoading,
      setIsPrimaryOpenDropDown: setIsOpenDropDown,
      setPrimDiagnosisListItems: setSecDiagnosisListItems,
    });
  };

  const secondaryDiagnosisDropdownValueOnChange = (selectedDiagnosis) => {
    setIsOpenDropDown((pre) => !pre);
    setSecondaryDiagnosisQuery(selectedDiagnosis?.Description);
    setSecondaryDiagnosisQueryId(selectedDiagnosis?.Code);
  };

  useEffect(() => {
    const makeFullName = (arr) => {
      let tempArr = [...arr]
      tempArr = tempArr.map((md) => {
        let capitalizedFullName = capitalizeFirstLetter(`${md?.firstName} ${md?.lastName}`)
        return { ...md, fullName: capitalizedFullName }
      })
      return tempArr
    }

    if (!MDData.length) {
      getRequest(GET_ALL_MD_BY_PRACTICE_ID + user._practiceId)
        .then((res) => {
          if (res?.data?.MD) {
            const { MD: mdData } = res.data
            let mappedWithFullName = makeFullName(mdData)
            setMDData(mappedWithFullName);
          }
        })
        .catch((e) => {
          toast.error(() => <>Unable to Fetch Data</>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        });
    }
    if (!QhCPUser.length) {
      getRequest(GET_ALL_QHCP_BY_PRACTICE_ID + user._practiceId)
        .then((res) => {
          if (res?.data?.QHCPUsers) {
            const { QHCPUsers: clinicalUsers } = res.data
            let mappedWithFullName = makeFullName(clinicalUsers)
            setQhCPUser(mappedWithFullName);
          }
        })
        .catch((e) => {
          toast.error(() => <>Unable to Fetch Data</>, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 2000,
          });
        });
    }
  }, []);

  const step1ValidateInput = async (field, value) => {
    let temp = { status: false, msg: `` };
    switch (field) {
      case `step1Manufacturer`:
        if (!step1Manufacturer) {
          temp.status = true;
          temp.msg = `*Required`;
          setStep1ManufacturerError(temp);
        } else {
          setStep1ManufacturerError(temp);
        }
        break;
      case `step1Number`:
        if (!step1Number) {
          temp.status = true;
          temp.msg = `*Required`;
          setStep1NumberError(temp);
        } else {
          setStep1NumberError(temp);
        }
        break;
      case `step1DOB`:
        if (!step1DateOfBirth?.toString()?.length && step1Manufacturer === `resmed`) {
          temp.status = true;
          temp.msg = `*Required`;
          setStep1DateOfBirthError(temp);
        } else {
          setStep1DateOfBirthError(temp);
        }
        break;
      default:
        if (!step1Manufacturer) {
          temp.status = true;
          temp.msg = `*Required`;
          setStep1ManufacturerError(temp);
          return false;
        }
        if (!step1Number && step1Manufacturer === `resmed`) {
          temp.status = true;
          temp.msg = `*Required`;
          setStep1NumberError(temp);
          return false;
        }
        if (!step1DateOfBirth && step1Manufacturer === `resmed`) {
          temp.status = true;
          temp.msg = `*Required`;
          setStep1DateOfBirthError(temp);
          return false;
        }
        if (!step1RespironicsNumber && step1Manufacturer === `respironics`) {
          if (!step1FirstName && !step1LastName && !step1RespironicsDateOfBirth && step1Manufacturer === `respironics`) {
            return false;
          } else {
            temp.status = true;
            temp.msg = `*Required`;
            if (!step1FirstName && step1Manufacturer === `respironics`) {
              setStep1FirstNameError(temp);
              return false;
            }
            if (!step1LastName && step1Manufacturer === `respironics`) {
              setStep1LastNameError(temp);
              return false;
            }
            if (!step1RespironicsDateOfBirth && step1Manufacturer === `respironics`) {
              setStep1RespironicsDateOfBirthError(temp);
              return false;
            }
          }
        } else {
          temp.status = false;
          temp.msg = ``;
          setStep1FirstNameError(temp);
          setStep1LastNameError(temp);
          setStep1RespironicsDateOfBirthError(temp);
          if (step1FirstName) {
            toast.error(() => <>firstName,can not be passed while deviceSerialNumber is passed.</>, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            });
            return false;
          }
          if (step1LastName) {
            toast.error(() => <>lastName can not be passed while deviceSerialNumber is passed.</>, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            });
            return false;
          }
          if (step1RespironicsDateOfBirth) {
            toast.error(() => <>dateOfBirth can not be passed while deviceSerialNumber is passed.</>, {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 2000,
            });
            return false;
          }
        }
        if (!patientDeviceIdValue && step1Manufacturer === `reactHealth`) {
          if (!step1FirstNameForReactHealth && !step1LastNameForReactHealth && !step1ReactHealthDateOfBirth && step1Manufacturer === `reactHealth`) {
            return false;
          } else {
            temp.status = true;
            temp.msg = `*Required`;
            if (!step1FirstNameForReactHealth && step1Manufacturer === `reactHealth`) {
              setStep1FirstNameErrorForReactHealth(temp);
              return false;
            }
            if (!step1LastNameForReactHealth && step1Manufacturer === `reactHealth`) {
              setStep1LastNameErrorForReactHealth(temp);
              return false;
            }
            if (!step1ReactHealthDateOfBirth && step1Manufacturer === `reactHealth`) {
              setStep1ReactHealthDateOfBirthError(temp);
              return false;
            }
          }
        } else {
          temp.status = false;
          temp.msg = ``;
          setStep1FirstNameErrorForReactHealth(temp);
          setStep1LastNameErrorForReactHealth(temp);
          setStep1ReactHealthDateOfBirthError(temp);
          if (step1FirstNameForReactHealth) {
            errorToast(`firstName,can not be passed while deviceSerialNumber is passed.`);
            return false;
          }
          if (step1LastNameForReactHealth) {
            errorToast(`lastName can not be passed while deviceSerialNumber is passed.`);
            return false;
          }
          if (step1ReactHealthDateOfBirth) {
            errorToast(`dateOfBirth can not be passed while deviceSerialNumber is passed.`);
            return false;
          }
        }
        return true;
    }
  };

  // STEP 02: FORM VALIDATION
  const validateInput = async (field) => {
    let temp = { status: false, msg: `` };
    switch (field) {
      case `firstName`:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
        } else {
          setFirstNameError(temp);
        }
        break;
      case `lastName`:
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
        } else {
          setLastNameError(temp);
        }
        break;
      case `gender`:
        if (!gender) {
          temp.status = true;
          temp.msg = `*Required`;
          setGenderError(temp);
        } else {
          setGenderError(temp);
        }
        break;
      case `phone`:
        if (!phone) {
          temp.status = true;
          temp.msg = `*Required`;
          setPhoneError(temp);
        } else if (!phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setPhoneError(temp);
        } else {
          setPhoneError(temp);
        }
        break;
      case `email`:
        if (email) {
          if (!emailRegex.test(email)) {
            temp.status = true;
            temp.msg = `Invalid Email Address`;
            setEmailError(temp);
          } else {
            setEmailError(temp);
          }
        } else {
          setEmailError(temp);
        }
        break;
      case `primaryDiagnosis`:
        if (!inputPDiagnosis?.current?.value) {
          temp.status = true;
          temp.msg = `*Required`;
          setPrimaryDiagnosisError(temp);
        } else {
          setPrimaryDiagnosisError(temp);
        }
        break;
      case `assignMD`:
        if (!assignMD) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignMDError(temp);
        } else {
          setAssignMDError(temp);
        }
        break;
      case `assignQHCP`:
        if (!assignQHCP) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignQHCPError(temp);
        } else {
          setAssignQHCPError(temp);
        }
        break;
      case `patientDeviceError`:
        if (!patientDeviceIdValue) {
          temp.status = true;
          temp.msg = `*Required`;
          setPatientDeviceError(temp);
        } else {
          setPatientDeviceError(temp);
        }
        break;
      default:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
          return false;
        }
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
          return false;
        }
        if (!gender) {
          temp.status = true;
          temp.msg = `*Required`;
          setGenderError(temp);
          return false;
        }
        if (!phone) {
          temp.status = true;
          temp.msg = `*Required`;
          setPhoneError(temp);
          return false;
        } else if (!phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setPhoneError(temp);
          return false;
        }
        if (email) {
          if (!emailRegex.test(email)) {
            temp.status = true;
            temp.msg = `Invalid Email Address`;
            setEmailError(temp);
            return false;
          }
        }
        if (!primaryDiagnosisQuery) {
          temp.status = true;
          temp.msg = `*Required`;
          setPrimaryDiagnosisError(temp);
          return false;
        }
        if (!assignMD) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignMDError(temp);
          return false;
        }
        if (!assignQHCP) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignQHCPError(temp);
          return false;
        }
        return true;
    }
  };

  // PHONE NO INPUT MASK
  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setPhone(temp);
  };

  // DEVICE NUMBER MASK
  const deviceNumberMask = (val) => {
    if (val.match(`^[a-zA-Z0-9]*$`) != null) {
      setStep1Number(val);
    }
  };

  // RESET FORM
  const resetForm = () => {
    setStep(1);
    setStep1Manufacturer(``);
    setStep1ManufacturerError({ status: false, msg: `` });
    setStep1Number(``);
    setStep1NumberError({ status: false, msg: `` });
    setStep1DateOfBirth();
    setStep1DateOfBirthError({ status: false, msg: `` });
    setFirstName(``);
    setInsuranceCompany(``);
    setFirstNameError({ status: false, msg: `` });
    setLastName(``);
    setLastNameError({ status: false, msg: `` });
    setDateOfBirth(``);
    setGender(``);
    setMRN(``);
    setGenderError({ status: false, msg: `` });
    setPhone(``);
    setPhoneError({ status: false, msg: `` });
    setEmail(``);
    setEmailError({ status: false, msg: `` });
    setPrimaryDiagnosisError({ status: false, msg: `` });
    setSecondaryDiagnosis(``);
    setSecondaryDiagnosisQuery(``);
    setPrimaryDiagnosisQuery(``);
    setManufacturer(``);
    setDeviceInfo({});
    setDeviceType(``);
    setMaskType(``);
    setMaskSize(``);
    setMaskCompany(``);
    setDeviceNumber(``);
    setAssignMD(``);
    setAssignMDError({ status: false, msg: `` });
    setAssignQHCP(``);
    setAssignQHCPError({ status: false, msg: `` });
    setNote(``);
    setOrgId(``);
    setOrgName(``);
    setPatientId(``);
    setSetupDate(``);
  };

  const queryStringify = (filters = {}) => {
    let query = `?`;
    for (let filterName in filters) {
      if (!filters[filterName] || filters[filterName] === ``) continue;
      let keyValPair = `${filterName}=${filters[filterName]}`;
      if (query !== `?`) {
        query += `&`;
      }
      query += keyValPair;
    }
    return query;
  };

  // STEP: 01, HANDLER, | FIELDS : DEVICE MANUFACTURE, DEVICE SERIAL NO, PATIENT DATE OF BIRTH
  const step1SubmitHandler = async () => {
    try {
      let temp = await step1ValidateInput();

      if (temp) {
        setLoading(true);
        if (step1Manufacturer === `resmed`) {
          let dob = momentOriginal(step1DateOfBirth).format(`YYYY-MM-DD`);
          let url = GET_RESMED_PATIENT_URL + `?dateOfBirth=${dob}&deviceSerialNumber=${step1Number}`;
          getRequest(url)
            .then((res) => {
              setFirstName(res?.data?.patient?.firstName);
              setLastName(res?.data?.patient?.lastName);
              setDateOfBirth(res?.data?.patient?.dateOfBirth);
              setGender(res?.data?.patient?.gender);
              setPhone(res?.data?.patient?.phoneNumber);
              setEmail(res?.data?.patient?.email);
              setPrimaryDiagnosis(res?.data?.patient?.primaryDiagnosis);
              setSecondaryDiagnosis(res?.data?.patient?.secondaryDiagnosis);
              setManufacturer(res?.data?.patient?.device?.manufacturer);
              setDeviceInfo(res?.data?.patient?.device);
              setDeviceType(res?.data?.patient?.device?.type);
              setMaskType(res?.data?.patient?.mask?.Type);
              // setMaskCompany(res?.data?.patient?.maskType); Uncomment after payload change backend
              // setMaskSize(res?.data?.patient?.maskType); Uncomment after payload change backend
              setDeviceNumber(res?.data?.patient?.device?.serialNumber);
              setAssignMD(res?.data?.patient?._providerId);
              setAssignQHCP(res?.data?.patient?._clinicalUserId);
              setNote(res?.data?.patient?.notes);
              setOrgId(res?.data?.patient?._deviceOrgId);
              setOrgName(res?.data?.patient?.deviceOrgName);
              setPatientId(res?.data?.patient?._idFromDevice);
              setSetupDate(res?.data?.patient?.setupDate);
              setLoading(false);
              setStep(2);
            })
            .catch((err) => {
              setLoading(false);
            });
        } else if (step1Manufacturer === `reactHealth`) {
          let reactHealthDob = step1ReactHealthDateOfBirth ? momentOriginal(step1ReactHealthDateOfBirth).format(`YYYY-MM-DD`) : ``;
          const payloadForReactHealth = queryStringify({
            patientIdFromDME: patientDeviceIdValue.trim(),
            firstName: step1FirstNameForReactHealth.trim(),
            lastName: step1LastNameForReactHealth.trim(),
            dateOfBirth: reactHealthDob.trim(),
          });
          getRequest(IMPORT_REACT_HEALTH_PATIENT + payloadForReactHealth)
            .then((response) => {
              if (response?.status === 200) {
                setFirstName(response?.data?.patient?.firstName);
                setLastName(response?.data?.patient?.lastName);
                setDateOfBirth(response?.data?.patient?.dateOfBirth);
                setGender(response?.data?.patient?.gender);
                setPhone(response?.data?.patient?.phoneNumber);
                setManufacturer(response?.data?.patient?.device?.manufacturer);
                setDeviceInfo(response?.data?.patient?.device);
                setDeviceNumber(response?.data?.patient?.device?.serialNumber);
                setDeviceType(response?.data?.patient?.device?.type);
                set_idFromDevice(response?.data?.patient?._idFromDevice);
                setExternalDeviceValue(response?.data?.patient?._externalDeviceId);
                setSetupDate(response?.data?.patient?.setupDate);
                setLoading(false);
                setStep(2);
              }
            })
            .catch(() => {
              setLoading(false);
            });
        } else {
          let dob = step1RespironicsDateOfBirth ? momentOriginal(step1RespironicsDateOfBirth).format(`YYYY-MM-DD`) : ``;
          let queryParamsValue = queryStringify({
            deviceSerialNumber: step1RespironicsNumber,
            firstName: step1FirstName,
            lastName: step1LastName,
            dateOfBirth: dob,
          });
          let url = GET_RESPIRONICS_PATIENT_URL + `${queryParamsValue}`;
          getRequest(url)
            .then((res) => {
              setFirstName(res?.data?.patient?.firstName);
              setLastName(res?.data?.patient?.lastName);
              setDateOfBirth(res?.data?.patient?.dateOfBirth);
              setGender(res?.data?.patient?.gender);
              setPhone(res?.data?.patient?.phoneNumber);
              setEmail(res?.data?.patient?.email);
              setPrimaryDiagnosis(res?.data?.patient?.primaryDiagnosis);
              setSecondaryDiagnosis(res?.data?.patient?.secondaryDiagnosis);
              setManufacturer(res?.data?.patient?.device?.manufacturer);
              setDeviceInfo(res?.data?.patient?.device);
              setDeviceType(res?.data?.patient?.device?.type);
              setMaskType(res?.data?.patient?.maskType);
              // setMaskCompany(res?.data?.patient?.maskType);  Uncomment after discussion/backend
              // setMaskSize(res?.data?.patient?.maskType);  Uncomment after discussion/backend
              setDeviceNumber(res?.data?.patient?.device?.serialNumber);
              setAssignMD(res?.data?.patient?._providerId);
              setAssignQHCP(res?.data?.patient?._clinicalUserId);
              setNote(res?.data?.patient?.notes);
              setOrgId(res?.data?.patient?._deviceOrgId);
              setOrgName(res?.data?.patient?.deviceOrgName);
              setPatientId(res?.data?.patient?._idFromDevice);
              setSetupDate(res?.data?.patient?.setupDate);
              setLoading(false);
              setStep(2);
            })
            .catch((err) => {
              setLoading(false);
            });
        }
      } else {
        toast.error(() => <>Unable to send request</>, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(() => <>Failed</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };
  // STEP: 02, HANDLER | PATIENT DETAILS LIKE, PHONE NO, EMAIL(OPTIONAL), DIAGNOSIS, MASK TYPE, MD, QHCP, NOTES ETC
  const submitHandler = async () => {
    try {
      const temp = await validateInput();
      if (temp) {
        setLoading(true);
        if (manufacturer === `resmed`) {
          postRequest(IMPORT_PATIENT_URL, {
            _practiceGroupId: _practiceGroupId,
            firstName,
            lastName,
            dateOfBirth,
            gender,
            phoneNumber: phone,
            email,
            primaryDiagnosis: primaryDiagnosisQuery,
            primaryDiagnosisId: primaryDiagnosisQueryId,
            secondaryDiagnosis: secondaryDiagnosisQuery,
            secondaryDiagnosisId: secondaryDiagnosisQueryId,
            device: {
              manufacturer: manufacturer,
              type: deviceType,
              serialNumber: deviceNumber,
              ...(typeof deviceInfo === `object` ? { ...deviceInfo } : {})
            },
            baseLineAHI,
            MRN,
            mask: {
              manufacturer: maskCompany,
              type: maskType,
              size: maskSize,
            },
            insuranceCompany,
            _providerId: assignMD,
            _clinicalUserId: assignQHCP,
            notes: note,
            _deviceOrgId: _orgId,
            deviceOrgName: orgName,
            _idFromDevice: patientId,
            setupDate,
          })
            .then(() => {
              setIsRegistered(true);
              setLoading(false);
              successToast(`New patient added`);
              dispatch(newImportPatientAddedSuccessfully()); // REFRESH LIST ALL PATIENT, IMPORT SUCCESSFULLY
              setImportPatientModal(false);
              resetForm();
              close.current.click();
            })
            .catch((err) => {
              if (err.response?.data?.message) {
                toast.error(() => <>{err.response?.data?.message ? err.response?.data?.message : err.response?.data?.error}</>, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
                });
              }
              setLoading(false);
            });
        } else if (manufacturer === `reactHealth`) {
          let stringKey = _idFromDevice.toString();
          postRequest(IMPORT_PATIENT_FROM_REACT_HEALTH, {
            _practiceGroupId: _practiceGroupId,
            _externalDeviceId: externalDeviceValue,
            firstName,
            lastName,
            dateOfBirth,
            gender,
            phoneNumber: phone,
            email,
            baseLineAHI: baseLineAHI,
            MRN: MRN,
            primaryDiagnosis: primaryDiagnosisQuery,
            primaryDiagnosisId: primaryDiagnosisQueryId,
            secondaryDiagnosis: secondaryDiagnosisQuery,
            secondaryDiagnosisId: secondaryDiagnosisQueryId,
            device: {
              manufacturer: manufacturer,
              type: deviceType,
              serialNumber: deviceNumber,
            },
            mask: {
              manufacturer: maskCompany,
              type: maskType,
              size: maskSize,
            },
            insuranceCompany,
            _providerId: assignMD,
            _clinicalUserId: assignQHCP,
            notes: note,
            _deviceOrgId: _orgId,
            deviceOrgName: orgName,
            _idFromDevice: stringKey,
            setupDate,
          }).then((response) => {
            setLoading(false);
            if (response?.status === 200) {
              setIsRegistered(true);
              setLoading(false);
              successToast(`New patient added`);
              setImportPatientModal(false);
            }
          }).catch(() => {
            setLoading(false);
          })
        } else {
          postRequest(IMPORT_PATIENT_URL_FROM_URL, {
            _practiceGroupId: _practiceGroupId,
            firstName,
            lastName,
            dateOfBirth,
            gender,
            phoneNumber: phone,
            email,
            baseLineAHI: baseLineAHI,
            MRN: MRN,
            primaryDiagnosis: primaryDiagnosisQuery,
            primaryDiagnosisId: primaryDiagnosisQueryId,
            secondaryDiagnosis: secondaryDiagnosisQuery,
            secondaryDiagnosisId: secondaryDiagnosisQueryId,
            device: {
              manufacturer: manufacturer,
              type: deviceType,
              serialNumber: deviceNumber,
              ...(typeof deviceInfo === `object` ? { ...deviceInfo } : {})
            },
            mask: {
              manufacturer: maskCompany,
              type: maskType,
              size: maskSize,
            },
            insuranceCompany,
            _providerId: assignMD,
            _clinicalUserId: assignQHCP,
            notes: note,
            _deviceOrgId: _orgId,
            deviceOrgName: orgName,
            _idFromDevice: patientId,
            setupDate,
          })
            .then(() => {
              setIsRegistered(true);
              setLoading(false);
              successToast(`New patient added`);
              dispatch(newImportPatientAddedSuccessfully()); // REFRESH LIST ALL PATIENT, IMPORT SUCCESSFULLY
              setImportPatientModal(false);
              resetForm();
              close.current.click();
            })
            .catch((err) => {
              if (err.response?.data?.message) {
                toast.error(() => <>{err.response?.data?.message ? err.response?.data?.message : err.response?.data?.error}</>, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 2000,
                });
              }
              setLoading(false);
            });
        }
      } else {
        toast.error(() => <>Please provide all the required fields</>, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error(() => <>Failed</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
      });
    }
  };

  const alertForConfirmation = (selectedValue) => {
    if (step1Number || step1DateOfBirth || step1FirstName || step1LastName || step1RespironicsDateOfBirth || step1RespironicsNumber || step1FirstNameForReactHealth || step1LastNameForReactHealth || step1ReactHealthDateOfBirth || patientDeviceIdValue) {
      Swal.fire({
        title: 'Attention!',
        text: 'Switching device type will clear input fields',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result?.isConfirmed) {
          setStep1Number(``);
          setStep1DateOfBirth(null);
          setStep1FirstName(``);
          setStep1LastName(``);
          setStep1RespironicsDateOfBirth(null);
          setStep1RespironicsNumber(``);
          setStep1FirstNameForReactHealth(``);
          setStep1LastNameForReactHealth(``);
          setStep1ReactHealthDateOfBirth(null);
          setPatientDeviceIdValue(``);
          setStep1Manufacturer(selectedValue);
        }
      });
    } else {
      // No need for confirmation if neither step1Number nor step1DateOfBirth has a value
      setStep1Manufacturer(selectedValue);
    }
  };

  //select package css styling
  const maskSizes = useMemo(() => {
    let sizes = [];
    if (maskCompany === `Resmed`) {
      sizes = resmedMaskTypes;
    } else if (maskCompany === `Respironics`) {
      sizes = respironicsMaskTypes;
    } else if (maskCompany === `Fisher & Paykel`) {
      sizes = fisherMaskTypes;
    }
    return sizes;
  });

  // apiCall to get the options
  const externalDeviceOption = () => {
    let queryParamsValue = `?deviceManufacturer=reactHealth`;
    getRequest(EXTERNAL_DEVICE_OPTION + queryParamsValue).then((response) => {
      setoptionsForExternalDevice(response?.data?.externalDevices);
    });
  };

  useEffect(() => {
    externalDeviceOption();
  }, []);

  return (
    <div
      style={{
        backgroundColor: `transparent`,
        position: `relative`,
      }}
    >
      <CustomizedDialogs
        title="IMPORT PATIENT ON EXISTING DEVICE"
        open={importPatientModal}
        setOpen={() => {
          if (isRegistered) {
            dispatch({ type: `ADD_PATIENT`, payLoad: true });
          }
          setImportPatientModal(false);
        }}
        size="md"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <ToastContainer />
        <div>
          {step === 2 ? (
            <>
              <p className={css.sectionHeadingText} style={{ paddingBottom: `16px` }}>
                STEP 2: EDIT AND ADD PATIENT
              </p>
              <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                Basic Information
              </p>
              <hr style={{ margin: `0px` }} />

              <Box sx={{ mt: 2, mb: 2 }}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputField
                      size="small"
                      fullWidth={true}
                      error={firstNameError.status && !firstName ? css.errorCustom : null}
                      onBlur={() => validateInput(`firstName`)}
                      autoComplete="off"
                      onChange={(event) => {
                        setFirstName(event.target.value);
                        validateInput(`firstName`);
                      }}
                      value={firstName}
                      type="text"
                      sx={{
                        textTransform: `capitalize`,
                      }}
                      label="First Name*"
                      maxLength="25"
                    />
                    <p className={firstNameError.status && !firstName ? `d-block text-danger` : `d-none`}>{firstNameError.msg}</p>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputField
                      size="small"
                      fullWidth={true}
                      error={lastNameError.status && !lastName ? css.errorCustom : null}
                      onBlur={() => validateInput(`lastName`)}
                      autoComplete="off"
                      onChange={(event) => {
                        setLastName(event.target.value);
                        validateInput(`lastName`);
                      }}
                      value={lastName}
                      type="text"
                      sx={{
                        textTransform: `capitalize`,
                      }}
                      label="Last Name*"
                      maxLength="25"
                    />
                    <p className={lastNameError.status && !lastName ? `d-block text-danger` : `d-none`}>{lastNameError.msg}</p>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <DatePickerCustomize
                      dateInputValue={dateOfBirth || null}
                      step1RespironicsNumber={false}
                      setDateInputValue={setDateOfBirth}
                      disabled={true}
                      pickerProps={{
                        size: `small`,
                        label: `DOB`,
                        fullWidth: true,
                      }}
                      InputLabelProps={{
                        style: {
                          left: `-1%`,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <MuiSelect
                      controlProps={{
                        id: `patientGender`,
                        size: `small`,
                        fullWidth: true,
                      }}
                      label="Gender*"
                      isSimple={true}
                      optionKey="name"
                      optionValue={`value`}
                      selectProps={{
                        error: genderError.status && !gender ? css.errorCustom : null,
                        label: `Gender*`,
                        onBlur: () => validateInput(`gender`),
                        onChange: (event) => {
                          setGender(event.target.value);
                          validateInput(`gender`);
                        },
                        value: gender,
                      }}
                      options={[...genderOPtions]}
                    />
                    <p className={genderError.status && !gender ? `d-block text-danger` : `d-none`}>{genderError.msg}</p>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputField
                      size="small"
                      fullWidth={true}
                      error={phoneError.status ? css.errorCustom : null}
                      onBlur={() => validateInput(`phone`)}
                      autoComplete="off"
                      onChange={(event) => {
                        phoneMask(event.target.value);
                        validateInput(`phone`);
                      }}
                      value={phone}
                      type="text"
                      label="Phone Number"
                    />
                    <p className={phoneError.status ? `d-block text-danger` : `d-none`}>{phoneError.msg}</p>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputField
                      size="small"
                      fullWidth={true}
                      error={emailError.status ? css.errorCustom : null}
                      onBlur={() => validateInput(`email`)}
                      autoComplete="off"
                      onChange={(event) => {
                        setEmail(event.target.value);
                        validateInput(`email`);
                      }}
                      value={email}
                      type="email"
                      label="Email"
                    />
                    <p className={emailError.status ? `d-block text-danger` : `d-none`}>{emailError.msg}</p>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputField
                      size="small"
                      fullWidth={true}
                      autoComplete="off"
                      onChange={(event) => {
                        setInsuranceCompany(event.target.value);
                      }}
                      value={insuranceCompany}
                      type="text"
                      label="Insurance Company"
                    />
                  </Grid>
                </Grid>
              </Box>

              <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                Clinical Information
              </p>
              <hr style={{ margin: `0px` }} />
              <Box sx={{ mt: 2, mb: 2 }}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Downshift
                      onChange={(e) => {
                        primaryDiagnosisDropdownValueOnChange(e);
                      }}
                      itemToString={(item) => (item ? item.Description : ``)}
                      inputValue={primaryDiagnosisQuery}
                    >
                      {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                        <div>
                          <div className="input-group mb-3">
                            <InputField
                              label="Primary Diagnosis Search*"
                              size="small"
                              fullWidth={true}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">{primaryDiagnosisIsLoading && <InputSpinner size="sm" />}</InputAdornment>
                                ),
                              }}
                              value={primaryDiagnosisQuery}
                              error={primaryDiagnosisError?.status ? true : false}
                              helperText={primaryDiagnosisError?.msg ? primaryDiagnosisError?.msg : null}
                              onBlur={() => {
                                validateInput(`primaryDiagnosis`);
                              }}
                              onChange={(e) => {
                                comBosPrimaryDiagnosisOnChange(e);
                                validateInput(`primaryDiagnosis`);
                                setUseState({
                                  key: `primary`,
                                  value: e.currentTarget,
                                  setState: setAnchorElemDiagnosis,
                                });
                              }}
                              inputProps={{
                                ref: inputPDiagnosis,
                              }}
                            />
                          </div>
                          {isPrimaryOpenDropDown ? (
                            <div
                              id={css.downshiftDropdown}
                              style={{
                                overflowY: `scroll`,
                                maxHeight: `140px`,
                              }}
                            >
                              {!primaryDiagnosisListItems.length ? (
                                <div
                                  id={css.dropdownItem}
                                  style={{
                                    backgroundColor: `rgb(251 251 251)`,
                                    fontWeight: `normal`,
                                    padding: `1px`,
                                    borderBottom: `1px solid lightgrey`,
                                    cursor: `pointer`,
                                    color: `red`,
                                  }}
                                >
                                  No Data Found
                                </div>
                              ) : primaryDiagnosisQuery === `` ? null : (
                                primaryDiagnosisListItems?.map((item, index) => (
                                  <div
                                    id={css.dropdownItem}
                                    {...getItemProps({ key: index, index, item })}
                                    style={{
                                      backgroundColor: highlightedIndex === index ? `lightgrey` : `rgb(251 251 251)`,
                                      fontWeight: selectedItem === item ? `bold` : `normal`,
                                      padding: `1px`,
                                      borderBottom: `1px solid lightgrey`,
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {item?.Description}
                                  </div>
                                ))
                              )}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Downshift>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Downshift
                      onChange={(e) => {
                        secondaryDiagnosisDropdownValueOnChange(e);
                      }}
                      itemToString={(item) => (item ? item.Description : ``)}
                      inputValue={secondaryDiagnosisQuery}
                    >
                      {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                        <div>
                          <div className="input-group mb-3">
                            <InputField
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">{secondaryDiagnosisLoading && <InputSpinner size="sm" />}</InputAdornment>
                                ),
                              }}
                              value={secondaryDiagnosisQuery}
                              size="small"
                              fullWidth={true}
                              label="Secondary Diagnosis Search"
                              onChange={(e) => {
                                comBosSecondaryDiagnosisOnChange(e);
                                setUseState({
                                  key: `secondary`,
                                  value: e.currentTarget,
                                  setState: setAnchorElemDiagnosis,
                                });
                              }}
                            />
                          </div>
                          {isOpenDropDown ? (
                            <div
                              id={css.downshiftDropdown}
                              style={{
                                overflowY: `scroll`,
                                maxHeight: `140px`,
                              }}
                            >
                              {!secDiagnosisListItems.length ? (
                                <div
                                  id={css.dropdownItem}
                                  style={{
                                    backgroundColor: `rgb(251 251 251)`,
                                    fontWeight: `normal`,
                                    padding: `1px`,
                                    borderBottom: `1px solid lightgrey`,
                                    cursor: `pointer`,
                                    color: `red`,
                                  }}
                                >
                                  No Data Found
                                </div>
                              ) : secondaryDiagnosisQuery === `` ? null : (
                                secDiagnosisListItems?.map((item, index) => (
                                  <div
                                    id={css.dropdownItem}
                                    {...getItemProps({ key: index, index, item })}
                                    style={{
                                      backgroundColor: highlightedIndex === index ? `lightgrey` : `rgb(251 251 251)`,
                                      fontWeight: selectedItem === item ? `bold` : `normal`,
                                      padding: `1px`,
                                      borderBottom: `1px solid lightgrey`,
                                      cursor: `pointer`,
                                    }}
                                  >
                                    {item?.Description}
                                  </div>
                                ))
                              )}
                            </div>
                          ) : null}
                        </div>
                      )}
                    </Downshift>
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputField
                      fullWidth={true}
                      size="small"
                      autoComplete="off"
                      // disabled
                      value={baseLineAHI}
                      type="text"
                      name="baselineAHI"
                      label="Baseline AHI"
                      onChange={(e) => setBaseLineAHI(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <InputField
                      fullWidth={true}
                      size="small"
                      autoComplete="off"
                      value={MRN}
                      type="text"
                      name="MRN"
                      label="Medical Record Number"
                      onChange={(e) => setMRN(e.target.value)}
                    />
                  </Grid>
                </Grid>
              </Box>

              {/* Mask Information */}
              <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                Equipment Information
              </p>
              <hr style={{ margin: `0px` }} />
              <Box sx={{ mt: 2, mb: 2 }}>
                <Grid container columnSpacing={1} rowSpacing={2}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <MuiSelect
                      controlProps={{
                        id: `addPatientManufacture`,
                        size: `small`,
                        fullWidth: true,
                      }}
                      label="Device Manufacturer*"
                      options={[...appDevices]}
                      isSimple={false}
                      optionKey={`name`}
                      optionValue={`value`}
                      selectProps={{
                        value: manufacturer,
                        name: `deviceManufacturer`,
                        id: `addPatientManufacture`,
                        label: `Device Manufacturer*`,
                        disabled: true,
                      }}
                    />
                  </Grid>
                  {step1Manufacturer === `reactHealth` ? (
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <InputField
                        size="small"
                        fullWidth={true}
                        autoComplete="off"
                        disabled
                        value={_idFromDevice}
                        type="text"
                        name="patientId"
                        label="PatientId"
                      />
                    </Grid>
                  ) : (
                    <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                      <InputField
                        size="small"
                        fullWidth={true}
                        autoComplete="off"
                        disabled
                        value={deviceNumber}
                        type="text"
                        name="deviceNumber"
                        label="Device Number"
                      />
                    </Grid>
                  )}
                  <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <MuiSelect
                      controlProps={{
                        id: `addPatientMaskCompany`,
                        size: `small`,
                        fullWidth: true,
                      }}
                      label="Mask Company"
                      options={[...maskDevices]}
                      isSimple={true}
                      selectProps={{
                        label: `Mask Company`,
                        id: `addPatientMaskCompany`,
                        onChange: (event) => setMaskCompany(event.target.value),
                        value: maskCompany,
                        name: `maskCompany`,
                      }}
                    />
                  </Grid>
                  {maskCompany ? (
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <TextField select fullWidth size="small" label="Mask Type" onChange={(event) => setMaskType(event.target.value)}>
                        {maskCompany === `Resmed`
                          ? resmedGroupedOptions.map((item, index) => [
                            <ListSubheader key={index + `resmed`}>{item.label}</ListSubheader>,
                            item.options.map((subItem, index) => (
                              <MenuItem key={`key` + index} value={subItem.value}>
                                {subItem.label}
                              </MenuItem>
                            )),
                          ])
                          : maskCompany === `Respironics`
                            ? respironicsGroupedOptions.map((item, index) => [
                              <ListSubheader key={index + `respironics` + item.label}>{item.label}</ListSubheader>,
                              item.options.map((subItem) => (
                                <MenuItem key={index + `resKey`} value={subItem.value}>
                                  {subItem.label}
                                </MenuItem>
                              )),
                            ])
                            : maskCompany === `Fisher & Paykel`
                              ? fisherAndPaykelGroupedOptions.map((item, index) => [
                                <ListSubheader key={`Paykel` + index}>{item.label}</ListSubheader>,
                                item.options.map((subItem) => (
                                  <MenuItem key={index + `key-Paykel`} value={subItem.value}>
                                    {subItem.label}
                                  </MenuItem>
                                )),
                              ])
                              : null}
                      </TextField>
                    </Grid>
                  ) : null}
                  {maskType ? (
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                      <MuiSelect
                        controlProps={{
                          id: `addPatientMaskSize`,
                          size: `small`,
                          fullWidth: true,
                        }}
                        label="Mask Size"
                        options={[...maskSizes]}
                        isSimple={true}
                        selectProps={{
                          label: `Mask Size`,
                          id: `addPatientMaskSize`,
                          onChange: (event) => setMaskSize(event.target.value),
                          value: maskSize,
                          name: `maskSize`,
                        }}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </Box>

              <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                Assign Care Team
              </p>
              <hr style={{ margin: `0px` }} />
              <Box sx={{ mt: 2, mb: 2 }}>
                <Grid container rowSpacing={2} columnSpacing={1}>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <MuiSelect
                      isSimple={false}
                      optionKey={`fullName`}
                      optionValue={`_id`}
                      options={[...MDData]}
                      controlProps={{
                        id: `addPatientAssignMd`,
                        fullWidth: true,
                        size: `small`,
                      }}
                      label="Provider*"
                      selectProps={{
                        label: `Provider*`,
                        error: assignMDError.status && !assignMD ? true : false,
                        onBlur: () => validateInput(`assignMD`),
                        onChange: (event) => {
                          setAssignMD(event.target.value);
                          validateInput(`assignMD`);
                        },
                        value: assignMD,
                        name: `assignMD`,
                      }}
                    />
                    <p className={assignMDError.status && !assignMD ? `d-block text-danger` : `d-none`}>{assignMDError.msg}</p>
                  </Grid>
                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                    <MuiSelect
                      isSimple={false}
                      optionKey={`fullName`}
                      optionValue={`_id`}
                      options={[...QhCPUser]}
                      controlProps={{
                        id: `assignQHCP`,
                        fullWidth: true,
                        size: `small`,
                      }}
                      label="Clinical Staff*"
                      selectProps={{
                        label: `Clinical Staff*`,
                        error: assignQHCPError.status && !assignQHCP ? css.errorCustom : null,
                        onBlur: () => validateInput(`assignQHCP`),
                        onChange: (event) => {
                          setAssignQHCP(event.target.value);
                          validateInput(`assignQHCP`);
                        },
                        value: assignQHCP,
                        name: `assignQHCP`,
                      }}
                    />
                    <p className={assignQHCPError.status && !assignQHCP ? `d-block text-danger` : `d-none`}>{assignQHCPError.msg}</p>
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ width: `100%`, mt: 2 }}>
                <InputField
                  multiline={true}
                  size="small"
                  fullWidth={true}
                  rows="5"
                  minRows={7}
                  maxRows={10}
                  value={note}
                  onChange={(event) => setNote(event.target.value)}
                  name="note"
                  label="Notes..."
                />
              </Box>
            </>
          ) : (
            <>
              <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                STEP 1: SEARCH PATIENT
              </p>
              <hr style={{ margin: `0px` }} />
              <Box sx={{ mt: 2, width: `50%` }}>
                <MuiSelect
                  controlProps={{
                    id: `deviceManufactureImport`,
                    size: `small`,
                    fullWidth: true,
                  }}
                  label="Device Manufacturer*"
                  isSimple={false}
                  optionKey="name"
                  optionValue={`value`}
                  selectProps={{
                    error: step1ManufacturerError.status && !step1Manufacturer ? true : false,
                    label: `Device Manufacturer*`,
                    onBlur: () => step1ValidateInput(`step1Manufacturer`),
                    onChange: (event) => {
                      alertForConfirmation(event.target.value);
                      step1ValidateInput(`step1Manufacturer`);
                    },
                    value: step1Manufacturer,
                  }}
                  options={[...appDevices]}
                />
              </Box>
              <p className={step1ManufacturerError.status && !step1Manufacturer ? `d-block text-danger` : `d-none`}>{step1ManufacturerError.msg}</p>

              {step1Manufacturer ? (
                <Box sx={{ mt: 2 }}>
                  <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                    Search By
                  </p>
                  <hr style={{ margin: `0px` }} />

                  {step1Manufacturer === `respironics` ? (
                    <Box sx={{ mt: 2 }}>
                      <Grid container columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <InputField
                            fullWidth={true}
                            size="small"
                            label={`First Name${isSerial === `hasSerial` || step1RespironicsNumber?.length > 0 ? `` : `*`}`}
                            disabled={isSerial === `hasSerial` || step1RespironicsNumber?.length > 0 ? true : false}
                            onFocus={() => setIsSerial(`noSerial`)}
                            onBlur={() => setIsSerial(``)}
                            error={step1FirstNameError.status && !step1FirstName ? css.errorCustom : null}
                            // onBlur={() => step1ValidateInput('step1FirstName')}
                            autoComplete="off"
                            onChange={(event) => {
                              setStep1FirstName(event.target.value);
                            }}
                            value={step1FirstName}
                            type="text"
                            name="step1FirstName"
                          />
                          <p className={step1FirstNameError.status && !step1FirstName ? `d-block text-danger` : `d-none`}>
                            {step1FirstNameError.msg}
                          </p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <InputField
                            size="small"
                            fullWidth={true}
                            label={`Last Name${isSerial === `hasSerial` || step1RespironicsNumber?.length > 0 ? `` : `*`}`}
                            disabled={isSerial === `hasSerial` || step1RespironicsNumber?.length > 0 ? true : false}
                            error={step1LastNameError.status && !step1LastName ? true : false}
                            // onBlur={() => step1ValidateInput('step1LastName')}
                            autoComplete="off"
                            onFocus={() => setIsSerial(`noSerial`)}
                            onBlur={() => setIsSerial(``)}
                            onChange={(event) => {
                              setStep1LastName(event.target.value);
                            }}
                            value={step1LastName}
                            type="text"
                            name="step1LastName"
                          />
                          <p className={step1LastNameError.status && !step1LastName ? `d-block text-danger` : `d-none`}>{step1LastNameError.msg}</p>
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <DatePickerCustomize
                            name="dateOfBirth"
                            setDateInputError={setDateError}
                            dateInputError={step1RespironicsDateOfBirthError}
                            dateInputValue={step1RespironicsDateOfBirth}
                            setDateInputValue={setStep1RespironicsDateOfBirth}
                            serial={isSerial}
                            step1RespironicsNumber={step1RespironicsNumber}
                            pickerProps={{ size: `small`, label: `DOB${isSerial === `hasSerial` || step1RespironicsNumber?.length > 0 ? `` : `*`}` }}
                          />
                          {dateError && (
                            <p style={{ color: `red` }}>Please Input Valid Date</p>
                          )}

                          <p
                            style={{ position: `absolute` }}
                            className={step1RespironicsDateOfBirthError.status && !step1RespironicsDateOfBirth ? `d-block text-danger` : `d-none`}
                          >
                            {step1RespironicsDateOfBirthError.msg}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <span>OR</span>
                          <br />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <InputField
                            fullWidth={true}
                            label=" Device Serial Number"
                            size="small"
                            disabled={
                              isSerial === `noSerial` || step1FirstName?.length > 0 || step1LastName?.length > 0 || step1RespironicsDateOfBirth
                                ? true
                                : false
                            }
                            error={step1RespironicsNumberError.status && !step1RespironicsNumber ? css.errorCustom : null}
                            autoComplete="off"
                            onFocus={() => setIsSerial(`hasSerial`)}
                            onBlur={() => setIsSerial(``)}
                            onChange={(event) => {
                              setStep1RespironicsNumber(event.target.value);
                            }}
                            value={step1RespironicsNumber}
                            type="text"
                            name="step1RespironicsNumber"
                          />
                          <p className={step1RespironicsNumberError.status && !step1RespironicsNumber ? `d-block text-danger` : `d-none`}>
                            {step1RespironicsNumberError.msg}
                          </p>
                        </Grid>
                      </Grid>
                    </Box>
                  ) : step1Manufacturer === `reactHealth` ? (
                    <>
                      <Box sx={{ mt: 2 }}>
                        <Grid container columnSpacing={1} rowSpacing={2}>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <InputField
                              fullWidth={true}
                              size="small"
                              label={`First Name${isSerialDeviceId === `hasSerial` || patientDeviceIdValue?.length > 0 ? `` : `*`}`}
                              disabled={isSerialDeviceId === `hasSerial` || patientDeviceIdValue?.length > 0 ? true : false}
                              onFocus={() => setIsSerialDeviceId(`noSerial`)}
                              onBlur={() => setIsSerialDeviceId(``)}
                              error={step1FirstNameErrorForReactHealth.status && !step1FirstNameForReactHealth ? css.errorCustom : null}
                              autoComplete="off"
                              onChange={(event) => {
                                setStep1FirstNameForReactHealth(event.target.value);
                              }}
                              value={step1FirstNameForReactHealth}
                              type="text"
                              name="step1FirstNameForReactHealth"
                            />
                            <p
                              className={step1FirstNameErrorForReactHealth.status && !step1FirstNameForReactHealth ? `d-block text-danger` : `d-none`}
                            >
                              {step1FirstNameErrorForReactHealth.msg}
                            </p>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <InputField
                              size="small"
                              fullWidth={true}
                              label={`Last Name${isSerialDeviceId === `hasSerial` || patientDeviceIdValue?.length > 0 ? `` : `*`}`}
                              disabled={isSerialDeviceId === `hasSerial` || patientDeviceIdValue?.length > 0 ? true : false}
                              error={step1LastNameNameErrorForReactHealth.status && !step1LastNameForReactHealth ? true : false}
                              autoComplete="off"
                              onFocus={() => setIsSerialDeviceId(`noSerial`)}
                              onBlur={() => setIsSerialDeviceId(``)}
                              onChange={(event) => {
                                setStep1LastNameForReactHealth(event.target.value);
                              }}
                              value={step1LastNameForReactHealth}
                              type="text"
                              name="step1LastNameForReactHealth"
                            />
                            <p
                              className={
                                step1LastNameNameErrorForReactHealth.status && !step1LastNameForReactHealth ? `d-block text-danger` : `d-none`
                              }
                            >
                              {step1LastNameNameErrorForReactHealth.msg}
                            </p>
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <DatePickerCustomize
                              name="dateOfBirth"
                              setDateInputError={setDateError}
                              dateInputError={step1ReactHealthDateOfBirthError}
                              dateInputValue={step1ReactHealthDateOfBirth}
                              setDateInputValue={setStep1ReactHealthDateOfBirth}
                              serial={isSerialDeviceId}
                              step1RespironicsNumber={patientDeviceIdValue}
                              pickerProps={{ size: `small`, label: `DOB${isSerialDeviceId === `hasSerial` || patientDeviceIdValue?.length > 0 ? `` : `*`}` }}
                            />
                            {dateError && (
                              <p style={{ color: `red` }}>Please Input Valid Date</p>
                            )}
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <span>OR</span>
                            <br />
                          </Grid>
                          <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                            <InputField
                              fullWidth={true}
                              label="Patient URL Id*"
                              size="small"
                              // error={patientDeviceError.status && !patientDeviceIdValue ? true : null}
                              disabled={
                                isSerialDeviceId === `noSerial` ||
                                  step1FirstNameForReactHealth?.length > 0 ||
                                  step1LastNameForReactHealth?.length > 0 ||
                                  step1ReactHealthDateOfBirth
                                  ? true
                                  : false
                              }
                              onFocus={() => setIsSerialDeviceId(`hasSerial`)}
                              onBlur={() => {
                                setIsSerialDeviceId(``);
                                // validateInput(`patientDeviceError`)
                              }}
                              autoComplete="off"
                              onChange={(event) => {
                                setPatientDeviceIdValue(event?.target?.value);
                                // validateInput(`patientDeviceError`);
                              }}
                              value={patientDeviceIdValue}
                              type="text"
                              maxLength="15"
                              helperText={!patientDeviceIdValue && patientDeviceError.msg ? patientDeviceError.msg : null}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                  ) : (
                    <Box sx={{ mt: 2 }}>
                      <Grid container columnSpacing={1} rowSpacing={2}>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <InputField
                            fullWidth={true}
                            label="Device Serial Number*"
                            size="small"
                            error={step1NumberError.status && !step1Number ? true : null}
                            onBlur={() => step1ValidateInput(`step1Number`)}
                            autoComplete="off"
                            onChange={(event) => {
                              setStep1Number(event.target.value);
                              deviceNumberMask(event.target.value);
                              step1ValidateInput(`step1Number`);
                            }}
                            value={step1Number}
                            type="text"
                            name="step1Number"
                            maxLength="15"
                            helperText={step1NumberError.msg ? step1NumberError.msg : null}
                          />
                        </Grid>
                        <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                          <DatePickerCustomize
                            setDateInputError={setDateError}
                            dateInputError={step1RespironicsDateOfBirthError}
                            dateInputValue={step1DateOfBirth}
                            setDateInputValue={(date) => {
                              setStep1DateOfBirth(date)
                              step1ValidateInput(`step1DOB`, date)
                            }}
                            pickerProps={{ size: `small`, label: `DOB*`, fullWidth: true }}
                          />
                          {dateError && (
                            <p style={{ color: `red` }}>Please Input Valid Date</p>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              ) : null}
            </>
          )}
        </div>
        <hr style={{ marginTop: 17 }} />
        <div className="d-flex justify-content-center">
          {step === 2 ? (
            <button
              type="button"
              className="btn btn-outline-info"
              id={css.addNewUserModalButton}
              onClick={submitHandler}
              disabled={!primaryDiagnosisListItems?.length || inputPDiagnosis?.current?.value === `` ? true : false}
            >
              ADD PATIENT
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-info"
              style={{ width: `auto` }}
              id={css.addNewUserModalButton}
              onClick={step1SubmitHandler}
              disabled={
                step1Manufacturer === `resmed`
                  ? false
                  : patientDeviceIdValue
                    ? false
                    : step1RespironicsNumber ||
                      step1FirstName ||
                      step1FirstNameForReactHealth ||
                      step1LastNameForReactHealth ||
                      step1LastName ||
                      step1ReactHealthDateOfBirth ||
                      step1RespironicsDateOfBirth
                      ? false
                      : true
              }
            >
              SEARCH PATIENT
            </button>
          )}
        </div>
        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    </div>
  );
};
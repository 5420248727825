import React, { useEffect, useState } from 'react';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';

export const AcceptTermsPrivacyPolicy = (props) => {
  const [agree, setAgree] = useState(false);
  useEffect(() => {
    props.setAgreeWithTermsAndPolicy(agree)
  }, [agree])

  return (
    <CustomizedDialogs
      noTitle={true}
      open={props.modal}
      setOpen={() => props.setModal(false)}
      size="md"
      fullWidth={true}
      showCloseButton={false}
      customCheckbox={true}
      customCheckboxLabel="Accept terms and conditions"
      customCheckboxValue={agree}
      customCheckboxAction={() => setAgree((p) => !p)}
    >
      <div style={{ overflowY: `auto`, height: 350, padding: 10 }}>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `right`,
          }}
        >
          <strong>
            <em>
              <span style={{ fontSize: `11px`, fontFamily: `"Times New Roman","serif"` }}>Revised August 10, 2022</span>
            </em>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `center`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontSize: `19px`, fontFamily: `"Times New Roman","serif"` }}>TERMS OF USE</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `center`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
                <span style={{ textDecoration: `none` }}>&nbsp;</span>
              </span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Thank you for using the MonitAir™ cloud hosted software as a service platform and related software, documentations, and applications
            (collectively, the “
            <strong>
              <em>Platform</em>
            </strong>
            ”) and/or the MonitAir<sup>TM&nbsp;</sup>mobile software application and related documentations (collectively, the “
            <strong>
              <em>App,</em>
            </strong>
            ” together with the Platform, the “
            <strong>
              <em>Services</em>
            </strong>
            ”). &nbsp;Please read these Terms of Use carefully before accessing or using our website monitairhealth.com (the “
            <strong>
              <em>Website</em>
            </strong>
            ”) and/or our Services.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            These Terms of Use (these “
            <strong>
              <em>Terms</em>
            </strong>
            ”) describe your rights and responsibilities as a customer of the Services. &nbsp;These Terms are between you and MonitAir, LLC here (“
            <strong>
              <em>MonitAir</em>
            </strong>
            ”, “
            <strong>
              <em>we</em>
            </strong>
            ” or “
            <strong>
              <em>us</em>
            </strong>
            ”). &nbsp;“
            <strong>
              <em>You</em>
            </strong>
            ” means the entity you represent in accepting these Terms or, if that does not apply, you individually. &nbsp;If you are accepting on
            behalf of your employer or another entity, you represent and warrant that: (i) you have full legal authority to bind your employer or
            such entity to these Terms; (ii) you have read and understand these Terms; and (iii) you agree to these Terms on behalf of the party
            that you represent.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>1. &nbsp;Acceptance of the Terms</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            By accessing or using our Website or Services, or acquiring rights to access or use our Services, you acknowledge that you have read and
            understood these Terms, including the terms of our Privacy Policy and other additional terms and conditions and policies that may be
            referenced herein and/or available by hyperlink. &nbsp;You agree to be bound by these Terms and to comply with all applicable laws and
            regulations regarding your use of the Website and/or the Services and you acknowledge that these Terms constitute a binding and
            enforceable legal contract between MonitAir and you.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            If you do not agree to these Terms, please do not enter, connect to, access, or use our Website, Services, or any part thereof in any
            manner. &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `12.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>
            Any new features or tools which we may add to the Website or the Services shall also be subject to these Terms. You can review the most
            current version of the Terms at any time at monitairhealth.com/term-of-use/. We reserve the right to update, change or replace any part
            of these Terms by posting updates and/or changes at monitairhealth.com/term-of-use/. It is your responsibility to check this page
            periodically for changes. Your continued use of or access to the Website or the Services following the posting of any changes
            constitutes acceptance of those changes.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>
              2. &nbsp;The Services and Third Party Products.
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Customers to our Platform are individual providers of health care services, their practice entities or other health care facilities who
            perform remote patient monitoring. &nbsp;Our Platform operates electronically to collect, collate, display, and organize certain care
            treatment plans, patient data, patient communications and reporting of remote patient monitoring services performed by our customers.
            &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Our App allows individual patient users to access their sleep data, track their adherence to CPAP usage, and check the MontiAir
            <sup>TM</sup> risk stratification information provided by the App. &nbsp; It also gives patient users the convenience to contact their
            health care providers at any time during use of the App by connecting the user to the mobile device’s text message system and populating
            it with the correct health care provider phone number pulled from the database. &nbsp;&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            In either cases, when collecting users’ sleep data and/or other patient data, our Services connect directly or indirectly with medical
            devices or monitoring equipment which we do not manufacture, sell, distribute, service or otherwise own (collectively, the “
            <strong>
              <em>Third Party Products</em>
            </strong>
            ”). &nbsp;Our Platform would operate in part to retrieve patient data collected and transmitted by Third Party Devices, and use such
            data to generate and facilitate reporting, work flows and billing and care records for our health care provider customers. &nbsp; On the
            other hand, our App would retrieve patient data collected and transmitted by Third Party Devices, organize and analyze the data to
            generate and display results for our patient users. &nbsp; In either case, we take no responsibility for the operation or use of Third
            Party Products or for the data generated or transmitted by such Third Party Products.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            If you are a patient user for the App or the Platform, you also hereby expressly consent and authorize us to retrieve any and all of
            your data collected or transmitted by any MonitAir<sup>TM</sup> or third-party medical device or monitoring equipment for purposes of
            providing our Services to you. &nbsp;&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Our Services are for informational and educational purposes only, and are not sponsored or endorsed in any manner by any medical or
            healthcare clinician, institution or any pharmaceutical or medical device corporation, and we do not provide any particular endorsement
            for such. &nbsp;We make no guarantees, promises, or predictions of success regarding any of the Services or resources offered through
            our Services or otherwise. &nbsp;You acknowledge and agree that the use of the Services is entirely at your own risk.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>3. &nbsp; No Medical Advice</span>
          </strong>
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>.</span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            We are not providers of any health care service. &nbsp;Our Platform or App is a useful tool in organizing, analyzing, and reporting
            certain data, but it is not intended to be, and is not, a substitute for the exercise of the professional medical judgment of
            practitioners. &nbsp; Your health professional is the single best source of information. &nbsp;Please consult them if you have any
            questions about your health or treatment.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            We take no responsibility for the medical or other information provided or conveyed via the Services or for the medical or clinical use
            that any health care practitioner may take on the basis on such information. &nbsp;By using the Services, you agree that we are not
            responsible or liable to you for any claim, loss, or damage arising from the Services or its use by you or any other user.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>
              4. &nbsp; Registration and User Account
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            In order to use the Service, you or the entity with which you are affiliated must register and open a user account on the Website, the
            Platform, the App, or any other related website or software application (the “
            <strong>
              <em>Account</em>
            </strong>
            ”). &nbsp;Registration must be done by providing us with your name, phone number, your e-mail address, and certain other demographics
            information. &nbsp; For the Platform, users will be asked to create a password and username for the Account during the initial
            registration process. &nbsp;For the App, users will use their email address as the username and will receive an access code to the
            registered email. After entering both the access code and the email address/user name in the App’s registration page, users will be
            asked to create a password for the Account. &nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            You are solely and fully responsible for maintaining the confidentiality of the login credentials (e-mail, username and password) of
            your Account and for all activities that occur under your Account. You agree not to disclose your login credentials to any third party.
            &nbsp;If you forget your password, you can request to have a new password issued and sent to your registered e-mail address. &nbsp;Your
            Account is at risk if you let someone else use it inappropriately and we reserve the right to terminate your Account if we determine
            that you or anyone using your Account violates these Terms.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            You must notify us immediately of any unauthorized use of your Account of login credentials or any other breach of security. We will not
            be liable for any loss or damage arising from any failure of yours to comply with the Terms or from any unauthorized use of your Account
            or any other breach of security. You may be liable for our losses and/or others due to any such use.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            If you wish to modify your Account information, or if you wish to terminate your Account, you may do so by contacting us, or, if you are
            a user for the Platform, you may also contact the administrator of the Account at your facility. Your Account will be terminated within
            a reasonable time following your request. &nbsp;Upon termination you will no longer be able to access your Account, and without
            derogating from any right or claim available to us, any and all permissions, rights and licenses granted to you under these Terms shall
            be instantly terminated and revoked.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Note that terminating your Account may cause the loss and/or unavailability of content, features, or capacity with regard to your
            Account. We shall not be liable in any way for such unavailability and/or loss.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>5. &nbsp;Minors</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Our Website or Services are not for use by minors. &nbsp;You must be over the age of majority in the jurisdiction where you reside in
            order to open an Account with us. &nbsp;We reserve the right to request proof of age at any stage to confirm whether minors under the
            age of majority are using our Website or Services.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>6. &nbsp;Use Restrictions</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Certain conduct is strictly prohibited when visiting or using our Website or Services. Your failure to comply with these prohibitions
            may result at our sole discretion in the termination of your access to the Website or the Services and may also expose you to civil
            and/or criminal liability.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            You may not, whether by yourself or anyone on your behalf: (i) copy, modify, adapt, translate, reverse engineer, decompile, or
            disassemble any portion of the Website or the Services, including but not limited to our proprietary videos, text, logos, button icons,
            images, data compilations, links, other specialized content, documentation, data, related graphics, our intellectual property and other
            features (collectively, the “
            <strong>
              <em>Content</em>
            </strong>
            ”), in any way or publicly display, perform, or distribute them; (ii) make any use of the Website, the Services, and/or the Content on
            any other website or networked computer environment other than in the Website or the Services for any purpose, or replicate or copy the
            Content without our prior written consent; (iii) create a browser or border environment around the Services, the Content and any part
            thereof (no frames or inline linking); (iv) interfere with or violate any other user’s right to privacy or other rights, or harvest or
            collect personally identifiable information about visitors or users of the Service without their express and informed consent, including
            using any robot, spider, site search or retrieval application, or other manual or automatic device or process to retrieve, index, or
            data-mine; (v) defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights of others; (vi) transmit or otherwise make
            available in connection with our Website or Services any virus, worm, Trojan Horse, time bomb, web bug, spyware, or any other computer
            code, file, or program that may or is intended to damage or hijack the operation of any hardware, software, or telecommunications
            equipment, or any other actually or potentially harmful, disruptive, or invasive code or component; (vii) interfere with or disrupt the
            operation of our Website or Services, or the servers or networks that host our Website or Services, or disobey any requirements,
            procedures, policies, or regulations of such servers or networks; (viii) sell, license, or exploit for any commercial purposes any use
            of or access to our Content, Website, or Services; (ix) use our Website or Services for any illegal, immoral or unauthorized purpose; or
            (xi) use our Website or Services or our Content for commercial purposes other than its intended use without our express prior written
            consent or pursuant to an express written agreement.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>7. &nbsp;Privacy Policy</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Our policies on the collection and use of the data transmitted through the Website or the Services are set out in our Privacy Policy
            available at monitairhealth.com/term-of-use/.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>8. &nbsp;Intellectual Property Rights</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            We are granting you with a limited, personal, not exclusive, non-assignable, not-tradeable license and right to use the Website or the
            Services. &nbsp;Such rights cannot be transferred or sub-licensed without our prior written consent. &nbsp;Our Terms do not entitle you
            with any right in the Website or the Services other than a limited right to use it in accordance herewith or any separate use agreement.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Our marks and logo (whether or not registered) and all other proprietary identifiers used by us in connection with the Website and/or
            the Services (the “
            <strong>
              <em>Marks</em>
            </strong>
            ”) are all trademarks and/or trade names of ours, whether they are registered or not. No right, license, or interest to the Marks is
            granted hereunder, and you agree that no such right, license, or interest shall be asserted by you with respect to the Marks. &nbsp;You
            agree not use any of the Marks unless such use was specifically authorized in a separate agreement or these Terms.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Our Website, Services, and Content and any and all intellectual property rights pertaining thereto, including, but not limited to,
            inventions, patents and patent applications, trademarks, trade names, logos, copyrightable materials, graphics, text, images, designs
            (including the “look and feel" of our Website or Services), specifications, methods, procedures, information, know-how, algorithms,
            data, technical data, interactive features, source and object code, files, interface and trade secrets, whether or not registered or
            capable of being registered (collectively, “
            <strong>
              <em>Intellectual Property</em>
            </strong>
            “), are owned and/or licensed to us and subject to copyright, trademark rights, and other applicable intellectual property rights under
            applicable law. You may not copy, distribute, display, execute publicly, make available to the public, reduce to human readable form,
            decompile, disassemble, adapt, sublicense, make any commercial use, sell, rent, lend, process, compile, reverse engineer, combine with
            other software, translate, modify or create derivative works of any of our Intellectual Property, either by yourself or by anyone on
            your behalf, in any way or by any means, unless expressly permitted in as separate agreement or these Terms.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            You may not remove or delete any and all copyright notices, restrictions and signs indicating proprietary rights of us or our licensors,
            including [®], copyright mark [©] or trademark [™] contained in or accompanying our Content included on the Website or in the Services,
            and you represent and warrant that you will abide by all applicable laws in this respect.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>9. &nbsp;Availability</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            The availability and functionality of our Website or Services depends on various factors, such as communication networks. We do not
            warrant or guarantee that the Website or Services will operate and/or be available at all times without disruption or interruption, or
            that it will be error-free.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>10. &nbsp;Changes to our Services</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            We reserve the right to modify, correct, amend, enhance, improve, make any other changes to, or discontinue, temporarily or permanently
            our Website or Services (or any part thereof) without notice, at any time and at its sole discretion. &nbsp;You agree that we will not
            be liable to you or to any third party for any modification, suspension, or discontinuance of our Website or Services.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>11. &nbsp;Disclaimer and Warranties</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            MONITAIR DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING ANY INFORMATION CONTAINED ON THE WEBSITE OR IN THE SERVICES, OR THE USE,
            THE INABILITY TO USE OR OPERATE, OR THE RESULTS OF THE USE OF THE WEBSITE, THE SERVICES, THE CONTENT, OR ANY PART THEREOF. YOU AGREE AND
            ACKNOWLEDGE THAT THE USE OF OUR WEBSITE, SERVICES, CONTENT, OR ANY PART THEREOF IS ENTIRELY AT YOUR OWN RISK.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            OUR SERVICES, INCLUDING ANY TECHNICAL SUPPORT SERVICES, ARE PROVIDED ON AN “AS IS" “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY
            KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO WARRANTIES OF TITLE OR NON-INFRINGEMENT OR IMPLIED WARRANTIES OF USE,
            MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR FUNCTION. MONITAIR DISCLAIMS AND MAKES NO REPRESENTATIONS OR WARRANTIES AS TO THE
            ACCURACY, QUALITY, AVAILABILITY, RELIABILITY, SUITABILITY, COMPLETENESS, TRUTHFULNESS, USEFULNESS, OR EFFECTIVENESS OF DATA TRANSMITTED
            THROUGH OUR WEBSITE OR SERVICES, INCLUDING ANY MEDICAL INFORMATION OR DATA FROM THIRD PARTY PRODUCTS. &nbsp;MONITAIR DISCLAIMS
            RESPONSIBILITY FOR ANY AND ALL MEDICAL INFORMATION UPLOADED OR COMMUNICATED THROUGH THE SERVICES BY USERS INCLUDING FOR ANY EXTERNAL
            MEDICAL INFORMATION.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            MONITAIR DOES NOT WARRANT THAT THE OPERATION OF THE SERVICES IS OR WILL BE SECURE, ACCURATE, COMPLETE, UNINTERRUPTED, WITHOUT ERROR, OR
            FREE OF VIRUSES, WORMS, OTHER HARMFUL COMPONENTS, OR OTHER PROGRAM LIMITATIONS.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>12. &nbsp;Limitation of Liability</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            In no event shall MonitAir, including its officers, directors, shareholders, employees, sub-contractors and its agents be liable for any
            damages whatsoever, including, but not limited to, direct, indirect, special, incidental or consequential damages of any kind, whether
            in an action of contract, negligence or other tortious actions resulting from or arising out of the Website, the Services, or the
            Content, or the use or inability to use the Website, the Services, or the Content, the performance or failure of MonitAir to perform
            under these terms, any other act or omission of MonitAir by any other cause whatsoever; or based upon breach of warranty, breach of
            contract, negligence, strict liability, or any other legal theory, regardless of whether MonitAir has been advised of the possibility of
            such damages.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            No action may be brought by you for any breach of these terms more than one (1) year after the accrual of such cause of action. As some
            states, provinces or other jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, then such
            limitations only may not apply to a user residing in such state, province or other jurisdiction.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            You acknowledge and agree that these limitations of liability are agreed allocations of risk constituting in part the consideration for
            MonitAir providing the Services to you, and such limitations will apply notwithstanding the failure of essential purpose of any limited
            remedy, and even if MonitAir has been advised of the possibility of such liabilities and/or damages. The foregoing limitation of
            liability shall apply to the fullest extent permitted by law in the applicable jurisdiction.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            In no event shall MonitAir’s cumulative liability to you exceed amounts paid to MonitAir for your use of the Website, the Services, or
            the Content in the twelve (12) months prior to the date any claim is made.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>13. &nbsp;Indemnification</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            You agree to indemnify, defend, and hold MonitAir, its owners, subsidiaries, affiliates, partners, officers, directors, agents,
            contractors, licensors, service providers, subcontractors, suppliers, interns and employees, harmless from any claim or demand,
            including reasonable investigation expenses and attorneys’ fees, which arises out of your breach of these Terms, the documents they
            incorporate by reference, or your use of the Website, the Services, or the Content. &nbsp;Your indemnification obligation under these
            Terms shall survive the termination of this Agreement for any reason.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>
              14. &nbsp;Termination and Suspension of Service
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            These Terms are effective unless and until terminated by either you or us. You may terminate these Terms at any time by notifying us (i)
            that you no longer wish to use our Website or Services, or (ii) when you cease using our Website or Services. &nbsp;Your obligations and
            liabilities incurred prior to the termination date shall survive the termination of this agreement for all purposes.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            We may suspend, deactivate, limit, or cancel a user’s account and impede or restrict access to our Website or Services if, in our sole
            discretion, we think that a user is causing issues, potential legal liabilities, or are acting in a manner inconsistent with the letter
            or spirit of our policies and these Terms. &nbsp;We may do this without limiting other possible remedies. We also have the ability to
            terminate user Accounts that have no activity for a long period of time.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>15. &nbsp;Severability</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            In the event that any provision of these Terms is determined to be unlawful, void, or unenforceable, such provision shall nonetheless be
            enforceable to the fullest extent permitted by applicable law, and the unenforceable portion shall be deemed to be severed from these
            Terms, such determination shall not affect the validity and enforceability of any other remaining provisions.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>
              16. &nbsp;No Waiver of Remedy; Entire Agreement
            </span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            The failure of us to exercise or enforce any right or provision of these Terms shall not constitute a waiver of such right or provision.
            &nbsp;These Terms, along with any separate use agreement between you and us, and any policies or operating rules posted by us on our
            Website or in respect to the Website or the Services constitute the entire agreement and understanding between you and us and govern
            your use of the Website and/or the Services, and supersede any prior or contemporaneous agreements, communications and proposals,
            whether oral or written, between you and us (including, but not limited to, any prior versions of these Terms). &nbsp;Any ambiguities in
            the interpretation of these Terms shall not be construed against the drafting party.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>17. &nbsp;Governing Law And Venue</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            These Terms and any separate agreements whereby we provide you access to the Website and/or the Services shall be governed by and
            construed in accordance with the laws of the State of Florida without regard to the conflict of laws principles of such jurisdiction.
            &nbsp;You agree that any claim or dispute you may have against MonitAir must be resolved by a court located in Miami-Dade County,
            Florida. You agree to submit to the personal jurisdiction of the courts located within Miami-Dade County, Florida for the purpose of
            litigating all such claims or disputes.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `24.75pt`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"`, color: `black` }}>18.&nbsp;</span>
          </strong>
          <strong>
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>&nbsp;Contact Information</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            Questions about the Terms should be sent to us via email at&nbsp;
          </span>
          <a href="mailto:info@monitairhealth.com">
            <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>info@monitairhealth.com</span>
          </a>
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>&nbsp;.</span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `right`,
            background: `white`,
          }}
        >
          <strong>
            <em>
              <span style={{ fontSize: `11px`, fontFamily: `"Times New Roman","serif"` }}>Revised August 10, 2022</span>
            </em>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `center`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontSize: `19px`, fontFamily: `"Times New Roman","serif"` }}>PRIVACY POLICY</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
                <span style={{ textDecoration: `none` }}>&nbsp;</span>
              </span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Introduction</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `.0001pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>&nbsp;</span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>MonitAir, LLC&nbsp;</span>
          </strong>
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            (“
            <strong>
              <em>MonitAir</em>
            </strong>
            ,” “
            <strong>
              <em>us</em>
            </strong>
            ,” “
            <strong>
              <em>our</em>
            </strong>
            ”) respects your privacy and are committed to protecting it through our compliance with this policy.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            This policy describes the types of information we may collect from you or that you may provide when you visit our website
            monitairhealth.com (the “
            <strong>
              <em>Website</em>
            </strong>
            ”) or access or use our MonitAir<sup>TM</sup> software as a service platform and related software, documentations, and applications
            (collectively, the “
            <strong>
              <em>Platform</em>
            </strong>
            ”) and/or our MonitAir<sup>TM</sup> mobile software application and related documentations (collectively, the “
            <strong>
              <em>App</em>
            </strong>
            <em>,</em>
            <em>”</em> together with the Platform, the “
            <strong>
              <em>Services</em>
            </strong>
            ”). &nbsp;This policy describes our practices for collecting, using, maintaining, protecting, and disclosing that information.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>This policy applies to information we collect:</span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>On this Website or through the Services.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              In email, text, and other electronic messages between you and the Website or between you and the Platform or the App.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Through the Services or any other mobile or desktop applications you acquire from us.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              When you interact with our advertising and applications on third-party websites and services, if those applications or advertising
              include links to this policy.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              When you communicate with our support staff or other customer representative regarding the Website or the Services.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>It does not apply to information collected by:</span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Us offline or through any other means, including on any other website operated by us or any third party; or
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Any third party, including through any application or content that may link to or be accessible from or on the Website, the Platform,
              or the App.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            Please read this policy carefully to understand our policies and practices regarding your information and how we will treat it. If you
            do not agree with our policies and practices, your choice is not to use our Website or Services. &nbsp;By accessing or using our Website
            or Services, you agree to this privacy policy. &nbsp; This policy may change from time to time. &nbsp;Your continued use of the Website
            or Services or after we make changes is deemed to be acceptance of those changes, so please check the policy periodically for updates.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Children Under the Age of 13</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            Our Website or Services are not intended for use by children under 13 years of age. No one under age 13 may provide any information to
            or on the Website or the Services. We do not knowingly collect personal information from children under 13. &nbsp;If you are under 13,
            do not (i) use or provide any information on this Website, the Services, or through any of their features, (ii) register or create an
            account on the Website or the Services, (iii) make any purchases through the Website, (iv) use any of the interactive or public comment
            features of this Website or the Services, or (v) provide any information about yourself to us, including your name, address, telephone
            number, email address, or any screen name or user name you may use. &nbsp;If we learn we have collected or received personal information
            from a child under 13 without verification of parental consent, we will delete that information.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            California residents under 16 years of age may have additional rights regarding the collection and sale of their personal information.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Information We Collect About You and How We Collect It</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            We collect several types of information from and about users of our Website or Services, including information:
          </span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              By which you may be personally identified, such as name, postal address, e-mail address, telephone number, age, health care condition,
              credit card or other payment data (“<strong>personal information</strong>“);
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              About your internet connection, the equipment you use to access our Website or Services, and usage details.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>We collect this information:</span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>Directly from you when you provide it to us.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Automatically as you navigate through the Website or use the Services. Information collected automatically may include usage details,
              IP addresses, and information collected through cookies or other tracking technologies.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <em>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>Information You Provide to Us</span>
          </em>
          <strong>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>&nbsp;</span>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            The information we collect on or through our Website or Services may include:
          </span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Information that you provide by creating an account or filling in forms on our Website or Services. This includes information provided
              at the time of registering to use our Website or Services, posting material, making orders for our services or products. We may also
              ask you for information when you report a problem with our Website, Services, or other related services.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Data that we retrieved directly or indirectly from any MonitAir<sup>TM</sup> or third-party medical devices or monitoring equipment
              with your authorization in accordance with the Terms of Use for purposes of providing the Services to you. &nbsp; &nbsp; &nbsp;
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Records and copies of your correspondence (including email addresses), if you contact us.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Your responses to surveys that we might ask you to complete for research purposes.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Details of transactions you carry out through our Website or Services and of the fulfillment of your orders or any product or service
              we offer. You may be required to provide financial information before placing an order with us through our Website.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>Your search queries on the Website.</span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            You also may provide information to be published or displayed (hereinafter, “<strong>posted</strong>“) on public areas of the Website,
            or transmitted to other users of the Website or third parties (collectively, “<strong>User Contributions</strong>“). Your User
            Contributions are posted on and transmitted to others at your own risk. &nbsp;Please be aware that no security measures are perfect or
            impenetrable. Additionally, we cannot control the actions of other users of the Website with whom you may choose to share your User
            Contributions. Therefore, we cannot and do not guarantee that your User Contributions will not be viewed by unauthorized persons.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <em>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Information We Collect Through Automatic Data Collection Technologies<strong>&nbsp;</strong>
            </span>
          </em>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            As you navigate through and interact with our Website or Services, we may use automatic data collection technologies to collect certain
            information about your equipment, browsing actions, and patterns, including:
          </span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Details of your visits to our Website or Services, including traffic data, location data, logs, and other communication data and the
              resources that you access and use on the Website or the Services.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Information about your computer, mobile device, and internet connection, including your IP address, operating system, and browser
              type.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            We also may use these technologies to collect information about your online activities over time and across third-party websites or
            other online services (behavioral tracking).
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            The information we collect automatically helps us to improve our Website and to deliver a better and more personalized service,
            including by enabling us to:
          </span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>Estimate our user size and usage patterns.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              Store information about your preferences, allowing us to customize our Website according to your individual interests.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>Speed up your searches.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>Recognize you when you return to our Website.</span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>The technologies we use for this automatic data collection may include:</span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.5in` }}>
          <li>
            <strong>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Cookies (or browser cookies).</span>
            </strong>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              &nbsp;A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the
              appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website.
              Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your
              browser to our Website.
            </span>
          </li>
        </ul>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <strong>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Flash Cookies.</span>
            </strong>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              &nbsp;Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your
              preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for
              browser cookies. We do not collect personal information automatically, but we may tie this information to personal information about
              you that we collect from other sources or you provide to us.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>How We Use Your Information</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            We use information that we collect about you or that you provide to us, including any personal information:
          </span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>To present our Website, Services, and their contents to you.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To provide you with information, products, or services that you request from us.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To fulfill your purchase orders any other purpose for which you provide it.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To provide you with notices about your account, including expiration and renewal notices.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing
              and collection.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>To notify you about changes to our Website or Services.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To allow you to participate in interactive features on our Website or Services.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>In any other way we may describe when you provide the information.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>For any other purpose with your consent.</span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            We may also use your information to contact you about our own and third-parties’ goods and services that may be of interest to you. If
            you do not want us to use your information in this way, please check the relevant box located on the form on which we collect your data
            or adjust your user preferences in your account profile.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Protected Health Information (HIPAA)</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            To the extent that we provide “Business Associate” services to you for any purpose, we comply with applicable privacy and security rules
            under HIPAA and applicable state law.&nbsp;
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Disclosure of Your Information</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            We may disclose aggregated information about our users, and information that is de-identified in the manner required under HIPAA without
            restriction.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            We may disclose personal information that we collect or you provide as described in this privacy policy:
          </span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>To our subsidiaries and affiliates.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations
              to keep personal information confidential and use it only for the purposes for which we disclose it to them.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or
              transfer of some or all of our s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in
              which personal information held by us about our Website users is among the assets transferred or reviewed as part of a customary due
              diligence review as part of such sale transaction.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>To fulfill the purpose for which you provide it.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>For any other purpose disclosed by us when you provide the information.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>With your consent.</span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>We may also disclose your personal information:</span>
        </p>
        <ul style={{ listStyleType: `disc`, marginLeft: `-0.25in` }}>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
            </span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>To enforce or apply our Terms of Use.</span>
          </li>
          <li>
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>
              If we believe disclosure is necessary or appropriate to protect the rights, property, or safety, of ourselves and of our customers or
              others. This may include exchanging information with other companies and organizations for the purposes of fraud protection and credit
              risk reduction.
            </span>
          </li>
        </ul>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Accessing and Correcting Your Information</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            You can review and change your personal information by logging into the Website or the Services and visiting your account profile page.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>You may also send us an email at&nbsp;</span>
          <a href="mailto:info@monitairhealth.com">
            <span style={{ fontFamily: `"Times New Roman","serif"` }}>info@monitairhealth.com</span>
          </a>
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            &nbsp; to request access to, correct or delete any personal information that you have provided to us. We cannot delete your personal
            information except by also deleting your user account. We may not accommodate a request to change information if we believe the change
            would violate any law or legal requirement or cause the information to be incorrect.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            If you delete your User Contributions from the Website, copies of your User Contributions may remain viewable in cached and archived
            pages, or might have been copied or stored by other Website users.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Data Security</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            We have implemented measures consistent with applicable law which are designed to secure your personal information from accidental loss
            and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind
            firewalls. Any transactions of protected health information under HIPAA or payment will be encrypted.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            The safety and security of certain information also depends on you. &nbsp;Where we have given you (or where you have chosen) a password
            for access to certain parts of our Website or Services, you are responsible for keeping this password confidential. We ask you not to
            share your password with anyone. &nbsp;We urge you to be careful about giving out information in public areas of the Website like
            message boards. The information you share in public areas may be viewed by any user of the Website.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            Unfortunately, the transmission of information via the internet is not completely secure. &nbsp;Although we do our best to protect your
            personal information, we cannot guarantee the security of your personal information transmitted to our Website. Any transmission of
            personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures
            contained on the Website.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Changes to Our Privacy Policy</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            It is our policy to post any changes we make to our privacy policy on this page. &nbsp;If we make material changes to how we treat our
            users’ personal information, we will notify you by email to the primary email address specified in your account or through a notice on
            the Website home page. The date the privacy policy was last revised is identified at the top of the page. You are responsible for
            ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy
            policy to check for any changes.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <strong>
            <u>
              <span style={{ fontFamily: `"Times New Roman","serif"` }}>Contact Information</span>
            </u>
          </strong>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
            background: `white`,
          }}
        >
          <span style={{ fontFamily: `"Times New Roman","serif"` }}>
            To ask questions or comment about this privacy policy and our privacy practices, contact us via&nbsp;
          </span>
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>
            email at info@monitairhealth.com or via our phone number at (305) 874-0086.
          </span>
        </p>
        <p
          style={{
            marginTop: `0in`,
            marginRight: `0in`,
            marginBottom: `8.0pt`,
            marginLeft: `0in`,
            lineHeight: `normal`,
            fontSize: `15px`,
            fontFamily: `"Calibri","sans-serif"`,
            textAlign: `justify`,
          }}
        >
          <span style={{ fontSize: `16px`, fontFamily: `"Times New Roman","serif"` }}>&nbsp;</span>
        </p>
      </div>
    </CustomizedDialogs>
  )
}
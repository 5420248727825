import React, { useReducer, useRef } from 'react';
import css from './test.module.css'
export const CustomBtn = ({ btnProps, element }) => {
  const { className = `` } = btnProps || { btnProps: { className: `` } }
  return <button   {...(!className ? { className: `btn btn-outline-info btn-sm` } : {})}   {...btnProps}>{element}</button>;
};


const initialState = {
  list: [],
  activeItem: false
}

const reducer = (state, action) => {
  const { activeItem: prevActiveItem, list: prevList } = state
  const { payLoad = {} } = action
  switch (action.type) {
    case `scrollChange`:
      const stateHandle = () => {
        if (payLoad === 38) {
          if (prevActiveItem === 0) return 0;
          return prevActiveItem - 1;

        } else if (payLoad === 40) {
          if (!prevActiveItem && prevActiveItem !== 0 || prevActiveItem === prevList.length - 1) return 0
          return prevActiveItem + 1;
        }
      }
      return {
        ...state, activeItem: stateHandle()
      }
    case `saveList`:
      return { ...state, list: [...payLoad] }
    default:
      return state;
  }
};


export const MenuCtm = () => {
  const [todos, dispatch] = useReducer(reducer, initialState);
  const { list, activeItem } = todos

  const renderCount = useRef(0)
  React.useEffect(() => {

    const keyListener = (e) => {
      const { keyCode } = e;
      e.preventDefault();
      dispatch({ type: `scrollChange`, payLoad: keyCode })

    };
    if (renderCount.current === 0) {
      let arr = [];
      for (let i = 0; i < 10; i++) {
        arr = [...arr, { label: `Item ${i}`, value: `item${i}` }];
      }
      dispatch({ type: `saveList`, payLoad: arr })


    }



    renderCount.current = renderCount.current + 1
    document.addEventListener(`keyup`, keyListener);
    return () => document.removeEventListener(`keyup`, keyListener);
  }, [list]);
  return (
    <ul className={css.ul} >
      {list?.map(({ label = ``, value = `` }, ind) => {
        return (
          <ListComp
            isActive={activeItem === ind}
            key={value}
            label={label}
            value={value}
          />
        );
      })}
    </ul>
  );
}


const ListComp = ({ label = ``, isActive }) => {
  const listItemRef = React.useRef(null);
  React.useEffect(() => {
    if (isActive && listItemRef?.current) listItemRef.current.scrollIntoView({ behavior: `smooth`, block: `end`, inline: `nearest` })


  }, [isActive, listItemRef,]);
  return (
    <li ref={listItemRef} className={`${isActive && css.active} ${css.list}`}>
      {` `}
      {label}
    </li>
  );
};
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import momentOriginal from 'moment';
import css from '../viewData.module.css';
import { PdfViewer } from '../pdfViewer/pdfViewer';
import { Bar, Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import './summary.module.css';
import $ from 'jquery';
import { REACT_HEALTH, fixNum, condForDetaiandSummaryGraphs } from 'src/utils';
import { ConditionallyRenderWrapper } from 'src/pages/common/HOC/ConditionallyRenderWrapper';

export const ViewDataSummary = ({ data, start, end, userId, reportId, selectedPatient }) => {
  const {
    resmed: { isRealTimeDataAvailable: realTimeDataAvailable },
  } = useSelector((state) => state);
  const patientData = useSelector((state) => state.patient.patientData);
  const device = patientData ? patientData?.data?.device?.manufacturer : selectedPatient;
  const [showModalList, setShowModalList] = useState(false);
  const [isResmedPdfSummaryReportAvailable, setIsPdfSummaryReportAvailable] = useState(null);
  const [chartLabels, setChartLabels] = useState([]);
  const [showBothPressures, setShowBothPressures] = useState(false);
  const [percentilePressureChartDataIPAP, setPercentilePressureChartDataIPAP] = useState([]);
  const [maxPressureChartDataIPAP, setMaxPressureChartDataIPAP] = useState([]);
  const [medianPressureChartDataIPAP, setMedianPressureChartDataIPAP] = useState([]);
  const [percentilePressureChartDataEPAP, setPercentilePressureChartDataEPAP] = useState([]);
  const [respironicsPercentilePressureChartDataIPAP, setRespironicsPercentilePressureChartDataIPAP] = useState([]);
  const [respironicsPercentilePressureChartDataEPAP, setRespironicsPercentilePressureChartDataEPAP] = useState([]);
  const [respironicsPercentilePressureChartDataCPAP, setRespironicsPercentilePressureChartDataCPAP] = useState([]);
  const [respironicsAvgPressCPAP, setRespironicsAvgPressCPAP] = useState([]);
  const [usageChartData, setUsageChartData] = useState([]);
  const [respironicsUsageChartData, setRespironicsUsageChartData] = useState([]);
  const [eventChartData, setEventChartData] = useState([]);
  const [respironicsEventChartData, setRespironicsEventChartData] = useState([]);
  const [leakChartData, setLeakChartData] = useState([]);
  const [respironicsLeakChartData, setRespironicsLeakChartData] = useState([]);
  const [maxLeakChartData, setMaxLeakChartData] = useState([]);
  const [medLeakChartData, setMedLeakChartData] = useState([]);
  const [tvChartData, setTVChartData] = useState([]);
  const [respironicsTvChartData, setRespironicsTvChartData] = useState([]);
  const [rrChartData, setRRChartData] = useState([]);
  const [maxRespiratoryRate, setMaxRespRate] = useState([]);
  const [mediumRespiratoryDate, setMedRespRate] = useState([]);
  const [respironicsRrChartData, setRespironicsRrChartData] = useState([]);
  const [mvChartData, setMVChartData] = useState([]);
  const [respironicsMvChartData, setRespironicsMvChartData] = useState([]);
  const [percentileIERatioChartData, setPercentileIERatioChartData] = useState([]);
  const [CPAPPressure] = useState([]);
  const [maxIERatioChartData, setMaxIERatioChartData] = useState([]);
  const [medIERatioChartData, setMedIERatioChartData] = useState([]);

  // React_Health API status Based.
  const [reactHealthEventDataTemp, setReactHealthEventDataTemp] = useState([]);
  const [reactHealthPressure95, setReactHealthPressure95] = useState([]);
  const [reactHealthMaximumPressure, setReactHealthMaximumPressure] = useState([]);
  const [reactHealthUsageChartData, setReactHealthUsageChartData] = useState([]);

  // RESMED API STATUS BASED, ENABLE/DISABLED
  const [isLiveDataAvailable, setIsLiveDataAvailable] = useState(null);
  const [respironicsPressureGraph, setRespironicsPressureGraph] = useState([]);

  const getBAR = (chart, tooltipModel) => {
    //
    const dataPoints = tooltipModel.dataPoints,
      datasetIndex = chart.data.datasets.length - 1,
      datasetMeta = chart.getDatasetMeta(datasetIndex),
      scaleBottom = chart.scales[`y-axis-0`].bottom,
      bar = datasetMeta.data[dataPoints[0].index]._model,
      canvasPosition = chart.canvas.getBoundingClientRect(),
      paddingLeft = parseFloat(getComputedStyle(chart.canvas).paddingLeft),
      paddingTop = parseFloat(getComputedStyle(chart.canvas).paddingTop),
      scrollLeft = document.body.scrollLeft,
      scrollTop = document.body.scrollTop;
    //
    return {
      top: bar.y + canvasPosition.top + paddingTop + scrollTop,
      left: bar.x - bar.width / 2 + canvasPosition.left + paddingLeft + scrollLeft,
      width: bar.width,
      height: scaleBottom - bar.y,
    };
  };

  useEffect(() => {
    let labelTemp = [];
    let usageDataTemp = [];
    let respironicsUsageDataTemp = [];
    let eventDataTemp = [];
    let respironicsEventDataTemp = [];
    let leakDataTemp = [];
    let respironicsLeakDataTemp = [];
    let medLeakDataTemp = [];
    let maxLeakDataTemp = [];
    let tvDataTemp = [];
    let respironicsTvDataTemp = [];
    let rrDataTemp = [];
    let maxResp = [];
    let medResp = [];
    let respironicsRrDataTemp = [];
    let mvDataTemp = [];
    let respironicsMvDataTemp = [];
    let pressurePercentileIPAP = [];
    let pressureMaxIPAP = [];
    let pressureMedIPAP = [];
    let pressurePercentileEPAP = [];
    let respironicsPressurePercentileIPAP = [];
    let respironicsPressurePercentileEPAP = [];
    let respironicsPressurePercentileCPAP = [];
    let respironicsPressAvgCPAP = [];
    let IEratioPercentile = [];
    let IEratioMax = [];
    let IEratioMed = [];

    // Declare empty arrays to store pressure readings and AHI data. These variables are declared for use in the `reactHealth` component
    let pressure95 = []; // array to store pressure readings at 95th percentile
    let maximumPressure = []; // array to store maximum pressure readings
    let ahiData = []; // array to store Apnea-Hypogene Index (AHI) data
    let reactHealthUsageDataTemp = [];

    if (data) {
      if (data?.usage?.averageNinetyFiveTgtEPAP && data?.usage?.averageMedTgtEPAP) {
        setShowBothPressures(true);
      } else {
        setShowBothPressures(false);
      }

      //
      for (let i = 0; i <= data?.usage?.DataPerDay?.length - 1; i++) {
        labelTemp.push(momentOriginal.utc(data.usage.DataPerDay[i].date).format(`DD`));
        usageDataTemp.push((data.usage.DataPerDay[i].usage / 60).toFixed(2));
        respironicsUsageDataTemp.push((data.usage.DataPerDay[i].usage / 60).toFixed(2));
        eventDataTemp.push(data.usage.DataPerDay[i].AHI);
        respironicsEventDataTemp.push(data.usage.DataPerDay[i].AHI);
        leakDataTemp.push(data.usage.DataPerDay[i].ninetyFiveLeak ? data.usage.DataPerDay[i].ninetyFiveLeak : null);
        respironicsLeakDataTemp.push(data.usage.DataPerDay[i].avgLeak ? data.usage.DataPerDay[i].avgLeak.toFixed(2) : null);
        maxLeakDataTemp.push(data.usage.DataPerDay[i].maxLeak ? data.usage.DataPerDay[i].maxLeak : null);
        medLeakDataTemp.push(data.usage.DataPerDay[i].medLeak ? data.usage.DataPerDay[i].medLeak : null);

        // For pressure
        pressureMaxIPAP.push(data.usage.DataPerDay[i].maxTgtIPAP);
        pressureMedIPAP.push(data.usage.DataPerDay[i].medTgtIPAP);
        tvDataTemp.push(data?.usage?.DataPerDay[i]?.ninetyFiveTidalVol ? data.usage.DataPerDay[i].ninetyFiveTidalVol : null);
        respironicsTvDataTemp.push(data?.usage?.DataPerDay[i]?.avgTidalVol ? data.usage.DataPerDay[i].avgTidalVol : null);
        maxResp.push(data?.usage?.DataPerDay[i]?.maxRespRate ? data?.usage?.DataPerDay[i]?.maxRespRate : null);
        medResp.push(data?.usage?.DataPerDay[i]?.medRespRate ? data?.usage?.DataPerDay[i]?.medRespRate : null);
        rrDataTemp.push(data?.usage?.DataPerDay[i]?.ninetyFiveRespRate ? data.usage.DataPerDay[i].ninetyFiveRespRate : null);
        respironicsRrDataTemp.push(data?.usage?.DataPerDay[i]?.avgBreathRate ? data.usage.DataPerDay[i].avgBreathRate : null);
        mvDataTemp.push(data?.usage?.DataPerDay[i]?.ninetyFiveMinuteVent ? data.usage.DataPerDay[i].ninetyFiveMinuteVent : null);
        respironicsMvDataTemp.push(data?.usage?.DataPerDay[i]?.avgMinuteVent ? data.usage.DataPerDay[i].avgMinuteVent : null);
        pressurePercentileIPAP.push(device === `reactHealth` ? data.usage.DataPerDay[i]?.ipapPressure95 : data.usage.DataPerDay[i].ninetyFiveTgtIPAP);
        pressurePercentileEPAP.push(device === `reactHealth` ? data.usage.DataPerDay[i]?.epapPressure95 : data.usage.DataPerDay[i].ninetyFiveTgtEPAP);
        respironicsPressurePercentileIPAP.push(data.usage.DataPerDay[i].ninetyTgtIPAP);
        respironicsPressurePercentileEPAP.push(data.usage.DataPerDay[i].ninetyTgtEPAP);
        respironicsPressurePercentileCPAP.push(data.usage.DataPerDay[i].ninetyTgtCPAP);
        respironicsPressAvgCPAP.push(data.usage.DataPerDay[i].avgTgtCPAP);
        IEratioPercentile.push(data?.usage.DataPerDay[i]?.ninetyFiveIERatio ? data.usage.DataPerDay[i].ninetyFiveIERatio : null);
        IEratioMax.push(data?.usage?.DataPerDay[i]?.maxIERatio ? data.usage.DataPerDay[i].maxIERatio : null);
        IEratioMed.push(data?.usage?.DataPerDay[i]?.medIERatio ? data.usage.DataPerDay[i].medIERatio : null);

        // For React_Health.
        // Pushes the p95 data for the i-th day to the pressure95 array
        pressure95.push(data?.usage?.DataPerDay[i]?.ipapPressure95);
        // Pushes the max_p data for the i-th day to the maximumPressure array
        maximumPressure.push(data?.usage?.DataPerDay[i]?.max_p);
        // Pushes the ahi data for the i-th day to the ahiData array
        ahiData.push(data?.usage?.DataPerDay[i]?.ahi);
        // Calculate the result by dividing the value of data?.usage?.DataPerDay[i]?.minutes by 60.
        let result = data?.usage?.DataPerDay[i]?.minutes / 60;
        // Add the calculated result to the reactHealthUsageDataTemp array.
        reactHealthUsageDataTemp.push(result);
      }
      setChartLabels(labelTemp);
      setUsageChartData(usageDataTemp);
      setRespironicsUsageChartData(respironicsUsageDataTemp);
      setEventChartData(eventDataTemp);
      setRespironicsEventChartData(respironicsEventDataTemp);
      setLeakChartData(leakDataTemp);
      setRespironicsLeakChartData(respironicsLeakDataTemp);
      setMaxLeakChartData(maxLeakDataTemp);
      setMedLeakChartData(medLeakDataTemp);
      setTVChartData(tvDataTemp);
      setRespironicsTvChartData(respironicsTvDataTemp);
      setRRChartData(rrDataTemp);
      setMedRespRate(medResp);
      setMaxRespRate(maxResp);
      setRespironicsRrChartData(respironicsRrDataTemp);
      setMVChartData(mvDataTemp);
      setRespironicsMvChartData(respironicsMvDataTemp);
      setPercentilePressureChartDataIPAP(pressurePercentileIPAP);
      setMaxPressureChartDataIPAP(pressureMaxIPAP);
      setMedianPressureChartDataIPAP(pressureMedIPAP);
      setPercentilePressureChartDataEPAP(pressurePercentileEPAP);

      // Update the React Health data with the latest pressure, maximum pressure, and AHI data
      setReactHealthPressure95(pressure95); // Set pressure95 data
      setReactHealthMaximumPressure(maximumPressure); // Set maximumPressure data
      setReactHealthEventDataTemp(ahiData); // Set AHI data
      setReactHealthUsageChartData(reactHealthUsageDataTemp);

      // Respironics Pressure to prepare dynamic object graph
      setRespironicsPercentilePressureChartDataIPAP(respironicsPressurePercentileIPAP);
      setRespironicsPercentilePressureChartDataEPAP(respironicsPressurePercentileEPAP);
      setRespironicsPercentilePressureChartDataCPAP(respironicsPressurePercentileCPAP);
      setRespironicsAvgPressCPAP(respironicsPressAvgCPAP);

      //end Respironics Pressure to prepare dynamic object graph
      setPercentileIERatioChartData(IEratioPercentile);
      setMaxIERatioChartData(IEratioMax);
      setMedIERatioChartData(IEratioMed);
    }
    return () => {
      setUsageChartData([]);
      setRespironicsUsageChartData([]);
      setEventChartData([]);
      setRespironicsEventChartData([]);
      setReactHealthEventDataTemp([]);
      setReactHealthPressure95([]);
      setReactHealthMaximumPressure([]);
      setLeakChartData([]);
      setRespironicsLeakChartData([]);
      setMaxLeakChartData([]);
      setMedLeakChartData([]);
      setTVChartData([]);
      setRespironicsTvChartData([]);
      setRRChartData([]);
      setRespironicsRrChartData([]);
      setMVChartData([]);
      setRespironicsMvChartData([]);
      setPercentilePressureChartDataIPAP([]);
      setMaxPressureChartDataIPAP([]);
      setMedianPressureChartDataIPAP([]);
      setPercentilePressureChartDataEPAP([]);
      setRespironicsPercentilePressureChartDataIPAP([]);
      setRespironicsPercentilePressureChartDataEPAP([]);
      setRespironicsPercentilePressureChartDataCPAP([]);
      setRespironicsAvgPressCPAP([]);
      setRespironicsPressureGraph([]);
      setPercentileIERatioChartData();
      setMaxIERatioChartData();
      setMedIERatioChartData();
      setReactHealthUsageChartData([]);
    };
  }, [data]);

  useEffect(() => {
    if (data?.usage?.avgNinetyIPAP) {
      const pressureArray = respironicsPressureGraph;
      pressureArray.push({
        label: `IPAP (90% avg)`,
        fill: false,
        lineTension: 0.5,
        backgroundColor: `#c51670`,
        borderColor: `#c51670`,
        borderCapStyle: `butt`,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: `round`,
        pointBorderColor: `#c51670`,
        pointBackgroundColor: `#fff`,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: `#c51670`,
        pointHoverBorderColor: `#c51670`,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: respironicsPercentilePressureChartDataIPAP,
      });
      setRespironicsPressureGraph([...pressureArray]);
    }

    if (data?.usage?.avgNinetyEPAP) {
      const pressureArray = respironicsPressureGraph;
      pressureArray.push({
        label: `EPAP (90% avg)`,
        fill: false,
        lineTension: 0.5,
        backgroundColor: `#1699C5`,
        borderColor: `#1699C5`,
        borderCapStyle: `butt`,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: `round`,
        pointBorderColor: `#1699C5`,
        pointBackgroundColor: `#fff`,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: `#1699C5`,
        pointHoverBorderColor: `#1699C5`,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: respironicsPercentilePressureChartDataEPAP,
      });
      setRespironicsPressureGraph([...pressureArray]);
    }

    if (data?.usage?.avgNinetyCPAP) {
      const pressureArray = respironicsPressureGraph;
      pressureArray.push({
        label: `CPAP`,
        fill: false,
        lineTension: 0.5,
        backgroundColor: `#99c516`,
        borderColor: `#99c516`,
        borderCapStyle: `butt`,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: `round`,
        pointBorderColor: `#99c516`,
        pointBackgroundColor: `#fff`,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: `#99c516`,
        pointHoverBorderColor: `#99c516`,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: respironicsPercentilePressureChartDataCPAP,
      });
      setRespironicsPressureGraph([...pressureArray]);
    }

    if (data?.usage?.averageAvgCPAP) {
      const pressureArray = respironicsPressureGraph;
      pressureArray.push({
        label: `Average`,
        fill: false,
        lineTension: 0.5,
        backgroundColor: `rgba(75,192,192,0.2)`,
        borderColor: `rgba(75,192,192,1)`,
        borderCapStyle: `butt`,
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: `round`,
        pointBorderColor: `rgba(75,192,192,1)`,
        pointBackgroundColor: `#fff`,
        pointBorderWidth: 2,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: `rgba(75,192,192,1)`,
        pointHoverBorderColor: `rgba(75,192,192,1)`,
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: respironicsAvgPressCPAP,
      });
      setRespironicsPressureGraph([...pressureArray]);
    }
  }, [respironicsPercentilePressureChartDataIPAP, respironicsPercentilePressureChartDataEPAP, respironicsPercentilePressureChartDataCPAP]);

  // EFFECT IS USE, TO ENABLE/DISABLED -> PENDING PATIENT LIST + IMPORT PATIENT BUTTON
  useEffect(() => {
    if (realTimeDataAvailable) {
      if (realTimeDataAvailable.apiStatus === `available`) {
        setIsLiveDataAvailable(false);
      }
      if (realTimeDataAvailable.apiStatus === `unavailable`) {
        setIsLiveDataAvailable(true);
      }
      if (realTimeDataAvailable.apiStatus === `disabled`) {
        setIsLiveDataAvailable(true);
      }
    }
  }, [realTimeDataAvailable]);

  const handleConvertAverageHighLeakInToHoursAndMinutes = (data) => {
    const averageHighLeakInToHours = Math.floor(data?.usage?.averageHighLeak / 60);
    const averageHighLeakInToMinutes = data?.usage?.averageHighLeak % 60;
    return `${averageHighLeakInToHours?.toString().padStart(2, `0`)}:${Math.round(averageHighLeakInToMinutes?.toString().padStart(2, `0`))}`;
  };

  return (
    <>
      {data?.usage?.daysUsed > 0 ? (
        <>
          <div id={css.viewSummaryData} className="pt-4">
            <table className="table table-borderless ml-1">
              <tbody>
                <tr>
                  <td colSpan="2" className="pt-0 pb-0">
                    <h5 className="mb-0" style={{ color: `black` }}>
                      Pressure:
                    </h5>
                  </td>
                  <td rowSpan={showBothPressures ? 5 : 4}>
                    {device === `resmed` ? (
                      //Resmed Pressure Line Graph
                      showBothPressures ? (
                        <BothPressureGraphResmAndReact
                          chartLabels={chartLabels}
                          percentilePressureChartDataEPAP={percentilePressureChartDataEPAP}
                          percentilePressureChartDataIPAP={percentilePressureChartDataIPAP}
                        />
                      ) : (
                        <Line
                          data={{
                            labels: chartLabels,
                            datasets: [
                              {
                                label: `95% Percentile `,
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: `#1699C5`,
                                borderColor: `#1699C5`,
                                borderCapStyle: `butt`,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: `round`,
                                pointBorderColor: `#1699C5`,
                                pointBackgroundColor: `#fff`,
                                pointBorderWidth: 2,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: `#1699C5`,
                                pointHoverBorderColor: `#1699C5`,
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: percentilePressureChartDataIPAP,
                              },
                              {
                                label: `Maximum `,
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: `#c51670`,
                                borderColor: `#c51670`,
                                borderCapStyle: `butt`,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: `round`,
                                pointBorderColor: `#c51670`,
                                pointBackgroundColor: `#fff`,
                                pointBorderWidth: 2,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: `#c51670`,
                                pointHoverBorderColor: `#c51670`,
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: maxPressureChartDataIPAP,
                              },
                              {
                                label: `Median `,
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: `#99c516`,
                                borderColor: `#99c516`,
                                borderCapStyle: `butt`,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: `round`,
                                poiusagentBorderColor: `#99c516`,
                                pointBackgroundColor: `#fff`,
                                pointBorderWidth: 2,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: `#99c516`,
                                pointHoverBorderColor: `#99c516`,
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: medianPressureChartDataIPAP,
                              },
                            ],
                          }}
                          width={100}
                          height={70}
                          options={{
                            tooltips: {
                              callbacks: {
                                label: function (tooltipItem, data) {
                                  let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                  label += tooltipItem.yLabel + ` cmH2O`;
                                  return label;
                                },
                              },
                            },
                            legend: {
                              position: `top`,
                              labels: {
                                usePointStyle: true,
                                boxWidth: 6,
                              },
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    display: false,
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `days`,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  gridLines: {
                                    color: `#dddddd`,
                                    borderDash: [5, 3],
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                    beginAtZero: false,
                                    max: 25,
                                    min: 4,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `Pressure`,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      )
                    ) : device === `reactHealth` ? (
                      //reactHealth Pressure Line Graphs
                      showBothPressures || (data?.usage?.averageIPAP95Pressure && data?.usage?.averageEPAP95Pressure) ? (
                        <BothPressureGraphResmAndReact
                          chartLabels={chartLabels}
                          percentilePressureChartDataEPAP={percentilePressureChartDataEPAP}
                          percentilePressureChartDataIPAP={percentilePressureChartDataIPAP}
                        />
                      ) : (
                        <Line
                          data={{
                            labels: chartLabels,
                            datasets: [
                              {
                                label: `95th Percentile`,
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: `#1699C5`,
                                borderColor: `#1699C5`,
                                borderCapStyle: `butt`,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: `round`,
                                pointBorderColor: `#1699C5`,
                                pointBackgroundColor: `#fff`,
                                pointBorderWidth: 2,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: `#1699C5`,
                                pointHoverBorderColor: `#1699C5`,
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: reactHealthPressure95,
                              },
                            ],
                          }}
                          width={100}
                          height={70}
                          options={{
                            tooltips: {
                              callbacks: {
                                label: function (tooltipItem, data) {
                                  let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                  label += tooltipItem.yLabel + ` cmH2O`;
                                  return label;
                                },
                              },
                            },
                            legend: {
                              position: `top`,
                              labels: {
                                usePointStyle: true,
                                boxWidth: 6,
                              },
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    display: false,
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `days`,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  gridLines: {
                                    color: `#dddddd`,
                                    borderDash: [5, 3],
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                    beginAtZero: false,
                                    max: 25,
                                    min: 4,
                                    // stepSize: 5,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `Pressure`,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      )
                    ) : (
                      //Respironics Pressure Line Graphs
                      <Line
                        data={{
                          labels: chartLabels,
                          datasets: respironicsPressureGraph,
                        }}
                        width={100}
                        height={70}
                        options={{
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                label += tooltipItem.yLabel;
                                return label;
                              },
                            },
                          },
                          legend: {
                            position: `top`,
                            labels: {
                              usePointStyle: true,
                              boxWidth: 6,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `days`,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  color: `#dddddd`,
                                  borderDash: [5, 3],
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                  beginAtZero: false,
                                  max: 25,
                                  min: 4,
                                  // stepSize: 5,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `Pressure`,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </td>
                </tr>
                {showBothPressures || (data?.usage?.averageIPAP95Pressure && data?.usage?.averageEPAP95Pressure) ? (
                  <tr>
                    <td colSpan="2" className="p-0" style={{ textAlign: `right` }}>
                      <span>IPAP &nbsp;&nbsp;&nbsp; EPAP</span>
                      <br />
                      <span style={{ fontWeight: `bold`, fontSize: `9px` }}>(cmH₂O) &nbsp; (cmH₂O)</span>
                    </td>
                  </tr>
                ) : null}
                {device === `respironics` && (
                  <tr>
                    <td colSpan="2" className="p-0" style={{ textAlign: `right` }}>
                      <span>
                        {data?.usage?.avgNinetyIPAP ? `IPAP` : null} &nbsp;&nbsp; {data.usage.avgNinetyEPAP ? `EPAP` : null} &nbsp;&nbsp;{` `}
                        {data?.usage?.avgNinetyCPAP ? `CPAP` : null}
                      </span>
                      <br />
                      <span style={{ fontWeight: `bold`, fontSize: `9px` }}>
                        {data?.usage?.avgNinetyIPAP ? `cmH₂O` : null} &nbsp;&nbsp; {data.usage.avgNinetyEPAP ? `cmH₂O` : null} &nbsp;&nbsp;{` `}
                        {data?.usage?.avgNinetyCPAP ? `cmH₂O` : null}
                      </span>
                    </td>
                  </tr>
                )}
                {device === `resmed` ? (
                  //Resmed 90 percentile
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>95th percentile:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      {CPAPPressure.length > 0 ? (
                        <h5 style={{ textAlign: `` }}>{CPAPPressure[0]}</h5>
                      ) : showBothPressures ? (
                        <h5 style={{ textAlign: `right` }}>
                          {data?.usage?.averageNinetyFiveTgtIPAP ? data.usage.averageNinetyFiveTgtIPAP.toFixed(2) : 0} &nbsp;&nbsp;{` `}
                          {data?.usage?.averageNinetyFiveTgtEPAP ? data.usage.averageNinetyFiveTgtEPAP.toFixed(2) : 0}
                        </h5>
                      ) : (
                        <h5>{data?.usage?.averageNinetyFiveTgtIPAP ? data.usage.averageNinetyFiveTgtIPAP : 0} cmH2O</h5>
                      )}
                    </td>
                  </tr>
                ) : device === `reactHealth` ? (
                  <>
                    <tr id={css.summaryDataRowborder}>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>95th Percentile:</h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        {data?.usage?.averageIPAP95Pressure && data?.usage?.averageEPAP95Pressure ? (
                          <h5 style={{ textAlign: `right` }}>
                            {data?.usage?.averageIPAP95Pressure ? <span>{fixNum(data?.usage?.averageIPAP95Pressure, 2)}</span> : 0}
                            {data?.usage?.averageEPAP95Pressure ? (
                              <span style={{ marginLeft: `14px` }}>{fixNum(data?.usage?.averageEPAP95Pressure, 2)}</span>
                            ) : (
                              0
                            )}
                          </h5>
                        ) : data?.usage?.averageP95 ? (
                          <h5>
                            {fixNum(data?.usage?.averageP95, 2)} cmH<sub>2</sub>O
                          </h5>
                        ) : null}
                      </td>
                    </tr>
                    <tr style={{ visibility: `hidden` }}>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>Average Maximum Pressure:</h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        {data?.usage?.averageIPAPMaxPressure && data?.usage?.averageEPAPMaxPressure ? (
                          <h5 style={{ textAlign: `right` }}>
                            {data?.usage?.averageIPAPMaxPressure ? <span>{fixNum(data.usage.averageIPAPMaxPressure, 2)}</span> : 0}
                            {data?.usage?.averageEPAPMaxPressure ? (
                              <span style={{ marginLeft: `5px` }}>{data.usage.averageEPAPMaxPressure.toFixed(2)}</span>
                            ) : (
                              0
                            )}
                          </h5>
                        ) : data?.usage?.averageMaxPressure ? (
                          <h5>
                            {data?.usage?.averageMaxPressure?.toFixed(1)} cmH<sub>2</sub>O
                          </h5>
                        ) : (
                          <h5>N/A</h5>
                        )}
                      </td>
                    </tr>
                  </>
                ) : (
                  //Respironics 90 percentile
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>90 percentile:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `20%`, textAlign: `right` }}>
                      {data?.usage?.avgNinetyIPAP ? data.usage.avgNinetyIPAP.toFixed(2) : null} &nbsp;&nbsp;&nbsp;{` `}
                      {data?.usage?.avgNinetyEPAP ? data.usage.avgNinetyEPAP.toFixed(2) : null} &nbsp;&nbsp;&nbsp;{` `}
                      {data?.usage?.avgNinetyCPAP ? data.usage.avgNinetyCPAP.toFixed(2) : null} &nbsp;&nbsp;&nbsp;{` `}
                    </td>
                  </tr>
                )}
                {device === `resmed` ? (
                  //Resmed Max and min pressure
                  <>
                    <tr id={css.summaryDataRowborder}>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>Maximum:</h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        {CPAPPressure.length > 0 ? (
                          <h5 style={{ textAlign: `right` }}>{CPAPPressure[0]}</h5>
                        ) : showBothPressures ? (
                          <h5 style={{ textAlign: `right` }}>
                            {data?.usage?.averageMaxTgtIPAP ? data.usage.averageMaxTgtIPAP.toFixed(2) : 0} &nbsp;&nbsp;{` `}
                            {data?.usage?.averageMaxTgtEPAP ? data.usage.averageMaxTgtEPAP.toFixed(2) : 0}
                          </h5>
                        ) : (
                          <h5>{data?.usage?.averageMaxTgtIPAP ? data.usage.averageMaxTgtIPAP : 0} cmH2O</h5>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>Median:</h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        {CPAPPressure.length > 0 ? (
                          <h5 style={{ textAlign: `right` }}>{CPAPPressure[0]}</h5>
                        ) : showBothPressures ? (
                          <h5 style={{ textAlign: `right` }}>
                            {data?.usage?.averageMedTgtIPAP ? data.usage.averageMedTgtIPAP.toFixed(2) : 0} &nbsp;&nbsp;&nbsp;{` `}
                            {data?.usage?.averageMedTgtEPAP ? data.usage.averageMedTgtEPAP.toFixed(2) : 0}
                          </h5>
                        ) : (
                          <h5>{data?.usage?.averageMedTgtIPAP ? data.usage.averageMedTgtIPAP : 0} cmH2O</h5>
                        )}
                      </td>
                    </tr>
                  </>
                ) : device === `reactHealth` ? null : (
                  //Respironics Average
                  <tr>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      {data?.usage?.averageAvgCPAP || data?.usage?.averageAvgEPAP || data?.usage?.averageAvgIPAP ? <h5>Average:</h5> : null}
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%`, textAlign: `right` }}>
                      {data?.usage?.averageAvgIPAP ? data.usage.averageAvgIPAP.toFixed(2) : null} &nbsp;&nbsp;&nbsp;{` `}
                      {data?.usage?.averageAvgEPAP ? data.usage.averageAvgEPAP.toFixed(2) : null} &nbsp;&nbsp;&nbsp;{` `}
                      {data?.usage?.averageAvgCPAP ? data.usage.averageAvgCPAP.toFixed(2) : null} &nbsp;&nbsp;&nbsp;{` `}
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan="3">{/* <hr style={{ marginTop: `0px`, marginBottom: `0px` }} /> */}</td>
                </tr>
                <tr>
                  <td colSpan="2" className="pt-0 pb-0">
                    <h5 className="mb-0" style={{ color: `black` }}>
                      Usage:
                    </h5>
                  </td>
                  <td rowSpan="8">
                    <div>
                      {device === `resmed` ? (
                        //Usage Graph for resmed
                        <Bar
                          data={{
                            labels: chartLabels,
                            datasets: [
                              {
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: `#F1C40F`,
                                borderColor: `#F1C40F`,
                                borderCapStyle: `butt`,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: `round`,
                                pointBorderColor: `#F1C40F`,
                                pointBackgroundColor: `#fff`,
                                pointBorderWidth: 2,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: `#F1C40F`,
                                pointHoverBorderColor: `#F1C40F`,
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: usageChartData,
                              },
                            ],
                          }}
                          width={100}
                          height={80}
                          options={{
                            tooltips: {
                              enabled: false,
                              custom: function (tooltipModel) {
                                /*** jQuery IS USED FOR SIMPLICITY ***/
                                /* TOOLTIP & CARET ELEMENT */
                                let tooltip = $(`#tooltip`);
                                let tooltipCaret = $(`#tooltip-caret`);

                                /* CREATE TOOLTIP & CARET ELEMENT AT FIRST RENDER */
                                if (!tooltip.length && !tooltipCaret.length) {
                                  tooltip = $(`<div></div>`).attr(`id`, `tooltip`);
                                  tooltipCaret = $(`<div></div>`).attr(`id`, `tooltip-caret`);
                                  $(`body`).append(tooltip, tooltipCaret);
                                }

                                /* HIDE IF NO TOOLTIP */
                                if (!tooltipModel.opacity) {
                                  tooltip.hide();
                                  tooltipCaret.hide();
                                  return;
                                }
                                //
                                /* GET BAR PROPS (width, height, top, left) */
                                const barWidth = getBAR(this._chart, tooltipModel).width,
                                  barHeight = getBAR(this._chart, tooltipModel).height,
                                  barTop = getBAR(this._chart, tooltipModel).top,
                                  barLeft = getBAR(this._chart, tooltipModel).left;

                                /* SET STYLE FOR TOOLTIP
                                   (these can also be set in separate css file) */
                                tooltip.css({
                                  display: `inline-block`,
                                  position: `absolute`,
                                  color: `rgba(255, 255, 255, 1)`,
                                  background: `black`,
                                  padding: `5px`,
                                  font: `12px Arial`,
                                  'border-radius': `3px`,
                                  'white-space': `nowrap`,
                                  pointerEvents: `none`,
                                  zIndex: 22,
                                });

                                /* SET STYLE FOR TOOLTIP CARET
                                   (these can also be set in separate css file) */
                                tooltipCaret.css({
                                  display: `block`,
                                  position: `absolute`,
                                  width: 0,
                                  pointerEvents: `none`,
                                  'border-style': `solid`,
                                  'border-width': `8px 10px 8px 0`,
                                  'border-color': `white`,
                                });

                                /* ADD CONTENT IN TOOLTIP */
                                tooltip.text(tooltipModel.dataPoints[0].xLabel);
                                let label =
                                  Math.floor(tooltipModel?.body[0]?.lines[0]) +
                                  `h ` +
                                  Math.floor((tooltipModel?.body[0]?.lines[0] - Math.floor(tooltipModel?.body[0]?.lines[0])) * 60) +
                                  `m`;
                                tooltip.append(
                                  `<br><div class="color-box"></div><div style="display:flex;align-items:center"><div style="background-color:#F1C40F;height:9px;width:9px"></div><label style="display: block; margin: -16px 0 0 16px;color:white"> ${label}<label></div>`
                                );

                                /* POSITION TOOLTIP & CARET
                                 (position should be set after tooltip & caret is rendered) */
                                const centerX = barLeft + barWidth / 2,
                                  centerY = barTop + barHeight / 2;

                                tooltip.css({
                                  top: centerY - tooltip.outerHeight() / 2 + `px`,
                                  left: centerX + tooltipCaret.outerWidth() + `px`,
                                });
                                tooltipCaret.css({
                                  top: centerY - tooltipCaret.outerHeight() / 2 + `px`,
                                  left: centerX + `px`,
                                });
                              },
                            },
                            legend: {
                              display: false,
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    display: false,
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `days`,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  gridLines: {
                                    color: `#dddddd`,
                                    borderDash: [5, 3],
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                    beginAtZero: true,
                                    max: 10,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `usage`,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      ) : device === REACT_HEALTH ? (
                        <Bar
                          data={{
                            labels: chartLabels,
                            datasets: [
                              {
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: `#F1C40F`,
                                borderColor: `#F1C40F`,
                                borderCapStyle: `butt`,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: `round`,
                                pointBorderColor: `#F1C40F`,
                                pointBackgroundColor: `#fff`,
                                pointBorderWidth: 2,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: `#F1C40F`,
                                pointHoverBorderColor: `#F1C40F`,
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: reactHealthUsageChartData,
                              },
                            ],
                          }}
                          width={100}
                          height={80}
                          options={{
                            tooltips: {
                              enabled: false,
                              custom: function (tooltipModel) {
                                /*** jQuery IS USED FOR SIMPLICITY ***/

                                /* TOOLTIP & CARET ELEMENT */
                                let tooltip = $(`#tooltip`);
                                let tooltipCaret = $(`#tooltip-caret`);

                                /* CREATE TOOLTIP & CARET ELEMENT AT FIRST RENDER */
                                if (!tooltip.length && !tooltipCaret.length) {
                                  tooltip = $(`<div></div>`).attr(`id`, `tooltip`);
                                  tooltipCaret = $(`<div></div>`).attr(`id`, `tooltip-caret`);
                                  $(`body`).append(tooltip, tooltipCaret);
                                }

                                /* HIDE IF NO TOOLTIP */
                                if (!tooltipModel.opacity) {
                                  tooltip.hide();
                                  tooltipCaret.hide();
                                  return;
                                }
                                //
                                /* GET BAR PROPS (width, height, top, left) */
                                const barWidth = getBAR(this._chart, tooltipModel).width,
                                  barHeight = getBAR(this._chart, tooltipModel).height,
                                  barTop = getBAR(this._chart, tooltipModel).top,
                                  barLeft = getBAR(this._chart, tooltipModel).left;

                                /* SET STYLE FOR TOOLTIP
                                   (these can also be set in separate css file) */
                                tooltip.css({
                                  display: `inline-block`,
                                  position: `absolute`,
                                  color: `rgba(255, 255, 255, 1)`,
                                  background: `black`,
                                  padding: `5px`,
                                  font: `12px Arial`,
                                  'border-radius': `3px`,
                                  'white-space': `nowrap`,
                                  pointerEvents: `none`,
                                  zIndex: 22,
                                });

                                /* SET STYLE FOR TOOLTIP CARET
                                   (these can also be set in separate css file) */
                                tooltipCaret.css({
                                  display: `block`,
                                  position: `absolute`,
                                  width: 0,
                                  pointerEvents: `none`,
                                  'border-style': `solid`,
                                  'border-width': `8px 10px 8px 0`,
                                  'border-color': `white`,
                                });

                                /* ADD CONTENT IN TOOLTIP */
                                tooltip.text(tooltipModel.dataPoints[0].xLabel);
                                // Extract hours from the tooltip value
                                let hour = Math.trunc(tooltipModel?.body[0]?.lines[0]);
                                // Calculate the fractional part as minutes
                                let minute = tooltipModel?.body[0]?.lines[0] - hour;
                                // Convert the fractional minutes to actual minutes (rounding to the nearest whole number)
                                let actualMinutes = Math.round(minute * 60);

                                let label = Math.floor(tooltipModel?.body[0]?.lines[0]) + `h ` + actualMinutes + `m`;
                                tooltip.append(
                                  `<br><div class="color-box"></div><div style="display:flex;align-items:center"><div style="background-color:#F1C40F;height:9px;width:9px"></div><label style="display: block; margin: -16px 0 0 16px;color:white"> ${label}<label></div>`
                                );

                                /* POSITION TOOLTIP & CARET
                                 (position should be set after tooltip & caret is rendered) */
                                const centerX = barLeft + barWidth / 2,
                                  centerY = barTop + barHeight / 2;

                                tooltip.css({
                                  top: centerY - tooltip.outerHeight() / 2 + `px`,
                                  left: centerX + tooltipCaret.outerWidth() + `px`,
                                });
                                tooltipCaret.css({
                                  top: centerY - tooltipCaret.outerHeight() / 2 + `px`,
                                  left: centerX + `px`,
                                });
                              },
                            },
                            legend: {
                              display: false,
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    display: false,
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `days`,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  gridLines: {
                                    color: `#dddddd`,
                                    borderDash: [5, 3],
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                    beginAtZero: true,
                                    max: 10,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `usage`,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      ) : (
                        //Usage Graph for respironics
                        <Bar
                          data={{
                            labels: chartLabels,
                            datasets: [
                              {
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: `#F1C40F`,
                                borderColor: `#F1C40F`,
                                borderCapStyle: `butt`,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                borderJoinStyle: `round`,
                                pointBorderColor: `#F1C40F`,
                                pointBackgroundColor: `#fff`,
                                pointBorderWidth: 2,
                                pointHoverRadius: 5,
                                pointHoverBackgroundColor: `#F1C40F`,
                                pointHoverBorderColor: `#F1C40F`,
                                pointHoverBorderWidth: 2,
                                pointRadius: 1,
                                pointHitRadius: 10,
                                data: respironicsUsageChartData,
                              },
                            ],
                          }}
                          width={100}
                          height={80}
                          options={{
                            tooltips: {
                              enabled: false,
                              custom: function (tooltipModel) {
                                /*** jQuery IS USED FOR SIMPLICITY ***/

                                /* TOOLTIP & CARET ELEMENT */
                                let tooltip = $(`#tooltip`);
                                let tooltipCaret = $(`#tooltip-caret`);

                                /* CREATE TOOLTIP & CARET ELEMENT AT FIRST RENDER */
                                if (!tooltip.length && !tooltipCaret.length) {
                                  tooltip = $(`<div></div>`).attr(`id`, `tooltip`);
                                  tooltipCaret = $(`<div></div>`).attr(`id`, `tooltip-caret`);
                                  $(`body`).append(tooltip, tooltipCaret);
                                }

                                /* HIDE IF NO TOOLTIP */
                                if (!tooltipModel.opacity) {
                                  tooltip.hide();
                                  tooltipCaret.hide();
                                  return;
                                }
                                //
                                /* GET BAR PROPS (width, height, top, left) */
                                const barWidth = getBAR(this._chart, tooltipModel).width,
                                  barHeight = getBAR(this._chart, tooltipModel).height,
                                  barTop = getBAR(this._chart, tooltipModel).top,
                                  barLeft = getBAR(this._chart, tooltipModel).left;

                                /* SET STYLE FOR TOOLTIP
                                   (these can also be set in separate css file) */
                                tooltip.css({
                                  display: `inline-block`,
                                  position: `absolute`,
                                  color: `rgba(255, 255, 255, 1)`,
                                  background: `black`,
                                  padding: `5px`,
                                  font: `12px Arial`,
                                  'border-radius': `3px`,
                                  'white-space': `nowrap`,
                                  pointerEvents: `none`,
                                  zIndex: 22,
                                });

                                /* SET STYLE FOR TOOLTIP CARET
                                   (these can also be set in separate css file) */
                                tooltipCaret.css({
                                  display: `block`,
                                  position: `absolute`,
                                  width: 0,
                                  pointerEvents: `none`,
                                  'border-style': `solid`,
                                  'border-width': `8px 10px 8px 0`,
                                  'border-color': `white`,
                                });

                                /* ADD CONTENT IN TOOLTIP */
                                tooltip.text(tooltipModel.dataPoints[0].xLabel);
                                let label =
                                  Math.floor(tooltipModel?.body[0]?.lines[0]) +
                                  `h ` +
                                  Math.floor((tooltipModel?.body[0]?.lines[0] - Math.floor(tooltipModel?.body[0]?.lines[0])) * 60) +
                                  `m`;
                                tooltip.append(
                                  `<br><div class="color-box"></div><div style="display:flex;align-items:center"><div style="background-color:#F1C40F;height:9px;width:9px"></div><label style="display: block; margin: -16px 0 0 16px;color:white"> ${label}<label></div>`
                                );

                                /* POSITION TOOLTIP & CARET
                                 (position should be set after tooltip & caret is rendered) */
                                const centerX = barLeft + barWidth / 2,
                                  centerY = barTop + barHeight / 2;

                                tooltip.css({
                                  top: centerY - tooltip.outerHeight() / 2 + `px`,
                                  left: centerX + tooltipCaret.outerWidth() + `px`,
                                });
                                tooltipCaret.css({
                                  top: centerY - tooltipCaret.outerHeight() / 2 + `px`,
                                  left: centerX + `px`,
                                });
                              },
                            },
                            legend: {
                              display: false,
                            },
                            responsive: true,
                            maintainAspectRatio: true,
                            scales: {
                              xAxes: [
                                {
                                  gridLines: {
                                    display: false,
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `days`,
                                  },
                                },
                              ],
                              yAxes: [
                                {
                                  gridLines: {
                                    color: `#dddddd`,
                                    borderDash: [5, 3],
                                  },
                                  ticks: {
                                    display: true,
                                    fontSize: 8,
                                    beginAtZero: true,
                                    max: 10,
                                  },
                                  scaleLabel: {
                                    display: true,
                                    labelString: `usage`,
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      )}
                    </div>
                  </td>
                </tr>
                <tr id={css.summaryDataRowborder}>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>Days used:</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.daysUsed !== null ? data?.usage?.daysUsed : `N/A`}/${data?.totalDays !== null ? data?.totalDays : `N/A`
                      } days`}</h5>
                  </td>
                </tr>
                <tr id={css.summaryDataRowborder}>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>{`Days used >= 4 hours:`}</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.daysUsedGreaterThanFour !== null ? data?.usage?.daysUsedGreaterThanFour : `N/A`}/${data?.totalDays !== null ? data?.totalDays : `N/A`
                      } days`}</h5>
                  </td>
                </tr>
                <tr id={css.summaryDataRowborder}>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>{`Days used >= 6 hours:`}</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.daysUsedGreaterThanSix !== null ? data?.usage?.daysUsedGreaterThanSix : `N/A`}/${data?.totalDays !== null ? data?.totalDays : `N/A`
                      } days`}</h5>
                  </td>
                </tr>
                <tr id={css.summaryDataRowborder}>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>Avg. usage (on days used):</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.averageUsageOnDaysUsed !== null ? Math.floor(data?.usage?.averageUsageOnDaysUsed / 60) : `N/A`}h ${data?.usage?.averageUsageOnDaysUsed !== null
                      ? data?.usage?.averageUsageOnDaysUsed - Math.floor(data?.usage?.averageUsageOnDaysUsed / 60) * 60
                      : `N/A`
                      }m`}</h5>
                  </td>
                </tr>
                <tr id={css.summaryDataRowborder}>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>Avg. usage (on total days):</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.averageUsageInTotalDays !== null ? Math.floor(data?.usage?.averageUsageInTotalDays / 60) : `N/A`}h ${data?.usage?.averageUsageInTotalDays !== null
                      ? data?.usage?.averageUsageInTotalDays - Math.floor(data?.usage?.averageUsageInTotalDays / 60) * 60
                      : `N/A`
                      }m`}</h5>
                  </td>
                </tr>
                <tr>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>Total hours used:</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.minutesUsed !== null ? Math.floor(data?.usage?.minutesUsed / 60) : `N/A`}h`}</h5>
                  </td>
                </tr>
                {device === `resmed` && (
                  //Only show in resmed graph
                  <tr>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>{`Mask On/Off (Median):`}</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.maskOffMedian !== null ? data?.usage?.maskOffMedian && data?.usage?.maskOffMedian?.toFixed(2) : `N/A`}`}</h5>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan="3">{/* <hr style={{ marginTop: `0px`, marginBottom: `0px` }} /> */}</td>
                </tr>
                <tr>
                  <td colSpan="2" className="pt-0 pb-0">
                    <h5 className="mb-0" style={{ color: `black` }}>
                      Events per hour:
                    </h5>
                  </td>
                  <td rowSpan={device === `resmed` ? `9` : `4`}>
                    {device === `resmed` ? (
                      <Bar
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              fill: false,
                              lineTension: 0.5,
                              backgroundColor: `#c51670`,
                              borderColor: `#c51670`,
                              borderCapStyle: `butt`,
                              borderDash: [],
                              borderDashOffset: 0.0,
                              borderJoinStyle: `round`,
                              pointBorderColor: `#c51670`,
                              pointBackgroundColor: `#fff`,
                              pointBorderWidth: 2,
                              pointHoverRadius: 5,
                              pointHoverBackgroundColor: `#c51670`,
                              pointHoverBorderColor: `#c51670`,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                              data: eventChartData,
                            },
                          ],
                        }}
                        width={100}
                        height={80}
                        options={{
                          tooltips: {
                            enabled: false,
                            custom: function (tooltipModel) {
                              /*** jQuery IS USED FOR SIMPLICITY ***/

                              /* TOOLTIP & CARET ELEMENT */
                              let tooltip = $(`#tooltip`);
                              let tooltipCaret = $(`#tooltip-caret`);

                              /* CREATE TOOLTIP & CARET ELEMENT AT FIRST RENDER */
                              if (!tooltip.length && !tooltipCaret.length) {
                                tooltip = $(`<div></div>`).attr(`id`, `tooltip`);
                                tooltipCaret = $(`<div></div>`).attr(`id`, `tooltip-caret`);
                                $(`body`).append(tooltip, tooltipCaret);
                              }

                              /* HIDE IF NO TOOLTIP */
                              if (!tooltipModel.opacity) {
                                tooltip.hide();
                                tooltipCaret.hide();
                                return;
                              }
                              /* GET BAR PROPS (width, height, top, left) */
                              const barWidth = getBAR(this._chart, tooltipModel).width,
                                barHeight = getBAR(this._chart, tooltipModel).height,
                                barTop = getBAR(this._chart, tooltipModel).top,
                                barLeft = getBAR(this._chart, tooltipModel).left;

                              /* SET STYLE FOR TOOLTIP
                                   (these can also be set in separate css file) */
                              tooltip.css({
                                display: `inline-block`,
                                position: `absolute`,
                                color: `rgba(255, 255, 255, 1)`,
                                background: `black`,
                                padding: `5px`,
                                font: `12px Arial`,
                                'border-radius': `3px`,
                                'white-space': `nowrap`,
                                pointerEvents: `none`,
                                zIndex: 22,
                                width: `90px`,
                              });

                              /* SET STYLE FOR TOOLTIP CARET
                                   (these can also be set in separate css file) */
                              tooltipCaret.css({
                                display: `block`,
                                position: `absolute`,
                                width: 0,
                                pointerEvents: `none`,
                                'border-style': `solid`,
                                'border-width': `8px 10px 8px 0`,
                                'border-color': `white`,
                              });

                              /* ADD CONTENT IN TOOLTIP */
                              tooltip.text(tooltipModel.dataPoints[0].xLabel);
                              let label = tooltipModel?.body[0]?.lines[0];
                              tooltip.append(
                                `<br><div class="color-box"></div><div style="display:flex;align-items:center"><div style="background-color:#c51670;height:9px;width:9px"></div><label style="display: block; margin: -16px 0 0 16px;color:white"> ${label}<label></div>`
                              );

                              /* POSITION TOOLTIP & CARET
                                 (position should be set after tooltip & caret is rendered) */
                              const centerX = barLeft + barWidth / 2,
                                centerY = barTop + barHeight / 2;

                              tooltip.css({
                                top: centerY - tooltip.outerHeight() / 2 + `px`,
                                left: centerX + tooltipCaret.outerWidth() + `px`,
                              });
                              tooltipCaret.css({
                                top: centerY - tooltipCaret.outerHeight() / 2 + `px`,
                                left: centerX + `px`,
                              });
                            },
                          },
                          legend: {
                            display: false,
                          },
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `days`,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  color: `#dddddd`,
                                  borderDash: [5, 3],
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                  beginAtZero: true,
                                  max: 30,
                                  stepSize: 5,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `AHI`,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    ) : device === `reactHealth` ? (
                      <Bar
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              fill: false,
                              lineTension: 0.5,
                              backgroundColor: `#c51670`,
                              borderColor: `#c51670`,
                              borderCapStyle: `butt`,
                              borderDash: [],
                              borderDashOffset: 0.0,
                              borderJoinStyle: `round`,
                              pointBorderColor: `#c51670`,
                              pointBackgroundColor: `#fff`,
                              pointBorderWidth: 2,
                              pointHoverRadius: 5,
                              pointHoverBackgroundColor: `#c51670`,
                              pointHoverBorderColor: `#c51670`,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                              data: reactHealthEventDataTemp,
                            },
                          ],
                        }}
                        width={100}
                        height={80}
                        options={{
                          tooltips: {
                            enabled: false,
                            custom: function (tooltipModel) {
                              /*** jQuery IS USED FOR SIMPLICITY ***/
                              /* TOOLTIP & CARET ELEMENT */
                              let tooltip = $(`#tooltip`);
                              let tooltipCaret = $(`#tooltip-caret`);
                              /* CREATE TOOLTIP & CARET ELEMENT AT FIRST RENDER */
                              if (!tooltip.length && !tooltipCaret.length) {
                                tooltip = $(`<div></div>`).attr(`id`, `tooltip`);
                                tooltipCaret = $(`<div></div>`).attr(`id`, `tooltip-caret`);
                                $(`body`).append(tooltip, tooltipCaret);
                              }

                              /* HIDE IF NO TOOLTIP */
                              if (!tooltipModel.opacity) {
                                tooltip.hide();
                                tooltipCaret.hide();
                                return;
                              }
                              /* GET BAR PROPS (width, height, top, left) */
                              const barWidth = getBAR(this._chart, tooltipModel).width,
                                barHeight = getBAR(this._chart, tooltipModel).height,
                                barTop = getBAR(this._chart, tooltipModel).top,
                                barLeft = getBAR(this._chart, tooltipModel).left;

                              /* SET STYLE FOR TOOLTIP
                                   (these can also be set in separate css file) */
                              tooltip.css({
                                display: `inline-block`,
                                position: `absolute`,
                                color: `rgba(255, 255, 255, 1)`,
                                background: `black`,
                                padding: `5px`,
                                font: `12px Arial`,
                                'border-radius': `3px`,
                                'white-space': `nowrap`,
                                pointerEvents: `none`,
                                zIndex: 22,
                                width: `90px`,
                              });

                              /* SET STYLE FOR TOOLTIP CARET
                                   (these can also be set in separate css file) */
                              tooltipCaret.css({
                                display: `block`,
                                position: `absolute`,
                                width: 0,
                                pointerEvents: `none`,
                                'border-style': `solid`,
                                'border-width': `8px 10px 8px 0`,
                                'border-color': `white`,
                              });

                              /* ADD CONTENT IN TOOLTIP */
                              tooltip.text(tooltipModel.dataPoints[0].xLabel);
                              let num = parseFloat(tooltipModel?.body[0]?.lines[0]);
                              let label = num.toFixed(2);
                              tooltip.append(
                                `<br><div class="color-box"></div><div style="display:flex;align-items:center"><div style="background-color:#c51670;height:9px;width:9px"></div><label style="display: block; margin: -16px 0 0 16px;color:white"> ${label}<label></div>`
                              );

                              /* POSITION TOOLTIP & CARET
                                 (position should be set after tooltip & caret is rendered) */
                              const centerX = barLeft + barWidth / 2,
                                centerY = barTop + barHeight / 2;

                              tooltip.css({
                                top: centerY - tooltip.outerHeight() / 2 + `px`,
                                left: centerX + tooltipCaret.outerWidth() + `px`,
                              });
                              tooltipCaret.css({
                                top: centerY - tooltipCaret.outerHeight() / 2 + `px`,
                                left: centerX + `px`,
                              });
                            },
                          },
                          legend: {
                            display: false,
                          },
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `days`,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  color: `#dddddd`,
                                  borderDash: [5, 3],
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                  beginAtZero: true,
                                  max: 30,
                                  stepSize: 5,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `AHI`,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    ) : (
                      <Bar
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              fill: false,
                              lineTension: 0.5,
                              backgroundColor: `#c51670`,
                              borderColor: `#c51670`,
                              borderCapStyle: `butt`,
                              borderDash: [],
                              borderDashOffset: 0.0,
                              borderJoinStyle: `round`,
                              pointBorderColor: `#c51670`,
                              pointBackgroundColor: `#fff`,
                              pointBorderWidth: 2,
                              pointHoverRadius: 5,
                              pointHoverBackgroundColor: `#c51670`,
                              pointHoverBorderColor: `#c51670`,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                              data: respironicsEventChartData,
                            },
                          ],
                        }}
                        width={100}
                        height={80}
                        options={{
                          tooltips: {
                            enabled: false,
                            custom: function (tooltipModel) {
                              /*** jQuery IS USED FOR SIMPLICITY ***/
                              /* TOOLTIP & CARET ELEMENT */
                              let tooltip = $(`#tooltip`);
                              let tooltipCaret = $(`#tooltip-caret`);
                              /* CREATE TOOLTIP & CARET ELEMENT AT FIRST RENDER */
                              if (!tooltip.length && !tooltipCaret.length) {
                                tooltip = $(`<div></div>`).attr(`id`, `tooltip`);
                                tooltipCaret = $(`<div></div>`).attr(`id`, `tooltip-caret`);
                                $(`body`).append(tooltip, tooltipCaret);
                              }

                              /* HIDE IF NO TOOLTIP */
                              if (!tooltipModel.opacity) {
                                tooltip.hide();
                                tooltipCaret.hide();
                                return;
                              }
                              /* GET BAR PROPS (width, height, top, left) */
                              const barWidth = getBAR(this._chart, tooltipModel).width,
                                barHeight = getBAR(this._chart, tooltipModel).height,
                                barTop = getBAR(this._chart, tooltipModel).top,
                                barLeft = getBAR(this._chart, tooltipModel).left;

                              /* SET STYLE FOR TOOLTIP
                                   (these can also be set in separate css file) */
                              tooltip.css({
                                display: `inline-block`,
                                position: `absolute`,
                                color: `rgba(255, 255, 255, 1)`,
                                background: `black`,
                                padding: `5px`,
                                font: `12px Arial`,
                                'border-radius': `3px`,
                                'white-space': `nowrap`,
                                pointerEvents: `none`,
                                zIndex: 22,
                                width: `90px`,
                              });

                              /* SET STYLE FOR TOOLTIP CARET
                                   (these can also be set in separate css file) */
                              tooltipCaret.css({
                                display: `block`,
                                position: `absolute`,
                                width: 0,
                                pointerEvents: `none`,
                                'border-style': `solid`,
                                'border-width': `8px 10px 8px 0`,
                                'border-color': `white`,
                              });

                              /* ADD CONTENT IN TOOLTIP */
                              tooltip.text(tooltipModel.dataPoints[0].xLabel);
                              let num = parseFloat(tooltipModel?.body[0]?.lines[0]);
                              let label = num.toFixed(2);
                              tooltip.append(
                                `<br><div class="color-box"></div><div style="display:flex;align-items:center"><div style="background-color:#c51670;height:9px;width:9px"></div><label style="display: block; margin: -16px 0 0 16px;color:white"> ${label}<label></div>`
                              );

                              /* POSITION TOOLTIP & CARET
                                 (position should be set after tooltip & caret is rendered) */
                              const centerX = barLeft + barWidth / 2,
                                centerY = barTop + barHeight / 2;

                              tooltip.css({
                                top: centerY - tooltip.outerHeight() / 2 + `px`,
                                left: centerX + tooltipCaret.outerWidth() + `px`,
                              });
                              tooltipCaret.css({
                                top: centerY - tooltipCaret.outerHeight() / 2 + `px`,
                                left: centerX + `px`,
                              });
                            },
                          },
                          legend: {
                            display: false,
                          },
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `days`,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  color: `#dddddd`,
                                  borderDash: [5, 3],
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                  beginAtZero: true,
                                  max: 30,
                                  stepSize: 5,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `AHI`,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </td>
                </tr>
                <tr id={css.summaryDataRowborder}>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>AHI:</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.averageAHI !== null ? data?.usage?.averageAHI && data?.usage?.averageAHI?.toFixed(1) : `N/A`}`}</h5>
                  </td>
                </tr>
                {device === `resmed` && (
                  //Only show these values to respironics
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>AI:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.averageAI !== null ? data?.usage?.averageAI && data?.usage?.averageAI?.toFixed(2) : `N/A`}`}</h5>
                    </td>
                  </tr>
                )}
                {device === `resmed` && (
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>HI:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.averageHI !== null ? data?.usage?.averageHI && data?.usage?.averageHI?.toFixed(2) : `N/A`}`}</h5>
                    </td>
                  </tr>
                )}
                <tr>
                  <td className="pr-0 pb-0" style={{ width: `20%` }}>
                    <h5>CAI:</h5>
                  </td>
                  <td className="pr-0 pb-0" style={{ width: `10%` }}>
                    <h5>{`${data?.usage?.averageCAI !== null ? data?.usage?.averageCAI && data?.usage?.averageCAI?.toFixed(2) : `N/A`}`}</h5>
                  </td>
                </tr>
                <tr></tr>
                {/* {device === `reactHealth` && (
                  <tr>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>EIP:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>
                        {data?.usage?.averageEIP}
                      </h5>
                    </td>
                  </tr>
                )} */}
                {device === `respironics` && (
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>PB:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>
                        {data?.usage?.averagePeriodicBreathingTime !== null ? data?.usage?.averagePeriodicBreathingTime && `${data?.usage?.averagePeriodicBreathingTime?.toFixed(2)}%` : `N/A`}
                      </h5>
                    </td>
                  </tr>
                )}
                {device === `resmed` && (
                  //Only show these values to resmed
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>OAI:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.averageOAI !== null ? data?.usage?.averageOAI && data?.usage?.averageOAI?.toFixed(2) : `N/A`}`}</h5>
                    </td>
                  </tr>
                )}
                {device === `resmed` && (
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>UAI:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.averageUAI !== null ? data?.usage?.averageUAI && data?.usage?.averageUAI?.toFixed(2) : `N/A`}`}</h5>
                    </td>
                  </tr>
                )}
                {device === `resmed` && (
                  <tr id={css.summaryDataRowborder}>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>CSR:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.averageCSR !== null ? data?.usage?.averageCSR && data?.usage?.averageCSR?.toFixed(2) : `N/A`}`}</h5>
                    </td>
                  </tr>
                )}
                {device === `resmed` && (
                  <tr>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>RERA:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.averageRERA !== null ? data?.usage?.averageRERA && data?.usage?.averageRERA?.toFixed(2) : `N/A`}`}</h5>
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan="3">
                    <hr style={{ marginTop: `0px`, marginBottom: `0px` }} />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2" className="pt-0 pb-0">
                    <h5 className="mb-0" style={{ color: `black` }}>
                      Leak:
                    </h5>
                  </td>
                  <td rowSpan={device === `resmed` ? `4` : `2`}>
                    {device === `resmed` ? (
                      //Leak graph for resmed
                      <Line
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              label: `95% Leak `,
                              fill: false,
                              lineTension: 0.5,
                              backgroundColor: `#1699C5`,
                              borderColor: `#1699C5`,
                              borderCapStyle: `butt`,
                              borderDash: [],
                              borderDashOffset: 0.0,
                              borderJoinStyle: `round`,
                              pointBorderColor: `#1699C5`,
                              pointBackgroundColor: `#fff`,
                              pointBorderWidth: 2,
                              pointHoverRadius: 5,
                              pointHoverBackgroundColor: `#1699C5`,
                              pointHoverBorderColor: `#1699C5`,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                              data: leakChartData,
                            },
                            {
                              label: `Max leak `,
                              fill: false,
                              lineTension: 0.5,
                              backgroundColor: `#c51670`,
                              borderColor: `#c51670`,
                              borderCapStyle: `butt`,
                              borderDash: [],
                              borderDashOffset: 0.0,
                              borderJoinStyle: `round`,
                              pointBorderColor: `#c51670`,
                              pointBackgroundColor: `#fff`,
                              pointBorderWidth: 2,
                              pointHoverRadius: 5,
                              pointHoverBackgroundColor: `#c51670`,
                              pointHoverBorderColor: `#c51670`,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                              data: maxLeakChartData,
                            },
                            {
                              label: `Med Leak `,
                              fill: false,
                              lineTension: 0.5,
                              backgroundColor: `#99c516`,
                              borderColor: `#99c516`,
                              borderCapStyle: `butt`,
                              borderDash: [],
                              borderDashOffset: 0.0,
                              borderJoinStyle: `round`,
                              pointBorderColor: `#99c516`,
                              pointBackgroundColor: `#fff`,
                              pointBorderWidth: 2,
                              pointHoverRadius: 5,
                              pointHoverBackgroundColor: `#99c516`,
                              pointHoverBorderColor: `#99c516`,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                              data: medLeakChartData,
                            },
                          ],
                        }}
                        width={100}
                        height={70}
                        options={{
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                label += tooltipItem.yLabel + ` L/min`;
                                return label;
                              },
                            },
                          },
                          legend: {
                            position: `top`,
                            labels: {
                              usePointStyle: true,
                              boxWidth: 6,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                  beginAtZero: true,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `days`,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  color: `#dddddd`,
                                  borderDash: [5, 3],
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `Leak`,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    ) : (
                      //Leak graph for respironics
                      <Line
                        data={{
                          labels: chartLabels,
                          datasets: [
                            {
                              label: `Average Leak`,
                              fill: false,
                              lineTension: 0.5,
                              backgroundColor: `#1699C5`,
                              borderColor: `#1699C5`,
                              borderCapStyle: `butt`,
                              borderDash: [],
                              borderDashOffset: 0.0,
                              borderJoinStyle: `round`,
                              pointBorderColor: `#1699C5`,
                              pointBackgroundColor: `#fff`,
                              pointBorderWidth: 2,
                              pointHoverRadius: 5,
                              pointHoverBackgroundColor: `#1699C5`,
                              pointHoverBorderColor: `#1699C5`,
                              pointHoverBorderWidth: 2,
                              pointRadius: 1,
                              pointHitRadius: 10,
                              data: respironicsLeakChartData,
                            },
                          ],
                        }}
                        width={100}
                        height={70}
                        options={{
                          tooltips: {
                            callbacks: {
                              label: function (tooltipItem, data) {
                                let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                label += tooltipItem.yLabel + ` L/min`;
                                return label;
                              },
                            },
                          },
                          legend: {
                            position: `top`,
                            labels: {
                              usePointStyle: true,
                              boxWidth: 6,
                            },
                          },
                          responsive: true,
                          maintainAspectRatio: true,
                          scales: {
                            xAxes: [
                              {
                                gridLines: {
                                  display: false,
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                  beginAtZero: true,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `days`,
                                },
                              },
                            ],
                            yAxes: [
                              {
                                gridLines: {
                                  color: `#dddddd`,
                                  borderDash: [5, 3],
                                },
                                ticks: {
                                  display: true,
                                  fontSize: 8,
                                },
                                scaleLabel: {
                                  display: true,
                                  labelString: `Leak`,
                                },
                              },
                            ],
                          },
                        }}
                      />
                    )}
                  </td>
                </tr>
                {device === `resmed` ? (
                  <>
                    <tr id={css.summaryDataRowborder}>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>95th percentile:</h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        <h5>{`${data?.usage?.averageNinetyFiveLeak !== null ? data?.usage?.averageNinetyFiveLeak && data?.usage?.averageNinetyFiveLeak?.toFixed(1) : `N/A`} L/min`}</h5>
                      </td>
                    </tr>
                    <tr id={css.summaryDataRowborder}>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>Maximum:</h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        <h5>{`${data?.usage?.averageMaxLeak !== null ? data?.usage?.averageMaxLeak && data?.usage?.averageMaxLeak?.toFixed(1) : `N/A`} L/min`}</h5>
                      </td>
                    </tr>
                    <tr>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>Median:</h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        <h5>{`${data?.usage?.averageMedLeak !== null ? data?.usage?.averageMedLeak && data?.usage?.averageMedLeak?.toFixed(1) : `N/A`} L/min`}</h5>
                      </td>
                    </tr>
                  </>
                ) : device === `reactHealth` ? (
                  <>
                    <tr>
                      <td className="pr-0 pb-0" style={{ width: `20%` }}>
                        <h5>Average Leak:</h5>
                        <h5 style={{ marginTop: `30px` }}>
                          High Leak Time
                          <br />
                          {`(>90 LPM):`}
                        </h5>
                      </td>
                      <td className="pr-0 pb-0" style={{ width: `10%` }}>
                        <h5>{`${data?.usage?.averageLeak ? data?.usage?.averageLeak?.toFixed(1) : `N/A`} L/min`}</h5>
                        <h5 style={{ marginTop: `30px` }}>{`${handleConvertAverageHighLeakInToHoursAndMinutes(data)} (HH:mm)`}</h5>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td className="pr-0 pb-0" style={{ width: `20%` }}>
                      <h5>Average Unintentional Leak:</h5>
                    </td>
                    <td className="pr-0 pb-0" style={{ width: `10%` }}>
                      <h5>{`${data?.usage?.averageAvgLeak !== null ? data?.usage?.averageAvgLeak && data?.usage?.averageAvgLeak?.toFixed(2) : `N/A`} L/min`}</h5>
                    </td>
                  </tr>
                )}

                <tr>
                  <td colSpan="3">
                    <hr style={{ marginTop: `0px`, marginBottom: `0px` }} />
                  </td>
                </tr>
                <ConditionallyRenderWrapper
                  con={condForDetaiandSummaryGraphs({
                    canStr: true, conArr: [data?.usage?.averageNinetyFiveTidalVol, data?.usage?.averageAvgTidalVolume]
                  })}
                >
                  <tr>
                    <td colSpan="3" className="pt-0 pb-0">
                      <h5 className="mb-0" style={{ color: `black` }}>
                        {device === `resmed`
                          ? `NIV Parameters (95th percentile metrics):`
                          : device === `reactHealth`
                            ? null
                            : `NIV Parameters (Average metrics):`}
                      </h5>
                    </td>
                  </tr>
                </ConditionallyRenderWrapper>

                {device === `reactHealth` ? null : (
                  <ConditionallyRenderWrapper
                    con={condForDetaiandSummaryGraphs({
                      canStr: true,
                      conArr: [data?.usage?.averageNinetyFiveTidalVol, data?.usage?.averageAvgTidalVolume],
                    })}
                  >
                    <tr>
                      <td style={{ width: `20%` }}>
                        <h5>Tidal Volume (TV):</h5>
                      </td>
                      {device === `resmed` ? (
                        <td style={{ width: `10%` }}>
                          <h5>{`${data?.usage?.averageNinetyFiveTidalVol !== null ? data?.usage?.averageNinetyFiveTidalVol && data?.usage?.averageNinetyFiveTidalVol?.toFixed() : `N/A`} ml/breath`}</h5>
                        </td>
                      ) : (
                        <td style={{ width: `10%` }}>
                          <h5>{`${data?.usage?.averageAvgTidalVolume !== null ? data?.usage?.averageAvgTidalVolume : `N/A`} ml/breath`}</h5>
                        </td>
                      )}
                      <td>
                        {device === `resmed` ? (
                          <Bar
                            data={{
                              labels: chartLabels,
                              datasets: [
                                {
                                  fill: false,
                                  lineTension: 0.5,
                                  backgroundColor: `#1699C5`,
                                  borderColor: `#1699C5`,
                                  borderCapStyle: `butt`,
                                  borderDash: [],
                                  borderDashOffset: 0.0,
                                  borderJoinStyle: `round`,
                                  pointBorderColor: `#1699C5`,
                                  pointBackgroundColor: `#fff`,
                                  pointBorderWidth: 2,
                                  pointHoverRadius: 5,
                                  pointHoverBackgroundColor: `#1699C5`,
                                  pointHoverBorderColor: `#1699C5`,
                                  pointHoverBorderWidth: 2,
                                  pointRadius: 1,
                                  pointHitRadius: 10,
                                  data: tvChartData,
                                },
                              ],
                            }}
                            width={100}
                            height={60}
                            options={{
                              tooltips: {
                                callbacks: {
                                  label: function (tooltipItem, data) {
                                    let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                    label += tooltipItem.yLabel.toFixed(1) + ` ml/breaths`;
                                    return label;
                                  },
                                },
                              },
                              legend: {
                                display: false,
                              },
                              responsive: true,
                              maintainAspectRatio: true,
                              scales: {
                                xAxes: [
                                  {
                                    gridLines: {
                                      display: false,
                                    },
                                    ticks: {
                                      display: true,
                                      fontSize: 8,
                                    },
                                    scaleLabel: {
                                      display: true,
                                      labelString: `days`,
                                    },
                                  },
                                ],
                                yAxes: [
                                  {
                                    gridLines: {
                                      color: `#dddddd`,
                                      borderDash: [5, 3],
                                    },
                                    ticks: {
                                      display: true,
                                      fontSize: 8,
                                      beginAtZero: true,
                                    },
                                    scaleLabel: {
                                      display: true,
                                      labelString: `TV`,
                                    },
                                  },
                                ],
                              },
                            }}
                          />
                        ) : (
                          <Bar
                            data={{
                              labels: chartLabels,
                              datasets: [
                                {
                                  fill: false,
                                  lineTension: 0.5,
                                  backgroundColor: `#1699C5`,
                                  borderColor: `#1699C5`,
                                  borderCapStyle: `butt`,
                                  borderDash: [],
                                  borderDashOffset: 0.0,
                                  borderJoinStyle: `round`,
                                  pointBorderColor: `#1699C5`,
                                  pointBackgroundColor: `#fff`,
                                  pointBorderWidth: 2,
                                  pointHoverRadius: 5,
                                  pointHoverBackgroundColor: `#1699C5`,
                                  pointHoverBorderColor: `#1699C5`,
                                  pointHoverBorderWidth: 2,
                                  pointRadius: 1,
                                  pointHitRadius: 10,
                                  data: respironicsTvChartData,
                                },
                              ],
                            }}
                            width={100}
                            height={60}
                            options={{
                              tooltips: {
                                callbacks: {
                                  label: function (tooltipItem, data) {
                                    let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                    label += tooltipItem.yLabel.toFixed(1) + ` ml/breaths`;
                                    return label;
                                  },
                                },
                              },
                              legend: {
                                display: false,
                              },
                              responsive: true,
                              maintainAspectRatio: true,
                              scales: {
                                xAxes: [
                                  {
                                    gridLines: {
                                      display: false,
                                    },
                                    ticks: {
                                      display: true,
                                      fontSize: 8,
                                    },
                                    scaleLabel: {
                                      display: true,
                                      labelString: `days`,
                                    },
                                  },
                                ],
                                yAxes: [
                                  {
                                    gridLines: {
                                      color: `#dddddd`,
                                      borderDash: [5, 3],
                                    },
                                    ticks: {
                                      display: true,
                                      fontSize: 8,
                                      beginAtZero: true,
                                    },
                                    scaleLabel: {
                                      display: true,
                                      labelString: `TV`,
                                    },
                                  },
                                ],
                              },
                            }}
                          />
                        )}
                      </td>
                    </tr>
                  </ConditionallyRenderWrapper>
                )}
                {device === `reactHealth` ? null : (
                  <ConditionallyRenderWrapper
                    con={condForDetaiandSummaryGraphs({
                      canStr: true,
                      conArr: [
                        data?.usage?.averageMaxRespRate,
                        data?.usage?.averageMedRespRate,
                        data?.usage?.averageNinetyFiveRespRate,
                        data?.usage?.averageAvgBreathRate,
                        data?.usage?.averageAvgMinuteVent,
                        data?.usage?.averageNinetyFiveMinuteVent,
                        data?.usage?.averageNinetyFiveIERatio,
                        data?.usage?.averageMaxIERatio,
                        data?.usage?.averageMedIERatio
                      ],
                      breaths: data?.usage?.averageSpontTriggBreaths || data?.usage?.averageSpontCycledBreaths ? true : false,
                    })}
                  >
                    <tr>
                      <td colSpan={3}>
                        <h5 className="mb-0" style={{ color: `black` }}>
                          Respiratory rate (RR):
                        </h5>
                      </td>
                    </tr>
                    {data?.usage?.averageMaxRespRate ||
                      data?.usage?.averageMedRespRate ||
                      data?.usage?.averageNinetyFiveRespRate ||
                      data?.usage?.averageSpontTriggBreaths ||
                      data?.usage?.averageSpontCycledBreaths ||
                      data?.usage?.averageAvgBreathRate ? (
                      <tr>
                        <td style={{ width: `20%` }}>
                          <div style={{ height: `40px` }}>
                            <h5>95th percentile:</h5>
                          </div>
                          {data?.usage?.averageMaxRespRate ? (
                            <div style={{ height: `35px` }}>
                              <h5>{`Maximum (avg)`}</h5>
                            </div>
                          ) : null}

                          {data?.usage?.averageMedRespRate ? (
                            <div style={{ height: `35px` }}>
                              <h5>{`Median (avg)`}</h5>
                            </div>
                          ) : null}
                          <div style={{ height: `36px` }}>
                            {data?.usage?.averageSpontTriggBreaths || data?.usage?.averageSpontCycledBreaths ? <h5>Spontaneous Breaths:</h5> : null}
                          </div>

                          {data?.usage?.averageSpontTriggBreaths ? (
                            <div style={{ height: `35px` }}>
                              <h5>Triggered:</h5>
                            </div>
                          ) : null}

                          {data?.usage?.averageSpontCycledBreaths ? (
                            <div style={{ height: `35px` }}>
                              <h5>Cycled:</h5>
                            </div>
                          ) : null}
                        </td>
                        {
                          device === `resmed` ? (
                            //averageAvgBreathRate show to resmed
                            <td style={{ width: `10%` }}>
                              {data?.usage?.averageNinetyFiveRespRate !== null ? (
                                <div style={{ height: `40px` }}>
                                  <h5>{`${data?.usage?.averageNinetyFiveRespRate !== null ? data?.usage?.averageNinetyFiveRespRate : `N/A`
                                    } breaths/min`}</h5>
                                </div>
                              ) : null}
                              {data?.usage?.averageMaxRespRate ? (
                                <div style={{ height: `35px` }}>
                                  {` `}
                                  <h5>{`${data?.usage?.averageMaxRespRate} breaths/min`}</h5>
                                </div>
                              ) : null}
                              {data?.usage?.averageMedRespRate ? (
                                <div style={{ height: `35px` }}>
                                  {` `}
                                  <h5>{`${data?.usage?.averageMedRespRate} breaths/min`}</h5>
                                </div>
                              ) : null}
                              {data?.usage?.averageSpontTriggBreaths || data?.usage?.averageSpontCycledBreaths ? (
                                <div style={{ height: `36px`, width: `100%`, backgroundColor: `white` }}></div>
                              ) : null}
                              <div style={{ height: `35px` }}>
                                <h5>{data?.usage?.averageSpontTriggBreaths ? `${data?.usage?.averageSpontTriggBreaths}%` : null}</h5>
                              </div>
                              <div style={{ height: `35px` }}>
                                <h5>{data?.usage?.averageSpontCycledBreaths ? `${data?.usage?.averageSpontCycledBreaths}%` : null}</h5>
                              </div>
                            </td>
                          ) : data?.usage?.averageAvgBreathRate ? (
                            <td style={{ width: `10%` }}>
                              <h5>{`${data?.usage?.averageAvgBreathRate !== null ? data?.usage?.averageAvgBreathRate : `N/A`} breaths/min`}</h5>
                            </td>
                          ) : null
                          //averageAvgBreathRate show to respironics
                        }
                        <td>
                          {device === `resmed` ? (
                            <Line
                              data={{
                                labels: chartLabels,
                                datasets: [
                                  {
                                    label: `95th percentile:`,
                                    fill: false,
                                    lineTension: 0.5,
                                    backgroundColor: `#F39C12`,
                                    borderColor: `#F39C12`,
                                    borderCapStyle: `butt`,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: `round`,
                                    pointBorderColor: `#F39C12`,
                                    pointBackgroundColor: `#fff`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: `#F39C12`,
                                    pointHoverBorderColor: `#F39C12`,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: rrChartData,
                                  },
                                  {
                                    ...(mediumRespiratoryDate?.length
                                      ? {
                                        label: `Median`,
                                        fill: false,
                                        lineTension: 0.5,
                                        backgroundColor: `#c51670`,
                                        borderColor: `#c51670`,
                                        borderCapStyle: `butt`,
                                        borderDash: [],
                                        borderDashOffset: 0.0,
                                        borderJoinStyle: `round`,
                                        pointBorderColor: `#c51670`,
                                        pointBackgroundColor: `#fff`,
                                        pointBorderWidth: 2,
                                        pointHoverRadius: 5,
                                        pointHoverBackgroundColor: `#c51670`,
                                        pointHoverBorderColor: `#c51670`,
                                        pointHoverBorderWidth: 2,
                                        pointRadius: 1,
                                        pointHitRadius: 10,
                                        data: mediumRespiratoryDate,
                                      }
                                      : {}),
                                  },
                                  {
                                    ...(maxRespiratoryRate?.length
                                      ? {
                                        label: `Maximum`,
                                        fill: false,
                                        lineTension: 0.5,
                                        backgroundColor: `#1699C5`,
                                        borderColor: `#1699C5`,
                                        borderCapStyle: `butt`,
                                        borderDash: [],
                                        borderDashOffset: 0.0,
                                        borderJoinStyle: `round`,
                                        pointBorderColor: `#1699C5`,
                                        pointBackgroundColor: `#fff`,
                                        pointBorderWidth: 2,
                                        pointHoverRadius: 5,
                                        pointHoverBackgroundColor: `#1699C5`,
                                        pointHoverBorderColor: `#1699C5`,
                                        pointHoverBorderWidth: 2,
                                        pointRadius: 1,
                                        pointHitRadius: 10,
                                        data: maxRespiratoryRate,
                                      }
                                      : {}),
                                  },
                                ],
                              }}
                              width={100}
                              height={70}
                              options={{
                                tooltips: {
                                  callbacks: {
                                    label: function (tooltipItem, data) {
                                      let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                      label += tooltipItem.yLabel + ` breaths/min`;
                                      return label;
                                    },
                                  },
                                },
                                legend: {
                                  position: `top`,
                                  labels: {
                                    usePointStyle: true,
                                    boxWidth: 6,
                                  },
                                },
                                responsive: true,
                                maintainAspectRatio: true,
                                scales: {
                                  xAxes: [
                                    {
                                      gridLines: {
                                        display: false,
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `days`,
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      gridLines: {
                                        color: `#dddddd`,
                                        borderDash: [5, 3],
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                        beginAtZero: true,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `RR`,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Line
                              data={{
                                labels: chartLabels,
                                datasets: [
                                  {
                                    fill: false,
                                    lineTension: 0.5,
                                    backgroundColor: `#F39C12`,
                                    borderColor: `#F39C12`,
                                    borderCapStyle: `butt`,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: `round`,
                                    pointBorderColor: `#F39C12`,
                                    pointBackgroundColor: `#fff`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: `#F39C12`,
                                    pointHoverBorderColor: `#F39C12`,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: respironicsRrChartData,
                                  },
                                ],
                              }}
                              width={100}
                              height={60}
                              options={{
                                tooltips: {
                                  callbacks: {
                                    label: function (tooltipItem, data) {
                                      let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                      label += tooltipItem.yLabel + ` breaths/min`;
                                      return label;
                                    },
                                  },
                                },
                                legend: {
                                  display: false,
                                },
                                responsive: true,
                                maintainAspectRatio: true,
                                scales: {
                                  xAxes: [
                                    {
                                      gridLines: {
                                        display: false,
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `days`,
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      gridLines: {
                                        color: `#dddddd`,
                                        borderDash: [5, 3],
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                        beginAtZero: true,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `RR`,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    ) : null}

                    <tr>
                      {data?.usage?.averageAvgMinuteVent || data?.usage?.averageNinetyFiveMinuteVent ? (
                        <>
                          {` `}
                          <td style={{ width: `20%` }}>
                            <h5>Minute ventilation (MV):</h5>
                          </td>{` `}
                          {device === `resmed` ? (
                            //averageAvgMinuteVent to resmed
                            <td style={{ width: `10%` }}>
                              <h5>{`${data?.usage?.averageNinetyFiveMinuteVent !== null ? data?.usage?.averageNinetyFiveMinuteVent : `N/A`
                                } L/min`}</h5>
                            </td>
                          ) : (
                            //averageAvgMinuteVent show to respironics
                            <td style={{ width: `10%` }}>
                              <h5>{`${data?.usage?.averageAvgMinuteVent !== null ? data?.usage?.averageAvgMinuteVent : `N/A`} L/min`}</h5>
                            </td>
                          )}
                        </>
                      ) : null}

                      {data?.usage?.averageAvgMinuteVent || data?.usage?.averageNinetyFiveMinuteVent ? (
                        <td>
                          {device === `resmed` ? (
                            <Bar
                              data={{
                                labels: chartLabels,
                                datasets: [
                                  {
                                    fill: false,
                                    lineTension: 0.5,
                                    backgroundColor: `#34495E`,
                                    borderColor: `#34495E`,
                                    borderCapStyle: `butt`,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: `round`,
                                    pointBorderColor: `#34495E`,
                                    pointBackgroundColor: `#fff`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: `#34495E`,
                                    pointHoverBorderColor: `#34495E`,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: mvChartData,
                                  },
                                ],
                              }}
                              width={100}
                              height={60}
                              options={{
                                tooltips: {
                                  callbacks: {
                                    label: function (tooltipItem, data) {
                                      let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                      label += tooltipItem.yLabel.toFixed(1) + ` L/min`;
                                      return label;
                                    },
                                  },
                                },
                                legend: {
                                  display: false,
                                },
                                responsive: true,
                                maintainAspectRatio: true,
                                scales: {
                                  xAxes: [
                                    {
                                      gridLines: {
                                        display: false,
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `days`,
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      gridLines: {
                                        color: `#dddddd`,
                                        borderDash: [5, 3],
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                        beginAtZero: true,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `MV`,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          ) : (
                            <Bar
                              data={{
                                labels: chartLabels,
                                datasets: [
                                  {
                                    fill: false,
                                    lineTension: 0.5,
                                    backgroundColor: `#34495E`,
                                    borderColor: `#34495E`,
                                    borderCapStyle: `butt`,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: `round`,
                                    pointBorderColor: `#34495E`,
                                    pointBackgroundColor: `#fff`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: `#34495E`,
                                    pointHoverBorderColor: `#34495E`,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: respironicsMvChartData,
                                  },
                                ],
                              }}
                              width={100}
                              height={60}
                              options={{
                                tooltips: {
                                  callbacks: {
                                    label: function (tooltipItem, data) {
                                      let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                      label += tooltipItem.yLabel.toFixed(1) + ` L/min`;
                                      return label;
                                    },
                                  },
                                },
                                legend: {
                                  display: false,
                                },
                                responsive: true,
                                maintainAspectRatio: true,
                                scales: {
                                  xAxes: [
                                    {
                                      gridLines: {
                                        display: false,
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `days`,
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      gridLines: {
                                        color: `#dddddd`,
                                        borderDash: [5, 3],
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                        beginAtZero: true,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `MV`,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          )}
                        </td>
                      ) : null}
                    </tr>
                    <tr>
                      <td colSpan="3">
                        <hr style={{ marginTop: `0px`, marginBottom: `0px` }} />
                      </td>
                    </tr>
                    {/* Show IE Graph only in resmed */}
                    {device === `resmed` && (
                      <ConditionallyRenderWrapper con={
                        condForDetaiandSummaryGraphs({
                          canStr: true,
                          conArr: [data?.usage?.averageNinetyFiveIERatio,
                          data?.usage?.averageMaxIERatio,
                          data?.usage?.averageMedIERatio]
                        })

                      }>
                        {` `}
                        <tr>
                          <td colSpan="2" className="pt-0 pb-0">
                            <h5 className="mb-0" style={{ color: `black` }}>
                              I:E Ratio:
                            </h5>
                          </td>
                          <td rowSpan="4">
                            <Line
                              data={{
                                labels: chartLabels,
                                datasets: [
                                  {
                                    label: `95% `,
                                    fill: false,
                                    lineTension: 0.5,
                                    backgroundColor: `#1699C5`,
                                    borderColor: `#1699C5`,
                                    borderCapStyle: `butt`,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: `round`,
                                    pointBorderColor: `#1699C5`,
                                    pointBackgroundColor: `#fff`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: `#1699C5`,
                                    pointHoverBorderColor: `#1699C5`,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: percentileIERatioChartData,
                                  },
                                  {
                                    label: `Max `,
                                    fill: false,
                                    lineTension: 0.5,
                                    backgroundColor: `#c51670`,
                                    borderColor: `#c51670`,
                                    borderCapStyle: `butt`,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: `round`,
                                    pointBorderColor: `#c51670`,
                                    pointBackgroundColor: `#fff`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: `#c51670`,
                                    pointHoverBorderColor: `#c51670`,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: maxIERatioChartData,
                                  },
                                  {
                                    label: `Med `,
                                    fill: false,
                                    lineTension: 0.5,
                                    backgroundColor: `#99c516`,
                                    borderColor: `#99c516`,
                                    borderCapStyle: `butt`,
                                    borderDash: [],
                                    borderDashOffset: 0.0,
                                    borderJoinStyle: `round`,
                                    pointBorderColor: `#99c516`,
                                    pointBackgroundColor: `#fff`,
                                    pointBorderWidth: 2,
                                    pointHoverRadius: 5,
                                    pointHoverBackgroundColor: `#99c516`,
                                    pointHoverBorderColor: `#99c516`,
                                    pointHoverBorderWidth: 2,
                                    pointRadius: 1,
                                    pointHitRadius: 10,
                                    data: medIERatioChartData,
                                  },
                                ],
                              }}
                              width={100}
                              height={70}
                              options={{
                                tooltips: {
                                  callbacks: {
                                    label: function (tooltipItem, data) {
                                      let label = data.datasets[tooltipItem.datasetIndex].label || ``;
                                      label += `1:` + tooltipItem.yLabel + ``;
                                      return label;
                                    },
                                  },
                                },

                                legend: {
                                  position: `top`,
                                  labels: {
                                    usePointStyle: true,
                                    boxWidth: 6,
                                  },
                                },
                                responsive: true,
                                maintainAspectRatio: true,
                                scales: {
                                  xAxes: [
                                    {
                                      gridLines: {
                                        display: false,
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: `days`,
                                      },
                                    },
                                  ],
                                  yAxes: [
                                    {
                                      gridLines: {
                                        color: `#dddddd`,
                                        borderDash: [5, 3],
                                      },
                                      ticks: {
                                        display: true,
                                        fontSize: 8,
                                        beginAtZero: true,
                                      },
                                      scaleLabel: {
                                        display: true,
                                        labelString: ``,
                                      },
                                    },
                                  ],
                                },
                              }}
                            />
                          </td>
                        </tr>
                        <tr id={css.summaryDataRowborder}>
                          <td className="pr-0 pb-0" style={{ width: `20%` }}>
                            <h5>95th %:</h5>
                          </td>
                          <td className="pr-0 pb-0" style={{ width: `10%` }}>
                            <h5>{`${data?.usage?.averageNinetyFiveIERatio !== null ? `1:${data?.usage?.averageNinetyFiveIERatio}` : `N/A`}`}</h5>
                          </td>
                        </tr>
                        <tr id={css.summaryDataRowborder}>
                          <td className="pr-0 pb-0" style={{ width: `20%` }}>
                            <h5>Maximum:</h5>
                          </td>
                          <td className="pr-0 pb-0" style={{ width: `10%` }}>
                            <h5>{`${data?.usage?.averageMaxIERatio !== null ? `1:${data?.usage?.averageMaxIERatio}` : `N/A`}`}</h5>
                          </td>
                        </tr>
                        <tr>
                          <td className="pr-0 pb-0" style={{ width: `20%` }}>
                            <h5>Median:</h5>
                          </td>
                          <td className="pr-0 pb-0" style={{ width: `10%` }}>
                            <h5>{`${data?.usage?.averageMedIERatio !== null ? `1:${data?.usage?.averageMedIERatio}` : `N/A`}`}</h5>
                          </td>
                        </tr>
                      </ConditionallyRenderWrapper>
                    )}
                  </ConditionallyRenderWrapper>
                )}
              </tbody>
            </table>
            <h5
              className="ml-3"
              data-toggle={!isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? `modal` : null}
              data-target="#PdfViewer"
              onClick={() => (!isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? setShowModalList(true) : null)}
              style={{
                color: !isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? `black` : `grey`,
                cursor: !isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? `pointer` : `unset`,
                textDecoration: !isLiveDataAvailable && isResmedPdfSummaryReportAvailable ? `underline` : `none`,
              }}
            >
              Click Here to View Summary
            </h5>
          </div>
          <PdfViewer
            userId={userId}
            start={start}
            end={end}
            reportId={reportId}
            show={showModalList}
            onHide={() => setShowModalList(false)}
            setIsPdfSummaryReportAvailable={setIsPdfSummaryReportAvailable}
            patientData={patientData ? patientData : device}
          />
        </>
      ) : (
        <div id={css.viewSummaryData}>
          {!data?.usage?.daysUsed ? (
            <div className="d-flex justify-content-center align-items-center" style={{ minHeight: `298px`, color: `rgb(193, 193, 193)` }}>
              Patient record not found.
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

const BothPressureGraphResmAndReact = ({ chartLabels, percentilePressureChartDataEPAP, percentilePressureChartDataIPAP }) => {
  return (
    <Line
      data={{
        labels: chartLabels,
        datasets: [
          {
            label: `EPAP (95% avg) `,
            fill: false,
            lineTension: 0.5,
            backgroundColor: `#1699C5`,
            borderColor: `#1699C5`,
            borderCapStyle: `butt`,
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: `round`,
            pointBorderColor: `#1699C5`,
            pointBackgroundColor: `#fff`,
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: `#1699C5`,
            pointHoverBorderColor: `#1699C5`,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [...percentilePressureChartDataEPAP],
          },
          {
            label: `IPAP (95% avg) `,
            fill: false,
            lineTension: 0.5,
            backgroundColor: `#c51670`,
            borderColor: `#c51670`,
            borderCapStyle: `butt`,
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: `round`,
            pointBorderColor: `#c51670`,
            pointBackgroundColor: `#fff`,
            pointBorderWidth: 2,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: `#c51670`,
            pointHoverBorderColor: `#c51670`,
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [...percentilePressureChartDataIPAP],
          },
        ],
      }}
      width={100}
      height={70}
      options={{
        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || ``;
              label += tooltipItem.yLabel + ` cmH2O`;
              return label;
            },
          },
        },
        legend: {
          position: `top`,
          labels: {
            usePointStyle: true,
            boxWidth: 6,
          },
        },
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: true,
                fontSize: 8,
              },
              scaleLabel: {
                display: true,
                labelString: `days`,
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                color: `#dddddd`,
                borderDash: [5, 3],
              },
              ticks: {
                display: true,
                fontSize: 8,
                beginAtZero: false,
                max: 25,
                min: 4,
                // stepSize: 5,
              },
              scaleLabel: {
                display: true,
                labelString: `Pressure`,
              },
            },
          ],
        },
      }}
    />
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { momentWrapper } from 'src/momentWrapper';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import css from './wrapper.module.css';
import { useHistory, useLocation } from 'react-router-dom';
import img from '../../../../assets/loader1.gif';
import { AvatorDropDown } from './components/avator/avator';
import { MessagesDropDown } from './components/messages/messages';
import { Grow, IconButton } from '@mui/material';
import { NotificationContextWrap } from './components/notification/NotificationContextWrap';
import { createTheme, ThemeProvider } from '@mui/material';
import { HelpDropDown } from './components/help/help';
import { ProviderDropDown } from './components/user/provider/provider';
import { PatientDropDown } from './components/user/patient/patient';
import { ViewProviderProfile } from '../../../home/modules/practices/components/viewProviderProfile/viewProviderProfile';
import { GLOBAL_PATIENT_SEARCH, getRequest } from '../../../../crud/crud';
import { GroupAdminDropDown } from './components/user/GroupAdmindropdown/GroupAdminDropDown';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { capitalizeFirstLetter } from 'src/utils';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { PracticeLogo } from '../PracticeLogo/PracticeLogo';
import { InputField } from '../InputField/InputField';
import { DatePickerCustomize } from '../datePicker/DatePickerCustomize';
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    transition: theme.transitions.create(`margin`, {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -180,
    minHeight: `inherit`,
    display: `flex`,
    alignItems: `center`,
  },
  contentShift: {
    transition: theme.transitions.create(`margin`, {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

export const Wrapper = ({ children, setGroupLogoPath }) => {
  const { user: parentUser, patient, resmed } = useSelector((state) => state);
  const { user } = parentUser;
  let selectedPatient = patient?.patientData?.data?._id;
  let realTimeDataAvailable = resmed?.isRealTimeDataAvailable;
  let history = useHistory();
  let location = useLocation();
  const classes = useStyles();
  const inputEl = useRef(null);
  const [showBackArrow, setShowBackArrow] = useState(true);
  const [refreshProviderListComp, setRefreshProviderListComp] = useState(false);
  const [open] = useState(true);
  const [loading, setLoading] = useState(false);
  const [listOfSearchPatients, setListOfSearchPatients] = useState([]);
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [selectedPendingUserDetails, setSelectedPendingUserDetails] = useState(null);
  // SEARCH
  const [inputFieldsCard, setInputFieldsCard] = useState(false);
  const [searchPayload, setSearchPayload] = useState({});
  const handleChange = (e) => {
    e.preventDefault();
    const copyUser = { ...searchPayload };
    copyUser[e.target.name] = e.target.value;
    setSearchPayload(copyUser);
  };
  // ALERT PROPERTIES

  useEffect(() => {
    if (inputFieldsCard) {
      inputEl.current.focus();
    }
  }, [inputFieldsCard]);

  useEffect(() => { }, [realTimeDataAvailable]);
  React.useEffect(() => {
    if (location.pathname === `/`) {
      setShowBackArrow(false);
    } else {
      setShowBackArrow(true);
    }
  }, [location.pathname]);
  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    const copyUser = { ...searchPayload };
    copyUser[`phoneNumber`] = temp;
    setSearchPayload(copyUser);
  };

  const redirectToPatient = (patientId) => {
    setShowSearchBox(false);
    setListOfSearchPatients([]);
    if (selectedPatient !== patientId) {
      history.push({ pathname: `/patient`, patientId: patientId });
    }
  };

  const globalPatientSearchHandler = () => {
    let objFilters = { ...searchPayload };
    if (!objFilters?.firstName && !objFilters?.lastName && !objFilters?.dateOfBirth && !objFilters?.phoneNumber) {
      return;
    }
    Object.keys(objFilters).forEach((key) => {
      if (objFilters[key] === null || objFilters[key] === `` || objFilters[key] === undefined) {
        delete objFilters[key];
      }
    });
    const queryParam = Object.keys(objFilters)
      .map((key) => {
        if (key === `dateOfBirth` && objFilters[key]) {
          objFilters[key] = moment(objFilters[key]).format(`MM/DD/YYYY`);
        }
        return `${key}=${objFilters[key]}`;
      })
      .join(`&`);

    setLoading(true);
    getRequest(`${GLOBAL_PATIENT_SEARCH}?${queryParam}`)
      .then((response) => {
        setLoading(false);
        setShowSearchBox(true);
        setListOfSearchPatients(response?.data?.patients);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const isOnVideo = useSelector((state) => state.patient.isOnVideo);

  // Handle Keypress On Enter Key.
  const handleKeyDown = (event) => {
    if (event?.key === `Enter`) {
      globalPatientSearchHandler();
    }
  };
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div id={css.topbar}>
          <div className={!showBackArrow ? `d-none` : `d-block`}>
            <IconButton
              title="go back"
              aria-label="delete"
              onClick={() => {
                history.goBack();
              }}
            >
              <ArrowBackIcon fontSize="small" />
            </IconButton>
          </div>
          {/* Search Icon */}
          {(!user.isSystemAdministrator && !user.isSuperAdmin && !user?.isGroupAdmin) ||
            ((user.isProvider || user.isClinicalStaff || user.isTransportationAdmin) && user.stripeSubscriptionId) ? (
            <IconButton disabled={isOnVideo}>
              <SearchIcon
                style={{
                  fontSize: `1.5rem`,
                  color: `#1699c5`,
                  cursor: `pointer`,
                }}
                onClick={() => {
                  setInputFieldsCard((p) => !p);
                  setShowSearchBox(false);
                }}
              />
            </IconButton>
          ) : null}
          {/* End Search Icon */}
          <Box
            className="searchContainer"
            sx={{
              position: `relative`,
              top: 0,
              left: 0,
              width: `100%`,
              // display: inputFieldsCard ? 'block' : 'none'
            }}
          >
            <Grow in={inputFieldsCard}>
              <Box
                className="card card-outline-danger"
                sx={{ width: `auto`, boxShadow: `0 2px 6px 0 rgb(0 0 0 / 10%), 0 6px 20px 0 rgb(0 0 0 / 19%)` }}
                onKeyDown={handleKeyDown}
              >
                <div style={{ marginTop: 10 }} className="card-block mx-2 mb-2 d-flex justify-content-between">
                  <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                    <InputField
                      size="small"
                      inputProps={{
                        ref: inputEl,
                      }}
                      type="text"
                      sx={{
                        width: `100%`,
                        textTransform: `capitalize`,
                      }}
                      label="First Name"
                      name="firstName"
                      value={searchPayload.firstName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                    <InputField
                      type="text"
                      size="small"
                      sx={{
                        width: `100%`,
                        textTransform: `capitalize`,
                      }}
                      name="lastName"
                      label="Last Name"
                      value={searchPayload.lastName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                    <DatePickerCustomize
                      name="dateOfBirth"
                      // setDateInputError={setDateError}
                      // dateInputError={dateError}
                      dateInputValue={searchPayload.dateOfBirth || null}
                      // setDateInputValue={setDateOfBirth}
                      pickerProps={{ size: `small` }}
                      onChange={(date) => {
                        setSearchPayload((p) => ({ ...p, dateOfBirth: date }));
                      }}
                    />
                  </div>
                  <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                    <InputField
                      size="small"
                      type="text"
                      sx={{ width: `100%` }}
                      placeholder="Phone Number"
                      onChange={(e) => phoneMask(e.target.value)}
                      value={searchPayload.phoneNumber}
                      name="phoneNumber"
                    />
                  </div>
                  <button
                    type="button"
                    style={{ height: `fit-content` }}
                    className="btn btn-info btn-sm mt-auto"
                    onClick={() => globalPatientSearchHandler()}
                  >
                    <span>
                      Search{` `}
                      <span style={{ display: loading ? null : `none` }}>
                        <img src={img} alt="img" style={{ height: `15px`, paddingLeft: `5px` }} />
                      </span>
                    </span>
                  </button>
                </div>
              </Box>
            </Grow>
            <div
              className="card card-outline-danger"
              id={css.globalSearch}
              onBlur={() => setShowSearchBox(false)}
              style={{ display: showSearchBox ? `block` : `none` }}
            >
              <span className="p-2 close-icon text-right" data-effect="fadeOut">
                <i className="fa fa-times" onClick={() => setShowSearchBox(false)} style={{ cursor: `pointer`, color: `#000` }}></i>
              </span>
              <div className="card-block m-1">
                {listOfSearchPatients.length <= 0 ? (
                  <h6 className="text-center pb-2" style={{ color: `#000` }}>
                    No record found
                  </h6>
                ) : (
                  <table className="table table-sm table-hover">
                    <thead>
                      <tr>
                        <th scope="col">First Name</th>
                        <th scope="col">Last Name</th>
                        <th scope="col">Date of Birth</th>
                        <th scope="col">Manufacturer</th>
                        <th scope="col">Provider</th>
                        <th scope="col">Clinical Staff</th>
                        <th scope="col">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {listOfSearchPatients.map((patient) => (
                        <tr
                          key={patient._id}
                          onClick={() => redirectToPatient(patient?._id)}
                          style={{
                            cursor: `pointer`,
                          }}
                        >
                          <td className="text-capitalize">{capitalizeFirstLetter(patient?.firstName)}</td>
                          <td className="text-capitalize">{capitalizeFirstLetter(patient?.lastName)}</td>
                          <td>{momentWrapper(patient?.dateOfBirth, `YYYY-MM-DD`).format(`MM-DD-YYYY`)}</td>
                          <td className="text-capitalize">{patient?.manufacturer}</td>
                          <td className="text-capitalize">{patient?.providerName}</td>
                          <td className="text-capitalize">{patient?.clinicalUserName}</td>
                          <td className="text-capitalize">{patient?.status}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </Box>
          <div
            style={{
              display: `flex`,
              justifyContent: `space-between`,
              alignItems: `center`,
              height: `65%`,
              width: user.isTransportationAdmin ? null : `auto`,
              marginRight: user.isTransportationAdmin ? `2%` : null,
              gap: 5,
            }}
          // className={window.innerWidth > 768 ? 'd-flex' : topBar ? 'd-flex' : 'd-none'}
          >
            {user.isSystemAdministrator ? (
              <ProviderDropDown refreshProviderListComp={refreshProviderListComp} viewUserDataHandler={setSelectedPendingUserDetails} />
            ) : (user.isProvider || user.isClinicalStaff || user.isSuperAdmin) && user.stripeSubscriptionId ? (
              <PatientDropDown />
            ) : null}
            {user?.isGroupAdmin && <GroupAdminDropDown />}
            <div id={css.aBSubContainer}>
              <div className="dropdown" id="message">
                {(user.isProvider || user.isClinicalStaff || user.isTransportationAdmin) && user.stripeSubscriptionId ? <MessagesDropDown /> : null}
              </div>
              <div className="dropdown" id="notification">
                {(user.isProvider || user.isClinicalStaff || user.isSuperAdmin || user.isTransportationAdmin) && user.stripeSubscriptionId ? (
                  <NotificationContextWrap />
                ) : null}
              </div>
              <div className="dropdown" id="help">
                <HelpDropDown />
              </div>
              <div className="dropdown" id={css.avator}>
                <AvatorDropDown setGroupLogoPath={setGroupLogoPath} />
              </div>
              {!user?.isSystemAdministrator && !user?.isGroupAdmin && user?.stripeSubscriptionId && (
                <div>
                  <PracticeLogo
                    logoProps={{
                      style: {
                        width: `90px`,
                        objectFit: `contain`,
                        height: `36px`,
                        marginLeft: `13px`,
                        boxShadow: `0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.19)`,
                        border: `2px solid white`,
                        borderRadius: `5px`,
                        alt: `logo`,
                      },
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>

        {children}
      </main>
      {user.isSystemAdministrator ? (
        <>
          <ViewProviderProfile
            refreshProviderListComp={refreshProviderListComp}
            setRefreshProviderListComp={setRefreshProviderListComp}
            id="viewPendingProviderProfile"
            data={selectedPendingUserDetails}
          />
        </>
      ) : null}
      {/* <ToastContainer /> */}
    </ThemeProvider>
  );
};

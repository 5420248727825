import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import css from './listAllPatients.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { momentWrapper } from 'src/momentWrapper';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Box, Collapse, Paper, Zoom } from '@mui/material';
import { setPatientsFilters, handleSelectedPatient, setQueryParamsFilters, handleSelectedPatientFromDashboard, switchPatient, handleReportIdFromReadyToSignReport } from '../../../../../../redux/patient/patientActions';
import { capitalizeFirstLetter, timeLogAddOnSwitchPatientAndSorting } from 'src/utils';
import { RenderAdherenceDot } from 'src/pages/common/components/RenderAdherenceDot/RenderAdherenceDot';
import { RoundDot } from 'src/pages/common/components/RoundDot/RoundDot';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';

let patients = null;
let patientsBillerRole = null;

export const ListAllPatients = ({ isAllowToClickOnPatient, videoCallStatus }) => {
  const user = useSelector((state) => state?.user?.user);
  const dispatch = useDispatch()
  const patientData = useSelector((state) => state?.patient?.patientData);
  const patientsList = useSelector((state) => state?.patient?.patientsList);
  const patientFilters = useSelector((state) => state?.patient?.patientsFilters);
  const audioCallStatus = useSelector((state) => state.timer.audioCall);
  //   Filters Hooks
  //   Filters Hooks
  // audioCall
  const [hasMore, setHasMore] = useState(true);
  const [adherent, setAdherent] = useState(``);
  const [last3daysFilters, setLast3DaysFilters] = useState([]);
  const [nonAdherent, setNonAdherent] = useState(``);
  const [noDataFound, setNoDataFound] = useState(``);
  const [online, setOnline] = useState(``);
  const [offline, setOffline] = useState(``);
  const [active, setActive] = useState(``);
  const isPatientAdded = useSelector((state) => state?.patient?.isAdded);
  const [inactive, setInactive] = useState(``);
  const [selectId, setSelectId] = useState(``);
  // Filters hooks end
  const [persistFilteredPayload, setPersistFilteredPayload] = useState({
    sortField: `lastName`,
    sortOrder: `ASC`,
    pageNumber: `1`,
    pageSize: `30`,
    searchFilters: {
      monitoringTime: [],
      tier: [],
      isAdherent: [],
      isAdherent3Days: [],
      online: [],
      status: [`active`],
    },
  });

  const dispatchRedux = useDispatch();
  const [sortKey, setSortKey] = useState(`lastName`);
  const [sortOrder, setSortOrder] = useState(1);
  //Pagination infinite values
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [opened, setOpened] = useState(false);
  const changeFiltersView = useCallback(() => {
    setOpened((p) => !p);
  }, []);

  const getParameterByName = (name, url) => {
    if (!url) url = window.location.href;
    // eslint-disable-next-line no-useless-escape
    name = name.replace(/[\[\]]/g, `\\$&`);
    let regex = new RegExp(`[?&]` + name + `(=([^&#]*)|&|#|$)`),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return ``;
    return decodeURIComponent(results[2].replace(/\+/g, ` `));
  }

  const checkTimeToggle = (patient) => {
    dispatchRedux(switchPatient(true))
    dispatchRedux({ type: `ISSENT`, payLoad: false });
    setSelectId(patient?._id);
    dispatchRedux(handleSelectedPatient({ patient: patient }));
  };
  const location = useLocation();

  // default checkbox on load of app from redux
  const defaultFilterSelect = () => {
    const urlParams = getParameterByName(`show`);

    if (urlParams === `all`) {
      const searchFiltersCopy = {};
      const copyFilteredObj = {};
      searchFiltersCopy[`monitoringTime`] = [];
      searchFiltersCopy[`tier`] = [];
      searchFiltersCopy[`isAdherent`] = [];
      searchFiltersCopy[`online`] = [];
      searchFiltersCopy[`status`] = [`active`];
      copyFilteredObj[`searchFilters`] = searchFiltersCopy;
      copyFilteredObj[`pageNumber`] = 1;
      copyFilteredObj[`pageSize`] = 30;
      copyFilteredObj[`sortField`] = `lastName`;
      copyFilteredObj[`sortOrder`] = `ASC`;
      copyFilteredObj[`isAdherent3Days`] = [];
      setActive(`active`);
      dispatch(setPatientsFilters(copyFilteredObj));
      dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredObj, scrollingParams: false }));
    } else {

      //Adherence
      if (Array.isArray(patientFilters?.searchFilters?.isAdherent) && patientFilters?.searchFilters?.isAdherent.length) {
        for (let filter of patientFilters.searchFilters.isAdherent) {
          if (filter) {
            setAdherent(`adherent`);
          }
          if (!filter) {
            setNonAdherent(`nonAdherent`);
          }
          if (filter === null) {
            setNoDataFound(`NO_DATA`);
          }
        }
      }


      // Status
      if (Array.isArray(patientFilters?.searchFilters?.status) && patientFilters?.searchFilters?.status.length) {
        for (let filter of patientFilters.searchFilters.status) {
          if (filter === `active`) {
            setActive(`active`);
          }

          if (filter === `inactive`) {
            setInactive(`inactive`);
          }
        }
      }

      //  online/offline
      if (Array.isArray(patientFilters?.searchFilters?.online) && patientFilters?.searchFilters?.online.length) {
        for (let filter of patientFilters.searchFilters.online) {
          if (filter) {
            setOnline(`online`);
          }
          if (!filter) {
            setOffline(`offline`);
          }
        }
      }

    }
  };
  // Filter functions and logic
  useEffect(() => {
    if ((location.patientId && !isPatientAdded) || (location.patientId && isPatientAdded)) {
      setSelectId(location.patientId);
      dispatchRedux(handleSelectedPatientFromDashboard(location.patientId));
    } else if ((location.reportId && !isPatientAdded) || (location.reportId && isPatientAdded)) {
      setSelectId(location.reportPatientID);
      dispatchRedux(handleReportIdFromReadyToSignReport(location)); //location-->param is sending complete object containing all values e.g report id and report id
    } else if (
      (getParameterByName(`Adherence`) && isPatientAdded) ||
      (getParameterByName(`risk`) && isPatientAdded) ||
      (getParameterByName(`monitoring`) && isPatientAdded) ||
      (getParameterByName(`status`) && isPatientAdded) ||
      (getParameterByName(`Adherence`) && !isPatientAdded) ||
      (getParameterByName(`risk`) && !isPatientAdded) ||
      (getParameterByName(`monitoring`) && !isPatientAdded) ||
      (getParameterByName(`status`) && !isPatientAdded) ||
      (getParameterByName(`last3DaysAdherence`) && !isPatientAdded)
    ) {
      let adherent = getParameterByName(`Adherence`);
      let last3DaysAdherence = getParameterByName(`last3DaysAdherence`)
      let _status = getParameterByName(`status`);
      let copyFilteredObj = { ...persistFilteredPayload };
      const searchFiltersCopy = { ...persistFilteredPayload.searchFilters };

      let isAdherentArray = [];
      let isOnlineArray = [];
      let last3daysAdherenceArray = []
      if (last3DaysAdherence) {
        let parsedAdherence = parseInt(last3DaysAdherence)
        last3daysAdherenceArray.push(parsedAdherence)
        setLast3DaysFilters((p) => [...p, ...last3daysAdherenceArray]);
      }
      if (adherent) {
        if (adherent === `adherent`) {
          isAdherentArray.push(true);
          setAdherent(`adherent`);
        }
        if (adherent === `nonAdherent`) {
          isAdherentArray.push(false, null);
          setNonAdherent(`nonAdherent`);
        }
        if (adherent === `NO_DATA`) {
          isAdherentArray.push(null);
          setNoDataFound(`NO_DATA`);
        }
      }
      if (_status) {
        if (_status == `online`) {
          isOnlineArray.push(true);
          setOnline(`online`);
        }
        if (_status == `offline`) {
          isOnlineArray.push(false);
          setOffline(`offline`);
        }
      }
      searchFiltersCopy.isAdherent = isAdherentArray;
      searchFiltersCopy.isAdherent3Days = last3daysAdherenceArray
      searchFiltersCopy.online = isOnlineArray;
      searchFiltersCopy.status = [`active`];
      copyFilteredObj[`searchFilters`] = searchFiltersCopy;
      copyFilteredObj[`pageNumber`] = 1;
      copyFilteredObj[`pageSize`] = 30;
      dispatch(setPatientsFilters(copyFilteredObj));
      setActive(`active`);
      setPersistFilteredPayload(copyFilteredObj);
      dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredObj, scrollingParams: false }));
      setPageNumber(1);
    } else if ((getParameterByName(`show`) === `all` && isPatientAdded) || (getParameterByName(`show`) === `all` && !isPatientAdded)) {
      defaultFilterSelect();
    } else {
      if (isPatientAdded || !isPatientAdded) {
        defaultFilterSelect();
        dispatchRedux(setQueryParamsFilters({ nextQueryParams: patientFilters, scrollingParams: false }));
      }
    }
  }, [location.patientId, location.reportId, isPatientAdded]);

  const sortHandler = (key) => {
    timeLogAddOnSwitchPatientAndSorting()
    const promise = new Promise((resolve) => {
      setSortKey(key);
      if (sortOrder === -1) {
        setSortOrder(1);
        resolve(1);
      } else {
        setSortOrder(-1);
        resolve(-1);
      }
    });
    promise
      .then((value) => {
        let copyFilteredObj = { ...patientFilters };
        // Set the obj values
        copyFilteredObj.sortField = key;
        copyFilteredObj.sortOrder = value === -1 ? `ASC` : value === 1 ? `DESC` : null;
        copyFilteredObj[`pageNumber`] = 1;
        copyFilteredObj[`pageSize`] = 30;
        // End
        setPersistFilteredPayload(copyFilteredObj);
        setPageNumber(1);
        dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredObj, scrollingParams: false }));
        setHasMore(true);
      })
      .catch(() => {

      });
  };

  const fetchMoreData = () => {
    if (patientsList?.data?.patients?.length >= patientsList?.data?.pager?.totalRecords) {
      setHasMore(false);
      return;
    }

    //  Make call here
    const copyFilteredObj = { ...patientFilters };
    copyFilteredObj[`pageNumber`] = pageNumber + 1;
    copyFilteredObj[`sortField`] = persistFilteredPayload?.sortField;
    copyFilteredObj[`sortOrder`] = persistFilteredPayload?.sortOrder;
    copyFilteredObj[`pageSize`] = pageSize;
    setPageNumber(pageNumber + 1);
    setPageSize(30);
    setPersistFilteredPayload(copyFilteredObj);
    dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredObj, scrollingParams: true }));
  };

  const patientsFilter = () => {
    timeLogAddOnSwitchPatientAndSorting()
    setSelectId(``);

    let copyFilteredObj = { ...persistFilteredPayload };
    const searchFiltersCopy = { ...persistFilteredPayload.searchFilters };
    let isAdherentArray = [];
    let isOnlineArray = [];
    let statusArray = [];
    let threeDaysArray = [];
    if (last3daysFilters) {
      threeDaysArray = [...last3daysFilters]
    }
    if (adherent || nonAdherent || noDataFound) {
      if (adherent) {
        isAdherentArray.push(true);
      }
      if (nonAdherent) {
        isAdherentArray.push(false, null);
      }
      if (noDataFound) {
        isAdherentArray.push(null);
      }
    }
    if (online || offline) {
      if (online) {
        isOnlineArray.push(true);
      }
      if (offline) {
        isOnlineArray.push(false);
      }
    }
    if (active || inactive) {
      if (active) {
        statusArray.push(`active`);
      }
      if (inactive) {
        statusArray.push(`inactive`);
      }
    }
    searchFiltersCopy.isAdherent = isAdherentArray;
    searchFiltersCopy.online = isOnlineArray;
    searchFiltersCopy.status = statusArray;
    searchFiltersCopy.isAdherent3Days = threeDaysArray
    copyFilteredObj[`searchFilters`] = searchFiltersCopy;
    copyFilteredObj[`pageNumber`] = 1;
    copyFilteredObj[`pageSize`] = 30;

    dispatch(setPatientsFilters(copyFilteredObj));
    setPersistFilteredPayload(copyFilteredObj);
    setPageNumber(1);
    dispatchRedux(setQueryParamsFilters({ nextQueryParams: copyFilteredObj, scrollingParams: false }));

    setHasMore(true);
    setOpened(false);
  };

  patients = patientsList?.data?.patients?.map((p) => {
    return (
      <>
        <tr
          style={{ color: p.status === `active` ? `#000` : p.status === `inactive` ? `rgb(193, 193, 193)` : null }}
          key={p._id}
          className={selectId === p._id ? css.touchableSelected : css.touchable}
          onClick={patientData?.data?._id !== p?._id && patientData?.loading !== true ? () => checkTimeToggle(p) : null}
        >
          <th style={{ maxWidth: `50px`, textAlign: `center` }} scope="row">
            <RenderAdherenceDot adherent={p?.isAdherent} />
          </th>
          <td style={{ maxWidth: `30px`, textAlign: `center` }} scope="row">
            {p?.adherenceAlerts ? p?.adherenceAlerts?.last3Days || p?.adherenceAlerts?.last30Days ? <RoundDot style={{
              width: `7px`, height: `7px`, backgroundColor: `red`
            }} /> : `-` : `-`}
          </td>
          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {capitalizeFirstLetter(p.lastName)}
          </td>
          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {capitalizeFirstLetter(p.firstName)}
          </td>
        </tr>
      </>
    );
  });

  patientsBillerRole = patientsList?.data?.patients?.map((p) => {
    return (
      <>
        <tr
          key={p._id}
          className={selectId === p._id ? css.touchableSelected : css.touchable}
          onClick={patientData?.data?._id !== p?._id && patientData?.loading !== true ? () => checkTimeToggle(p) : null}
        >
          <td></td>
          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {p.lastName}
          </td>
          <td style={{ maxWidth: `72px` }} className={css.truncate}>
            {p.firstName}
          </td>
          <td style={{ maxWidth: `100px`, textAlign: `center` }}>{momentWrapper(p.dateOfBirth).format(`MM/DD/YYYY`)}</td>
        </tr>
      </>
    );
  });

  return (
    <div
      id={css.listAllPatient}
      className="table-responsive"
      style={{
        pointerEvents: videoCallStatus ? `none` : null,
        overflowY: `auto`,
        // width: user?.isTransportationAdmin && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff ? '30%' : '25%',
      }}
    >
      {user.isSuperAdmin || user.isProvider || user.isClinicalStaff || !user.isTransportationAdmin ? (
        <Paper
          sx={{
            padding: `6px 5px 6px 4px`,
            borderRadius: `1px`,
            // backgroundColor: 'red',
            // overflow: 'auto',
            // zIndex: 25
          }}
        >
          <button
            onClick={changeFiltersView}
            className="btn btn-light btn-sm w-100 pl-2 pr-2 "
            style={{
              border: `1px solid #c4c4c4`,
              color: `grey`,
              fontSize: `small`,
              textAlign: `start`,
            }}
            type="button"
          >
            <div className="d-flex justify-content-between align-items-center">
              <span className="text-truncate">Filter</span>
              <i className="fa fa-chevron-down" style={{ fontSize: `x-small` }} aria-hidden="true"></i>
            </div>
          </button>
          <Collapse in={opened} mountOnEnter unmountOnExit timeout={500}>
            <div style={{
              width: `100%`,
              overflowY: `scroll`,
              padding: `3%`,
              position: `relative`
            }}>

              <Box sx={{ width: `80%`, textAlign: `end` }}>
                <ToolTipProvider
                  toolTipProps={{
                    title: `Filter Patients!`,
                    arrow: true,
                    placement: `top`,
                  }}
                  element={
                    <button className={css.customFilterButton} type="button" data-toggle="collapse" onClick={() => patientsFilter()}>
                      <i className="fa fa-filter"></i>
                      Filter
                    </button>
                  }
                />
              </Box>
              <form aria-labelledby="dropdownMenuButton">
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `600ms` : `0ms` }}>
                  <p style={{ fontWeight: 600 }}>
                    30 Day Adherence:
                  </p>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `700ms` : `0ms` }}>
                  <div>
                    <input
                      checked={adherent}
                      onChange={(e) => {
                        setAdherent(e.target.checked ? `adherent` : ``);
                      }}
                      type="checkbox"
                      name="adherent"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="adherent" style={{ fontSize: `x-small`, color: `black` }}>
                      Adherent
                    </label>
                  </div>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `800ms` : `0ms` }}>
                  <div>
                    <input
                      checked={nonAdherent}
                      onChange={(e) => {
                        setNonAdherent(e.target.checked ? `nonAdherent` : ``);
                      }}
                      type="checkbox"
                      name="nonAdherent"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="nonAdherent" style={{ fontSize: `x-small`, color: `black` }}>
                      Non Adherent
                    </label>
                  </div>
                </Zoom>

                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `600ms` : `0ms` }}>
                  <p className="pt-2" style={{ fontWeight: 600 }}>
                    3 Day Usage Rate:
                  </p>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `700ms` : `0ms` }}>
                  <div>
                    <input
                      checked={last3daysFilters?.length && last3daysFilters.indexOf(0) > -1 ? true : false}
                      onChange={() => {
                        let index = last3daysFilters.indexOf(0)
                        if (index > -1) {
                          let temp = [...last3daysFilters]
                          temp.splice(index, 1);
                          setLast3DaysFilters(temp);
                        } else {
                          setLast3DaysFilters((p) => [...p, 0]);
                        }
                      }}
                      type="checkbox"
                      name="0"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="0" style={{ fontSize: `x-small`, color: `black` }}>
                      0%
                    </label>
                  </div>
                </Zoom>

                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `700ms` : `0ms` }}>
                  <div>
                    <input
                      checked={last3daysFilters?.length && last3daysFilters?.indexOf(33) > -1 ? true : false}
                      onChange={() => {
                        let index = last3daysFilters.indexOf(33)
                        if (index > -1) {
                          let temp = [...last3daysFilters]
                          temp.splice(index, 1);
                          setLast3DaysFilters(temp);
                        } else {
                          setLast3DaysFilters((p) => [...p, 33]);
                        }
                      }}
                      type="checkbox"
                      name="adherent"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="adherent" style={{ fontSize: `x-small`, color: `black` }}>
                      33%
                    </label>
                  </div>
                </Zoom>

                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `700ms` : `0ms` }}>
                  <div>
                    <input
                      checked={last3daysFilters?.length && last3daysFilters?.indexOf(67) > -1 ? true : false}
                      onChange={() => {
                        let index = last3daysFilters.indexOf(67)
                        if (index > -1) {
                          let temp = [...last3daysFilters]
                          temp.splice(index, 1);
                          setLast3DaysFilters(temp);
                        } else {
                          setLast3DaysFilters((p) => [...p, 67]);
                        }
                      }}
                      type="checkbox"
                      name="adherent"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="adherent" style={{ fontSize: `x-small`, color: `black` }}>
                      67%
                    </label>
                  </div>
                </Zoom>

                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `700ms` : `0ms` }}>
                  <div>
                    <input
                      checked={last3daysFilters?.length && last3daysFilters?.indexOf(100) > -1 ? true : false}
                      onChange={() => {
                        let index = last3daysFilters.indexOf(100)
                        if (index > -1) {
                          let temp = [...last3daysFilters]
                          temp.splice(index, 1);
                          setLast3DaysFilters(temp);
                        } else {
                          setLast3DaysFilters((p) => [...p, 100]);
                        }
                      }}
                      type="checkbox"
                      name="adherent"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="adherent" style={{ fontSize: `x-small`, color: `black` }}>
                      100%
                    </label>
                  </div>
                </Zoom>

                {/* This filter only comment but the value of filter is not commented */}
                {/* ------------------ */}
                {/* <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `900ms` : `0ms` }}>
                <div>
                  <input
                    checked={noDataFound}
                    onChange={(e) => {
                      setNoDataFound(e.target.checked ? `NO_DATA` : ``);
                    }}
                    type="checkbox"
                    name="noDataFound"
                    style={{ width: `11px`, height: `11px` }}
                  />
                  <label htmlFor="noDataFound" style={{ fontSize: `x-small`, color: `black` }}>
                    {`No data > 30 days`}
                  </label>
                </div>
              </Zoom> */}
                {/* ------------------ */}
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `300ms` : `0ms` }}>
                  <p className="pt-2" style={{ fontWeight: 600 }}>
                    Online/Offline:
                  </p>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `400ms` : `0ms` }}>
                  <div>
                    <input
                      checked={online}
                      onChange={(e) => {
                        setOnline(e.target.checked ? `online` : ``);
                      }}
                      type="checkbox"
                      name="online"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="online" style={{ fontSize: `x-small`, color: `black` }}>
                      Online
                    </label>
                  </div>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                  <div>
                    <input
                      checked={offline}
                      onChange={(e) => {
                        setOffline(e.target.checked ? `offline` : ``);
                      }}
                      type="checkbox"
                      name="offline"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="offline" style={{ fontSize: `x-small`, color: `black` }}>
                      Offline
                    </label>
                  </div>
                </Zoom>
                {/* ---------Active | Inactive-------- */}
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `300ms` : `0ms` }}>
                  <p className="pt-2" style={{ fontWeight: 600 }}>
                    Status:
                  </p>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `400ms` : `0ms` }}>
                  <div>
                    <input
                      checked={active}
                      onChange={(e) => {
                        setActive(e.target.checked ? `active` : ``);
                      }}
                      type="checkbox"
                      name="active"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="active" style={{ fontSize: `x-small`, color: `black` }}>
                      Active
                    </label>
                  </div>
                </Zoom>
                <Zoom in={opened} mountOnEnter unmountOnExit style={{ transitionDelay: opened ? `500ms` : `0ms` }}>
                  <div>
                    <input
                      checked={inactive}
                      onChange={(e) => {
                        setInactive(e.target.checked ? `inactive` : ``);
                      }}
                      type="checkbox"
                      name="inactive"
                      style={{ width: `11px`, height: `11px` }}
                    />
                    <label htmlFor="inactive" style={{ fontSize: `x-small`, color: `black` }}>
                      Inactive
                    </label>
                  </div>
                </Zoom>
              </form>
            </div>
          </Collapse>
        </Paper>
      ) : null}

      <div id="scrollableDiv" style={{ height: `96%`, overflowY: `scroll` }}>
        <InfiniteScroll
          scrollableTarget="scrollableDiv"
          dataLength={patientsList?.data?.patients ? patientsList?.data?.patients?.length : null}
          next={() => fetchMoreData()}
          hasMore={hasMore}
        >
          {user?.isTransportationAdmin && !user.isSystemAdministrator && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff ? (
            <table className="table table-striped table-sm" id="patientTable">
              <thead style={{ backgroundColor: `white`, color: `black` }}>
                <tr>
                  <th></th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`lastName`)}>
                    LAST
                    {sortKey === `lastName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `lastName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`firstName`)}>
                    FIRST
                    {sortKey === `firstName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `firstName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2 text-center" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`dateOfBirth`)}>
                    Date Of Birth
                    {sortKey === `dateOfBirth` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `dateOfBirth` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  pointerEvents: isAllowToClickOnPatient ? `none` : `unset`,
                }}
              >
                {patientsList?.data?.patients?.length ? (
                  <>
                    {patientsBillerRole}
                    <tr>
                      <td colSpan="6" className="text-center" style={{ color: `#000` }}>
                        <div>
                          {patientsList?.data?.patients?.length === patientsList?.data?.pager?.totalRecords ? <b>End of patients list</b> : null}
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center" style={{ color: `#c1c1c1` }}>
                      No Patient Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          ) : (
            <table style={{ width: `130% !important` }} className="table table-striped table-sm" id="patientTable">
              <thead style={{ backgroundColor: `white`, color: `black` }}>
                <tr>
                  <th className="py-2 text-center" scope="col">
                    ADH
                  </th>
                  <th className="py-2 text-center" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`isAlertOff`)}>
                    ALERT
                    {sortKey === `isAlertOff` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `isAlertOff` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>

                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`lastName`)}>
                    LAST
                    {sortKey === `lastName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `lastName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th className="py-2" scope="col" style={{ cursor: `pointer` }} onClick={() => sortHandler(`firstName`)}>
                    FIRST
                    {sortKey === `firstName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `firstName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                </tr>
              </thead>
              <tbody
                style={{
                  pointerEvents: isAllowToClickOnPatient ? `none` : `unset`,
                }}
                id={audioCallStatus && css.disabledIfCallTrue}
              >
                {patientsList?.data?.patients?.length ? (
                  <>
                    {patients}
                    <tr>
                      <td colSpan="6" className="text-center" style={{ color: `#000` }}>
                        <div>
                          {patientsList?.data?.patients?.length === patientsList?.data?.pager?.totalRecords ? <b>End of patients list</b> : null}
                        </div>
                      </td>
                    </tr>
                  </>
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center" style={{ color: `#c1c1c1` }}>
                      No Patient Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </InfiniteScroll>
      </div>
      {patientsList?.loading && <PuffSpinner />}
    </div>
  );
}
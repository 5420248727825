import React, { useEffect, useRef } from 'react';
import css from './htmlFormatter.module.css';

export const HtmlFormatter = ({ title, shouldTrunc }) => {
  const titleRef = useRef(null);
  useEffect(() => {
    if (title) titleRef.current.innerHTML = title;
  }, [title]);
  return <div className={`text-capitalize ${shouldTrunc && css.truncateWrapper}`} ref={titleRef}></div>;
};

import React, { useState } from 'react';
import Moment from 'react-moment';
import css from './listAllPractice.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { DetailsCard } from './detailsCard/detailsCard';
import { AdvanceFilter } from './advanceFilter/advanceFilter';
import { setAllPractices } from '../../../../../../redux/practices/practiceActions';
import { useHistory } from 'react-router-dom';
import { ErrorOutlined } from '@mui/icons-material';
import { Skelton } from 'src/pages/common/components/Skelton/Skelton';
import { Slide, Zoom } from '@mui/material';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import { capitalizeFirstLetter, moneyFormatter } from '../../../../../../utils';
let dynamicRow = null;

export const ListAllPractices = () => {
  let history = useHistory();
  const dispatch = useDispatch()
  const [data, setData] = useState([]);
  const user = useSelector((state) => state.user);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  const [sortKey, setSortKey] = useState(`createdAt`);
  const [sortOrder, setSortOrder] = useState(1);

  const [loading, setLoading] = useState({ loading: false, error: false });

  const sortHandler = (key) => {
    setSortKey(key);
    if (sortOrder === -1) setSortOrder(1);
    else setSortOrder(-1);
  };

  const redirectToUserManagment = (practiceId, payload) => {
    dispatch(setAllPractices(payload))
    if (user.user?.isGroupAdmin) {
      history.push(`/groupAdmin/administration/user`);
    } else {
      history.push(`/systemAdmin/administration/user`);
    }
  };

  dynamicRow = data?.map((row, index) => {
    return (
      <React.Fragment key={row._id}>
        <Zoom in={data?.length} timeout={500} mountOnEnter unmountOnExit>
          <tr onClick={() => redirectToUserManagment(row._id, row)} style={{ cursor: `pointer` }}>
            <td style={{ textAlign: `center` }}>{row.pid}</td>
            <td>{capitalizeFirstLetter(row.practiceName)}</td>
            <td style={{ textAlign: `center` }}>
              <Moment date={row.createdAt} format="MM/DD/YYYY" />
            </td>
            <td style={{ textAlign: `center` }}>{row?.usersCount?.provider?.total}</td>
            <td style={{ textAlign: `center` }}>{row?.patientsCount}</td>
            <td style={{ textAlign: `center` }}>{row?.inactivePatientsCount}</td>
            <td style={{ textAlign: `center` }}>{row?.activeTicketsCount}</td>
            <td style={{ textAlign: `center` }}>{row.timeSinceRegistrationInDays + ` Days`}</td>
            {user?.user?.isSystemAdministrator && <td style={{ textAlign: `center` }}>{row?.income ? moneyFormatter(row?.income) : `-`}</td>}

          </tr>
        </Zoom>
        <Slide in={data?.length} mountOnEnter unmountOnExit timeout={500}>
          <tr>
            <td colSpan="9" style={{ padding: `0px 0px 0px 0px` }}>
              <div className="collapse" id={`asdf${index}`}>
                <DetailsCard data={row} />
              </div>
            </td>
          </tr>
        </Slide>
      </React.Fragment>
    );
  });
  const renderTablleBody = () => {
    if (loading?.error) {
      return (
        <tr>
          <td colSpan="9" style={{ textAlign: `center` }}>
            <ErrorOutlined sx={{ fontSize: `medium`, color: `red` }} />
          </td>
        </tr>
      );
    } else if (loading?.loading) {
      return <Skelton isGreater={false} width={20} isAdmin={user?.user?.isSystemAdministrator ? true : false} />;
    } else if (data?.length) {
      return dynamicRow;
    } else {
      return (
        <tr>
          <td colSpan="9" style={{ textAlign: `center` }}>
            <span>No Record Found</span>
          </td>
        </tr>
      );
    }
  };
  return (
    <div id={css.main} style={{ position: `relative` }}>
      <AdvanceFilter
        rowPerPage={rowsPerPage}
        page={currentPage}
        dataHandler={setData}
        loadingHandler={setLoading}
        totalRowHandler={setTotalRows}
        totalPageHandler={setTotalPage}
        sortOrder={sortOrder}
        sortKey={sortKey}
        data={data}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={setRowsPerPage}
        auth={true}
      />
      <div className="table-responsive">
        <table className="table table-sm table-borderless table-striped">
          <thead>
            <tr
              style={{
                width: `10%`,
                textAlign: `center`,
                verticalAlign: `middle`,
              }}
            >
              <th scope="col" style={{ width: `10%`, cursor: `pointer`, minWidth: `106px` }} onClick={() => sortHandler(`pid`)}>
                ID
                {sortKey === `pid` && sortOrder === 1 ? (
                  <i className="fa fa-sort-asc ml-2" />
                ) : sortKey === `pid` && sortOrder === -1 ? (
                  <i className="fa fa-sort-desc ml-2" />
                ) : (
                  <i className="fa fa-sort ml-2" />
                )}
              </th>
              <th
                scope="col"
                style={{
                  width: `45%`,
                  textAlign: `start`,
                  cursor: `pointer`,
                  minWidth: `374px`,
                }}
                onClick={() => sortHandler(`practiceName`)}
              >
                Practice Name
                {sortKey === `practiceName` && sortOrder === 1 ? (
                  <i className="fa fa-sort-asc ml-2" />
                ) : sortKey === `practiceName` && sortOrder === -1 ? (
                  <i className="fa fa-sort-desc ml-2" />
                ) : (
                  <i className="fa fa-sort ml-2" />
                )}
              </th>
              <th scope="col" style={{ width: `20%`, cursor: `pointer`, minWidth: `210px` }} onClick={() => sortHandler(`createdAt`)}>
                Registration Date
                {sortKey === `createdAt` && sortOrder === 1 ? (
                  <i className="fa fa-sort-asc ml-2" />
                ) : sortKey === `createdAt` && sortOrder === -1 ? (
                  <i className="fa fa-sort-desc ml-2" />
                ) : (
                  <i className="fa fa-sort ml-2" />
                )}
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of Providers
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of Active Patients
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of In-Active Patients
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `100px` }}>
                # Of Open Tickets
              </th>
              <th scope="col" style={{ width: `20%`, minWidth: `210px` }}>
                Time Since Registration
              </th>
              {user?.user?.isSystemAdministrator && <th scope="col" style={{ width: `20%`, minWidth: `210px` }}>
                Income
              </th>}

            </tr>
          </thead>
          <tbody>{renderTablleBody()}</tbody>
          <TableFooter
            data={data}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            isPatientView={false}
          />
        </table>
      </div>
    </div>
  );
}


import { axiosClient } from './axiosClient.js';

export const GET_ALL_PATIENTS_CSV = `patients/download/csv`
export const CHECK_MULTILPLE_SESSIONS = `users/checkLoginStatus`
export const FAQS_LIST = `faqs/list`;
export const LOGIN_URL = `users/v2/login`;
export const LOGIN_BY_ROLE = `users/loginByRole/`;
export const CHECK_EMAIL_AND_LAST_NAME = `users/validate/emailAndLastName`;
export const RESET_PATIENT_PASSWORD_URL = `patients/resetPassword`;
export const RESEND_EMAIL_GRP_SYS_ADMIN = `users/resend/verificationEmail`
export const PUBLIC_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL = `communications/twilio/verify/videoRoom/`;
export const GLOBAL_PATIENT_SEARCH = `patients/search`;
export const VERIFY_OTP = `users/v2/verifyOTP`;
export const LOG_OUT = `users/logout`;
export const FORGET_PASSWORD_URL = `users/forgotPassword`;
export const RESET_PASSWORD_URL = `users/resetPassword`;
export const RESEND_EMAIL_USER_VERIFICATION = `users/resend/verificationEmail`
export const SET_PASSWORD_URL = `users/setPassword`;
export const VERIFY_EMAIL_URL = `users/verify`;
export const PRACTICE_REGISTER_URL = `practices/register/`;
export const GROUP_PRACTICE_REGISTER_URL = `practiceGroup/register`;
export const EDIT_GROUP_LOGO = `practiceGroup/updateGroupLogo`;
export const CHECK_EMAIL_USERNAME_AVAILABILITY_URL = `users/validate/username`;
export const VERIFY_USERNAME = `users/checkUserName`;
export const REFRESH_AUTH_TOKEN = `users/refresh-token`
export const CHECK_PRACTICE_GROUP_AVAILABILITY = `practiceGroup/validate`;
export const CHECK_PRACTICE_CREDENTIALS_AVAILABILITY = `practiceGroup/validate/practiceName`;
export const OTP_REQUEST = `users/sendOTP`;
export const VERIFY_OTP_SEND = `users/verifyOtpSend`;
export const REMOVE_OTP = `users/removeTFA`;
export const GET_ALL_GROUP_OPTIONS = `practiceGroup/all`;
export const TOTAL_STATS_URL = `systemAdmins/count/practicesPatientsProviders`;
export const TOTAL_STATS_URL_GRP_ADMIN = `practiceGroup/count/practicesProvidersAndPatients`;
export const FILTER_PRACTICE_GROUP_URL = `practiceGroup/search/practices`;
export const GET_ALL_GROUPS = `practiceGroup/`;
export const LIST_PENDING_PROVIDERS_URL = `systemAdmins/providers/pending`;
export const PENDING_PROVIDERS_STATUS_UPDATE_URL = `systemAdmins/providers/acceptOrReject/`;
export const ALL_NOTIFICATIONS_URL = `systemAdmins/serverSentEvents/unread/notifications`;
export const LIST_ALL_NOTIFICATIONS_URL = `systemAdmins/notifications/list/unread`;
export const ALL_UNREAD_MESSAGE_RED_DOT_SHOW = `communications/unread`;
export const ALL_RISK_NOTIFICATION = `notifications/rf`;
export const UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS = `communications/markAsRead/`;
export const UNREAD_NOTIFICATIONS_STATUS_UPDATE_URL = `systemAdmins/notifications/change/unreadToRead/`;
export const ALL_UNREAD_PATIENT_NOTIFICATION = `notifications/patientAdded/`;

/*task managemaent*/
export const CREATE_TASK = `/tasks/create`;
export const UPDATE_TASK = `tasks/update/`
export const GET_ALL_TASKS = `/tasks/all`;
export const SNOOZE_TASK = `tasks/snooze/`
export const GET_ALL_PATIENTS = `patients/listAllPatientsOfThatPractice`;
export const GET_ALL_PATIENTS_WRT_USER = `patients/listAllPatientsOfThatUser/`
export const GET_ALL_USERS = `users/getAllUsers`;
export const ADD_COMMENT_TO_SPECIFIC_TASK = `tasks/addComment/`;
export const UPDATE_TASK_DESCRIPTION = `tasks/update/`;
export const GET_TASK_BY_ID = `tasks/getTaskById/`
export const GET_TASK_NOTIFICATIONS_MISSING = `tasks/missedNotifications/`
/*common*/
export const GET_S3_FILE = `communications/pipeFile`
/* Super Admin */

export const LIST_ALL_USER_URL = `superAdmins/list/users`;
export const LIST_ALL_USER_URL_PRACTICE_GROUP = `practiceGroup/list/users/`;
export const PRACTICE_INFO_URL = `practices/info`;
export const PRACTICE_MAIN_ADMIN_INFO_URL = `superAdmins/users/getMainAdmin`;
export const GET_USER_BY_ID = `users/getUserById/`;
export const GET_USER_BY_PRACTICE = `users/getUserByPractice/`;
export const GET_ALL_MD_BY_PRACTICE_ID = `users/list/md/`;
export const GET_ALL_QHCP_BY_PRACTICE_ID = `users/list/qhcp/`;
export const GET_LIST_OF_ALL_THE_PROVIDER_OR_CLINICAL_USER_PATIENTS = `patients/list/providersOrClinicalsPatients/`;
export const DASHBOARD_ANALYTICS = `analytics/dashboard`;
export const REASSIGN_PATIENTS = `superAdmins/reassign/patients`;
export const VIEW_ALL_PATIENTS = `patients/list/allPatients`;
export const PATIENT_WITH_OUT_DEVICE_DME_URL = `patients/updatePatientsWithOutDevice/`;
export const VIEW_ALL_PATIENTS_GROUP = `practiceGroup/list/allPatients/`;
export const VERIFY_PASSWORD_CONFIRMATION = `users/verfiyPassword`;
export const RESET_PASSWORD_CONFIRMATION = `users/resetPassword`;
export const PRACTICE_UPDATE = `practices/update`;
export const GET_PATIENT_ONLINE_OFFLINE_STATUS = `analytics/dashboard/patients/onlineStatus`;
export const DELETE_SPECIFIC_PENDING_PATIENT = `patients/delete/pendingPatients/`;

/* Patients */

export const PATIENT_SIGNUP = `patients/signup`;
export const IMPORT_PATIENT_URL = `patients/resmed`;
export const BULK_IMPORT_PATIENT_URL = `patients/bulkImport`;
export const BULK_IMPORT_PATIENT_WOD_URL = `patients/v2/bulkImportWithOutDevice`;
export const ADD_PATIENT_WITHOUT_DEVICE = `patients/addPatientsWithOutDevice`;
export const ADD_PATIENT_BULK_IMPORT_WITHOUT_DEVICE = `patients/addPatientsWithOutDevice`;
export const GET_RESMED_PATIENT_URL = `patients/import/resmed`;
export const GET_RESPIRONICS_PATIENT_URL = `patients/import/respironics`;
export const LIST_ALL_PATIENTS_URL = `patients/list`;
export const GET_ALL_PROVIDERS = `users/getProviders`;
export const GET_PATIENT_BY_ID_URL = `patients/getPatientById/`;
export const ADD_PATIENT_MONITORING_TIME_LOG_URL = `timeLogs/v2/addLog/`;
export const PATIENT_VIEW_DATA_RESMED_URL = `sessions/resmed/therapy/`;
export const PATIENT_VIEW_DATA_RESPIRONICS_URL = `sessions/respironics/`;
export const PATIENT_TIME_LOGS_URL = `timeLogs/v2/list`;
export const INACTIVE_ADMIN_USER = `users`;
export const PATIENT_UPDATE_URL = `patients/update/`;
export const PATIENT_SEND_TEXT_MESSAGE_URL = `communications/twilio/mms/send/`;
export const PATIENT_INVITE_MESSAGE_URL = `patients/sms/send/signupInvite/`;
export const PATIENT_ALL_TEXT_MESSAGES_URL = `communications/twilio/allMessages/`;
export const PATIENT_RESMED_SUMMARY_PDF_URL = `sessions/resmed/therapy/compliance/`;
export const PATIENT_RESPIRONICS_SUMMARY_PDF_URL = `sessions/respironics/pdf/`;
export const PATIENT_RESMED_DETAIL_PDF_URL = `sessions/resmed/therapy/detail/`;
export const PATIENT_CREATE_REPORT_URL = `reports/v2/create/`;
export const PATIENT_CREATE_REPORT_URL_RESPIRONICS = `reports/create/respironics/`;
export const PATIENT_TWILIO_VOICE_CALL_TOKEN_VERIFY_URL = `communications/twilio/voice/verify/`;
export const PATIENT_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL = `communications/twilio/video/`;
export const REPORT_NOTES = `reportNotes/list/`;
export const REPORT_ADD_NEW_NOTE = `reportNotes/v2/add/`;
export const SEND_REPORT_TO_MD = `reports/sendToMD/`;
export const LIST_ALL_USER_URL_PRACTICE = `systemAdmins/list/users/`;

// For get the options of externalDevice.
export const EXTERNAL_DEVICE_OPTION = `externalDevices/listNames`;

// For get the lists of systemAdmin credentials.
export const EXTERNAL_DEVICE_CREDENTIALS_LIST = `externalDevices/list`;
export const EXTERNAL_DEVICE_CREDENTIALS_DELETE = `externalDevices`;
export const EXTERNAL_DEVICE_CREDENTIALS_UPDATE = `externalDevices/update`;
export const LIST_ALL_PRACTICES = `systemAdmins/list/practices`;

export const EXTERNAL_DEVICE_CREDENTIALS_ADD = `externalDevices/add`;

// Fro import the patient of reactHealth.
export const IMPORT_REACT_HEALTH_PATIENT = `patients/import/reactHealth`;

export const IMPORT_PATIENT_FROM_REACT_HEALTH = `patients/react-health`;

// Integration React_Health.
export const PATIENT_REACT_HEALTH_SUMMARY_PDF = `sessions/react-health/compliance/`

export const PATIENT_VIEW_DATA_REACT_HEALTH = `sessions/react-health/`;

//  smart phrases list

export const SMART_PHRASE_LIST = `reportNotes/phrase/`;
export const COUNT_ALL_PHRASES = `reportNotes/phrase/ShowAllPhrases`;

/* Patients */

export const IMPORT_PATIENT_URL_FROM_URL = `patients/respironics`;
export const LIST_ALL_PATIENTS_BILLER_ROLE_URL = `patients/list/allPatients`;

export const MONITAIR_TREND_AHI_REPORT = `reports/trend/ahi/`;
export const MONITAIR_TREND_USAGE_REPORT = `reports/trend/usage/`;

//ADD PATIENT WITH NO DEVICE

export const ADD_PATIENT_WITH_NO_DEVICE = `patients/add/noDevice`;

// GET LIST OF ALL THE PENDING PATIENTS

export const GET_LIST_OF_ALL_PENDING_PATIENTS = `patients/list/pending/patients`;

export const GET_DETAILS_OF_PENDING_PATIENT = `patients/pendingPatient/`;

/* Provider */

export const ADD_SIGNATURE_URL = `signatures/add/signature/`;
export const GET_USER_DETAILS = `users/getUserByPractice/`;
export const UPDATE_USER = `users/update`;
export const UPDATE_USER_PROFILE = `users/update/profile/`;
export const UPDATE_ADMIN_USER_PROFILE = `users/update/sysAdmin/`;
export const PENDING_PROVIDERS = `superAdmins/pending/providers`;

/* Reports */

export const UPDATE_REPORT_BILL_STATUS = `reports/report/markAsBilled/`;
export const UPDATE_ALL_REPORT_BILL_STATUS = `reports/markAllAsBilled/`;
export const GETTING_REPORT_STATUS = `reports/v2/report/`;
export const CREATE_ADDENDUM_REPORT = `reports/v2/create/addendum/`;
export const CREATE_ADDENDUM_REPORT_RESPIRONICS = `reports/create/addendum/respironics/`;
export const SEND_ADDENDUM_REPORT = `reports/v2/sendToMD/addendum/`;
export const GET_REPORTS_BY_STATUS_BILLER_ROLE = `reports/list/readyToBillOrBilled`;

export const ANALYTICS_NO_OF_PATIENTS = `analytics/patients/noOfPatients`;
export const ANALYTICS_ADHERENCE = `analytics/patients/adherence`;

export const REMOTE_ADHERENCE = `analytics/remotePatients/adherence`;
export const REMOTE_RPM = `analytics/remotePatients/monitoring`;
export const SYSTEM_ADMIN_ANALYTICS = `count/analyticcount`;

export const BILLING_COMPLETED = `analytics/billing/reportStatus`;

/** Ticketing System */

// Create Ticket - POST
export const CREATE_TICKET = `tickets/create`;
// Get list of all tickets - GET
export const LIST_OF_TICKETS = `tickets/list`;
// Get ticket detail - GET
export const GET_TICKET_DETAIL = `tickets/ticket/`;
// Update ticket status - PATCH
export const UPDATE_TICKET_ISSUE_STATUS = `tickets/update/ticketStatus/`;
// Add comment on specific Ticket - POST
export const ADD_COMMENT_TO_SPECIFIC_TICKET = `ticketComments/add/`;
export const ADD_NEW_ROLE = `users/addNewRole/`;
export const CREATE_NEW_USER_URL = `superAdmins/create/users`;

/** Stripe */

export const CREATE_CUSTOMER = `stripe/create-customer`;
export const GET_HISTORY = `stripe/history`;
export const GET_CARD = `stripe/card`;
export const CREATE_SUBSCRIPTION = `stripe/create-subscription`;
export const UPDATE_STRIPE_CARD = `stripe/update-card`;

export const CREATE_CHECKOUT_SESSION = `stripe/create-checkout-session`;
export const UPDATE_PAYMENT_METHOD_CHECKOUT = `stripe/update-payment-method-checkout`;
export const CHECK_PENDING_VERIFICATION = `stripe/check-pending-verification`;

// For Post Request In Overall App.

export const postRequest = (url, payLoad, responseType) => {
  return axiosClient.post(url, payLoad, { ...(responseType ? { ...responseType } : {}) });
};

// For Get Request In Overall App.

export const getRequest = (url, responseType) => {
  return axiosClient.get(url, { ...(responseType ? { ...responseType } : {}) });
};

// For patch Request In Overall App.

export const patchRequest = (url, payLoad) => {
  return axiosClient.patch(url, payLoad);
};

// For delete Request In Overall App.

export const deleteRequest = (url) => {
  return axiosClient.delete(url);
};
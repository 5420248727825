/* eslint-disable no-case-declarations */
import {
  LIST_ALL_PATIENTS,
  LIST_ALL_PATIENTS_ERROR,
  LIST_ALL_PATIENTS_EMPTY,
  LIST_ALL_PATIENTS_LOADING,
  PATIENT_DATA_FLAG,
  SELECTED_PATIENT,
  SELECTED_PATIENT_LOADING,
  SELECTED_PATIENT_ERROR,
  SELECTED_PATIENT_REPORT,
  PATIENTS_FILTERS,
  SELECTED_PATIENT_UNUSE,
  PATIENT_DATA_EMPTY,
  SET_VIDEO_CALL_STATUS,
  HANDLE_SELECTED_TAB,
  REPORT_LOADING,
  HIDE_TIMER,
  SET_NOTES,
  SET_MESSAGES,
  SET_MESSAGES_CURRENT_PAGE,
  SET_MESSAGES_HASMORE,
  SMSRESPONSE,
  SOCKET_RESPONSE,
  TXT_ERROR,
} from './patientTypes';
import { momentWrapper } from 'src/momentWrapper';

const initialState = {
  hasPatientPastData: false,
  hideTimer: false,
  patientsList: ``,
  patientData: ``,
  selectedPatient: null,
  patientReport: null,
  videoCall: null,
  isOnVideo: false,
  selectedTab: null,
  isSwitchingPatient: false,
  messages: [],
  messagesCurrentPage: 1,
  messagesHasMore: true,
  messageLoadingFlag: { loading: false, error: false },
  popedMessages: [],
  notes: [],
  notesFlag: { loadingCall: false, error: false },
  reportLoadingFlag: false,
  globalFlag: false,
  currenTab: `msg`,
  allTimeLogs: {
    loading: false,
    timeLogs: null,
  },
  patientsFilters: {
    sortField: `lastName`,
    sortOrder: `ASC`,
    pageNumber: 1,
    pageSize: 30,
    searchFilters: {
      monitoringTime: [],
      tier: [],
      isAdherent: [],
      online: [],
      status: [`active`],
    },
  },
  smartPhrases: [],
};

export const patientReducer = (state = initialState, action) => {
  switch (action.type) {
    case PATIENT_DATA_FLAG:
      return { ...state, hasPatientPastData: action.payLoad };
    case LIST_ALL_PATIENTS:
      return {
        ...state,
        patientsList: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };

    case LIST_ALL_PATIENTS_LOADING:
      return {
        ...state,
        patientsList: {
          ...state.patientsList,
          loading: true,
          error: false,
        },
      };

    case LIST_ALL_PATIENTS_ERROR:
      return {
        ...state,
        patientsList: {
          ...state.patientsList,
          loading: false,
          error: true,
        },
      };
    case SELECTED_PATIENT_LOADING:
      return {
        ...state,
        patientData: {
          ...state.patientData,
          loading: true,
        },
      };
    case SELECTED_PATIENT_ERROR:
      return {
        ...state,
        patientData: {
          ...state.patientData,
          loading: false,
          error: true,
          success: false,
        },
      };

    case SELECTED_PATIENT:
      return {
        ...state,
        patientData: {
          data: action.payload,
          loading: false,
          error: false,
          success: false,
          currenTab: `msg`,
        },
      };
    case `ADD_PATIENT`:
      return { ...state, isAdded: action.payLoad };
    case LIST_ALL_PATIENTS_EMPTY:
      return {
        ...state,
        patientsList: action.payload,
      };
    case PATIENT_DATA_EMPTY:
      return {
        ...state,
        patientData: action.payload,
      };
    case SET_VIDEO_CALL_STATUS:
      return { ...state, videoCall: action.payload };
    case `VIDEOFLAG`:
      return { ...state, isOnVideo: action.payLoad };
    case HANDLE_SELECTED_TAB:
      return { ...state, selectedTab: action.payload };
    case SET_MESSAGES:
      return { ...state, messages: action.payload };
    case SET_MESSAGES_CURRENT_PAGE:
      return { ...state, messagesCurrentPage: action.payload };
    case SET_MESSAGES_HASMORE:
      return { ...state, messagesHasMore: action.payload };
    case `SET_MESSAGES_REPLY`:
      return { ...state, messages: [...state.messages, action.payload] };
    case `MSGAPICALL`:
      return { ...state, messageLoadingFlag: action.payLoad };
    case SOCKET_RESPONSE:
      let messagess = state.messages;
      let ids = action?.payLoad?.id;
      let data = action?.payLoad?.data;
      let error = action?.payLoad?.error;
      let hasID = messagess.find((e) => {
        return e._uniqueId === ids;
      });
      if (error && hasID) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === ids
              ? {
                ...e,
                error: true,
                status: `failed`,
              }
              : { ...e }
          ),
        };
      } else if (hasID) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === ids
              ? {
                ...data,
                status: `delivered`,
              }
              : { ...e }
          ),
        };
      } else {
        return state;
      }
    case SMSRESPONSE:
      let message = action.payLoad;
      let uniqueId = message?._uniqueId;
      let hasUnique = state.messages.find((e) => e._uniqueId === uniqueId);
      if (hasUnique) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === uniqueId
              ? {
                ...message,
                status: `delivered`,
              }
              : { ...e }
          ),
        };
      } else {
        return state;
      }

    case TXT_ERROR:
      let errors = action.payLoad.error;
      let uni = action.payLoad._uniqueId;
      let hasUni = state.messages.find((e) => e._uniqueId === uni);

      if (hasUni && errors) {
        return {
          ...state,
          messages: state.messages.map((e) =>
            e._uniqueId === uni
              ? {
                ...e,
                error: true,
                status: `failed`,
              }
              : {
                ...e,
              }
          ),
        };
      } else {
        return state;
      }
    case SET_NOTES:
      return { ...state, notes: action.payload };
    case `LOADING`:
      return { ...state, notesFlag: action.payLoad };
    case REPORT_LOADING:
      return { ...state, reportLoadingFlag: action.payload };
    case HIDE_TIMER:
      if (action.payload) {
        return { ...state, timer: { ...state.timer, startTime: momentWrapper().toISOString() }, hideTimer: action.payload };
      } else {
        return { ...state, timer: { ...state.timer, endTime: momentWrapper().toISOString() }, hideTimer: action.payload };
      }
    // Not in use right now just doing for the patient 1 module
    case SELECTED_PATIENT_UNUSE:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    //End
    case PATIENTS_FILTERS:
      return {
        ...state,
        patientsFilters: action.payload,
      };
    case SELECTED_PATIENT_REPORT:
      return {
        ...state,
        patientReport: action.payload,
      };

    // //  set smart phrases
    // case GET_SMART_PHRASE:
    //   return { ...state, smartPhrases: action.payload };
    default:
      return state;
  }
};

import moment from 'moment-timezone';
import { getPracticeTimezone } from 'src/utils';

export const momentWrapper = (...str) => {
  let temp;
  if (str) temp = moment.tz(...str, getPracticeTimezone());
  else temp = moment.tz(getPracticeTimezone());
  return temp;
};


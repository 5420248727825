import React from 'react';
import { useSelector } from 'react-redux';
import { PaymentInfo } from './paymentInfo/index';
import { BillingHistory } from './billingHistory/index';
import css from '../admin.module.css';
import Box from '@mui/material/Box';
import {loadStripe} from "@stripe/stripe-js";
import config from "src/config";
import {Elements} from "@stripe/react-stripe-js";

const stripePromise = loadStripe(config.STRIPE_PUBLISH_KEY);

export const AdministrationPayment = () => {
  const { user } = useSelector((state) => state.user);
  const practice = useSelector((state) => state.practice);
  return (
      <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
          <Elements stripe={stripePromise}>
              <div id={css.main}>
                  <div id={css.paymentCards} className={!user?.isSystemAdministrator ? `mb-5` : `d-none`}>
                      {user.stripeSubscriptionId || (!user.stripeSubscriptionId && practice?.stripeSubscriptionId) ? <BillingHistory /> : <PaymentInfo />}
                  </div>
                  {user?.isSystemAdministrator && (
                      <div id={css.paymentCards} className={user?.isSystemAdministrator ? `d-block mb-5` : `d-none`}>
                          <BillingHistory />
                      </div>
                  )}
              </div>
          </Elements>
      </Box>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import { getRequest } from 'src/crud/crud';
import { Filters } from '../Filters/Filters';
import { IconButton, Fade, Box } from '@mui/material';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { momentWrapper } from 'src/momentWrapper';
import HomeIcon from '@mui/icons-material/Home';
import {
  capitalizeResmed, capitalizeRespironics, manufacturerForReact_Health, roundNumber,
  extraWordRemover, monthsArray, dynamicObjCreator
} from 'src/utils';
import { errorToast } from 'src/pages/common/components/snackBar/toast';

export const useFilters = (pagination, setPagination, alertClick, daysOnDeviceClick) => {
  const [data, setData] = useState([]);
  const [filterFlag, setFilterFlag] = useState(0);
  const [filtersObj, setFilters] = useState({
    search: ``,
    manufacturer: ``,
    isAdherent: ``,
    isAdherent3Days: ``,
    status: ``,
    alert: ``,
  });

  const queryParams = (object) => {
    let arrayObject = Object.keys(object);
    let firstElemen = arrayObject[0];
    let query = ``;
    for (const i in object) {
      if (i === firstElemen) {
        query += `?${i}=${encodeURIComponent(object[i] ? object[i] : null)}`;
      } else if (i === `filters`) {
        query += `&${i}=${encodeURIComponent(JSON.stringify(object.filters))}`;
      } else {
        query += `&${i}=${encodeURIComponent(object[i] ? object[i] : null)}`;
      }
    }
    return query;
  };
  const dataMapHelper = async (response) => {
    let reports = response?.map((e) => {
      let preciseAHI = roundNumber({
        num: e?.AHI,
        roundTo: 1,
      });
      let preciseLeak = roundNumber({
        num: e?.leak,
        roundTo: 1,
      });
      let capitalizedManufacturer =
        e?.device?.manufacturer && e?.device?.manufacturer === `respironics` ? capitalizeRespironics() : e?.device?.manufacturer === `reactHealth` ? manufacturerForReact_Health() : capitalizeResmed();
      let tier = e?.tier === `VERY HIGH RISK` ? `Very High` : extraWordRemover({ word: e?.tier, capitalizeFirstLetter: true, isAllUpperCase: true });
      let mappedReports =
        e?.reports &&
        e?.reports?.map((elem) => {
          if (elem?.month) {
            let month = elem?.month?.toString();
            let array = month?.split(``);
            let isGreater = array[array?.length - 2] + array[array?.length - 1];
            let currentMonthIndex;
            if (parseInt(isGreater) >= 10) {
              let currentMonth = monthsArray[parseInt(isGreater)];
              let reportDate = currentMonth + ` ` + array?.slice(0, 4)?.join(``);
              return { ...elem, month: reportDate };
            } else {
              currentMonthIndex = array[array?.length - 1];
              let currentMonth = monthsArray[parseInt(currentMonthIndex)];
              let reportDate = currentMonth + ` ` + array?.slice(0, 4)?.join(``);
              return { ...elem, month: reportDate };
            }
          } else {
            return { ...elem };
          }
        });

      return {
        ...e,
        reports: mappedReports,
        AHI: preciseAHI,
        leak: preciseLeak,
        deviceManufacturer: capitalizedManufacturer,
        tier: tier,
      };
    });
    return reports;
  };
  const mappedPromise = (res) => {
    setPagination((p) => ({ ...p, totalRows: res?.totalPatients ? res?.totalPatients : 0 }));
    if (Math.ceil(res?.totalPatients / res?.currentPageRecords)) {
      setPagination((p) => ({
        ...p,
        totalPage: res?.totalPages ? res?.totalPages : 0,
      }));
    }
    let mappedProm = new Promise((resolve, reject) => {
      let mappedData = dataMapHelper(res?.data);
      resolve(mappedData);
      reject(`error`);
    });
    mappedProm.then((res) => {
      setData(res);
    });
  };
  const apiCall = useCallback(() => {
    let { isAdherent, isAdherent3Days, alert } = filtersObj;
    let stringFilters = dynamicObjCreator(filtersObj);
    let filtersPayLoad = {
      ...stringFilters,
      ...(alert?.length > 0 && { alert: alert === `Yes` ? true : false }),
      ...(isAdherent3Days ? { isAdherent3Days: parseInt(isAdherent3Days) } : {}),
      ...(isAdherent?.length > 0 && { isAdherent: isAdherent === `Adherent` ? true : isAdherent === `Non-Adherent` ? false : null }),
    };
    const endDate = momentWrapper(new Date()).subtract(1, `days`).set({ hours: 23, minutes: 59, seconds: 59 }).toISOString();
    const startDate = momentWrapper(new Date(endDate)).subtract(29, `days`).set({ hours: 0, minutes: 0, seconds: 0 }).toISOString();
    let copPagination = {
      endDate: JSON.stringify(endDate),
      startDate: JSON.stringify(startDate),
      pageNumber: pagination?.currentPage,
      pageSize: pagination?.rowsPerPage,
      filters: { ...filtersPayLoad },
    };
    let sortingObject = {
      field: pagination?.sortField,
      sortOrder: pagination?.sortOrder === `ASC` ? `1` : pagination?.sortOrder === `DSC` ? `-1` : ``,
    };
    copPagination[`sorting`] = JSON.stringify(sortingObject);
    let query = queryParams(copPagination);

    if (!Object.keys(copPagination?.filters).length) {
      setFilters({
        search: ``,
        manufacturer: ``,
        status: ``,
        isAdherent: ``,
        isAdherent3Days: ``,
      });
    }
    if (
      (Object.keys(filtersPayLoad).length && document.getElementById(`navigateHome`).style.display === `none`) ||
      (pagination?.sortField !== `lastName` && document.getElementById(`navigateHome`).style.display === `none`)
    ) {
      document.getElementById(`navigateHome`).style.display = `inline-block`;
    }
    setData(`loading`);
    getRequest(`patients/overview${query}`)
      .then((res) => {
        mappedPromise(res?.data);
      })
      .catch(() => setData(`error`));
  }, [pagination?.sortField, pagination?.sortOrder, pagination?.rowsPerPage, pagination?.currentPage, filterFlag]);

  useEffect(() => {
    apiCall();
  }, [apiCall]);
  const validate = () => {
    let check = Object.values(filtersObj);
    let isValid = check?.find((e) => e?.length > 0);
    if (isValid) {
      return true;
    } else {
      errorToast(`Please Enter at least one filter!`);
      return false;
    }
  };

  let filters = (
    <Filters filtersObj={filtersObj} setFilters={setFilters} validate={validate} setFilterFlag={setFilterFlag} setPagination={setPagination} />
  );
  let defaultButton = (
    <Fade in={filterFlag !== 0 || pagination?.sortField !== `lastName`} mountOnEnter unmountOnExit>
      <Box sx={{ display: `inline-block` }}>
        <ToolTipProvider
          toolTipProps={{
            title: `Restore Default Sort Settings`,
            placement: `top`,
            arrow: true,
          }}
          element={
            <IconButton
              id="navigateHome"
              onClick={() => {
                alertClick.current = 0
                daysOnDeviceClick.current = 0
                document.getElementById(`navigateHome`).style.display = `none`;
                if (Object.keys(filtersObj).length) {
                  setFilters({
                    search: ``,
                    manufacturer: ``,
                    isAdherent: ``,
                    isAdherent3Days: ``,
                    status: ``,
                  });
                }
                setFilterFlag((p) => p + 1);
                setPagination((p) => ({ ...p, rowsPerPage: 25, sortField: `lastName`, sortOrder: `ASC` }));
              }}
              sx={{ color: `rgb(22, 153, 197)` }}
            >
              <HomeIcon sx={{ fontSize: `34px` }} />
            </IconButton>
          }
        />
      </Box>
    </Fade>
  );

  return { data, filters, defaultButton };
};

import { Box } from '@mui/material'
import React from 'react'
import { ToolTipProvider } from '../components/Tooltip/ToolTipProvider'


export const TruncateWraper = ({ tag: Tag, children, maxWidth = `70px`, WebkitLineClamp = false }) => {
    return <ToolTipProvider toolTipProps={{
        arrow: true,
        placement: `top`,
        title: children
    }} element={<Box><Tag style={{
        overflow: `hidden`,
        ...(WebkitLineClamp ? {
            WebkitLineClamp,
            display: `-webkit-box`,
            WebkitBoxOrient: `vertical`,
        } : { maxWidth, whiteSpace: `nowrap`, textOverflow: `ellipsis` }),

    }}>{children}</Tag></Box>} />


}







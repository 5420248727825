import React, { useState, useEffect } from 'react';
import css from '../viewData.module.css';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-annotation';
import {
  getRequest,
  MONITAIR_TREND_USAGE_REPORT,
  MONITAIR_TREND_AHI_REPORT,
} from '../../../../../../../../../crud/crud';

// MONITAIR TREND REPORT

export const ViewDataMonitAir = ({ user }) => {

  //AHI
  const [dataSetAHI, setDataSetAHI] = useState([]);
  const [ahiLabels, setAhiLabels] = useState([]);
  //Usage
  const [dataSetUsage, setDataSetUsage] = useState([]);
  const [usageLabels, setUsageLabels] = useState([]);

  useEffect(() => {
    if (user) {
      let monthLabel = [`Jan`, `Feb`, `Mar`, `Apr`, `May`, `Jun`, `Jul`, `Aug`, `Sep`, `Oct`, `Nov`, `Dec`];
      getRequest(MONITAIR_TREND_AHI_REPORT + user._id)
        .then((response) => {
          const averageAHIPerMonthResponse = response.data?.averageAHIPerMonth;
          let AHIFilteredData = [];
          let AHILabels = [];

          for (let i = 0; i < averageAHIPerMonthResponse.length; i++) {
            AHIFilteredData.push(averageAHIPerMonthResponse[i].averageAHI);
            let monthNumber = Number(averageAHIPerMonthResponse[i].month.split(` `)[0]);

            AHILabels.push(monthLabel[monthNumber - 1]);
          }

          setDataSetAHI(AHIFilteredData);
          setAhiLabels(AHILabels);
        })
        .catch(() => { });

      // Usage Graph Payload

      getRequest(MONITAIR_TREND_USAGE_REPORT + user._id)
        .then((response) => {
          const averageUsagePerMonthResponse = response.data?.usagePerMonth;
          let UsageFilteredData = [];
          let UsageLabels = [];
          for (let i = 0; i < averageUsagePerMonthResponse.length; i++) {
            let avgSortedNumber = (averageUsagePerMonthResponse[i].averageUsage * 60) / 3600;
            UsageFilteredData.push(avgSortedNumber.toFixed(2));
            let monthNumber = Number(averageUsagePerMonthResponse[i].month.split(` `)[0]);
            UsageLabels.push(monthLabel[monthNumber - 1]);
          }

          setDataSetUsage(UsageFilteredData);
          setUsageLabels(UsageLabels);
        })
        .catch(() => { });
    } else {
      return (
        <div className="d-flex justify-content-center align-items-center" style={{ minHeight: `298px`, color: `rgb(193, 193, 193)` }}>
          No record found.
        </div>
      );
    }
  }, []);

  return (
    <>
      <div id={css.viewSummaryData} className="pt-3">
        <table className="table table-borderless ml-1">
          <tbody>
            {/* AHI */}
            <tr>
              <td colSpan="2" className="pt-0 pb-0">
                <h5 className="mb-0" style={{ color: `black` }}>
                  AHI:
                </h5>
              </td>
            </tr>

            {/* AHI Graph */}
            <tr>
              <td colSpan="2">
                <Line
                  data={{
                    labels: ahiLabels,
                    datasets: [
                      {
                        label: `AHI`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `#141E27`,
                        borderColor: `#141E27`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `#141E27`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `#141E27`,
                        pointHoverBorderColor: `#141E27`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: [...dataSetAHI],
                      },
                      {
                        label: `HIGH RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(167, 9, 9)`,
                        borderColor: `rgb(167, 9, 9)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(167, 9, 9)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(167, 9, 9)`,
                        pointHoverBorderColor: `rgb(167, 9, 9)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `MEDIUM RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(34, 112, 147)`,
                        borderColor: `rgb(34, 112, 147)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(34, 112, 147)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(34, 112, 147)`,
                        pointHoverBorderColor: `rgb(34, 112, 147)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `LOW RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(23, 162, 184)`,
                        borderColor: `rgb(23, 162, 184)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(23, 162, 184)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(23, 162, 184)`,
                        pointHoverBorderColor: `rgb(23, 162, 184)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                    ],
                  }}
                  width={20}
                  height={10}
                  options={{
                    legend: {
                      display: true,
                      onClick: null,
                      labels: {
                        filter: function (item) {
                          if (item.text == `AHI`) {
                            return false;
                          } else {
                            return item;
                          }
                        },
                      },
                    },
                    annotation: {
                      annotations: [
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 30,
                          backgroundColor: `rgb(167, 9, 9)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 15,
                          backgroundColor: `rgb(34, 112, 147)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 5,
                          backgroundColor: `rgb(23, 162, 184)`,
                        },
                      ],
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Months`,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: `#0000`,
                            // borderDash: [5, 3],
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Events per hour`,
                          },
                        },
                      ],
                    },
                  }}
                />
              </td>
            </tr>
            {/* Usage */}
            <tr>
              <td colSpan="2" className="pt-0 pb-0">
                <h5 className="mb-0" style={{ color: `black` }}>
                  Usage:
                </h5>
              </td>
            </tr>
            {/* Usage Graph */}
            <tr>
              <td colSpan="2">
                <Line
                  data={{
                    labels: usageLabels,
                    datasets: [
                      {
                        label: `Usage`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `#141E27`,
                        borderColor: `#141E27`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `#141E27`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `#141E27`,
                        pointHoverBorderColor: `#141E27`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                        data: [...dataSetUsage],
                      },
                      {
                        label: `HIGH RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(167, 9, 9)`,
                        borderColor: `rgb(167, 9, 9)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(167, 9, 9)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(167, 9, 9)`,
                        pointHoverBorderColor: `rgb(167, 9, 9)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `MEDIUM RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(34, 112, 147)`,
                        borderColor: `rgb(34, 112, 147)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(34, 112, 147)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(34, 112, 147)`,
                        pointHoverBorderColor: `rgb(34, 112, 147)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                      {
                        label: `LOW RISK`,
                        fill: false,
                        lineTension: 0,
                        backgroundColor: `rgb(23, 162, 184)`,
                        borderColor: `rgb(23, 162, 184)`,
                        borderCapStyle: `butt`,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        borderJoinStyle: `round`,
                        pointBorderColor: `rgb(23, 162, 184)`,
                        pointBackgroundColor: `#fff`,
                        pointBorderWidth: 2,
                        pointHoverRadius: 5,
                        pointHoverBackgroundColor: `rgb(23, 162, 184)`,
                        pointHoverBorderColor: `rgb(23, 162, 184)`,
                        pointHoverBorderWidth: 2,
                        pointRadius: 5,
                        pointHitRadius: 10,
                      },
                    ],
                  }}
                  width={20}
                  height={10}
                  options={{
                    legend: {
                      display: true,
                      onClick: null,
                      labels: {
                        filter: function (item) {
                          if (item.text == `Usage`) {
                            return false;
                          } else {
                            return item;
                          }
                        },
                      },
                    },
                    annotation: {
                      annotations: [
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 12,
                          backgroundColor: `rgb(23, 162, 184)`,
                        },

                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 6,
                          backgroundColor: `rgb(34, 112, 147)`,
                        },
                        {
                          drawTime: `beforeDatasetsDraw`,
                          type: `box`,
                          xScaleID: `x-axis-0`,
                          yScaleID: `y-axis-0`,
                          borderWidth: 0,
                          yMin: 0,
                          yMax: 4,
                          backgroundColor: `rgb(167, 9, 9)`,
                        },
                      ],
                    },
                    responsive: true,
                    maintainAspectRatio: true,
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            display: false,
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Months`,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            color: `#0000`,
                            // borderDash: [5, 3],
                          },
                          ticks: {
                            display: true,
                            min: 0,
                          },
                          scaleLabel: {
                            display: true,
                            labelString: `Hours`,
                          },
                        },
                      ],
                    },
                  }}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}



import React, { useState, useEffect, useRef } from 'react';
import '@reach/combobox/styles.css';
import css from './editPatient.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { resmedGroupedOptions, respironicsGroupedOptions, fisherAndPaykelGroupedOptions } from '../../importPatient/data';
import { momentWrapper } from 'src/momentWrapper';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { updateCreatedAt, updatePatientMask, updateEditPatient } from 'src/redux/patient/patientActions';
import MenuItem from '@mui/material/MenuItem';
import {
  getRequest, PATIENT_WITH_OUT_DEVICE_DME_URL, PATIENT_UPDATE_URL, patchRequest
} from '../../../../../../../crud/crud.js';
import {
  Box, ListSubheader, TextField, Grid,
  Select, FormControl, InputLabel, InputAdornment
} from '@mui/material';
import { toast, ToastContainer } from 'react-toastify';
import {
  phoneRegex, emailRegex, isDme, resmedMaskTypes,
  respironicsMaskTypes, fisherMaskTypes
} from 'src/utils';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { InputSpinner } from 'src/pages/common/components/InputSpinner/InputSpinner';
import Downshift from 'downshift';

export const EditPatient = ({ patientId, editPatientModal, setEditPatientModal, set_edit_patient_rerender_flag }) => {
  const selectedPatient = useSelector((state) => state.patient?.patientData?.data);
  const [inputsToedit, setinputsToedit] = useState({});
  const [payloadRedux, setpayLoadRedux] = useState({});
  const [loading, setLoading] = useState(false);
  const [secondaryDiagnosisLoading, setSecondaryDiagnosisLoading] = useState(false);
  const [isPrimaryOpenDropDown, setIsPrimaryOpenDropDown] = useState(false);
  const [primaryDiagnosisIsLoading, setPrimaryDiagnosisLoading] = useState(false);
  const [secDiagnosisListItems, setSecDiagnosisListItems] = useState([]);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [primaryDiagnosisListItems, setPrimDiagnosisListItems] = useState([]);
  const [anchorElems, setanchorElems] = useState({
    primary: null,
    secondary: null
  })

  //errors
  const [errors, seterrors] = useState({
    genderError: ``,
    phoneError: ``,
    primaryDiagnosisError: ``,
    emailError: ``,
    statusError: ``,
    notesError: ``,
    manufacturerError: ``,
    maskTypeError: ``,
    maskSizeError: ``,
  });
  //Refs
  const close = useRef(null);
  const phoneRef = useRef(null);
  const inputPDiagnosis = useRef(null);
  const genderRef = useRef(null);
  const statusRef = useRef(null);
  const emailRef = useRef(null);
  const notesRef = useRef(null);
  const manufacturerRef = useRef(null);
  const typeRef = useRef(null);
  const sizeRef = useRef(null);
  process.env.NODE_ENV === `development` && console.log(sizeRef, `sizeREfValue`)
  const dispatchRedux = useDispatch();

  useEffect(() => {
    setpayLoadRedux(selectedPatient);
  }, [selectedPatient]);
  const achorElemHandler = (name, elem) => {
    setanchorElems((p) => ({ ...p, [name]: elem }))
  }
  const comboboxPrimaryDiagnosisOnChange = (value) => {
    setpayLoadRedux({ ...payloadRedux, primaryDiagnosis: value });
    getNIHPrimaryDiagnosis(value);
  };
  //
  const primaryDiagnosisDropdownValueOnChange = (selectedDisgnosis) => {
    setIsPrimaryOpenDropDown((pre) => !pre);
    setpayLoadRedux({ ...payloadRedux, primaryDiagnosis: selectedDisgnosis.Description });
    setinputsToedit({ ...inputsToedit, primaryDiagnosis: selectedDisgnosis.Description });
  };


  // GET LIST OF NIH PRIMARY DIAGNOSIS

  const getNIHPrimaryDiagnosis = (query) => {
    if (query.length) {
      setPrimaryDiagnosisLoading(true);
      getRequest(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${query}`).then((response) => {
        setPrimaryDiagnosisLoading(false);
        setIsPrimaryOpenDropDown((pre) => !pre);
        const listOfDiagnosis = response?.data[3].map(([Code, Description]) => ({ Code, Description }));
        setPrimDiagnosisListItems(listOfDiagnosis);
        setIsPrimaryOpenDropDown(true);
      })
        .catch(() => {
          setPrimaryDiagnosisLoading(false);
        });

    }
  };

  const secondaryDiagnosisDropdownValueOnChange = (selectedDisgnosis) => {
    setpayLoadRedux({ ...payloadRedux, secondaryDiagnosis: selectedDisgnosis.Description });
    setinputsToedit({ ...inputsToedit, secondaryDiagnosis: selectedDisgnosis.Description });
    setIsOpenDropDown((pre) => !pre);
  };

  const comboboxSecondaryDiagnosisOnChange = (value) => {
    setpayLoadRedux({ ...payloadRedux, secondaryDiagnosis: value });
    setinputsToedit({ ...inputsToedit, secondaryDiagnosis: value });
    getNIHSecondayDiagnosis(value);
  };

  // GET NIH SECONDARY DIAGNOSIS

  const getNIHSecondayDiagnosis = (query) => {
    if (query?.length) {
      setSecondaryDiagnosisLoading(true);
      getRequest(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${query}`).then((response) => {
        const listOfDiagnosis = response?.data[3].map(([Code, Description]) => ({ Code, Description }));

        setSecondaryDiagnosisLoading(false);
        // setSecondaryDiagnosisQuery("")
        setSecDiagnosisListItems(listOfDiagnosis);
        setIsOpenDropDown(true);
      })
        .catch(() => {
          setSecondaryDiagnosisLoading(false);
          // setSecondaryDiagnosisQuery("")

        });
    }
  };

  const validateInput = async (field) => {
    process.env.NODE_ENV === `development` && console.log(field, `fieldComing`, sizeRef.current.value)
    switch (field) {
      case `gender`:
        if (!genderRef.current.value) {
          seterrors({ ...errors, genderError: `Required` });
        } else {
          seterrors({ ...errors, genderError: `` });
        }
        break;
      case `phone`:
        if (!phoneRef?.current?.value) {
          seterrors({ ...errors, phoneError: `Required*` });
        } else if (!phoneRegex.test(phoneRef.current.value)) {
          seterrors({ ...errors, phoneError: `Invalid No. number should neither be composed of text nor be a non-American` });
        } else {
          seterrors({ ...errors, phoneError: `` });
        }

        break;
      case `email`:
        if (!emailRef?.current?.value) {
          seterrors({ ...errors, emailError: `` });
        } else if (emailRef.current.value) {
          if (!emailRegex.test(emailRef.current.value)) {
            seterrors({ ...errors, emailError: `Invalid Email Address` });
          } else {
            seterrors({ ...errors, emailError: `` });
          }
        }
        break;
      case `primaryDiagnosis`:
        if (!inputPDiagnosis.current.value) {
          seterrors({ ...errors, primaryDiagnosisError: `*Required` });
        } else if (inputPDiagnosis.current.value.length > 0) {
          seterrors({ ...errors, primaryDiagnosisError: `` });
        }
        break;
      case `status`:
        if (!statusRef.current.value) {
          seterrors({ ...errors, statusError: `*Required` });
        } else {
          seterrors({ ...errors, statusError: `` });
        }
        break;
      case `notes`:
        if (notesRef.current?.value?.length > 570) {
          seterrors({ ...errors, notesError: `Should not be greater than 570 words` });
        } else {
          seterrors({ ...errors, notesError: `` });
        }
        break;
      case `size`:
        if (!sizeRef?.current?.value) {
          seterrors({ ...errors, maskSizeError: `*Required` });
        } else {
          seterrors({ ...errors, maskSizeError: `` });
        }
        break;
      case `manufacturer`:
        if (!manufacturerRef.current.value) {
          seterrors({ ...errors, maskSizeError: ``, maskTypeError: ``, manufacturerError: `` });
        } else if (manufacturerRef.current.value) {
          seterrors({ ...errors, maskSizeError: `*Required`, maskTypeError: `*Required`, manufacturerError: `` });
        }
        break;
      default:
        return true;
    }
  };

  const submitHandler = async () => {
    try {
      const successValidation = await validateInput();
      if (successValidation) {
        const splitingDaysInWIP = inputsToedit?.daysInWIP && inputsToedit?.daysInWIP.split(`_`)
        let daysInWIPResult = inputsToedit?.daysInWIP && Number(splitingDaysInWIP[0])

        let payLoad = {}
        if (inputsToedit?.daysInWIP) {
          payLoad = { ...inputsToedit, daysInWIP: daysInWIPResult }
        } else {
          if (inputsToedit?.reactivateAt) {
            payLoad = { ...inputsToedit }
            delete payLoad[`reactivateAt`]

          } else {
            payLoad = { ...inputsToedit }
          }

        }

        let query = inputsToedit?.reactivateAt && payloadRedux?.status === `inactive` ? `/?reactivateAt=${momentWrapper(inputsToedit?.reactivateAt).toISOString()}` : `?/reactivateAt=false`
        let url = isDme() ? PATIENT_WITH_OUT_DEVICE_DME_URL + patientId : PATIENT_UPDATE_URL + patientId + query
        setLoading(true);
        patchRequest(url, payLoad)
          .then((res) => {
            dispatchRedux(updateEditPatient(res?.data?.updatePatient));
            dispatchRedux(updatePatientMask(res?.data?.updatePatient));
            dispatchRedux(updateCreatedAt(res?.data?.updatePatient?.createdAt));
            set_edit_patient_rerender_flag((edit_rerender_counter) => edit_rerender_counter + 1); // RE-RENDER CONDITIONAL BASIC-INFO COMPONENT
            setLoading(false);
            successToast(`Edit patient successfully`);
            setEditPatientModal(false);
            close.current.click();
          })
          .catch((err) => {
            if (err?.response) {
              let responseText = err?.response?.data?.message || err?.response?.data?.error?.status;
              toast.error(() => <>{responseText?.charAt(0).toUpperCase().concat(responseText.slice(1, responseText?.length))}</>, {
                position: toast.POSITION.BOTTOM_RIGHT,
              });
            }

            setLoading(false);
          });
      } else {
        toast.error(() => <>Unable to send request</>, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      toast.error(() => <>Failed</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };

  const onChangeforvalues = (e, error) => {
    // e.persist();
    let name = e.target.name;

    let value = e.target.value;

    if (name === `manufacturer`) {
      const copyPayloadRedux = { ...payloadRedux };
      copyPayloadRedux[`mask`] = {
        [name]: value,
        type: ``,
        size: ``,
      };
      setpayLoadRedux(copyPayloadRedux);
      const copyInputsToedit = { ...inputsToedit };
      copyInputsToedit[`mask`] = {
        [name]: value,
      };
      setinputsToedit(copyInputsToedit);
    } else if (name === `size`) {
      const copyPayloadRedux = { ...payloadRedux };
      copyPayloadRedux[`mask`] = {
        ...copyPayloadRedux.mask,
        [name]: value,
      };
      const copyInputsToedit = { ...inputsToedit };
      copyInputsToedit[`mask`] = {
        ...copyInputsToedit.mask,
        [name]: value,
      };
      setpayLoadRedux(copyPayloadRedux);
      setinputsToedit(copyInputsToedit);
    } else if (name === `phoneNumber`) {
      let slicedNo = value.slice(0, 14);

      const copyPayloadRedux = { ...payloadRedux };
      copyPayloadRedux[name] = slicedNo;
      const copyInnputsToedit = { ...inputsToedit };
      copyInnputsToedit[name] = slicedNo;
      setinputsToedit(copyInnputsToedit);
      setpayLoadRedux(copyPayloadRedux);
    } else {
      const copyPayloadRedux = { ...payloadRedux };
      copyPayloadRedux[name] = value;
      setpayLoadRedux(copyPayloadRedux);

      const copyInputsToedit = { ...inputsToedit };
      copyInputsToedit[name] = value;
      setinputsToedit(copyInputsToedit);
    }
    // if (error) {
    //   validateInput(error);
    // }
  };

  const phoneMaskfunc = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setpayLoadRedux({ ...payloadRedux, phoneNumber: temp });
  };

  ///onModalunmount
  const onEdirmodalunMount = () => {
    setEditPatientModal(false);
    seterrors({});
    setinputsToedit({});
    setpayLoadRedux(selectedPatient);
  };

  const maskTypeSubHeaderStyles = {
    backgroundColor: `#1c72aae3`,
    color: `white`,
    textAlign: `center`,
    paddingTop: `0px`,
  };
  const maskTypestyles = {
    backgroundColor: `#9fd1f9a8`,
    '&:hover': {
      backgroundColor: `rgba(25, 118, 210, 0.08)`,
    },
  };


  return (
    <CustomizedDialogs
      title="EDIT PATIENT"
      open={editPatientModal}
      setOpen={onEdirmodalunMount}
      size="md"
      fullWidth={true}
      customButton={true}
      customButtonText="Save Patient"
      customButtonDisable={
        errors.phoneError ||
          errors.primaryDiagnosisError ||
          errors.genderError ||
          errors.emailError ||
          errors.statusError ||
          errors.notesError ||
          errors.manufacturerError ||
          errors.maskTypeError ||
          errors.maskSizeError
          ? true
          : false
      }
      customButtonAction={() => submitHandler()}
    >
      <ToastContainer />
      {/*  */}
      <div style={{ position: `static` }}>
        <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
          Basic Information
        </p>
        <hr style={{ margin: `0px` }} />
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container columnSpacing={1} rowSpacing={1.5}>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size='small'
                disabled={true}
                type="text"
                sx={{
                  textTransform: `capitalize`,
                }}
                label={payloadRedux?.firstName}
                maxLength="25"
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>

              <InputField
                fullWidth={true}
                size='small'
                label={payloadRedux?.lastName}
                type="text"
                sx={{
                  textTransform: `capitalize`,
                }}
                maxLength="25"
                disabled={true}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size='small'
                disabled={true}
                autoComplete="off"
                sx={{ opacity: `.6`, fontSize: `13px` }}
                value={momentWrapper(payloadRedux?.dateOfBirth).format(`MM/DD/YYYY`)}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <FormControl fullWidth >
                <InputLabel>*Gender</InputLabel>
                <Select
                  fullWidth
                  error={errors.genderError ? true : false}
                  onBlur={() => validateInput(`gender`)}
                  sx={{ height: `40px` }}
                  label='*Gender'
                  size='small'
                  // id={payloadRedux?.gender ? css.selectedSelector : css.selector}
                  onChange={(event) => {
                    onChangeforvalues(event, `gender`);
                  }}
                  value={payloadRedux?.gender}
                  inputProps={{
                    ref: genderRef
                  }}
                  name="gender"
                >
                  <MenuItem defaultValue value="">
                    Gender*
                  </MenuItem>
                  <MenuItem value="Male">Male</MenuItem>
                  <MenuItem value="Female">Female</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
              </FormControl>
              <p className={errors.genderError ? `d-block text-danger` : `d-none`}>{errors.genderError ? errors.genderError : ``}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size='small'
                inputProps={{
                  ref: phoneRef
                }}
                autoComplete="off"
                onBlur={() => validateInput(`phone`)}
                onChange={(e) => {
                  onChangeforvalues(e);
                  validateInput(`phone`);
                  phoneMaskfunc(e.target.value);
                }}
                value={payloadRedux?.phoneNumber}
                type="text"
                error={errors.phoneError ? true : false}
                label="Phone Number*"
                name="phoneNumber"
              />
              <p className={errors.phoneError ? `d-block text-danger` : `d-none`}>{errors.phoneError ? errors.phoneError : ``}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size='small'
                className={errors.emailError ? css.errorCustom : null}
                autoComplete="off"
                onChange={(e) => onChangeforvalues(e, `email`)}
                value={payloadRedux?.email}
                type="email"
                name="email"
                label="Email"
                id="email"
                inputProps={{
                  ref: emailRef
                }}

              />
              <p className={errors.emailError ? `d-block text-danger` : `d-none`}>{errors.emailError ? errors.emailError : ``}</p>
            </Grid>
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <InputField
                fullWidth={true}
                size='small'
                autoComplete="off"
                onChange={(event) => {
                  onChangeforvalues(event, `insuranceCompany`);
                }}
                value={payloadRedux?.insuranceCompany}
                type="text"
                label="Insurance Company"
                name="insuranceCompany"
              />
            </Grid>
          </Grid>
        </Box>
        <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
          Clinical Information
        </p>
        <hr style={{ margin: `0px` }} />
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container rowSpacing={1.5} columnSpacing={1}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Downshift
                onChange={(eventValue) => {
                  primaryDiagnosisDropdownValueOnChange(eventValue)
                }}
                itemToString={(item) => (item ? item.Description : ``)}
                onBlur={() => {
                  setIsPrimaryOpenDropDown((p) => !p);
                  validateInput(`primaryDiagnosis`);
                }}
                inputValue={payloadRedux?.primaryDiagnosis}
              >
                {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                  <div>
                    <div className="input-group mb-3">
                      <InputField
                        name="primaryDiagnosis"
                        label="*Primary Diagnosis..."
                        size='small'
                        type="text"
                        fullWidth={true}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {primaryDiagnosisIsLoading && <InputSpinner size='sm' />}
                            </InputAdornment>
                          )
                        }}
                        value={payloadRedux?.primaryDiagnosis}
                        error={errors?.primaryDiagnosisError ? true : false}
                        helperText={errors?.primaryDiagnosisError ? errors?.primaryDiagnosisError : null}
                        onChange={(event) => {
                          const { target, currentTarget } = event
                          const { value } = target
                          achorElemHandler(`primary`, currentTarget)
                          comboboxPrimaryDiagnosisOnChange(value)
                          validateInput(`primaryDiagnosis`);
                        }}
                        inputProps={{
                          ref: inputPDiagnosis
                        }}
                      />

                    </div>
                    {isPrimaryOpenDropDown ? (
                      <div
                        id={css.downshiftDropdown}
                        style={{
                          overflowY: `scroll`,
                          maxHeight: `140px`,
                        }}
                      >
                        {!primaryDiagnosisListItems.length ? (
                          <div
                            id={css.dropdownItem}
                            style={{
                              backgroundColor: `rgb(251 251 251)`,
                              fontWeight: `normal`,
                              padding: `1px`,
                              borderBottom: `1px solid lightgrey`,
                              cursor: `pointer`,
                              color: `red`
                            }}
                          >
                            No Data Found
                          </div>
                        ) : payloadRedux?.primaryDiagnosis === `` ? (
                          null
                        ) : (
                          primaryDiagnosisListItems?.map((item, index) => (
                            <div
                              id={css.dropdownItem}
                              {...getItemProps({ key: index, index, item })}
                              style={{
                                backgroundColor: highlightedIndex === index ? `lightgrey` : `rgb(251 251 251)`,
                                fontWeight: selectedItem === item ? `bold` : `normal`,
                                padding: `1px`,
                                borderBottom: `1px solid lightgrey`,
                                cursor: `pointer`,
                              }}
                            >
                              {item?.Description}
                            </div>
                          ))
                        )}

                      </div>
                    ) : (
                      null
                    )}
                  </div>
                )}
              </Downshift>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Downshift
                onChange={(event) => {
                  secondaryDiagnosisDropdownValueOnChange(event)
                }}
                itemToString={(item) => (item ? item.Description : ``)}
                inputValue={payloadRedux?.secondaryDiagnosis}
              >
                {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                  <div>
                    <div className="input-group mb-3">
                      <InputField
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {secondaryDiagnosisLoading && <InputSpinner size='sm' />}
                            </InputAdornment>
                          )
                        }}
                        value={payloadRedux?.secondaryDiagnosis}
                        size='small'
                        fullWidth={true}
                        label="Secondary Diagnosis ..."
                        onChange={(event) => {
                          const { target, currentTarget } = event
                          const { value } = target
                          achorElemHandler(`secondary`, currentTarget)
                          comboboxSecondaryDiagnosisOnChange(value)
                        }}
                      />
                    </div>
                    {isOpenDropDown ? (
                      <div
                        id={css.downshiftDropdown}
                        style={{
                          overflowY: `scroll`,
                          maxHeight: `140px`,
                        }}
                      >
                        {!secDiagnosisListItems.length ? (
                          <div
                            id={css.dropdownItem}
                            style={{
                              backgroundColor: `rgb(251 251 251)`,
                              fontWeight: `normal`,
                              padding: `1px`,
                              borderBottom: `1px solid lightgrey`,
                              cursor: `pointer`,
                              color: `red`
                            }}
                          >
                            No Data Found
                          </div>
                        ) : payloadRedux?.secondaryDiagnosis === `` ? (
                          null
                        ) : (
                          secDiagnosisListItems?.map((item, index) => (
                            <div
                              id={css.dropdownItem}
                              {...getItemProps({ key: index, index, item })}
                              style={{
                                backgroundColor: highlightedIndex === index ? `lightgrey` : `rgb(251 251 251)`,
                                fontWeight: selectedItem === item ? `bold` : `normal`,
                                padding: `1px`,
                                borderBottom: `1px solid lightgrey`,
                                cursor: `pointer`,
                              }}
                            >
                              {item?.Description}
                            </div>
                          ))
                        )}

                      </div>
                    ) : (
                      null
                    )}
                  </div>
                )}
              </Downshift>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                fullWidth={true}
                size='small'
                value={payloadRedux?.baseLineAHI}
                type="text"
                // sx={{ width: `95%`, padding: `2%` }}
                name="baseLineAHI"
                label="BaseLine AHI"
                onChange={(e) => {
                  onChangeforvalues(e, `baseLineAHI`);
                }}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <InputField
                fullWidth={true}
                size='small'
                value={payloadRedux?.MRN}
                type="text"
                name="MRN"
                label="Medical Record Number"
                onChange={(e) => onChangeforvalues(e, `MRN`)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Equipment Information */}
        <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
          Equipment Information
        </p>
        <hr style={{ margin: `0px` }} />

        {/*   End tags of Mask */}
        <Box sx={{ mt: 2, mb: 2 }}>
          <Grid container columnSpacing={1} rowSpacing={1.5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} >
              <FormControl fullWidth>
                <InputLabel id='device label'>
                  Device Manufacturer
                </InputLabel>
                <Select id='device label' label='Device Manufacturer' size='small'
                  value={payloadRedux?.device?.manufacturer}
                  name="deviceManufacturer"
                  disabled
                >
                  <MenuItem defaultValue value="">Select</MenuItem>
                  <MenuItem value="resmed">Resmed</MenuItem>
                  <MenuItem value="respironics">Respironics</MenuItem>
                  <MenuItem value="reactHealth">React Health</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {payloadRedux?.deviceManufacturer === `reactHealth` ? (
              <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                <InputField
                  fullWidth={true}
                  size='small'
                  autoComplete="off"
                  value={payloadRedux?._idFromDevice}
                  disabled
                  type="text"
                  name="deviceSerialNumber"
                  label="Device Number"
                />
              </Grid>
            ) : (
              <Grid xs={6} sm={6} md={6} lg={6} xl={6} item>
                <InputField
                  fullWidth={true}
                  size='small'
                  autoComplete="off"
                  value={payloadRedux?.device?.deviceNumber || payloadRedux?.device?.serialNumber || payloadRedux?.deviceSerialNumber}
                  disabled
                  type="text"
                  name="deviceSerialNumber"
                  label="Device Number"
                />
              </Grid>
            )}

            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} >
              <FormControl fullWidth size='small' >
                <InputLabel id='deviceMnfctr'>
                  Mask Company
                </InputLabel>
                <Select
                  inputProps={{
                    ref: manufacturerRef
                  }}
                  onBlur={() => validateInput(`manufacturer`)}
                  id="deviceMnfctr"
                  label='Mask Company'
                  labelId='deviceMnfctr'
                  onChange={(event) => {
                    onChangeforvalues(event, `manufacturer`);
                  }}
                  value={payloadRedux?.mask?.manufacturer}
                  name="manufacturer"
                // sx={{ height: `40px` }}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="resmed">Resmed</MenuItem>
                  <MenuItem value="respironics">Respironics</MenuItem>
                  <MenuItem value={`Fisher & Paykel`}>{`Fisher & Paykel`}</MenuItem>
                </Select>
              </FormControl>
              <div className="small text-danger">{errors.manufacturerError ? errors.manufacturerError : ``}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <TextField
                fullWidth={true}
                inputProps={{
                  ref: typeRef
                }}
                error={errors.maskTypeError ? true : false}
                helperText={`${errors.maskTypeError ? errors.maskTypeError : ``}`}
                size="small"
                value={payloadRedux?.mask?.type}
                label="Mask Type"
                onChange={(e) => {
                  setpayLoadRedux({
                    ...payloadRedux,
                    mask: {
                      ...payloadRedux?.mask,
                      type: e.target.value,
                    },
                  });
                  setinputsToedit({
                    ...inputsToedit,
                    mask: {
                      ...inputsToedit?.mask,
                      type: e.target.value,
                    },
                  });
                  seterrors({ ...errors, maskTypeError: `` });
                }}
                select
                name="type"
              >
                {payloadRedux?.mask?.manufacturer === `resmed`
                  ? resmedGroupedOptions.map((item, index) => [
                    <ListSubheader
                      sx={{
                        ...maskTypeSubHeaderStyles,
                      }}
                      key={index + `resemd`}
                    >
                      {item.label}
                    </ListSubheader>,
                    item.options.map((subItem, index) => (
                      <MenuItem
                        sx={{
                          ...maskTypestyles,
                        }}
                        key={`key` + index}
                        value={subItem.value}
                      >
                        {subItem.label}
                      </MenuItem>
                    )),
                  ])
                  : payloadRedux?.mask?.manufacturer === `respironics`
                    ? respironicsGroupedOptions.map((item, index) => [
                      <ListSubheader key={index + `respironics` + item.label}>{item.label}</ListSubheader>,
                      item.options.map((subItem) => (
                        <MenuItem
                          sx={{
                            ...maskTypestyles,
                          }}
                          key={index + `resKey`}
                          value={subItem.value}
                        >
                          {subItem.label}
                        </MenuItem>
                      )),
                    ])
                    : payloadRedux?.mask?.manufacturer === `Fisher & Paykel`
                      ? fisherAndPaykelGroupedOptions.map((item, index) => [
                        <ListSubheader key={`Paykel` + index}>{item.label}</ListSubheader>,
                        item.options.map((subItem) => (
                          <MenuItem
                            sx={{
                              ...maskTypestyles,
                            }}
                            key={index + `key-Paykel`}
                            value={subItem.value}
                          >
                            {subItem.label}
                          </MenuItem>
                        )),
                      ])
                      : null}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4} >
              <FormControl size="small" id='sizeMask' fullWidth>
                <InputLabel>
                  Mask Size
                </InputLabel>
                <Select
                  inputProps={{
                    ref: sizeRef
                  }}
                  error={errors.maskSizeError ? true : false}
                  id="sizeMask"
                  label='Mask Size'
                  onChange={(event) => {
                    onChangeforvalues(event,);
                  }}
                  onBlur={() => validateInput(`size`)}
                  value={payloadRedux?.mask?.size ? payloadRedux?.mask?.size : ``}
                  name="size"
                  sx={{ height: `40px` }}
                >
                  <MenuItem defaultValue value="">
                    Select
                  </MenuItem>
                  {payloadRedux?.mask?.manufacturer === `resmed` ? (
                    resmedMaskTypes?.map((mask, ind) => <MenuItem value={mask} key={`${mask}${ind}`}>{mask}</MenuItem>)
                  ) : payloadRedux?.mask?.manufacturer === `respironics` ? (
                    respironicsMaskTypes?.map((mask, ind) => <MenuItem value={mask} key={`${mask}${ind}`}>{mask}</MenuItem>)
                  ) : payloadRedux?.mask?.manufacturer === `Fisher & Paykel` ? (
                    fisherMaskTypes?.map((mask, ind) => <MenuItem value={mask} key={`${mask}${ind}`}>{mask}</MenuItem>)
                  ) : null}
                </Select>
              </FormControl>
              <div className="small text-danger">{errors.maskSizeError ? errors.maskSizeError : ``}</div>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <FormControl size='small' fullWidth>
                <InputLabel id='patientStatus'>
                  Status
                </InputLabel>
                <Select
                  label='Status'
                  id="patientStatus"
                  error={errors?.statusError ? true : false}
                  onChange={(event) => {
                    onChangeforvalues(event, `status`);
                  }}
                  value={payloadRedux?.status}
                  name="status"
                  inputProps={{
                    ref: statusRef
                  }}
                >
                  <MenuItem defaultValue value="">
                    Select
                  </MenuItem>
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">Inactive</MenuItem>
                </Select>
              </FormControl>
              <p className="d-block text-danger">{errors?.statusError ? errors?.statusError : ``}</p>
            </Grid>
          </Grid>
        </Box>
        <div className="d-flex flex-wrap pb-3">
          <div className="w-100"></div>
        </div>
        <div className="d-flex flex-wrap pb-3">
          <div className="w-100">
            <label htmlFor="note" id={css.customLabel}>
              Notes
            </label>
            <textarea
              rows="5"
              ref={notesRef}
              style={{ borderColor: `#dddddd`, width: `98%` }}
              value={payloadRedux?.notes}
              onChange={(event) => {
                onChangeforvalues(event, `notes`);
              }}
              name="notes"
            />
          </div>
          <p className="text-danger">{errors.notesError ? errors.notesError : ``}</p>
        </div>
      </div>

      {loading && <PuffSpinner />}
    </CustomizedDialogs>
  );
}




import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import css from './addNewPatient.module.css';
import { appDevices, emailRegex, isDme, phoneRegex, genderOPtions } from 'src/utils';
import momentOriginal from 'moment';
import {
  getRequest,
  postRequest,
  ADD_PATIENT_WITH_NO_DEVICE,
  ADD_PATIENT_WITHOUT_DEVICE,
  GET_ALL_MD_BY_PRACTICE_ID,
  GET_ALL_QHCP_BY_PRACTICE_ID
} from '../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../common/components/spinner/spinner';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import { newPatientAddedManully } from '../../../../../../redux/resmed/resmedActions';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { Box, Grid } from '@mui/material';
import Downshift from 'downshift';
import { DatePickerCustomize } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import { fisherAndPaykelGroupedOptions, resmedGroupedOptions, respironicsGroupedOptions } from '../importPatient/data';
import { FieldMask } from 'src/pages/common/components/maskOnFeild/fieldMask';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

let mdList = null;
let qhcpList = null;

export const AddNewPatient = ({ addNewPatientModal, setAddNewPatientModal }) => {
  const { user: { user } } = useSelector((state) => state)
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState(``);
  const [firstNameError, setFirstNameError] = useState({
    status: false,
    msg: ``,
  });
  const [lastName, setLastName] = useState(``);
  const [lastNameError, setLastNameError] = useState({
    status: false,
    msg: ``,
  });
  const [dateOfBirth, setDateOfBirth] = useState(null);
  const [dateOfBirthError, setDateOfBirthError] = useState({
    status: false,
    msg: ``,
  });
  const [manufacturer, setManufacturer] = useState(``);
  const [manufacturerError, setManufacturerError] = useState({
    status: false,
    msg: ``,
  });

  // fields in csae of patient without device
  const [primaryDignosisError, setPrimaryDignosisError] = useState({
    status: false,
    msg: ``,
  });

  const [gender, setGender] = useState(``);
  const [genderError, setGenderError] = useState({ status: false, msg: `` });
  const [phone, setPhone] = useState(``);
  const [phoneError, setPhoneError] = useState({ status: false, msg: `` });
  const [email, setEmail] = useState(``);
  const [emailError, setEmailError] = useState({ status: false, msg: `` });
  const [insuranceCompany, setInsuranceCompany] = useState(``);

  // Mask Hooks
  const [maskCompany, setMaskCompany] = useState(``);
  const [maskType, setMaskType] = useState(``);
  const [maskSize, setMaskSize] = useState(``);

  // 20200811001
  const [MDData, setMDData] = useState([]);
  const [QHCPData, setQHCPData] = useState([]);
  const [assignMD, setAssignMD] = useState(``);
  const [assignMDError, setAssignMDError] = useState({
    status: false,
    msg: ``,
  });
  const [assignQHCP, setAssignQHCP] = useState(``);
  const [assignQHCPError, setAssignQHCPError] = useState({
    status: false,
    msg: ``,
  });
  const [note, setNote] = useState(``);

  const [loading, setLoading] = useState(false);
  // GET DIAGNOSIS LIST
  // PRIMARY DIAGNOSIS LIST
  const [primaryDiagnosisQueryId, setPrimaryDiagnosisQueryId] = useState(``);
  const [secondaryDiagnosisQueryId, setSecondaryDiagnosisQueryId] = useState(``);
  // SECONDARY DIAGNOSIS LIST
  const [secondaryDiagnosisLoading, setSecondaryDiagnosisLoading] = useState(false);

  /// IMPLEMENTING PRIMARY & SECONDARY DIAGNOSIS COMBOBOX ///
  /// PRIMARY DIAGNOSIS
  const [primaryDiagnosisListItems, setPrimDiagnosisListItems] = useState([]);
  const [primaryDiagnosisQuery, setPrimaryDiagnosisQuery] = useState(``);
  const [isPrimaryOpenDropDown, setIsPrimaryOpenDropDown] = useState(false);
  const [primaryDiagnosisIsLoading, setPrimaryDiagnosisLoading] = useState(false);
  const inputPDiagnosis = useRef(null);
  /// SECONDARY DIAGNOSIS
  const [secDiagnosisListItems, setSecDiagnosisListItems] = useState([]);
  const [secondaryDiagnosisQuery, setSecondaryDiagnosisQuery] = useState(``);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);

  const [baseLineAHI, setBaseLineAHI] = useState(``);
  const [WIP, setWIP] = useState(``);
  const [brightreeID, setBrightreeID] = useState(``);
  const [salesOrderID, setSalesOrderID] = useState(``);
  const [daysInWIP, setDaysInWIP] = useState(``);
  const [followUpDate, setFollowUpDate] = useState(``);
  const [MFL, setMFL] = useState(``);


  //select package css styling
  const CustomStyle = {
    control: (provided) => ({
      ...provided,
      borderRadius: 0,
      // height: 10,
    }),
  };

  // PHONE MASK
  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setPhone(temp);
  };

  const comboboxPrimaryDiagnosisOnChange = (event) => {
    setPrimaryDiagnosisQuery(event.target.value);
    getNIHPrimaryDiagnosis(event.target.value);
  };

  const primaryDiagnosisDropdownValueOnChange = (selectedDiagnosis) => {
    setIsPrimaryOpenDropDown((pre) => !pre);
    setPrimaryDiagnosisQuery(selectedDiagnosis?.Description);
    setPrimaryDiagnosisQueryId(selectedDiagnosis?.Code);
  };

  const comboboxSecondaryDiagnosisOnChange = (event) => {
    setSecondaryDiagnosisQuery(event.target.value);
    getNIHSecondayDiagnosis(event.target.value);
  };

  const secondaryDiagnosisDropdownValueOnChange = (selectedDiagnosis) => {
    setIsOpenDropDown((pre) => !pre);
    setSecondaryDiagnosisQuery(selectedDiagnosis?.Description);
    setSecondaryDiagnosisQueryId(selectedDiagnosis?.Code);
  };

  /// GET LIST OF PRIMARY DIAGNOSIS
  const getNIHPrimaryDiagnosis = (query) => {
    setPrimaryDiagnosisLoading(true);
    getRequest(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${query}`).then((response) => {
      setPrimaryDiagnosisLoading(false);
      // setSecondaryDiagnosisQuery("")
      const listOfDiagnosis = response?.data[3].map(([Code, Description]) => ({ Code, Description }));

      setPrimDiagnosisListItems(listOfDiagnosis);
      setIsPrimaryOpenDropDown(true);
    }).catch(() => setPrimaryDiagnosisLoading(false))

  };

  // GET LIST OF SECONDARY DIAGNOSIS
  const getNIHSecondayDiagnosis = (query) => {
    setSecondaryDiagnosisLoading(true);
    getRequest(`https://clinicaltables.nlm.nih.gov/api/icd10cm/v3/search?sf=code,name&terms=${query}`).then((response) => {
      setSecondaryDiagnosisLoading(false);

      const listOfDiagnosis = response?.data[3].map(([Code, Description]) => ({ Code, Description }));

      setSecDiagnosisListItems(listOfDiagnosis);
      setIsOpenDropDown(true);
    })
      .catch(() => {
        setSecondaryDiagnosisLoading(false);


      });

  };

  const filterOption = ({ label, value }, string) => {
    // default search
    if (label.includes(string) || value.includes(string)) return true;
    var selectedValuesForSort = [];

    if (maskCompany === `Resmed`) {
      selectedValuesForSort.push(resmedGroupedOptions);
    } else if (maskCompany === `Respironics`) {
      selectedValuesForSort.push(respironicsGroupedOptions);
    } else if (maskCompany === `Fisher & Paykel`) {
      selectedValuesForSort.push(fisherAndPaykelGroupedOptions);
    }

    // check if a group as the filter string as label
    const groupOptions = selectedValuesForSort[0].filter((group) => group.label.toLocaleLowerCase().includes(string));

    if (groupOptions) {
      for (const groupOption of groupOptions) {
        // Check if current option is in group
        const option = groupOption.options.find((opt) => opt.value === value);
        if (option) {
          return true;
        }
      }
    }
    return false;
  };

  // MD LIST
  mdList = MDData.map((md) => {
    return <option key={md._id} value={md._id}>{`${md.firstName} ${md.lastName}`}</option>;
  });

  // QHCP LIST
  qhcpList = QHCPData.map((qhcp) => {
    return <option key={qhcp._id} value={qhcp._id}>{`${qhcp.firstName} ${qhcp.lastName}`}</option>;
  });

  useEffect(() => {
    if (!MDData.length) {
      getRequest(GET_ALL_MD_BY_PRACTICE_ID + user._practiceId)
        .then((res) => {
          //
          setMDData(res.data.MD);
        })
        .catch((e) => {
          process.env.NODE_ENV === `development` && console.log(e)
          // errorToast(`Unable to Fetch Data`);
        });
    }
    if (!QHCPData.length) {
      getRequest(GET_ALL_QHCP_BY_PRACTICE_ID + user._practiceId)
        .then((res) => {
          //
          setQHCPData(res.data.QHCPUsers);
        })
        .catch((e) => {
          process.env.NODE_ENV === `development` && console.log(e)
          // errorToast(`Unable to Fetch Data`);
        });
    }
  }, [!MDData.length]);

  // VALIDATION HANDLER
  const validateInput = async (field) => {
    let temp = { status: false, msg: `` };
    switch (field) {
      case `firstName`:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
        } else {
          setFirstNameError(temp);
        }
        break;
      case `lastName`:
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
        } else {
          setLastNameError(temp);
        }
        break;
      case `dateOfBirth`:
        if (!dateOfBirth) {
          temp.status = true;
          temp.msg = `*Required`;
          setDateOfBirthError(temp);
        } else {
          setDateOfBirthError(temp);
        }
        break;
      case `gender`:
        if (!gender) {
          temp.status = true;
          temp.msg = `*Required`;
          setGenderError(temp);
        } else {
          setGenderError(temp);
        }
        break;
      case `phone`:
        if (!phone) {
          temp.status = true;
          temp.msg = `*Required`;
          setPhoneError(temp);
        } else if (!phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setPhoneError(temp);
        } else {
          setPhoneError(temp);
        }
        break;
      case `email`:
        if (email) {
          if (!emailRegex.test(email)) {
            temp.status = true;
            temp.msg = `Invalid Email Address`;
            setEmailError(temp);
          } else {
            setEmailError(temp);
          }
        } else {
          setEmailError(temp);
        }
        break;
      case `manufacturer`:
        if (!manufacturer.length) {
          temp.status = true;
          temp.msg = `*Required`;
          setManufacturerError(temp);
        } else {
          setManufacturerError(temp);
        }
        break;
      case `primaryDignosis`:
        if (!inputPDiagnosis.current.value) {
          temp.status = true;
          temp.msg = `*Required`;
          setPrimaryDignosisError(temp);
        } else {
          setPrimaryDignosisError(temp);
        }
        break;
      case `assignMD`:
        if (!assignMD) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignMDError(temp);
        } else {
          setAssignMDError(temp);
        }
        break;
      case `assignQHCP`:
        if (!assignQHCP) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignQHCPError(temp);
        } else {
          setAssignQHCPError(temp);
        }
        break;
      default:
        if (!firstName) {
          temp.status = true;
          temp.msg = `*Required`;
          setFirstNameError(temp);
          return false;
        }
        if (!lastName) {
          temp.status = true;
          temp.msg = `*Required`;
          setLastNameError(temp);
          return false;
        }
        if (!dateOfBirth) {
          temp.status = true;
          temp.msg = `*Required`;
          setDateOfBirthError(temp);
          return false;
        }
        // if (!manufacturer.length) {
        //   temp.status = true;
        //   temp.msg = `*Required`;
        //   setManufacturerError(temp);
        //   return false;
        // }
        if (isDme() && !gender) {
          temp.status = true;
          temp.msg = `*Required`;
          setGenderError(temp);
          return false;
        }
        if (isDme() && !phone) {
          temp.status = true;
          temp.msg = `*Required`;
          setPhoneError(temp);
          return false;
        } else if (isDme() && !phoneRegex.test(phone)) {
          temp.status = true;
          temp.msg = `Invalid Phone No.`;
          setPhoneError(temp);
          return false;
        }
        if (isDme() && email) {
          if (!emailRegex.test(email)) {
            temp.status = true;
            temp.msg = `Invalid Email Address`;
            setEmailError(temp);
            return false;
          }
        }
        if (isDme() && !primaryDiagnosisQuery) {
          temp.status = true;
          temp.msg = `*Required`;
          setPrimaryDignosisError(temp);
          return false;
        }
        if (isDme() && !assignMD) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignMDError(temp);
          return false;
        }
        if (isDme() && !assignQHCP) {
          temp.status = true;
          temp.msg = `*Required`;
          setAssignQHCPError(temp);
          return false;
        }
        return true;
    }
  };

  const resetForm = () => {
    setFirstName(``);
    setFirstNameError({ status: false, msg: `` });
    setLastName(``);
    setLastNameError({ status: false, msg: `` });
    setDateOfBirth(null);
    setDateOfBirthError({ status: false, msg: `` });
    setManufacturer(``);
    setManufacturerError({ status: false, msg: `` });

    setPrimaryDignosisError({
      status: false,
      msg: ``,
    });
    setGender(``);
    setGenderError({ status: false, msg: `` });
    setPhone(``);
    setPhoneError({ status: false, msg: `` });
    setEmail(``);
    setEmailError({ status: false, msg: `` });
    setInsuranceCompany(``);
    setMaskCompany(``);
    setMaskType(``);
    setMaskSize(``);

    setMDData([]);
    setQHCPData([]);
    setAssignMD(``);
    setAssignMDError({
      status: false,
      msg: ``,
    });
    setAssignQHCP(``);
    setAssignQHCPError({
      status: false,
      msg: ``,
    });
    setNote(``);

    setPrimaryDiagnosisQueryId(``);
    setSecondaryDiagnosisQueryId(``);
    setSecondaryDiagnosisLoading(false);

    setPrimDiagnosisListItems([]);
    setPrimaryDiagnosisQuery(``);
    setIsPrimaryOpenDropDown(false);
    setPrimaryDiagnosisLoading(false);
    // inputPDiagnosis?.current?.value = ``;
    setSecDiagnosisListItems([]);
    setSecondaryDiagnosisQuery(``);
    setIsOpenDropDown(false);

    setBaseLineAHI(``);
    setWIP(``);
    setBrightreeID(``);
    setSalesOrderID(``);
    setDaysInWIP(``);
    setFollowUpDate(``);
    setMFL(``);
  };

  const submitHandler = async () => {
    try {
      const temp = await validateInput();
      if (temp) {
        let dob = momentOriginal(dateOfBirth).format(`YYYY-MM-DD`);
        setLoading(true);
        if (isDme()) {
          const splitingDaysInWIP = daysInWIP.split(`_`)
          let daysInWIPResult = Number(splitingDaysInWIP[0])
          postRequest(ADD_PATIENT_WITHOUT_DEVICE, {
            _practiceGroupId: user._practiceroupId,
            firstName,
            lastName,
            dateOfBirth: dob,
            gender,
            phoneNumber: phone,
            email,
            primaryDiagnosis: primaryDiagnosisQuery,
            primaryDiagnosisId: primaryDiagnosisQueryId,
            secondaryDiagnosis: secondaryDiagnosisQuery,
            secondaryDiagnosisId: secondaryDiagnosisQueryId,
            device: {
              manufacturer: manufacturer,
              type: ``,
              serialNumber: ``,
            },
            baseLineAHI,
            // MRN,
            WIP,
            brightreeID,
            salesOrderID,
            daysInWIP: daysInWIPResult,
            followUpDate,
            MFL,
            mask: {
              manufacturer: maskCompany,
              type: maskType,
              size: maskSize,
            },
            insuranceCompany,
            _providerId: assignMD,
            _clinicalUserId: assignQHCP,
            notes: note,
          })
            .then(() => {
              setLoading(false);
              dispatch(newPatientAddedManully());
              successToast(`New Patient Added !`);
              resetForm();
            })
            .catch((err) => {
              if (err.response) {
                if (err.response.status === 404) errorToast(`Patient not found.`);
                else if (err.response.status === 400) errorToast(`Bad request please verify your fields`);
                else if (err.response.status === 401) errorToast(`You are not authorized to import this patient.`);
                else if (err.response.status === 403) errorToast(`You are not authorized to import this patient.`);
                else if (err.response.status === 409) errorToast(`Patient already exist.`)
                else if (err.response.status === 429) errorToast(`Resmed server is busy, try again later`);
                else if (err.response.status === 500) errorToast(`Internal server error, Resmed is down`);
                else if (err.response.status === 502) errorToast(`Resmed server is down`);
              } else errorToast(`Network Error`);
              setLoading(false);
            });
        } else {
          postRequest(ADD_PATIENT_WITH_NO_DEVICE, {
            firstName,
            lastName,
            dateOfBirth: dob,
            // gender,
            // phoneNumber: phone,
            // email,
            // primaryDiagnosis: primaryDignosis,
            // secondaryDiagnosis: secondaryDignosis,
            deviceManufacturer: manufacturer,
            // deviceType,
            // maskType,
            // deviceSerialNumber: airViewNumber,
            // providerId: assignMD,
            // clinicalUserId: assignQHCP,
            // notes: note,
          })
            .then(() => {
              successToast(`Patient is being added. Please Wait!`);
              dispatch(newPatientAddedManully());
              resetForm();
              setLoading(false);
            })
            .catch((err) => {
              errorToast(err?.response?.data?.message ? err.response?.data?.message : err.response?.data?.error);
              setLoading(false);
            });
        }
      } else {
        errorToast(`Unable to send request`);
      }
    } catch (error) {
      errorToast(`Failed`);
    }
  };

  return (
    <div>
      <CustomizedDialogs
        title="ADD PATIENT AWAITING DEVICE SET UP"
        open={addNewPatientModal}
        setOpen={() => setAddNewPatientModal(false)}
        size="md"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <div className="modal-body" id={css.addNewUserModalBody}>
          <Grid container>
            <Grid item xs={9} sm={9} md={9} lg={9} xl={9} style={{ marginTop: `9px` }}>
              <p className={css.sectionHeadingText} >
                Basic Information
              </p>
            </Grid>

          </Grid>
          <hr style={{ margin: `0px` }} />
          <div className="d-flex flex-wrap pb-1">
            <Box sx={{ mt: 2, }}>
              <Grid container rowSpacing={2} columnSpacing={1}>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputField
                    size='small'
                    fullWidth={true}
                    error={firstNameError.status && !firstName ? true : false}
                    onBlur={() => validateInput(`firstName`)}
                    autoComplete="off"
                    onChange={(event) => {
                      setFirstName(event.target.value);
                      validateInput(`firstName`);
                    }}
                    value={firstName}
                    type="text"
                    label="First Name"
                  />
                  <p className={firstNameError.status && !firstName ? `d-block text-danger` : `d-none`}>{firstNameError.msg}</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <InputField
                    size='small'
                    fullWidth={true}
                    error={lastNameError.status && !lastName ? true : false}
                    onBlur={() => validateInput(`lastName`)}
                    autoComplete="off"
                    onChange={(event) => {
                      setLastName(event.target.value);
                      validateInput(`lastName`);
                    }}
                    value={lastName}
                    type="text"
                    label="Last Name"
                  />
                  <p className={lastNameError.status && !lastName ? `d-block text-danger` : `d-none`}>{lastNameError.msg}</p>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <div
                    className={`${dateOfBirthError.status && !dateOfBirth ? css.errorCustom : null}
                              ${css.datePickerCustomeStyle}`}
                  >
                    <DatePickerCustomize

                      dateInputValue={dateOfBirth}
                      setDateInputValue={setDateOfBirth}
                      pickerProps={{ size: `small`, label: `DOB*`, fullWidth: true }}
                    />
                  </div>
                  <p className={dateOfBirthError.status && !dateOfBirth ? `d-block text-danger` : `d-none`}>{dateOfBirthError.msg}</p>
                </Grid>
                {!isDme() && <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                  <MuiSelect
                    controlProps={{
                      size: `small`,
                      fullWidth: true,
                      id: `deviceManufacturerAddNew`
                    }}
                    label="Device Manufacturer*"
                    options={[...appDevices]}
                    optionKey={`name`}
                    optionValue={`value`}
                    selectProps={{
                      error: manufacturerError.status && !manufacturer ? true : false,
                      label: `Device Manufacturer*`,
                      value: manufacturer,
                      name: `manufacturer`,
                      onBlur: () => validateInput(`manufacturer`),
                      onChange: (event) => {
                        setManufacturer(event.target.value);
                        validateInput(`manufacturer`);
                      },
                    }}

                  />
                  <p className={manufacturerError.status && !manufacturer ? `d-block text-danger` : `d-none`}>{manufacturerError.msg}</p>
                </Grid>}
              </Grid>
            </Box>


            {isDme() && (
              <>
                <div className="w-100 mt-2">
                  <div className="d-flex flex-wrap">
                    <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                      <MuiSelect
                        controlProps={{
                          size: `small`,
                          fullWidth: true,
                          id: `genderAddNewPatient`
                        }}
                        label="Gender*"
                        options={[...genderOPtions]}
                        isSimple={true}
                        selectProps={{
                          error: genderError.status && !gender ? true : false,
                          id: `genderAddNewPatient`,
                          label: `Gender*`,
                          value: gender,
                          name: `gender`,
                          onChange: (event) => {
                            setGender(event.target.value);
                            validateInput(`gender`);
                          },
                        }}

                      />
                      <p className={genderError.status && !gender ? `d-block text-danger` : `d-none`}>{genderError.msg}</p>
                    </div>
                    <div className={css.widthCustom}>
                      <label htmlFor="phone" id={css.customLabel}>
                        Phone
                      </label>
                      <InputField
                        size='small'
                        fullWidth={true}
                        error={phoneError.status ? true : false}
                        onBlur={() => validateInput(`phone`)}
                        autoComplete="off"
                        onChange={(event) => {
                          phoneMask(event.target.value);
                          validateInput(`phone`);
                        }}
                        value={phone}
                        type="text"

                        label="Phone Number*"
                      />
                      <p className={phoneError.status ? `d-block text-danger` : `d-none`}>{phoneError.msg}</p>
                    </div>
                    <div className={css.widthCustom}>
                      <label htmlFor="email" id={css.customLabel}>
                        Email
                      </label>
                      <InputField
                        size='small'
                        fullWidth={true}
                        error={emailError.status ? true : false}
                        onBlur={() => validateInput(`email`)}
                        autoComplete="off"
                        onChange={(event) => {
                          setEmail(event.target.value);
                          validateInput(`email`);
                        }}
                        value={email}
                        type="email"

                        label="Email"
                      />
                      <p className={emailError.status ? `d-block text-danger` : `d-none`}>{emailError.msg}</p>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-wrap pb-3">
                  <div className="w-100 mt-2">
                    <div className="d-flex flex-wrap">
                      <div style={{ marginLeft: 0 }}>
                        <label htmlFor="insuranceCompany" id={css.customLabel}>
                          Insurance Company
                        </label>
                        <InputField
                          size='small'
                          fullWidth={true}
                          autoComplete="off"
                          onChange={(event) => {
                            setInsuranceCompany(event.target.value);
                          }}
                          value={insuranceCompany}
                          type="text"
                          label="Insurance Company"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-100 mt-2">
                  <p className={css.sectionHeadingText} style={{ marginTop: `20px` }}>
                    Clinical Information
                  </p>
                  <hr style={{ margin: `2px` }} />
                  <div className="d-flex flex-wrap pb-3">
                    <div className="w-100 mt-1">
                      <div className="d-flex flex-wrap">
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px`, zIndex: 1 }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            Primary Diagnosis search*
                          </label>
                          <Downshift
                            onChange={(e) => {
                              primaryDiagnosisDropdownValueOnChange(e);
                            }}
                            itemToString={(item) => (item ? item.Description : ``)}
                            onBlur={() => {
                              setIsPrimaryOpenDropDown((p) => !p);
                              validateInput(`primaryDiagnosis`);
                            }}
                            inputValue={primaryDiagnosisQuery}
                          >
                            {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                              <div>
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    ref={inputPDiagnosis}
                                    name="primaryDiagnosis"
                                    placeholder="Diagnosis search..."
                                    {...getInputProps({
                                      onChange: (event) => {
                                        comboboxPrimaryDiagnosisOnChange(event);
                                        validateInput(`primaryDiagnosis`);
                                      },
                                    })}
                                    onBlur={() => {
                                      setIsPrimaryOpenDropDown(false);
                                    }}
                                    className={`${primaryDiagnosisIsLoading ? css.loading : null} ${primaryDignosisError.status && !primaryDiagnosisQuery ? css.errorCustom : null
                                      }`}
                                    style={{ width: `95%`, padding: `2%`, marginRight: `1px` }}
                                    aria-label="diagnosis"
                                    aria-describedby="basic-addon2"
                                  />
                                  {/* <div class="input-group-append">
                                          <button class="btn btn-outline-light" type="button" disabled={primaryDiagnosisIsLoading} onClick={getPrimaryDiagnosis} style={{borderColor : "rgb(221 221 221)", borderRadius : '0px'}}><img src="https://img.icons8.com/officexs/16/000000/search.png"/></button>
                                        </div> */}
                                  <p className={primaryDignosisError.status && !primaryDiagnosisQuery ? `d-block text-danger` : `d-none`}>
                                    {primaryDignosisError.msg}
                                  </p>
                                </div>

                                {isPrimaryOpenDropDown ? (
                                  <div
                                    id={css.downshiftDropdown}
                                    style={{
                                      overflowY: `scroll`,
                                      maxHeight: `140px`,
                                    }}
                                  >
                                    {primaryDiagnosisListItems?.map((item, index) => (
                                      <div
                                        id={css.dropdownItem}
                                        {...getItemProps({ key: index, index, item })}
                                        style={{
                                          backgroundColor: highlightedIndex === index ? `lightgray` : `rgb(251 251 251)`,
                                          fontWeight: selectedItem === item ? `bold` : `normal`,
                                          padding: `1px`,
                                          borderBottom: `1px solid lightgray`,
                                          cursor: `pointer`,
                                        }}
                                      >
                                        {item.Description}
                                      </div>
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Downshift>
                        </div>
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px`, zIndex: 1 }}>
                          <label htmlFor="secondarydiagnosis" id={css.customLabel}>
                            Secondary Diagnosis search
                          </label>
                          <Downshift
                            onChange={(e) => secondaryDiagnosisDropdownValueOnChange(e)}
                            itemToString={(item) => (item ? item.Description : ``)}
                            onBlur={() => setIsOpenDropDown((p) => !p)}
                            inputValue={secondaryDiagnosisQuery}
                          >
                            {({ selectedItem, getInputProps, getItemProps, highlightedIndex }) => (
                              <div>
                                <div className="input-group mb-3">
                                  <input
                                    type="text"
                                    placeholder="Diagnosis search..."
                                    {...getInputProps({ onChange: (event) => comboboxSecondaryDiagnosisOnChange(event) })}
                                    onBlur={() => setIsOpenDropDown(false)}
                                    className={`${secondaryDiagnosisLoading ? css.loading : null}`}
                                    style={{ width: `95%`, padding: `2%`, marginRight: `1px` }}
                                    aria-label="diagnosis"
                                    aria-describedby="basic-addon2"
                                  />
                                  {/* <div class="input-group-append">
                                          <button class="btn btn-outline-light" type="button" disabled={secondaryDiagnosisLoading} onClick={getSecondayDiagnosis} style={{borderColor : "rgb(221 221 221)", borderRadius : '0px'}}><img src="https://img.icons8.com/officexs/16/000000/search.png"/></button>
                                        </div> */}
                                </div>

                                {isOpenDropDown ? (
                                  <div
                                    id={css.downshiftDropdown}
                                    style={{
                                      overflowY: `scroll`,
                                      maxHeight: `140px`,
                                    }}
                                  >
                                    {secDiagnosisListItems?.map((item, index) => (
                                      <div
                                        id={css.dropdownItem}
                                        {...getItemProps({ key: index, index, item })}
                                        style={{
                                          backgroundColor: highlightedIndex === index ? `lightgray` : `rgb(251 251 251)`,
                                          fontWeight: selectedItem === item ? `bold` : `normal`,
                                          padding: `1px`,
                                          borderBottom: `1px solid lightgray`,
                                          cursor: `pointer`,
                                        }}
                                      >
                                        {item.Description}
                                      </div>
                                    ))}
                                  </div>
                                ) : null}
                              </div>
                            )}
                          </Downshift>
                        </div>
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px` }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            Baseline AHI
                          </label>
                          <input
                            autoComplete="off"
                            value={baseLineAHI}
                            type="text"
                            style={{ width: `95%`, padding: `2%` }}
                            name="baselineAHI"
                            placeholder="Baseline AHI"
                            onChange={(e) => setBaseLineAHI(e.target.value)}
                          />
                        </div>
                        {/* <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: '78px' }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            MRN
                          </label>
                          <input
                            autoComplete="off"
                            value={MRN}
                            type="text"
                            style={{ width: '95%', padding: '2%' }}
                            name="MRN"
                            placeholder="Medical Record Number"
                            onChange={(e) => setMRN(e.target.value)}
                          />
                        </div> */}
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px` }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            WIP Status
                          </label>
                          <input
                            autoComplete="off"
                            value={WIP}
                            type="text"
                            style={{ width: `95%`, padding: `2%` }}
                            name="WIP Status"
                            placeholder="WIP Status"
                            onChange={(e) => setWIP(e.target.value)}
                          />
                        </div>
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px` }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            Brightree ID
                          </label>
                          <input
                            autoComplete="off"
                            value={brightreeID}
                            type="text"
                            style={{ width: `95%`, padding: `2%` }}
                            name="brightreeID"
                            placeholder="Brightree ID"
                            onChange={(e) => setBrightreeID(e.target.value)}
                          />
                        </div>
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px` }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            Sales Order ID
                          </label>
                          <input
                            autoComplete="off"
                            value={salesOrderID}
                            type="text"
                            style={{ width: `95%`, padding: `2%` }}
                            name="salesOrderID"
                            placeholder="Sales Order ID"
                            onChange={(e) => setSalesOrderID(e.target.value)}
                          />
                        </div>
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px` }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            Days in WIP
                          </label>
                          <FieldMask
                            maskProps={{
                              value: daysInWIP,
                              onChange: (e) => setDaysInWIP(e.target.value),
                              mask: `999`,
                            }}
                            child={
                              <input placeholder="Days in WIP"
                                style={{ width: `95%`, padding: `2%` }}
                              />
                            }
                          />
                        </div>
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px` }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            WIP Follow Up Date
                          </label>
                          <input
                            autoComplete="off"
                            value={followUpDate}
                            type="date"
                            style={{ width: `95%`, padding: `2%` }}
                            name="followUpDate"
                            placeholder="WIP Follow Up Date"
                            onChange={(e) => setFollowUpDate(e.target.value)}
                          />
                        </div>
                        <div className={css.comboboxWidth} style={{ marginLeft: 0, maxHeight: `78px` }}>
                          <label htmlFor="diagnosis" id={css.customLabel}>
                            MFL (Preferred Equipment)
                          </label>
                          <input
                            autoComplete="off"
                            value={MFL}
                            type="text"
                            style={{ width: `95%`, padding: `2%` }}
                            name="MFL"
                            placeholder="MFL"
                            onChange={(e) => setMFL(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="w-100 mt-2">
                  <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                    Equipment Information
                  </p>
                  <hr style={{ margin: `0px` }} />
                  <div className="d-flex flex-wrap pb-3">
                    <div className="w-100 mt-1">
                      <div className="d-flex flex-wrap">
                        <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                          <label htmlFor="deviceManufacturer" id={css.customLabel}>
                            Device Manufacturer
                          </label>
                          <select
                            style={{ padding: `3.7% 0%`, backgroundColor: `#fafafa` }}
                            id={manufacturer ? css.selectedSelector : css.selector}
                            value={manufacturer}
                            name="deviceManufacturer"
                            onBlur={() => validateInput(`manufacturer`)}
                            onChange={(event) => {
                              setManufacturer(event.target.value);
                              validateInput(`manufacturer`);
                            }}
                          >
                            <option defaultValue value="">
                              Select
                            </option>
                            <option value="resmed">Resmed</option>
                            <option value="respironics">Respironics</option>
                          </select>
                          <p className={manufacturerError.status && !manufacturer ? `d-block text-danger` : `d-none`}>{manufacturerError.msg}</p>
                        </div>
                        <div className={css.widthCustom}>
                          <label htmlFor="deviceNumber" id={css.customLabel}>
                            Device Number
                          </label>
                          <input
                            autoComplete="off"
                            disabled
                            type="text"
                            style={{ width: '100%', padding: '2%' }}
                            placeholder="Device Number"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <div className="d-flex flex-wrap">
                        <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                          <label htmlFor="maskCompany" id={css.customLabel}>
                            Mask Company
                          </label>
                          <select
                            style={{ marginLeft: 0, height: 40 }}
                            id={maskCompany ? css.selectedSelector : css.selector}
                            onChange={(event) => setMaskCompany(event.target.value)}
                            value={maskCompany}
                            name="maskCompany"
                          >
                            <option defaultValue value="">
                              Select
                            </option>
                            <option value="Resmed">Resmed</option>
                            <option value="Respironics">Respironics</option>
                            <option value="Fisher & Paykel">Fisher & Paykel</option>
                          </select>
                        </div>
                        {maskCompany ? (
                          <div className={css.widthCustom}>
                            <label htmlFor="maskType" id={css.customLabel}>
                              Mask Type
                            </label>
                            <Select
                               id={
                                  maskCompany ? css.selectedSelector : css.selector
                                }
                              onChange={(event) => setMaskType(event.value)}
                              styles={CustomStyle}
                              filterOption={filterOption}
                              options={
                                maskCompany === `Resmed`
                                  ? resmedGroupedOptions
                                  : maskCompany === `Respironics`
                                    ? respironicsGroupedOptions
                                    : maskCompany === `Fisher & Paykel`
                                      ? fisherAndPaykelGroupedOptions
                                      : null
                              }
                            />
                          </div>
                        ) : null}
                        {maskType ? (
                          <div className={css.widthCustom}>
                            <label htmlFor="maskCompany" id={css.customLabel}>
                              Mask Size
                            </label>
                            <select
                              style={{ marginLeft: 0, height: 40 }}
                              id={maskSize ? css.selectedSelector : css.selector}
                              onChange={(event) => setMaskSize(event.target.value)}
                              value={maskSize}
                              name="maskSize"
                            >
                              <option defaultValue value="">
                                Select
                              </option>
                              {maskCompany === `Resmed` ? (
                                <>
                                  <option value="Standard">Standard</option>
                                  <option value="Wide">Wide</option>
                                  <option value="X-Small">X-Small</option>
                                  <option value="Small">Small</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Large">Large</option>
                                  <option value="Small Wide">Small Wide</option>
                                </>
                              ) : maskCompany === `Respironics` ? (
                                <>
                                  <option value="Petite">Petite</option>
                                  <option value="X-Small">X-Small</option>
                                  <option value="Small">Small</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Large">Large</option>
                                  <option value="Medium Wide">Medium Wide</option>
                                  <option value="Wide">Wide</option>
                                </>
                              ) : maskCompany === `Fisher & Paykel` ? (
                                <>
                                  <option value="XS/S">XS/S</option>
                                  <option value="M/L">M/L</option>
                                  <option value="Small">Small</option>
                                  <option value="Medium">Medium</option>
                                  <option value="Large">Large</option>
                                </>
                              ) : null}
                            </select>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div> */}
                <div className="w-100 mt-2">
                  <p className={css.sectionHeadingText} style={{ marginTop: `16px` }}>
                    Assign Care Team
                  </p>
                  <hr style={{ margin: `0px` }} />
                  <div className="d-flex flex-wrap pb-3">
                    <div className="w-100">
                      <div className="d-flex flex-wrap">
                        <div className={css.widthCustom} style={{ marginLeft: 0 }}>
                          <label
                            htmlFor="assignMD"
                            style={{
                              fontSize: `xx-small`,
                              color: `black`,
                              fontWeight: `bold`,
                            }}
                          >
                            Provider*
                          </label>
                          <select
                            className={assignMDError.status && !assignMD ? css.errorCustom : null}
                            onBlur={() => validateInput(`assignMD`)}
                            style={{ padding: `3.7% 0%`, textTransform: `capitalize` }}
                            id={assignMD ? css.selectedSelector : css.selector}
                            onChange={(event) => {
                              setAssignMD(event.target.value);
                              validateInput(`assignMD`);
                            }}
                            value={assignMD}
                            name="assignMD"
                          >
                            <option defaultValue value="">
                              Select
                            </option>
                            {mdList}
                          </select>
                          <p className={assignMDError.status && !assignMD ? `d-block text-danger` : `d-none`}>{assignMDError.msg}</p>
                        </div>
                        <div className={css.widthCustom}>
                          <label
                            htmlFor="assignQHCP"
                            style={{
                              fontSize: `xx-small`,
                              color: `black`,
                              fontWeight: `bold`,
                            }}
                          >
                            Clinical Staff*
                          </label>
                          <select
                            className={assignQHCPError.status && !assignQHCP ? css.errorCustom : null}
                            onBlur={() => validateInput(`assignQHCP`)}
                            style={{ padding: `3.7% 0%`, textTransform: `capitalize` }}
                            id={assignQHCP ? css.selectedSelector : css.selector}
                            onChange={(event) => {
                              setAssignQHCP(event.target.value);
                              validateInput(`assignQHCP`);
                            }}
                            value={assignQHCP}
                            name="assignQHCP"
                          >
                            <option defaultValue value="">
                              Select
                            </option>
                            {qhcpList}
                          </select>
                          <p className={assignQHCPError.status && !assignQHCP ? `d-block text-danger` : `d-none`}>{assignQHCPError.msg}</p>
                        </div>
                      </div>
                    </div>
                    <div className="w-100">
                      <label htmlFor="note" id={css.customLabel}>
                        Notes
                      </label>
                      <textarea
                        rows="5"
                        style={{ borderColor: `#dddddd`, width: `98%` }}
                        value={note}
                        onChange={(event) => setNote(event.target.value)}
                        name="note"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <hr style={{ marginTop: 17 }} />
        <div className="d-flex justify-content-center">
          <button type="button" className="btn btn-outline-info" id={css.addNewUserModalButton} onClick={submitHandler}>
            ADD PATIENT
          </button>
        </div>

        {loading && <PulseSpinner />}
      </CustomizedDialogs>
    </div>
  );
}


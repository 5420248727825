import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import css from './avator.module.css';
import Skeleton from '@mui/material/Skeleton';
import { GET_USER_DETAILS, getRequest, GET_USER_BY_ID } from '../../../../../../crud/crud';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { EditUser } from './editUser/editUser';
import { useSelector } from 'react-redux';
import { Fade, Grow, Zoom } from '@mui/material';
import { Device } from 'twilio-client';
import { endAudioCall } from 'src/redux/timer/timerActions';
import { SwitchPractice } from 'src/pages/home/modules/administration/user/components/SwitchPractice/SwitchPractice';
import { Setting } from 'src/pages/home/modules/administration/user/components/settings/setting';
import { BootstrapSpinner } from '../../../Bootstrapspinner/BootstrapSpinner';
import { doLogOut, timeLogAddOnSwitchPatientAndSorting } from 'src/utils';
import { useConfirm } from 'src/pages/common/hooks/useConfirm';
import { useRef } from 'react';
import { SERVER_ADDRESS } from 'src/crud/axiosClient';

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & {
//     children: React.ReactElement,
//   },
//   ref: React.Ref
// ) {
//   return <Zoom ref={ref} {...props} />;
// });

export const AvatorDropDown = ({ setGroupLogoPath }) => {
  const { user: parentUser, timer, patient } = useSelector((state) => state);
  const { user, auth } = parentUser;
  const { audioCall } = timer;
  const { timer: patientTimer } = patient;
  const { enableSwitch, systemRoles } = user;
  const [isLoggingOut, setLogout] = useState(false);
  const [editUserModalFlag, setEditUserModalFlag] = useState(false);
  const [settingModalFlag, setSettingModalFlag] = useState(false);
  // const [resetPasswordModalFlag, setResetPasswordModalFlag] = useState(false);
  const [userData, setUserData] = useState(``);
  const [isOpen, setisOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listOfSignatures, setListOfSignatures] = useState([]);
  const [hasEdited, sethasEdited] = useState(false);
  const dispatchRedux = useDispatch();
  const history = useHistory();
  const logOutModal = useSelector((state) => state.timer.logout);
  const [openSwitchPractice, setOpenSwitchPractice] = useState(false);
  const ref = useRef(null);
  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setisOpen(false);
    }
  };
  const renderCount = useRef(0);

  useEffect(() => {
    if (renderCount.current === 0 || hasEdited === `edited`) {
      renderCount.current = renderCount.current + 1;

      // checking the role of a user
      let isSysOrGrp = user?.isSystemAdministrator || user?.isGroupAdmin ? true : false;

      // set route according to user role
      let route = isSysOrGrp ? `${GET_USER_BY_ID}${user?._id}` : GET_USER_DETAILS + user._id + `/` + user?._practiceId;

      // set loading true before api call
      setIsLoading(true);
      setGroupLogoPath(`loading`, true);
      setGroupLogoPath(`error`, false);
      // API call to get user data
      getRequest(route)
        .then((res) => {
          let defObj = { user: { signatures: ``, _practiceGroupId: { practiceGroupLogoPath: `` } } };
          const { data: { user } = { ...defObj } } = res;
          const { signatures = ``, _practiceGroupId = { practiceGroupLogoPath: `` } } = user;
          const { practiceGroupLogoPath } = _practiceGroupId;
          setGroupLogoPath(`loading`, false);
          setUserData(user);
          setListOfSignatures(signatures);
          sethasEdited(false);
          setIsLoading(false);
          setGroupLogoPath(`src`, practiceGroupLogoPath);
        })
        .catch((err) => {
          setGroupLogoPath(`loading`, false);
          setGroupLogoPath(`error`, true);
          setIsLoading(false);
        });
    }

    document.addEventListener(`click`, handleClickOutside, true);
    return () => {
      document.removeEventListener(`click`, handleClickOutside, true);
      setisOpen(false);
      setOpenSwitchPractice(false);
    };
  }, [user, hasEdited]);

  let confirmationForTimerOrCall = useConfirm({
    open: (audioCall && logOutModal) || (patientTimer?.toggleButton && logOutModal) ? true : false,
    successFunc: () => {
      dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
      if (patientTimer?.toggleButton) {
        timeLogAddOnSwitchPatientAndSorting();
      }
      if (audioCall && logOutModal) {
        Device.disconnectAll();
        dispatchRedux(endAudioCall);
      }

      if (newSocket) {
        newSocket.close();
      }
      doLogOut(auth, setLogout, history);
      setLogout(true);
    },
    cancelFunc: () => {
      dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: false });
    },
    successText:
      audioCall && logOutModal ? `End call in progress and logout!` : patientTimer?.toggleButton && logOutModal && `Stop Timer and logout!`,
    cancelText: `Cancel`,
    title: audioCall && logOutModal ? `End call in progress?` : patientTimer?.toggleButton && logOutModal && `Stop Timer`,
    description:
      audioCall && logOutModal
        ? `In order to logout you must end call first !`
        : patientTimer?.toggleButton && logOutModal && `In order to logout you must stop patientTimer first !`,
  });

  // LOGOUT HANDLER
  const logOutHandler = () => {
    if (!audioCall && !patientTimer?.toggleButton) {
      if (newSocket) {
        newSocket.close(); //  CLOSING THE SOCKET, WHEN LOGOUT
      }
      // _resetRealDataFlag();
      doLogOut(auth, setLogout, history); // API CALL - IN CASE OF USER LOGOUT
    } else {
      dispatchRedux({ type: `HANDLE_LOGOUT_MODAL`, payLoad: true });
    }
  };

  const isOnVideo = useSelector((state) => state.patient.isOnVideo);

  return (
    <div ref={ref}>
      {confirmationForTimerOrCall}
      {openSwitchPractice && <SwitchPractice open={openSwitchPractice} setOpen={() => setOpenSwitchPractice(false)} />}

      {settingModalFlag && (
        <Setting
          open={settingModalFlag}
          setOpen={() => setSettingModalFlag(false)}
          setSettingModalFlag={setSettingModalFlag}
          sethasEdited={sethasEdited}
          userData={userData}
          hasEdited={hasEdited}
        />
      )}

      {editUserModalFlag && (
        <EditUser
          userData={userData}
          show={editUserModalFlag}
          setShow={setEditUserModalFlag}
          setUserData={setUserData}
          _listOfSignatures={listOfSignatures}
          sethasEdited={sethasEdited}
          hasEdited={hasEdited}
        />
      )}
      {/* {resetPasswordModalFlag && <ResetPassword show={resetPasswordModalFlag} setShow={setResetPasswordModalFlag} />} */}
      <button
        disabled={isOnVideo}
        id={css.avator}
        type="button"
        onClick={() => {
          setisOpen((p) => !p);
        }}
      >
        {isLoading ? (
          <Skeleton variant="circular" width={44} height={44} />
        ) : userData?.profilePhotoPath ? (
          <Fade timeout={500} in={true} mountOnEnter unmountOnExit>
            <img
              src={userData?.profilePhotoPath || null}
              alt={userData?.firstName ? userData?.firstName.charAt(0).toUpperCase() + userData?.lastName.charAt(0).toUpperCase() : ``}
              width="100%"
              height="100%"
            />
          </Fade>
        ) : (
          <p style={{ fontWeight: 600 }}>
            {userData?.firstName ? userData?.firstName.charAt(0).toUpperCase() + userData?.lastName.charAt(0).toUpperCase() : ``}
          </p>
        )}
      </button>
      <Grow style={{ transformOrigin: `0 0 0` }} {...(isOpen ? { timeout: 200 } : {})} mountOnEnter unmountOnExit in={isOpen}>
        <div className="mb-0 pb-0 pt-0" id={css.AvatorDropDown}>
          <span className={css.menuArrow}></span>
          <table className="table table-borderless table-hover mb-0">
            <tbody>
              <tr onClick={() => setisOpen(false)} style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                <td className="pl-4">
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <i className="fa fa-user-o" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  </Zoom>
                </td>

                <Fade timeout={700} in={isOpen}>
                  <td className="pr-4" style={{ textAlign: `end` }} onClick={() => setEditUserModalFlag(true)}>
                    Profile
                  </td>
                </Fade>
              </tr>

              <tr
                onClick={() => setisOpen(false)}
                style={{ borderBottom: `1px solid rgb(211, 209, 209)` }}
                id={css.row}
                className={`${user?.isSystemAdministrator || user?.isGroupAdmin ? `d-none` : ``}`}
              >
                <td className="pl-4">
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <i className="fa fa-cog" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  </Zoom>
                </td>
                <Fade timeout={700} in={isOpen}>
                  <td className="pr-4" style={{ textAlign: `end` }} onClick={() => setSettingModalFlag(true)}>
                    Settings
                  </td>
                </Fade>
              </tr>

              <tr
                onClick={() => setisOpen(false)}
                style={{ borderBottom: `1px solid rgb(211, 209, 209)` }}
                id={css.row}
                className={`${user?.isSystemAdministrator || user?.isGroupAdmin ? `d-none` : ``}`}
              >
                <td className="pl-4">
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <i className="fa fa-comments" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  </Zoom>
                </td>
                <Fade timeout={700} in={isOpen}>
                  <td
                    className={`pr-4 ${user?.isSystemAdministrator || user?.isGroupAdmin ? `d-none` : ``}`}
                    style={{ textAlign: `end` }}
                    onClick={() => history.push(`/smart/phrases`)}
                  >
                    Smart Phrase
                  </td>
                </Fade>
              </tr>

              {/* <tr onClick={() => setisOpen(false)} style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                <td className="pl-4">
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <i className="fa fa-unlock" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                  </Zoom>
                </td>

                <Fade timeout={700} in={isOpen}>
                  <td className="pr-4" style={{ textAlign: `end` }} onClick={() => setResetPasswordModalFlag(true)}>
                    Reset Password
                  </td>
                </Fade>
              </tr> */}
              {!user?.isSystemAdministrator && !user?.isGroupAdmin && enableSwitch && systemRoles?.length > 1 && (
                <tr style={{ borderBottom: `1px solid rgb(211, 209, 209)` }} id={css.row}>
                  <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                    <td className="pl-4">
                      <i style={{ color: `#1699C5` }} aria-hidden="true" className="bi bi-toggles2"></i>
                    </td>
                  </Zoom>
                  <Fade timeout={700} in={isOpen}>
                    <td onClick={() => setOpenSwitchPractice(true)} className="pr-4" style={{ textAlign: `end` }}>
                      Switch Practice
                    </td>
                  </Fade>
                </tr>
              )}
              <tr
                id={css.row}
                onClick={() => {
                  logOutHandler();
                }}
              >
                <Zoom in={isOpen} style={{ transitionDelay: isOpen ? `300ms` : `0ms` }}>
                  <td className="pl-4">
                    {isLoggingOut ? (
                      <BootstrapSpinner
                        spinnerProps={{
                          className: `spinner-border spinner-border-sm text-primary`,
                        }}
                      />
                    ) : (
                      <i className="fa fa-sign-out" style={{ color: `#1699C5` }} aria-hidden="true"></i>
                    )}
                  </td>
                </Zoom>
                <Fade timeout={700} in={isOpen}>
                  <td className="pr-4" style={{ textAlign: `end` }}>
                    Logout
                  </td>
                </Fade>
              </tr>
            </tbody>
          </table>
        </div>
      </Grow>
    </div>
  );
};

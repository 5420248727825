import { useCallback } from "react";




export const useBrowserConfirmation = ({
    condition
}) => {
    const specialHandler = useCallback((e) => {
        e.preventDefault();
        e.returnValue = ``;
    }, []);
    const addConfirmation = () => {
        if (condition) {
            window.addEventListener(`beforeunload`, specialHandler);
        } else {
            window.removeEventListener(`beforeunload`, specialHandler)
        }
    }
    return addConfirmation()
}
import React from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';

export const useConfirm = ({
    open,
    successFunc,
    cancelFunc,
    successText,
    cancelText,
    title,
    description,
}) => {
    return (
        <Dialog
            open={open}
            onClose={cancelFunc}
            aria-describedby="alert-dialog-slide-description"
        >
            {` `}
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">{description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{
                        backgroundColor: `red`,
                        textTransform: `none`,
                        color: `white`,
                        '&:hover': {
                            backgroundColor: `#bc2929`,
                        },
                    }}
                    onClick={() => successFunc()}
                >
                    {successText}
                </Button>
                <Button
                    sx={{
                        backgroundColor: `#03b403`,
                        color: `white`,
                        textTransform: `none`,
                        '&:hover': {
                            backgroundColor: `green`,
                        },
                    }}
                    onClick={() => cancelFunc()}
                >
                    {cancelText}
                </Button>
            </DialogActions>
        </Dialog>

    )

}
import React, { useState } from 'react';
import css from './adherenceWidget.module.css';
import { PuffSpinner } from '../../../../../common/components/spinner/puff/puff';
import { Link } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import $ from 'jquery';
import { useSelector } from 'react-redux';

$(function () {
  $(`[data-toggle="tooltip"]`).tooltip();
});

export const AdherenceWidget = ({ adherenceArr, isLast3days, }) => {
  const { user: { user } } = useSelector((state) => state)
  const { last3DaysStats, last30DaysStats } = adherenceArr || {}
  const [loading] = useState(false);
  return (
    <div id={css.main}>
      <div className="d-flex justify-content-between align-items-center ml-4 mr-4 mt-4">
        <h4 style={{ fontWeight: 400 }}>{`${isLast3days ? `3 Day Usage Rate` : `30 Day Adherence`}`}</h4>
        <Tooltip
          title={
            <>
              <i style={{ fontSize: `10px` }}>
                {`${isLast3days ? (`Shows percentage of nights`) : (`Last 30 day adherence`)}`}
                <br />
                {`${isLast3days ? (`with usage >= 4 hours`) : (`is defined as usage`)}`}
                <br />
                {`${isLast3days ? (`over the last 3 night`) : (`>= 4hrs/night on 21 days`)}`}
                <br />
                {`${isLast3days ? `` : (`during the last 30 days.`)}`}
              </i>
            </>
          }
          placement="top"
          arrow
        >
          <button type="button" data-toggle="tooltip" data-placement="top" title="">
            <i className="fa fa-info-circle" aria-hidden="true"></i>
          </button>
        </Tooltip>
      </div>

      {/* <div className="m-4" id={css.statsBar}></div> */}
      <div className="m-4">
        <div className="progress" style={{ height: `35px`, borderRadius: `0.35rem` }}>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${isLast3days ? `${last3DaysStats?.percentage0}` : last30DaysStats?.nonAdherentPercentage ? last30DaysStats?.nonAdherentPercentage : 0}%`, backgroundColor: `#eb2f06` }}
            aria-valuenow="15"
            aria-valuemin="0"
            aria-valuemax="100"
          >
          </div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${isLast3days ? `${last3DaysStats?.percentage33}` : last30DaysStats?.adherentPercentage ? last30DaysStats?.adherentPercentage : 0}%`, backgroundColor: isLast3days ? `rgb(167, 9, 9)` : `#17a2b8` }}
            aria-valuenow="30"
            aria-valuemin="0"
            aria-valuemax="100"
          >
          </div>
          <div
            className="progress-bar"
            role="progressbar"
            style={{ width: `${isLast3days ? `${last3DaysStats?.percentage67}` : last30DaysStats?.noDataPercentage ? last30DaysStats?.noDataPercentage : 0}%`, backgroundColor: isLast3days ? `rgb(34, 112, 147)` : `#95afc0` }}
            aria-valuenow="20"
            aria-valuemin="0"
            aria-valuemax="100"
          >
          </div>
          {isLast3days && <>
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${last3DaysStats?.percentage100}`, backgroundColor: `#17a2b8` }}
              aria-valuenow="20"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </>
          }
        </div>
      </div>

      <div className="table-responsive">
        <table className="table table-sm table-borderless table-striped">
          <tbody id={css.widgetTable}>
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#eb2f06` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                {user?.isTransportationAdmin ?
                  <Link to={`#`} style={{ cursor: `auto`, textDecoration: `none` }}>
                    {isLast3days ? `0%` : `Non-Adherent`}
                  </Link> :
                  <Link to={`/patient?${isLast3days ? `last3DaysAdherence` : `Adherence`}=${isLast3days ? `0` : `nonAdherent`}`}>{isLast3days ? `0%` : `Non-Adherent`}
                  </Link>
                }
              </td>
              <td style={{ fontSize: `small` }}>{isLast3days ? last3DaysStats?.count0 ? `${last3DaysStats?.count0}` : 0 : last30DaysStats ? `${last30DaysStats?.nonAdherentCount}` : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                {isLast3days ? last3DaysStats?.percentage0 ? `${last3DaysStats?.percentage0}%` : 0 : last30DaysStats ? `${last30DaysStats?.nonAdherentPercentage}%` : 0}
              </td>
            </tr>
            {isLast3days && <>
              <tr>
                <td style={{ paddingLeft: `3.5%` }}>
                  <i className="fa fa-circle" aria-hidden="true" style={{ color: `rgb(167, 9, 9)` }}></i>
                </td>
                <td style={{ textAlign: `start` }}>
                  {/* to={`/patient?Adherence=${isLast3days ? `33` : `nonAdherent`}`}
                {`/patient?Adherence=nonAdherent`} */}
                  {user?.isTransportationAdmin ?
                    <Link to={`#`} style={{ cursor: `auto`, textDecoration: `none` }}>
                      {`33%`}
                    </Link> :
                    <Link to={`/patient?${isLast3days ? `last3DaysAdherence` : `Adherence`}=${isLast3days ? `33` : `nonAdherent`}`}>
                      33%
                    </Link>
                  }
                </td>
                <td style={{ fontSize: `small` }}>{last3DaysStats?.count33 ? `${last3DaysStats?.count33}` : 0}</td>
                <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                  {last3DaysStats?.percentage33 ? `${last3DaysStats?.percentage33}%` : 0}
                </td>
              </tr>
              <tr>
                <td style={{ paddingLeft: `3.5%` }}>
                  <i className="fa fa-circle" aria-hidden="true" style={{ color: `rgb(34, 112, 147)` }}></i>
                </td>
                <td style={{ textAlign: `start` }}>
                  {user?.isTransportationAdmin ? (
                    <Link to={`#`} style={{ cursor: `auto`, textDecoration: `none` }}>
                      {`67%`}
                    </Link>
                  ) : (
                    <Link to={`/patient?${isLast3days ? `last3DaysAdherence` : `Adherence`}=${isLast3days ? `67` : `nonAdherent`}`}>
                      67%
                    </Link>
                  )}
                </td>
                <td style={{ fontSize: `small` }}>   {last3DaysStats?.count67 ? `${last3DaysStats?.count67}` : 0}</td>
                <td style={{ fontSize: `small`, paddingRight: `8%` }}>
                  {last3DaysStats?.percentage67 ? `${last3DaysStats?.percentage67}%` : 0}
                </td>
              </tr>
            </>}
            <tr>
              <td style={{ paddingLeft: `3.5%` }}>
                <i className="fa fa-circle" aria-hidden="true" style={{ color: `#17a2b8` }}></i>
              </td>
              <td style={{ textAlign: `start` }}>
                {user?.isTransportationAdmin ? (
                  <Link to={`#`} style={{ cursor: `auto`, textDecoration: `none` }}>
                    {isLast3days ? `100%` : `Adherent`}
                  </Link>
                ) : (
                  <Link to={`/patient?${isLast3days ? `last3DaysAdherence` : `Adherence`}=${isLast3days ? `100` : `adherent`}`}>{isLast3days ? `100%` : `Adherent`}
                  </Link>
                )}
              </td>
              <td style={{ fontSize: `small` }}>{isLast3days ? last3DaysStats?.count100 ? `${last3DaysStats?.count100}` : 0 : last30DaysStats?.adherentCount ? `${last30DaysStats?.adherentCount}` : 0}</td>
              <td style={{ fontSize: `small`, paddingRight: `8%` }}>{isLast3days ? last3DaysStats?.percentage100 ? `${last3DaysStats?.percentage100}%` : 0 : last30DaysStats?.adherentPercentage ? `${last30DaysStats?.adherentPercentage}%` : 0}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {loading ? <PuffSpinner /> : null}
    </div>
  );
}
import React from 'react'
import { UserRole } from './UserRole'
import { Grid } from '@mui/material';

export const MapUserRoles = ({
    systemRoles, uniqueString, _id, loadingHandler,
    error, isSwitch
}) => {

    return (
        <Grid container spacing={2} rowSpacing={2}>
            {systemRoles.map((role, index) => (
                <UserRole
                    role={{ uniqueString: uniqueString, userId: _id, ...role }}
                    index={index}
                    loadingHandler={loadingHandler}
                    error={error}
                    isSwitch={isSwitch}
                    systemRoles={systemRoles}
                />
            ))}
        </Grid>
    )
}


import {
  HANDLE_TIMER,
  LIST_ALL_PATIENTS_LOADING,
  LIST_ALL_PATIENTS,
  INITIALIZE_START_END_TIME,
  SELECTED_PATIENT,
  SELECTED_PATIENT_LOADING,
  SELECTED_PATIENT_ERROR,
  PATIENT_DATA_FLAG,
  SELECTED_PATIENT_REPORT,
  PATIENTS_FILTERS,
  PATIENT_DATA_EMPTY,
  LIST_ALL_PATIENTS_EMPTY,
  LIST_ALL_PATIENTS_ERROR,
  SET_VIDEO_CALL_STATUS,
  HANDLE_SELECTED_TAB,
  REPORT_LOADING,
  HIDE_TIMER,
  SET_MESSAGES,
  SET_MESSAGES_CURRENT_PAGE,
  SET_MESSAGES_HASMORE,
  SMSRESPONSE,
  TXT_ERROR,
  FIRST_RESPOSNE,
  SET_NOTES,
  SELECTED_PATIENT_UNUSE,
  SOCKET_RESPONSE,
  COMMUNICATION_PATIENT,
  ALL_TIME_LOGS,
  ALL_TIME_LOGS_LOADING,
  PATIENT_SWITCHING,
} from './patientTypes';
import { store } from 'src/redux/store';
import { momentWrapper } from 'src/momentWrapper';
import { successToast, errorToast } from 'src/pages/common/components/snackBar/toast';
import {
  PATIENT_ALL_TEXT_MESSAGES_URL,
  REPORT_NOTES,
  getRequest,
  GET_PATIENT_BY_ID_URL,
  postRequest,
  ADD_PATIENT_MONITORING_TIME_LOG_URL,
  LIST_ALL_PATIENTS_BILLER_ROLE_URL,
  GETTING_REPORT_STATUS,
  PATIENT_TIME_LOGS_URL,
  LIST_ALL_PATIENTS_URL,
} from 'src/crud/crud';

let updatePatientlogFlag = false;

export const handleTimer = (payload) => {
  return {
    type: HANDLE_TIMER,
    payload: payload,
    oldPatientState: store.getState()?.patient,
  };
};
export const initializeStartAndEndTime = (payload) => {
  return {
    type: INITIALIZE_START_END_TIME,
    payload: payload,
  };
};
export const emptyPatientsList = (payload) => {
  return {
    type: LIST_ALL_PATIENTS_EMPTY,
    payload: payload,
  };
};
export const patientsListLoading = (payload) => {
  return {
    type: LIST_ALL_PATIENTS_LOADING,
    payload: payload,
  };
};

export const updatePatientDataFlag = (payLoad) => {
  return {
    type: PATIENT_DATA_FLAG,
    payLoad: payLoad,
  };
};

export const timeLogsLoading = (payload) => {
  return {
    type: ALL_TIME_LOGS_LOADING,
    payload: payload,
  };
};
export const patientsListError = (payload) => {
  return {
    type: LIST_ALL_PATIENTS_ERROR,
    payload: payload,
  };
};
export const selectedPatientLoading = (payload) => {
  return {
    type: SELECTED_PATIENT_LOADING,
    payload: payload,
  };
};
export const selectedPatientError = (payload) => {
  return {
    type: SELECTED_PATIENT_ERROR,
    payload: payload,
  };
};
export const emptyPatientData = (payload) => {
  return {
    type: PATIENT_DATA_EMPTY,
    payload: payload,
  };
};

export const updateAllTimeLogsAfterAudioCall = (payload) => {
  const patientId = store.getState()?.patient?.patientData.data._id;
  const auth = store.getState()?.user?.auth;
  localStorage.setItem(`updatePatientlogFlag`, true);
  return (dispatch) => {
    let reportId = undefined;
    postRequest(auth, PATIENT_TIME_LOGS_URL + `/?reportId=${reportId}`, {
      reportId: undefined,
      startDate: momentWrapper().startOf(`month`).toISOString(),
      endDate: payload.endDate,
      patientId: patientId,
      updateLag: true,
    })
      .then((res) => {
        dispatch({
          type: ALL_TIME_LOGS,
          payload: res.data,
        });
        updatePatientlogFlag = true;
      })
      .catch(() => {});
  };
};
export const getAllTimeLogs = (payload) => {
  const { startDate, endDate, patientId, reportId } = payload;
  return (dispatch) => {
    if (!localStorage.getItem(`updatePatientlogFlag`)) {
      dispatch(timeLogsLoading({ type: ALL_TIME_LOGS_LOADING, payload: `` }));
      postRequest(PATIENT_TIME_LOGS_URL + `/?reportId=${reportId}`, {
        startDate: startDate ? startDate : momentWrapper().startOf(`month`).toISOString(),
        endDate: endDate ? endDate : momentWrapper().toISOString(),
        patientId,
      })
        .then((res) => {
          dispatch({
            type: ALL_TIME_LOGS,
            payload: res.data,
          });
        })
        .catch(() => {});
    } else {
      localStorage.removeItem(`updatePatientlogFlag`);
    }
  };
};

export const switchPatient = (payLoad) => {
  return {
    type: PATIENT_SWITCHING,
    payLoad: payLoad,
  };
};

export const saveTimerUser = (payload) => {
  return (dispatch) => {
    const { videoOrAudioOn, callSuccess = false, statuss, sources, callSelectedPatient, callSelectedPatientData } = payload;
    // Add loader on Patient Data when we save time in database
    dispatch(selectedPatientLoading({ type: SELECTED_PATIENT_LOADING, payload: `` }));
    // Add loader on Patient List when we save time in database
    dispatch(patientsListLoading({ type: LIST_ALL_PATIENTS_LOADING, payload: `` }));
    // ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${reportId}`
    let patientId = store.getState()?.patient?.patientData?.data?._id;
    try {
      postRequest(
        ADD_PATIENT_MONITORING_TIME_LOG_URL + `${patientId}` + `?reportId=${null}`,
        {
          startDate: store.getState()?.patient?.timer?.startTime, // User start time
          endDate: store.getState()?.patient?.timer?.endTime, // User end time
          startOfMonth: momentWrapper().startOf(`month`).toISOString(),
          now: momentWrapper().toISOString(),
          source: sources ? sources : store.getState()?.patient?.timer?.viewSource, // Source  i.e View Report, Audio Call, Video Call
          type: `Auto`, // Type: i.e Auto,Manual
          description: statuss ? statuss : store.getState()?.patient?.timer?.logDescription, // Status Description
          callSuccess: callSuccess,
        }
        //patient id in params
      ).then((res) => {
        let updatedPatientsList = [];
        let updatePatientData = {};
        if (callSuccess) {
          // Updating patient Data
          updatePatientData = {
            ...store.getState()?.patient?.patientData?.data,
            lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
            timeMonitored: res?.data?.timeLog?.accumulatedTime,
            interactiveCommunication: true,
          };

          // Updating patients List Data
          updatedPatientsList = store.getState()?.patient?.patientsList?.data?.patients?.map((patient) => {
            return patient?._id === store.getState()?.patient?.patientData?.data?._id
              ? {
                  ...patient,
                  lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
                  timeMonitored: res?.data?.timeLog?.accumulatedTime,
                  interactiveCommunication: true,
                }
              : patient;
          });
        } else {
          updatePatientData = {
            ...store.getState()?.patient?.patientData?.data,
            lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
            timeMonitored: res?.data?.timeLog?.accumulatedTime,
          };

          // updating patients lists info
          updatedPatientsList = store.getState()?.patient?.patientsList?.data?.patients?.map((patient) => {
            return patient?._id === res?.data?.timeLog?._patientId //patient id
              ? {
                  ...patient,
                  lastMonitoredAt: store.getState()?.patient?.timer?.endTime,
                  timeMonitored: res?.data?.timeLog?.accumulatedTime,
                }
              : patient;
          });
        }

        // updating patient Data on Redux SELECTED_PATIENT (Patient list)
        dispatch({
          type: SELECTED_PATIENT,
          payload: updatePatientData,
        });
        // updating patients lists on redux LIST_ALL_PATIENTS (all patients list)
        dispatch({
          type: LIST_ALL_PATIENTS,
          payload: {
            error: false,
            loading: false,
            success: true,
            pager: store.getState()?.patient?.patientsList?.data?.pager,
            patients: updatedPatientsList,
          },
        });
        if (callSelectedPatient) {
          dispatch(handleSelectedPatient({ patient: callSelectedPatientData }));
        }

        dispatch(initializeStartAndEndTime());

        if (videoOrAudioOn === `video`) {
          dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: `video` }));
        }

        if (videoOrAudioOn === `timerOn`) {
          dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: null, autoTimer: `false` }));
        }

        successToast(`Time Log Added.`);
        // return res;
      });
    } catch (err) {
      dispatch(
        selectedPatientError({
          type: SELECTED_PATIENT_ERROR,
          payload: err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error,
        })
      );
      if (err?.response?.status === 401) {
        errorToast(`Auth failed`);
      }
      if (err?.response?.status !== 401) {
        errorToast(`An error occured.`);
      }
      return null;
    }
  };
};
// updating time logs
export const updateTimeLog = (payload) => {
  return (dispatch) => {
    const patientData = store.getState()?.patient?.patientData;
    const patientsList = store.getState()?.patient?.patientsList;

    if (payload?.callSuccess) {
      // Patient Data update
      let copyInsideDataObject = patientData?.data;
      copyInsideDataObject[`timeMonitored`] = payload?.accumulatedTime;
      copyInsideDataObject[`interactiveCommunication`] = true;
      // updating patient Data
      dispatch({
        type: SELECTED_PATIENT,
        payload: copyInsideDataObject,
      });

      // Patient List

      let copyPatientsList = patientsList;
      let copyPatientsListDataObject = patientsList?.data;
      let patientsArray = copyPatientsList?.data?.patients;
      for (let i = 0; i < patientsArray?.length; i++) {
        if (payload?._patientId === patientsArray[i]?._id) {
          patientsArray[i].timeMonitored = payload?.accumulatedTime;
          patientsArray[i].interactiveCommunication = true;
        }
      }
      copyPatientsListDataObject[`patients`] = patientsArray;
      copyPatientsList[`data`] = copyPatientsListDataObject;
      // Dispatch here
      dispatch({
        type: LIST_ALL_PATIENTS,
        payload: copyPatientsListDataObject,
      });
    } else {
      // Patient Data update
      let copyInsideDataObject = patientData?.data;
      copyInsideDataObject[`timeMonitored`] = payload?.accumulatedTime;
      // updating patient Data
      dispatch({
        type: SELECTED_PATIENT,
        payload: copyInsideDataObject,
      });

      // Patient List

      let copyPatientsList = patientsList;
      let copyPatientsListDataObject = patientsList?.data;
      let patientsArray = copyPatientsList?.data?.patients;
      for (let i = 0; i < patientsArray?.length; i++) {
        if (payload?._patientId === patientsArray[i]?._id) {
          patientsArray[i].timeMonitored = payload?.accumulatedTime;
        }
      }
      copyPatientsListDataObject[`patients`] = patientsArray;
      copyPatientsList[`data`] = copyPatientsListDataObject;
      // Dispatch here
      dispatch({
        type: LIST_ALL_PATIENTS,
        payload: copyPatientsListDataObject,
      });
    }
  };
};
export const deletePatientTimeLog = (payload) => {
  return (dispatch) => {
    const patientsList = store.getState()?.patient?.patientsList;
    const patientData = store.getState()?.patient?.patientData;
    // Patient Data delete timelog
    let copyInsideDataObject = patientData?.data;

    copyInsideDataObject[`timeMonitored`] = copyInsideDataObject?.timeMonitored - payload?.duration;

    // updating patient Data
    dispatch({
      type: SELECTED_PATIENT,
      payload: copyInsideDataObject,
    });

    // Patient List delete time log

    let copyPatientsList = patientsList;
    let copyPatientsListDataObject = patientsList?.data;
    let patientsArray = copyPatientsList?.data?.patients;
    for (let i = 0; i < patientsArray?.length; i++) {
      if (payload?._patientId === patientsArray[i]?._id) {
        patientsArray[i].timeMonitored = patientsArray[i].timeMonitored - payload?.duration;
      }
    }
    copyPatientsListDataObject[`patients`] = patientsArray;
    copyPatientsList[`data`] = copyPatientsListDataObject;
    // Dispatch here
    dispatch({
      type: LIST_ALL_PATIENTS,
      payload: copyPatientsListDataObject,
    });
  };
};
export const updatePatientMask = (payload) => {
  return (dispatch) => {
    const patientData = store.getState()?.patient?.patientData;
    let copyInsideDataObject = patientData?.data;
    let copyInsideMaskObject = patientData?.data?.mask;
    if (patientData?.data?.mask) {
      copyInsideMaskObject[`type`] = payload?.mask?.type;
      copyInsideMaskObject[`size`] = payload?.mask?.size;
      copyInsideMaskObject[`manufacturer`] = payload?.mask?.manufacturer;
      copyInsideDataObject[`mask`] = copyInsideMaskObject;
    } else {
      copyInsideDataObject[`mask`] = payload?.mask;
    }
    // updating patient Data
    dispatch({
      type: SELECTED_PATIENT,
      payload: copyInsideDataObject,
    });
  };
};
// update Edit Patient
export const updateEditPatient = (payload) => {
  return (dispatch) => {
    const patientData = store.getState()?.patient?.patientData;
    let copyInsideDataObject = patientData?.data;
    if (payload?.deactivatedAt) {
      copyInsideDataObject[`deactivatedAt`] = payload?.deactivatedAt;
    } else {
      copyInsideDataObject[`deactivatedAt`] = ``;
    }
    if (payload?.baseLineAHI) {
      copyInsideDataObject[`baseLineAHI`] = payload?.baseLineAHI;
    } else {
      copyInsideDataObject[`baseLineAHI`] = ``;
    }
    copyInsideDataObject[`MRN`] = payload?.MRN;
    copyInsideDataObject[`gender`] = payload?.gender;
    copyInsideDataObject[`phoneNumber`] = payload?.phoneNumber;
    copyInsideDataObject[`email`] = payload?.email;
    copyInsideDataObject[`insuranceCompany`] = payload?.insuranceCompany;
    copyInsideDataObject[`WIP`] = payload?.WIP;
    copyInsideDataObject[`brightreeID`] = payload?.brightreeID;
    copyInsideDataObject[`salesOrderID`] = payload?.salesOrderID;
    copyInsideDataObject[`followUpDate`] = payload?.followUpDate;
    copyInsideDataObject[`MFL`] = payload?.MFL;
    copyInsideDataObject[`daysInWIP`] = payload?.daysInWIP;
    copyInsideDataObject[`primaryDiagnosis`] = payload?.primaryDiagnosis;
    copyInsideDataObject[`secondaryDiagnosis`] = payload?.secondaryDiagnosis;
    let copycopyInsideMaskObject = copyInsideDataObject.mask;
    if (payload?.mask) {
      copycopyInsideMaskObject[`type`] = payload?.mask?.type;
      copycopyInsideMaskObject[`size`] = payload?.mask?.size;
      copycopyInsideMaskObject[`manufacturer`] = payload?.mask?.manufacturer;
      copyInsideDataObject[`mask`] = copycopyInsideMaskObject;
    }
    copyInsideDataObject[`status`] = payload?.status;
    copyInsideDataObject[`notes`] = payload?.notes;
    // updating patient Data
    dispatch({
      type: SELECTED_PATIENT,
      payload: copyInsideDataObject,
    });
  };
};
export const setQueryParamsFilters = (payload) => {
  return (dispatch) => {
    dispatch(setAllPatients(payload));
  };
};
export const setAllPatients = (payload) => {
  return (dispatch) => {
    const { nextQueryParams, scrollingParams } = payload;
    const user = store.getState()?.user?.user;
    const patientsList = store.getState()?.patient?.patientsList;
    const patientData = store?.getState()?.patient?.patientData;
    // Empty the Selected patient in patientData on new Api Call
    if (patientData?.data) {
      dispatch(emptyPatientData(null));
    }
    // Empty the messages on new Api Call
    if (store.getState()?.patient?.messages) {
      dispatch(setAllMessages([]));
    }
    // Empty the logs on new Api Call
    if (store.getState()?.patient?.notes) {
      dispatch(setAllNotes([]));
    }
    // Empty the Patient Report of the patient
    if (patientsList?.data) {
      dispatch(setSelectedPatientReport(null));
      dispatch(
        handleTimer({
          status: false,
        })
      );
    }
    // Before Load false the timer toggle button

    const selectedTabsent =
      user?.isTransportationAdmin && !user.isSystemAdministrator && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff
        ? `past`
        : `viewData`;

    // Set the Selected tab at initial
    dispatch({
      type: HANDLE_SELECTED_TAB,
      payload: selectedTabsent,
    });
    // Add loader on Patient List when we save time in database
    dispatch(patientsListLoading({ type: LIST_ALL_PATIENTS_LOADING, payload: `` }));
    if (user?.isTransportationAdmin && !user.isSystemAdministrator && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff) {
      let copyNextQueryParams = { ...nextQueryParams };
      const endDate = momentWrapper(new Date()).startOf(`day`).subtract(1, `days`).toISOString();
      const startDate = momentWrapper(endDate).startOf(`day`).subtract(30, `days`).toISOString();
      copyNextQueryParams[`startDate`] = startDate;
      copyNextQueryParams[`endDate`] = endDate;
      postRequest(LIST_ALL_PATIENTS_BILLER_ROLE_URL, copyNextQueryParams)
        .then((res) => {
          if (scrollingParams) {
            let previousData = patientsList?.data?.patients;
            let latestData = res?.data?.data;
            let concatenatedPatients = previousData.concat(latestData);
            const copyPatientResponseObj = {};
            copyPatientResponseObj[`patients`] = concatenatedPatients;
            copyPatientResponseObj[`pager`] = res?.data?.pager;
            dispatch({
              type: LIST_ALL_PATIENTS,
              payload: copyPatientResponseObj,
            });
          } else {
            const data = {};
            data[`patients`] = res?.data?.data;
            data[`pager`] = res?.data?.pager;
            dispatch({
              type: LIST_ALL_PATIENTS,
              payload: data,
            });
          }
        })
        .catch(() => {});
    } else {
      // let today = new Date();
      // let priorDate = new Date(new Date().setDate(today.getDate() - 30));
      // copyNextQueryParams[`startDate`] = priorDate.toISOString().toString();
      // copyNextQueryParams[`endDate`] = momentWrapper().toISOString();
      const endDate = momentWrapper(new Date()).subtract(1, `days`).set({ hours: 23, minutes: 59, seconds: 59 }).toISOString();
      const startDate = momentWrapper(new Date(endDate)).subtract(29, `days`).set({ hours: 0, minutes: 0, seconds: 0 }).toISOString();
      let copyNextQueryParams = { ...nextQueryParams };
      copyNextQueryParams[`startDate`] = startDate;
      copyNextQueryParams[`endDate`] = endDate;
      postRequest(LIST_ALL_PATIENTS_URL, copyNextQueryParams)
        .then((res) => {
          if (scrollingParams) {
            let previousData = patientsList?.data?.patients;
            let latestData = res?.data?.data;
            let concatenatedPatients = previousData.concat(latestData);
            const copyPatientResponseObj = {};
            copyPatientResponseObj[`patients`] = concatenatedPatients;
            copyPatientResponseObj[`pager`] = res?.data?.pager;
            dispatch({
              type: LIST_ALL_PATIENTS,
              payload: copyPatientResponseObj,
            });
          } else {
            const data = {};
            data[`patients`] = res?.data?.data;
            data[`pager`] = res?.data?.pager;

            dispatch({
              type: LIST_ALL_PATIENTS,
              payload: data,
            });
          }
        })
        .catch(() => {});
    }
  };
};

export const messagesApiCall = (id) => {
  return (dispatch) => {
    if (id) {
      dispatch({ type: `MSGAPICALL`, payLoad: { loading: true, error: false } });
      getRequest(PATIENT_ALL_TEXT_MESSAGES_URL + id + `?pageSize=10&pageNumber=${1}`, `my hun`)
        .then((res) => {
          dispatch({ type: `MSGAPICALL`, payLoad: { loading: false, error: false } });
          if (res.data.messages.length) dispatch(setAllMessages(res.data.messages.reverse()));
        })
        .catch(() => {
          dispatch({ type: `MSGAPICALL`, payLoad: { loading: false, error: true } });
        });
    }
  };
};

export const logsApiCall = (patientId) => {
  return (dispatch) => {
    let url = REPORT_NOTES + `${patientId}`;
    dispatch({ type: `LOADING`, payLoad: { loadingCall: true, error: false } });
    getRequest(url)
      .then((response) => {
        dispatch({ type: `LOADING`, payLoad: { loadingCall: false, error: false } });
        dispatch(setAllNotes(response.data.reportNotes));
      })
      .catch(() => {
        dispatch({ type: `LOADING`, payLoad: { loadingCall: false, error: true } });
      });
  };
};

export const handleSelectedPatient = (payload) => {
  return async (dispatch) => {
    const { patient } = payload;
    // Add loader on Patient Data when we save time in database
    dispatch(selectedPatientLoading({ type: SELECTED_PATIENT_LOADING, payload: `` }));

    // update user selected id
    dispatch(setSelectedPatientReport({ id: patient?._id }));

    // get selected user report ID
    const endDate = momentWrapper(new Date()).subtract(1, `days`).set({ hours: 23, minutes: 59, seconds: 59 }).toISOString();
    const startDate = momentWrapper(new Date(endDate)).subtract(29, `days`).set({ hours: 0, minutes: 0, seconds: 0 }).toISOString();
    let paramPayload = `?provider=true&clinicalUser=true&startDate=${startDate}
    &endDate=${endDate}`;
    getRequest(GET_PATIENT_BY_ID_URL + patient?._id + paramPayload)
      .then((res) => {
        dispatch(updatePatientDataFlag(false));
        dispatch(setMessagesCurrentPage(1));
        dispatch(setMessagesHasMore(true));
        dispatch({
          type: SELECTED_PATIENT,
          payload: res.data,
        });
        dispatch(logsApiCall(patient?._id));
        dispatch(switchPatient(false));
      })
      .catch(() => {});
  };
};

export const setPatientsFilters = (filters) => {
  return {
    type: PATIENTS_FILTERS,
    payload: filters,
  };
};
export const setHandleTimer = (payload) => {
  return {
    type: HIDE_TIMER,
    payload: payload,
  };
};
export const setSelectedPatient = (patientId) => {
  return {
    type: SELECTED_PATIENT_UNUSE,
    payload: patientId,
  };
};
// updating audio time logs, audio timer is handle in backend
export const updateAudioTimeLogs = (payload) => {
  return async (dispatch) => {
    const { data, callSuccess, runTimer } = payload;
    if (!runTimer) {
      const patientData = store.getState()?.patient?.patientData;
      const patientsList = store.getState()?.patient?.patientsList;
      const selectedPatient = store.getState()?.patient?.patientData?.data;
      if (data?.duration !== 0) {
        if (callSuccess) {
          const updatePatientDataObject = {
            ...patientData?.data,
            lastMonitoredAt: data?.endDate,
            timeMonitored: patientData?.data?.timeMonitored + data?.duration,
            interactiveCommunication: true,
          };

          const updatedPatientsListObject = patientsList?.data?.patients?.map((patient) => {
            return patient?._id === selectedPatient?._id
              ? {
                  ...patient,
                  lastMonitoredAt: data?.endDate,
                  timeMonitored: patientData?.data?.timeMonitored + data?.duration,
                  interactiveCommunication: true,
                }
              : patient;
          });

          // updating patient Data on Redux SELECTED_PATIENT (Patient list)
          dispatch({
            type: SELECTED_PATIENT,
            payload: updatePatientDataObject,
          });
          // updating patients lists on redux LIST_ALL_PATIENTS (all patients list)
          dispatch({
            type: LIST_ALL_PATIENTS,
            payload: {
              error: false,
              loading: false,
              success: true,
              patients: updatedPatientsListObject,
            },
          });
          successToast(`Audio Time Log Added`);
        } else {
          const updatePatientDataObject = {
            ...patientData?.data,
            lastMonitoredAt: data?.endDate,
            timeMonitored: patientData?.data?.timeMonitored + data?.duration,
          };

          const updatedPatientsListObject = patientsList?.data?.patients?.map((patient) => {
            return patient?._id === selectedPatient?._id
              ? {
                  ...patient,
                  lastMonitoredAt: data?.endDate,
                  timeMonitored: patient?.timeMonitored + data?.duration,
                }
              : patient;
          });

          // updating patient Data on Redux SELECTED_PATIENT (Patient list)
          dispatch({
            type: SELECTED_PATIENT,
            payload: updatePatientDataObject,
          });
          // updating patients lists on redux LIST_ALL_PATIENTS (all patients list)
          dispatch({
            type: LIST_ALL_PATIENTS,
            payload: {
              error: false,
              loading: false,
              success: true,
              patients: updatedPatientsListObject,
            },
          });
          successToast(`Audio Time Log Added`);
        }
      }
      // Turn on timer if already on
      if (store.getState()?.patient?.timer?.autoTimer === true) {
        dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: null, autoTimer: `false` }));
      }
    } else {
      if (store.getState()?.patient?.timer?.autoTimer === true) {
        dispatch(handleTimer({ status: true, timeEnd: null, videoOrAudio: null, autoTimer: `false` }));
      }
    }
  };
};

export const setSelectedPatientReport = (report) => {
  return {
    type: SELECTED_PATIENT_REPORT,
    payload: report,
  };
};
// update patient data, getting latest report status
export const handleUpdatePatientData = (patient) => {
  return async (dispatch) => {
    let reportStatus = await postRequest(`${GETTING_REPORT_STATUS}${patient?._id}?reportId=${null}`, {
      reportMonth: Number(momentWrapper().format(`YYYYMM`)),
    });
    dispatch(setSelectedPatientReport({ patient: { ...patient }, selectedPatientReport: reportStatus?.data?.report }));
  };
};
// Video Status Calling
export const videoCallAction = (payload) => {
  return {
    type: SET_VIDEO_CALL_STATUS,
    payload: payload,
  };
};
// Handle Selected Tab for patient view/past (data)
export const handleSelectedTab = (payload) => {
  return {
    type: HANDLE_SELECTED_TAB,
    payload: payload,
  };
};
export const handleReportLoadingFlag = (payload) => {
  return {
    type: REPORT_LOADING,
    payload: payload,
  };
};
export const updateCreatedAt = (newDate) => {
  return (dispatch) => {
    const updatePatientDataObject = {
      ...store.getState()?.patient?.patientData?.data,
      createdAt: newDate,
    };
    dispatch({
      type: SELECTED_PATIENT,
      payload: updatePatientDataObject,
    });
  };
};
// Get selected Patient data from search dashboard
export const handleSelectedPatientFromDashboard = (payload) => {
  return (dispatch) => {
    // let today = new Date();
    // let priorDate = new Date(new Date().setDate(today.getDate() - 30));
    const endDate = momentWrapper(new Date()).subtract(1, `days`).set({ hours: 23, minutes: 59, seconds: 59 }).toISOString();
    const startDate = momentWrapper(new Date(endDate)).subtract(29, `days`).set({ hours: 0, minutes: 0, seconds: 0 }).toISOString();
    let paramPayload = `?provider=true&clinicalUser=true&startDate=${startDate}
    &endDate=${endDate}`;
    getRequest(GET_PATIENT_BY_ID_URL + payload + paramPayload)
      .then((response) => {
        dispatch(updatePatientDataFlag(false));
        const data = {
          patients: [response.data],
        };

        // Dispatch here
        dispatch({
          type: LIST_ALL_PATIENTS,
          payload: data,
        });
        // dispatch(handleSelectedPatient({ patient: response.data }));
        dispatch(setMessagesCurrentPage(1));
        dispatch(setMessagesHasMore(true));
        dispatch({
          type: SELECTED_PATIENT,
          payload: response.data,
        });
        dispatch(logsApiCall(payload));
      })
      .catch(() => {});
  };
};
// Get selected Patient report from dashboard (Ready to sign report chip)
export const handleReportIdFromReadyToSignReport = (payload) => {
  return (dispatch) => {
    let paramPayload;
    const { reportId, reportPatientID, endDateOfReport } = payload;
    if (reportId) {
      paramPayload = `?reportId=${reportId}&timeLogs=true`;
    } else {
      paramPayload = `?startDate="${momentWrapper().startOf(`month`).toISOString()}"&endDate="${momentWrapper().toISOString()}"&timeLogs=true`;
    }
    getRequest(GET_PATIENT_BY_ID_URL + reportPatientID + paramPayload)
      .then((response) => {
        // Dispatch here
        dispatch(updatePatientDataFlag(false));
        const data = {
          patients: [response.data],
        };
        // Dispatch here
        dispatch({
          type: LIST_ALL_PATIENTS,
          payload: data,
        });
        if (momentWrapper().format(`YYYYMM`) > momentWrapper(endDateOfReport).format(`YYYYMM`)) {
          dispatch(handleSelectedPatient({ patient: response.data, reportId: reportId, location: payload }));
        } else {
          dispatch(handleSelectedPatient({ patient: response.data }));
        }
      })
      .catch(() => {});
  };
};

// Patient Messages
export const setAllMessages = (payload) => {
  return {
    type: SET_MESSAGES,
    payload: payload,
  };
};

export const setMessagesCurrentPage = (payload) => {
  return {
    type: SET_MESSAGES_CURRENT_PAGE,
    payload: payload,
  };
};

export const setMessagesHasMore = (payload) => {
  return {
    type: SET_MESSAGES_HASMORE,
    payload: payload,
  };
};
export const smsResponse = (payLoad) => {
  return {
    type: SMSRESPONSE,
    payLoad: payLoad,
  };
};
export const textError = (payLoad) => {
  return {
    type: TXT_ERROR,
    payLoad: payLoad,
  };
};
//popMessages
export const firstResponsemessage = (payLoad) => {
  return {
    type: FIRST_RESPOSNE,
    payLoad: payLoad,
  };
};
// socketEvent resposne;
export const socketResponse = (payLoad) => {
  return {
    type: SOCKET_RESPONSE,
    payLoad: payLoad,
  };
};
export const communcicationWithPatient = (payLoad) => {
  return {
    type: COMMUNICATION_PATIENT,
    payLoad: payLoad,
  };
};

// Patient Logs
export const setAllNotes = (payload) => {
  return {
    type: SET_NOTES,
    payload: payload,
  };
};
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _setSelectedPatient = (patientId) => {
  return (dispatch) => {
    dispatch(setSelectedPatient(patientId));
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _setSelectedPatientReport = (report) => {
  let reportProperties = {};
  if (report) {
    const { _id, reportStatus, ready_to_addendum, isBilled, isSigned, compliant, isAddendumBilled, isAddendumSigned } = report;
    reportProperties[`reportId`] = _id;
    reportProperties[`reportStatus`] = reportStatus;
    reportProperties[`ready_to_addendum`] = ready_to_addendum;
    reportProperties[`isBilled`] = isBilled;
    reportProperties[`isSigned`] = isSigned;
    reportProperties[`compliant`] = compliant;
    reportProperties[`isAddendumBilled`] = isAddendumBilled;
    reportProperties[`isAddendumSigned`] = isAddendumSigned;

    return (dispatch) => {
      dispatch(setSelectedPatientReport(reportProperties));
    };
  } else {
    return (dispatch) => {
      dispatch(setSelectedPatientReport(report));
    };
  }
};

import { memo } from 'react';
import { useSelector } from 'react-redux';

const AutoWriteCredentialsHandler = ({ value, setValue, setShallowValue }) => {

  const { email, firstName, lastName, practiceNumber, practiceEmail, providerType, title, phoneNumber, practiceName } = useSelector((state) => state?.user?.user);
  const runEditing = () => {
    const mentionDecider = (mention) => {
      mention = mention?.toLowerCase()
      if (mention === `email` || mention === `em`) {
        return email;
      } else if (mention === `fn` || mention === `firstname`) {
        return firstName;
      } else if (mention === `ln` || mention === `lastname`) {
        return lastName;
      } else if (mention === `pn` || mention === `practicename`) {
        return practiceName;
      } else if (mention === `ppn` || mention === `practicePhonenumber`) {
        return practiceNumber;
      } else if (mention === `pe` || mention === `practiceemail`) {
        return practiceEmail;
      } else if (mention === `pt` || mention === `providertype`) {
        return providerType;
      } else if (mention === `t` || mention === `title`) {
        return title;
      } else if (mention === `p` || mention === `phone`) {
        return phoneNumber;
      } else {
        return mention;
      }
    };
    if (value) {
      let valueArray = value && value?.split(` `);
      let foundIndex = ``;
      let findEditor =
        valueArray &&
        valueArray?.find((word, index) => {
          let condition = ``;
          if (word?.charAt(0) === `{` && word?.charAt(word?.length - 1) === `}`) {
            foundIndex = index;
            condition = word?.charAt(0) === `{` && word?.charAt(word?.length - 1) === `}`;
          }
          return condition ? condition : null;
        });

      if (findEditor) {
        let word = valueArray[foundIndex].slice(1, valueArray[foundIndex]?.length - 1);
        let mentionedVariable = mentionDecider(word);
        valueArray[foundIndex] = mentionedVariable;
        let joinedAgain = valueArray?.join(` `);
        valueArray = joinedAgain;
        setValue(joinedAgain);
        setShallowValue && setShallowValue(joinedAgain);
      }
    } else {
      return null;
    }
  };

  return runEditing();
};
export const AutoWriteCredentials = memo(AutoWriteCredentialsHandler);
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useElements, useStripe} from '@stripe/react-stripe-js';
import css from './paymentInfo.module.css';
import {PulseSpinner} from 'src/pages/common/components/spinner/spinner';
import {CHECK_PENDING_VERIFICATION, CREATE_CHECKOUT_SESSION, CREATE_SUBSCRIPTION, postRequest,} from 'src/crud/crud';
import {useDispatch, useSelector} from 'react-redux';
import {setAllPractices} from 'src/redux/practices/practiceActions';
import {getSafe} from 'src/utils';
import {errorToast} from 'src/pages/common/components/snackBar/toast';
import {BillingHistoryContext} from 'src/pages/home/modules/administration/payment/billingHistory';
import {ConfirmationWrapper} from 'src/pages/common/HOC/ConfirmationWrapper';
import {logoutHandlerS} from 'src/redux/action/logoutHandler';
import {useLocation} from "react-router-dom/cjs/react-router-dom";
import Alert from "@mui/material/Alert";

export const SplitForm = () => {
  const [payLoadObj, setPayLoadObj] = useState({});
  const [openFirstTimePaymentConf, setopenFirstTimePaymentConf] = useState(false);
  const dispatch = useDispatch();
  const context = useContext(BillingHistoryContext);
  const {
    user,
    practice,
  } = useSelector((state) => state);
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);
  const [verificationUrl, setVerificationUrl] = useState(``);

  const errorCatcher = (payLoad, err) => {
    setLoading(false);
    if (payLoad?.error) {
      const { error = {} } = payLoad;
      let { message = `` } = error;
      errorToast(() => message);
    } else {
      errorToast(getSafe(() => err.response.data.message) || getSafe(() => err.message) || `Unable to create subscription for stripe`);
    }
  };

  const subscripeFirstTime = async () => {
    setopenFirstTimePaymentConf(false);
    if (payLoadObj?.error) {
      errorCatcher(payLoadObj, {});
    } else {
      await handleSubscriptionCreation();
    }
  };

  const getPendingVerification = () => {
    // console.error("HERe", user?.user?.stripeCustomerId, practice)
    postRequest(CHECK_PENDING_VERIFICATION, {
      stripeCustomerId: user?.user?.stripeCustomerId || practice?.stripeCustomerId
    })
        .then(res => {
          if(res.status === 200 && res.data?.verification_url) {
            setVerificationUrl(res.data.verification_url);
          } else if(res.status === 404) {
            setVerificationUrl("");
          }
        });
  }


  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const query = useQuery();

  useEffect(() => {

    getPendingVerification();
    const retrieveSession = async () => {
      try {
        return postRequest(CREATE_SUBSCRIPTION, {
          sessionId: query.get('session_id'),
        });
      } catch (e) {
        console.error(e);
      }
      // if (user?.user?.isGroupAdmin) {
      //   try {
      //     const res = await postRequest(CREATE_SUBSCRIPTION, {
      //       sessionId: query.get('session_id'),
      //     });
      //     dispatch(setAllPractices({ ...practice, stripeSubscriptionId: res.data.id, stripeCustomerId: res.data.customer }));
      //   } catch (e) {
      //     console.error(e)
      //   }
      // } else {
      //
      // }
    };


    if (query.get('cancel') === 'true') {
      errorToast('Payment cancelled');

    } else if (query.get('session_id')) {
      retrieveSession().then((res) => {
        getPendingVerification();
        console.error("Logout ka locha Root", res);
        if (user?.user?.isGroupAdmin) {
          dispatch(setAllPractices({ ...practice, stripeSubscriptionId: res.data?.id, stripeCustomerId: res.data?.customer }));
        } else if(user?.user?.isSuperAdmin) {
          console.error("Logout ka locha SA", res);
          if(res.data?.verification_url) {
            setVerificationUrl(res.data.verification_url);
          } else {
            console.error("Else Ran")
            dispatch(logoutHandlerS());
          }
        }
      }).catch();
    }

    // (query.get('session_id' && user?.user?.isGroupAdmin)) {
    //   console.error("not is ga")
    //   retrieveSession().then((res) => dispatch(setAllPractices({ ...practice, stripeSubscriptionId: res.data.id, stripeCustomerId: res.data.customer }))).catch();
    // } else if (query.get('session_id' && user?.user?.isSuperAdmin)) {
    //   console.error("is ga")
    //   retrieveSession().then(() => dispatch(logoutHandlerS())).catch();
    // }
    window.history.replaceState({}, document.title, window.location.pathname);

  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    let payLoad = {};
    try {
      if (!stripe || !elements) {
        return;
      }
      if(user?.user?.isGroupAdmin) {
        await handleSubscriptionCreation();
      } else setopenFirstTimePaymentConf(true);
    } catch (err) {
      errorCatcher(payLoad, err);
    }
  };

  const handleSubscriptionCreation = async () => {
    try {
      setopenFirstTimePaymentConf(false);
      const response = await postRequest(CREATE_CHECKOUT_SESSION, {
        customerId: user?.user?.stripeCustomerId || practice?.stripeCustomerId,
      });

      const { session = { id: "" } } = response.data;


      await stripe.redirectToCheckout({
        sessionId: session.id,
      });

    } catch (error) {
      console.error(error);
      // errorCatcher(payLoad, err);
    }
  };

  return (
      <ConfirmationWrapper
          open={openFirstTimePaymentConf}
          title="Attention!"
          description={`After establishing payment information you will be required to log back into the MonitAir platform.`}
          successText="Ok"
          successFunc={subscripeFirstTime}
      >

        { verificationUrl && <Alert severity="info">
          <h5>ACH Manual Payment Method in progress</h5>
          <p>You will receive a Microdeposit of $0.1 within 2 days, click <a href={verificationUrl} style={{ fontSize: '16px' }} target="_blank">here</a> to proceed. Please come back to the app after verifying your microdeposit.</p>
        </Alert> }


        {/*<tbody id={css.body}>*/}
        <button
            id={css.headButton}
            className="btn btn-info h-25 my-2 mx-4"
            onClick={handleSubmit}
        >
          On Board Now
        </button>
        {loading && <PulseSpinner />}
      </ConfirmationWrapper>
  );
};

const useResponsiveFontSize = () => {
  const getFontSize = () => (window.innerWidth < 450 ? `16px` : `18px`);
  const [fontSize, setFontSize] = useState(getFontSize);

  useEffect(() => {
    const onResize = () => {
      setFontSize(getFontSize());
    };

    window.addEventListener(`resize`, onResize);

    return () => {
      window.removeEventListener(`resize`, onResize);
    };
  });

  return fontSize;
};

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize,
            color: `#424770`,
            letterSpacing: `0.025em`,
            fontFamily: `Source Code Pro, monospace`,
            '::placeholder': {
              color: `#aab7c4`,
            },
          },
          invalid: {
            color: `#9e2146`,
          },
        },
      }),
      [fontSize]
  );

  return options;
};


import React, { useEffect, useRef, useState } from 'react';
import css from './messages.module.css';
import { PATIENT_ALL_TEXT_MESSAGES_URL, getRequest, PATIENT_INVITE_MESSAGE_URL, postRequest } from '../../../../../../../crud/crud';
import { useSelector, useDispatch } from 'react-redux';
import { _twilioOutGoingSMS } from '../../../../../../../redux/twilio/twilioActions';
import { PracticeMessageCard } from './card';
import { PatientVoiceCall } from '../voiceCall/voiceCall';
import { PatientVideoCall } from '../videoCall/videoCall';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Skeleton, Zoom } from '@mui/material';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { SendMsg } from './SendMsg';
import { useLocation } from 'react-router-dom';
import Spinner from 'react-spinners/PuffLoader';
import { setAllMessages, setMessagesCurrentPage, setMessagesHasMore } from '../../../../../../../redux/patient/patientActions';

export const chatSkelton = (
  <>
    <Skeleton className={css.chatSkeltonvideo} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonText} height={40} sx={{ mt: -2 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonimage} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonTextinBound} height={40} sx={{ mt: -2 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonvideo} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonText} height={40} sx={{ mt: -2 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonimage} height={180} sx={{ mt: -3 }} type="rectangular" />
    <Skeleton className={css.chatSkeltonTextinBound} height={40} sx={{ mt: -2 }} type="rectangular" />
  </>
);
let chat = null;

export const PatientMessages = ({
  reportId,
  setRefreshFlag,
  handleMessageToggle,
  toggle,
  setCurrentTab,
  currentTab,
}) => {
  const [msgLoading, setMsgLoading] = useState(false)
  const [singleMsg, setSingleMsg] = useState(false)
  const dispatchRedux = useDispatch();

  const auth = useSelector((state) => state.user.auth);
  const patientId = useSelector((state) => state?.patient?.patientData?.data?._id);
  const messagesStore = useSelector((state) => state.patient.messages);
  const page = useSelector((state) => state.patient.messagesCurrentPage);
  const hasMore = useSelector((state) => state.patient.messagesHasMore);
  const location = useLocation();

  const chatBox = useRef(null);

  useEffect(() => {
    if ((patientId) && hasMore) {
      let url = PATIENT_ALL_TEXT_MESSAGES_URL + patientId + `?pageSize=10&pageNumber=${page}`
      setMsgLoading(true)
      getRequest(url).then(({ data }) => {
        setMsgLoading(false)
        dispatchRedux(setMessagesHasMore(data?.hasMore))
        if (page === 1) {
          if (data?.messages) {
            dispatchRedux(setAllMessages([...data.messages.reverse()]))
          }
          chatBox.current.scrollTop = chatBox?.current?.scrollHeight;
        } else {
          if (data?.messages) {
            dispatchRedux(setAllMessages([...data.messages.reverse(), ...messagesStore]))
          }
          chatBox.current.scrollTop = 785
        }
      }).catch(() => {
        setMsgLoading(false)
      })

    }
  }, [page, patientId])

  const handleScroll = (e) => {
    if (e.currentTarget.scrollTop === 0 && messagesStore?.length > 1 && (patientId) && hasMore) {
      dispatchRedux(setMessagesCurrentPage(page + 1))
    }
  }
  const twilioOutGoingSMS = (payLoad) => {
    dispatchRedux(_twilioOutGoingSMS(payLoad))
  }
  // REDUX - SHIFT EVENTS TO MAIN.JS,


  chat = messagesStore.length > 0 && messagesStore?.map((m, i) => {
    return <PracticeMessageCard i={i} msg={m} key={`key` + i} />;
  });
  function sendInvitation() {
    postRequest(PATIENT_INVITE_MESSAGE_URL + patientId, {})
      .then(() => {
        successToast(`Invitation text has been sent!`);
      })
      .catch((e) => {
        if (e.response) {
          errorToast(e.response?.data?.message);
        } else {
          errorToast(`Application error has occurred please try again later after sometime!`);
        }
      });
  }

  useEffect(() => {
    if (singleMsg) {
      chatBox.current.scrollTop = chatBox.current.scrollHeight;
      setSingleMsg(false)
    }
  }, [singleMsg])

  return (
    <div id={css.msgMain}>
      <div
        style={{
          display: `flex`,
          borderTop: `1px solid #c1c1c1`,
          borderBottom: `1px solid #c1c1c1`,
          backgroundColor: `#1699c5`,
          height: `30px`,
          color: `white`,
        }}
      >
        {toggle === true ? (
          <ExpandLessIcon onClick={() => handleMessageToggle(toggle)} />
        ) : (
          <ExpandMoreIcon onClick={() => handleMessageToggle(toggle)} />
        )}
        Communication Center
      </div>
      {toggle && (
        <Box
          sx={{
            height: `${location.pathname === `/patient` ? `80vh` : `100vh`}`,
            position: `relative`,
            backgroundColor: `white`,
          }}
        >
          <div id={css.customMsgHead}>
            <div
              id={currentTab === `msg` ? css.menuItemSelected : css.menuItem}
              onClick={() => (patientId ? setCurrentTab(`msg`) : null)}
              className="p-3"
            >
              <i style={{ fontSize: `x-large` }} className="fa fa-comment-o" aria-hidden="true"></i>
            </div>
            <div
              id={currentTab === `voice` ? css.menuItemSelected : css.menuItem}
              className="p-3"
              onClick={() => (patientId ? setCurrentTab(`voice`) : null)}
            >
              <i style={{ fontSize: `x-large` }} className="fa fa-phone" aria-hidden="true"></i>
            </div>
            <div
              id={currentTab === `video` ? css.menuItemSelected : css.menuItem}
              onClick={() => (patientId ? setCurrentTab(`video`) : null)}
              className="p-3"
            >
              <i style={{ fontSize: `x-large` }} className="fa fa-video-camera" aria-hidden="true"></i>
            </div>
          </div>
          {currentTab === `voice` ? (
            <PatientVoiceCall reportId={reportId} setRefreshFlag={setRefreshFlag} />
          ) : currentTab === `video` ? (
            <PatientVideoCall reportId={reportId} setRefreshFlag={setRefreshFlag} />
          ) : (
            <>
              <Zoom in={currentTab !== `voice` || currentTab !== `video`} mountOnEnter unmountOnExit>
                <div
                  ref={chatBox}
                  id={css.customBody}
                  className="p-2"
                  style={{
                    backgroundColor: `white`,
                    overflow: `auto`,
                  }}
                  onScroll={handleScroll}
                >
                  {((patientId) && msgLoading && messagesStore.length < 1) ? chatSkelton :
                    <>
                      <div
                        style={{
                          zIndex: 20,
                          display: `flex`,
                          justifyContent: `center`,
                          alignItems: `center`,
                        }}
                      >
                        <Spinner
                          //   css={override}
                          size={25}
                          color={`rgb(22, 153, 197)`}
                          loading={msgLoading}
                        />
                      </div>
                      {chat}
                    </>
                  }
                </div>
              </Zoom>
              <div style={{ position: `absolute`, bottom: 0, width: `100%` }}>
                <SendMsg setSingleMsg={() => setSingleMsg(true)} auth={auth} patientId={patientId} twilioOutGoingSMS={(payLoad) => twilioOutGoingSMS(payLoad)} overView={false} conditionalStyles={{ bottom: `60px`, width: `90%`, left: `1.6%`, position: `absolute` }} />
              </div>
            </>
          )}
        </Box>
      )}
    </div>
  );
}




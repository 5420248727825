import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../../../assets/monitair-logo.svg';
import css from './otp.module.css';
import { LOGIN_URL, postRequest } from '../../../crud/crud';
import { PulseSpinner } from '../../common/components/spinner/spinner';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { FooterWrapper } from 'src/pages/common/HOC/FooterWrapper';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import { authTokenHandler } from 'src/redux/action/authTokenHandler';
import { setUserHandler } from 'src/redux/action/setUserHandler';

export const Otp = () => {
  const dispatch = useDispatch()
  const [userName, setUserName] = useState(``);
  const [userNameError, setUserNameError] = useState({
    status: false,
    msg: ``,
  });
  const [password, setPassword] = useState(``);
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const validateInput = () => {
    let temp = { status: false, msg: `` };
    if (!userName) {
      temp.status = true;
      temp.msg = `Invalid username`;
      setUserNameError(temp);
    } else {
      setUserNameError(temp);
    }
  };

  const loginHandler = (e) => {
    e.preventDefault();
    setError(false);
    setLoading(true);
    postRequest(LOGIN_URL, { username: userName, password: password })
      .then((res) => {
        if (res?.data?.otp) {
          setLoading(false);
        } else {
          dispatch(authTokenHandler(res?.data?.token));
          dispatch(setUserHandler(res?.data?.user));
        }
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  return (
    <FooterWrapper>
      <Box>
        <div id={css.container}>
          <img id={css.logo} src={logo} alt="Logo" />
          {error ? (
            <div id={css.bannerError}>
              <p id={css.bannerErrorText}>Incorrect username or password.</p>
            </div>
          ) : null}
          <form id={css.form} onSubmit={loginHandler}>
            <InputField
              fullWidth={true}
              size="small"
              type="text"
              error={!!userNameError.status}
              label="Username"
              onChange={(e) => {
                setUserName(e.target.value);
                setError(false);
              }}
              onBlur={() => validateInput()}
              value={userName}
            />
            <p className={userNameError.status ? `d-block text-danger` : `d-none`} style={{ fontSize: `x-small`, margin: `1%` }}>
              {userNameError.msg}
            </p>
            <InputField
              sx={{ mt: 1 }}
              fullWidth={true}
              size="small"
              type="password"
              label="Password"
              onChange={(e) => {
                setPassword(e.target.value);
                setError(false);
              }}
              value={password}
            />
            <Link to="/forget">Forgot Username or Password</Link>
            <div id={css.remember}>
              <input type="checkbox" name="remember" onClick={() => setRemember(!remember)} />
              <label htmlFor="remember">Remember me</label>
            </div>
            <div id={css.buttonHolder}>
              <button type="submit" id={css.button}>
                LOGIN
              </button>
            </div>
          </form>
        </div>
        {loading ? <PulseSpinner /> : null}
      </Box>
    </FooterWrapper>
  );
};
import React from 'react';
import { Box, MenuItem, Menu, Fade } from '@mui/material';
import { MessageList } from 'src/pages/home/modules/messenger/components/MessageList/MessageList';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { _selectedUser } from 'src/redux/messenger/messengerActions';
import { capitalizeFirstLetter } from 'src/utils';

export const PracticeMessenger = () => {
  const { patientData } = useSelector((state) => state?.readyToSign);
  const { _id: patientId } = patientData
  let patientName = capitalizeFirstLetter(patientData?.firstName) + ` ` + capitalizeFirstLetter(patientData?.lastName)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const listOfUsers = useSelector((state) => state.messenger.conversationList);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  return (
    <Box sx={{ border: `1px solid #2299c5`, borderRadius: `3px`, position: `relative` }}>
      <Box
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        sx={{
          backgroundColor: `rgb(22, 153, 197)`,
          color: `white`,
          padding: `10px`,
          fontFamily: `sans-serif`,
          textAlign: `start`,
          borderTopLeftRadius: `5px`,
          borderTopRightRadius: `5px`,
          '&:hover': {
            cursor: `pointer`,
          },
        }}
      >
        Practice Communication
        <span>
          <Fade in={open || !open} mountOnEnter unmountOnExit>
            {open ? <ArrowDropUpIcon sx={{ color: `white` }} /> : <ArrowDropDownIcon sx={{ color: `white` }} />}
          </Fade>
        </span>
      </Box>
      <Box
        id="askForSelection"
        sx={{
          position: `absolute`,
          zIndex: `6`,
          top: `47%`,
          left: { xs: `8%`, sm: `8%`, md: `12%`, lg: `15%`, xl: `23%` },
          fontSize: `larger`,
          fontFamily: `sans-serif`,
          opacity: `.5`,
        }}
      >
        Select user to send a message!
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        {listOfUsers.map((e) => {
          return (
            <MenuItem
              onClick={() => {
                document.getElementById(`askForSelection`).style.display = `none`;
                dispatch(_selectedUser(e?._id));
                setAnchorEl(null);
              }}
              sx={{ width: `200px !important` }}
              key={e?._id}
              value={e?._id}
            >
              {capitalizeFirstLetter(e?.userName)}
            </MenuItem>
          );
        })}
      </Menu>
      <MessageList patientId={patientId} isFromView={true} selectedUser={null} user={null} patientName={patientName} />
    </Box>
  );
}

import React from 'react';
import css from './detailsCard.module.css';
import { Line } from 'react-chartjs-2';
import { capitalizeFirstLetter } from '../../../../../../../utils'
const chartData = {
  labels: ['Dec 19', 'Jan 20', 'Feb 20', 'Mar 20', 'Apr 20', 'May 20', 'Jun 20'],
  datasets: [
    {
      fill: false,
      lineTension: 0.5,
      backgroundColor: '#1699C5',
      borderColor: '#1699C5',
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'round',
      pointBorderColor: '#1699C5',
      pointBackgroundColor: '#fff',
      pointBorderWidth: 2,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: '#1699C5',
      pointHoverBorderColor: '#1699C5',
      pointHoverBorderWidth: 2,
      pointRadius: 5,
      pointHitRadius: 10,
      data: [0, 59, 80, 81, 56, 55, 40],
    },
  ],
};

export const DetailsCard = ({ data }) => {
  return (
    <div className="d-flex justify-content-between" id={css.main} style={{ margin: '2% 3%' }}>
      <div style={{ width: '36%' }}>
        <h4 style={{ fontWeight: 600, fontSize: 'small', margin: '10px 0% 5px 0%' }}>PRACTICE CONTACT INFO:</h4>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Name</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>{capitalizeFirstLetter(data.practiceName)}</p>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Location:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>{capitalizeFirstLetter(data.line1 + ' ' + data.line2 + ', ' + data.city + ', ' + data.state)}</p>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Phone:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>{data.phoneNumber}</p>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Email:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>{data.email}</p>
            </div>
          </div>
        </div>
        <h4 style={{ fontWeight: 600, fontSize: 'small', margin: '5px 0% 5px 0%' }}>PRACTICE OVERALL STATS:</h4>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Admin:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>{data.usersCount.superAdmin.total}</p>
            </div>
          </div>
          {data?.usersCount?.superAdmin?.users?.map((item) => (
            <span>
              <div className="d-flex">
                <div style={{ width: '35%' }}>
                  <p>Admin Email:</p>
                </div>
                <div style={{ width: '80%' }}>
                  <p>{item.email}</p>
                </div>
              </div>
              <div className="d-flex">
                <div style={{ width: '35%' }}>
                  <p>Admin Phone No:</p>
                </div>
                <div style={{ width: '80%' }}>
                  <p>{item.phoneNumber}</p>
                </div>
              </div>
            </span>
          ))}
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Provider:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>{data.usersCount.provider.total}</p>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Clinical Staff:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>{data.usersCount.clinicalStaff.total}</p>
            </div>
          </div>
        </div>
        <h4 style={{ fontWeight: 600, fontSize: 'small', margin: '10px 0% 5px 0%' }}>PROVIDER PAYMENT INFO:</h4>
        <div className="d-flex flex-column">
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Last Payment Date:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>Provider Name G oes Here</p>
            </div>
          </div>
          <div className="d-flex">
            <div style={{ width: '35%' }}>
              <p>Last Payment Amount:</p>
            </div>
            <div style={{ width: '80%' }}>
              <p>Provider Type</p>
            </div>
          </div>
        </div>
        <h4 style={{ fontWeight: 600, fontSize: 'small', margin: '10px 0% 5px 0%' }}>NOTES:</h4>
        <textarea rows="5" style={{ borderColor: '#dddddd', width: '100%' }} value={data.notes} />
      </div>
      <div style={{ width: '62%' }}>
        <h4 style={{ fontWeight: 600, fontSize: 'small', margin: '5px 0% 5px 0%' }}>TOTAL BILLED SINCE REGISTRATION: $45,000</h4>
        <input placeholder="04/01/20-04/30/20" style={{ margin: '12px 0px' }} />
        <Line
          data={chartData}
          width={100}
          height={58}
          options={{
            legend: {
              display: false,
            },
            maintainAspectRatio: true,
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Time',
                    fontColor: '#000000',
                    fontSize: 20,
                  },
                },
              ],
              yAxes: [
                {
                  gridLines: {
                    color: '#dddddd',
                    borderDash: [5, 3],
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Billed ($)',
                    fontColor: '#000000',
                    fontSize: 20,
                  },
                  ticks: {
                    display: false,
                  },
                },
              ],
            },
          }}
        />
      </div>
    </div>
  );
}


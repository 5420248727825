import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FILTER_PRACTICE_GROUP_URL, getRequest } from '../../../../../../../crud/crud';
import { usStateName } from 'src/utils';
import HomeIcon from '@mui/icons-material/Home';
import { Box, Fade, Grid, IconButton } from '@mui/material';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import css from './advanceFilter.module.css';
import { DatePickerCustomizeForFilter } from 'src/pages/common/components/datePicker/DatePickerCustomize';
import momentOriginal from 'moment';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';

let selectorData = null;

export const AdvanceFilter = ({
  auth,
  addNewPractice,
  rowPerPage,
  page,
  dataHandler,
  loadingHandler,
  totalRowHandler,
  totalPageHandler,
  sortOrder,
  sortKey,
  setCurrentPage,
  setRowsPerPage,
}) => {



  const [visible, setVisible] = useState(false);

  const [name, setName] = useState(``);

  const [addressLine1, setAddressLine1] = useState(``);
  const [addressLine2, setAddressLine2] = useState(``);

  const [city, setCity] = useState(``);
  const [state, setState] = useState(``);
  const [zip, setZip] = useState(``);

  const [phone, setPhone] = useState(``);
  const [email, setEmail] = useState(``);

  const [startDate, setStartDate] = useState(``);
  const [endDate, setEndDate] = useState(``);

  const [flag, setFlag] = useState(0);

  const checkAndSetValues = () => {
    if (name?.length) {
      setName(``);
    }
    if (addressLine1?.length) {
      setAddressLine1(``);
    }
    if (addressLine2?.length) {
      setAddressLine2(``);
    }
    if (phone?.length) {
      setPhone(``);
    }
    if (email?.length) {
      setEmail(``);
    }
    if (startDate?.toString()?.length) {
      setStartDate(``);
    }
    if (endDate?.toString()?.length) {
      setEndDate(``);
    }
    if (city?.length) {
      setCity(``);
    }
    if (zip?.length) {
      setZip(``);
    }
    if (state?.length) {
      setState(``);
    }
  };
  const user = useSelector((state) => state?.user?.user);
  const _practiceGroupId = user?._practiceGroupId;
  const groupId = useSelector((state) => state?.practiceGroup?.groupId);
  const refreshGroups = useSelector((state) => state?.practiceGroup?.isRegistered);
  let payLoad = {
    page,
    ...(rowPerPage && { recordsPerPage: rowPerPage }),
    sort: JSON.stringify({ [sortKey]: sortOrder }),
    filters: {
      ...(startDate && { startDate: momentOriginal(startDate).format(`YYYY-MM-DD`) }),
      ...(endDate && { endDate: momentOriginal(endDate).format(`YYYY-MM-DD`) }),
      ...(name && { practiceName: name }),
      ...(city && { city: city }),
      ...(phone && { phone: phone }),
      ...(email && { email: email }),
      ...(zip && { zipCode: zip }),
      ...(addressLine1 && { line1: addressLine1 }),
      ...(addressLine2 && { line2: addressLine2 }),
      ...(state && { state: state }),
    },
    ...(user?.isSystemAdministrator ? { _practiceGroupId: groupId } : { _practiceGroupId: _practiceGroupId }),
  };

  const queryParams = (queryObj) => {
    let queryString = ``;
    for (const item in queryObj) {
      if (item === `page`) {
        queryString += `?${item}=${queryObj[item]}`;
      } else if (item !== `filters`) {
        queryString += `&${item}=${queryObj[item]}`;
      } else if (item === `filters`) {
        queryString += `&${item}=${JSON.stringify(queryObj.filters)}`;
      } else if (item == `sort`) {
        queryString += `&${item}=${queryObj[item]}`;
      }
    }

    return queryString;
  };

  useEffect(() => {
    let query = queryParams(payLoad);
    const getPractices = async () => {
      let navigateHome = document.getElementById(`practicesNavigateHomeButton`);
      if ((refreshGroups && query) || (auth && query)) {
        loadingHandler({ loading: true, error: false });
        getRequest(FILTER_PRACTICE_GROUP_URL + query)
          .then((res) => {
            if (
              Object.keys(payLoad?.filters).length &&
              navigateHome &&
              document.getElementById(`practicesNavigateHomeButton`).style.display === `none` &&
              flag > 0
            ) {
              document.getElementById(`practicesNavigateHomeButton`).style.display = `block`;
            }
            loadingHandler({ loading: false, error: false });
            dataHandler(res.data.data.practiceDataArrayWithNumberOfDays);
            totalRowHandler(res.data.data.pager.totalRecords);
            if (Math.ceil(res.data.data.pager.totalRecords / res.data.data.pager.recordsPerPage)) {
              totalPageHandler(Math.ceil(res.data.data.pager.totalRecords / res.data.data.pager.recordsPerPage));
            }
          })
          .catch(() => {
            loadingHandler({ loading: false, error: true });
          });
      }
    };
    getPractices();
  }, [
    flag,
    rowPerPage,
    page,
    sortKey,
    sortOrder,
    addNewPractice,
    refreshGroups
  ]);

  const phoneMask = (val) => {
    let temp = ``;
    let x = val.replace(/\D/g, ``).match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    temp = !x[2] ? x[1] : `(` + x[1] + `) ` + x[2] + (x[3] ? `-` + x[3] : ``);
    setPhone(temp);
  };

  const resetHandler = () => {
    setStartDate(``);
    setEndDate(``);
    setName(``);
    setCity(``);
    setPhone(``);
    setEmail(``);
    setState(``);
    setZip(``);
    setAddressLine1(``);
    setAddressLine2(``);
  };

  const filterHandler = () => {
    const getValues = [startDate?.toString(), endDate?.toString(), name, city, phone, email, state, zip, addressLine1, addressLine2];
    const checkInputs = getValues.filter((e) => {
      return e?.length > 0;
    });

    if (checkInputs?.length > 0) {
      setFlag((p) => p + 1);
    }
  };

  selectorData = usStateName.map((val, index) => {
    return (
      <option key={index} value={val.name}>
        {val.name}
      </option>
    );
  });

  return (
    <div>
      <div className="collapse" id="advanceFilterCard">
        <div
          style={{
            backgroundColor: `white`,
            padding: `5% 2%`,
            margin: `2% 0%`,
          }}
        >
          <Grid container columnSpacing={1} rowSpacing={2}>
            <Grid item xs={6} xm={6} md={6} lg={6} xl={6}>
              <DatePickerCustomizeForFilter
                dateInputValue={startDate}
                setDateInputValue={setStartDate}
              />
            </Grid>
            <Grid item xs={6} xm={6} md={6} lg={6} xl={6}>
              <DatePickerCustomizeForFilter
                dateInputValue={endDate}
                setDateInputValue={setEndDate}
              />
            </Grid>
            <Grid item xs={8} xm={8} md={8} lg={8} xl={8}>
              <InputField
                autoComplete="off"
                onChange={(event) => setName(event.target.value)}
                value={name}
                type="text"
                size='small'
                fullWidth={true}
                label="Practice Name"
              />
            </Grid>
            <Grid item xs={4} xm={4} md={4} lg={4} xl={4}>
              <InputField
                autoComplete="off"
                onChange={(event) => setCity(event.target.value)}
                value={city}
                type="text"
                size='small'
                fullWidth={true}
                label="City"
              />
            </Grid>
            <Grid item xs={5} xm={5} md={5} lg={5} xl={5}>
              <InputField
                autoComplete="off"
                onChange={(event) => phoneMask(event.target.value)}
                value={phone}
                type="text"
                size='small'
                fullWidth={true}
                label="Phone Number"
              />
            </Grid>
            <Grid item xs={7} xm={7} md={7} lg={7} xl={7}>
              <InputField
                autoComplete="off"
                onChange={(event) => setEmail(event.target.value)}
                value={email}
                type="email"
                size='small'
                fullWidth={true}
                label="E-mail Address"
              />

            </Grid>
            <Grid item xs={8} xm={8} md={8} lg={8} xl={8}>

              <MuiSelect
                controlProps={{
                  id: `practiceState`,
                  fullWidth: true,
                  size: `small`
                }}
                label='Select State'
                isSimple={false}
                optionKey={`name`}
                optionValue={`abbreviation`}
                options={[...usStateName]}
                selectProps={{
                  label: `Select State`,
                  name: `practiceState`,
                  onChange: (event) => setState(event.target.value),
                  value: state
                }}

              />
            </Grid>
            <Grid item xs={4} xm={4} md={4} lg={4} xl={4}>
              <InputField
                autoComplete="off"
                onChange={(event) => setZip(event.target.value)}
                value={zip}
                type="number"
                size='small'
                fullWidth={true}
                label="Zip Code"
              />

            </Grid>
            <Grid item xs={12} xm={12} md={12} lg={12} xl={12}>

              <InputField
                autoComplete="off"
                onChange={(event) => setAddressLine1(event.target.value)}
                value={addressLine1}
                type="text"
                size='small'
                fullWidth={true}
                label="Address Line 1"
              />
            </Grid>
            <Grid item xs={12} xm={12} md={12} lg={12} xl={12}>
              <InputField
                autoComplete="off"
                onChange={(event) => setAddressLine2(event.target.value)}
                value={addressLine2}
                type="text"
                size='small'
                fullWidth={true}
                label="Address Line 2"
              />
            </Grid>
          </Grid>
        </div>
      </div>
      {window.innerWidth > 768 ? (
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: `400`, marginLeft: `3%` }}>
            <span className="ml-1">Registered Practices</span>
          </h4>
          <div className="d-flex justify-content-end">
            <Fade in={flag !== 0} mountOnEnter unmountOnExit>
              <Box sx={{ display: `inline-block` }}>
                <ToolTipProvider
                  toolTipProps={{
                    title: `Navigate to default page!`,
                    placement: `top`,
                    arrow: true,
                  }}
                  element={
                    <IconButton
                      id="practicesNavigateHomeButton"
                      onClick={() => {
                        document.getElementById(`practicesNavigateHomeButton`).style.display = `none`;
                        checkAndSetValues();
                        setFlag((p) => p + 1);
                        setCurrentPage(1);
                        setRowsPerPage(10);
                      }}
                      sx={{ color: `rgb(22, 153, 197)`, marginRight: `9px`, padding: `0px` }}
                    >
                      <HomeIcon sx={{ fontSize: `40px` }} />
                    </IconButton>
                  }
                />
              </Box>
            </Fade>
            <button className={visible ? css.customResetButton : `d-none`} type="button" onClick={resetHandler}>
              <i className="fa fa-times"></i> Reset
            </button>
            <button className={visible ? css.customApplyButton : `d-none`} type="button" onClick={filterHandler}>
              <i className="fa fa-search"></i> Apply
            </button>
            <button
              className={css.customFilterButton}
              type="button"
              data-toggle="collapse"
              data-target="#advanceFilterCard"
              onClick={() => setVisible(!visible)}
            >
              <i className="fa fa-filter"></i> Filter
            </button>
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-between align-items-center">
          <h4 style={{ fontWeight: `400`, fontSize: 15, marginLeft: `6%` }}>Registered Practices</h4>
          <div className="d-flex justify-content-end" style={{ marginRight: `6%` }}>
            <Fade in={flag !== 0} mountOnEnter unmountOnExit>
              <Box sx={{ display: `inline-block` }}>
                <ToolTipProvider
                  toolTipProps={{
                    title: `Navigate to default page!`,
                    placement: `top`,
                    arrow: true,
                  }}
                  element={
                    <IconButton
                      id="practicesNavigateHomeButton"
                      onClick={() => {
                        document.getElementById(`practicesNavigateHomeButton`).style.display = `none`;
                        checkAndSetValues();
                        setFlag((p) => p + 1);
                        setCurrentPage(1);
                        setRowsPerPage(10);
                      }}
                      sx={{ color: `rgb(22, 153, 197)`, marginRight: `9px`, padding: `0px` }}
                    >
                      <HomeIcon sx={{ fontSize: `40px` }} />
                    </IconButton>
                  }
                />
              </Box>
            </Fade>
            <button className={visible ? null : `d-none`} type="button" onClick={resetHandler}>
              <i className="fa fa-times" style={{ color: `#c41717` }}></i>
            </button>
            <button className={visible ? null : `d-none`} type="button" onClick={filterHandler}>
              <i className="fa fa-search" style={{ color: `green` }}></i>
            </button>
            <button type="button" data-toggle="collapse" data-target="#advanceFilterCard" onClick={() => setVisible(!visible)}>
              <i className="fa fa-filter" style={{ color: `#1699C5` }}></i>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};





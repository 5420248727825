import {
  UPDATE_TIME_OBJECT,
  SET_TIMER_TOGGLE_BUTTON,
  PATIENT_REPORT_TIME_IN_SECONDS,
  REFRESH_MANUAL_TIMELOG_COMPONENT,
  TIMER_REFRESH_FLAG,
  TURN_ON_PATIENT_TIMER,
  TURN_OFF_PATIENT_TIMER,
  RESET_TIMER_VALUE,
  START_AUDIO_CALL,
  END_AUDIO_CALL,
  START_VIDEO_CALL,
  END_VIDEO_CALL,
  NO_OF_SECONDS_FOR_PATIENT,
  BASIC_DETAIL_TIMER_REFRESH_FLAG,
  UPDATE_INDIVIDUAL_PATIENT_ACCUMULATED_TIME,
  SPECIAL_TIMER_FLAG_CHECK_BEFORE_MAKE_ADDENDUM,
} from './timerTypes';

export const updateTimerObject = (timerObject) => {
  return {
    type: UPDATE_TIME_OBJECT,
    payload: timerObject,
  };
};

export const specialTimerFlagCheckBeforeMakeAddendum = (timerFlag) => {
  return {
    type: SPECIAL_TIMER_FLAG_CHECK_BEFORE_MAKE_ADDENDUM,
    payload: timerFlag,
  };
};

export const setTimerToggleButtonOff = () => {
  return {
    type: SET_TIMER_TOGGLE_BUTTON,
  };
};
export const setTimerToggleButtonOn = () => {
  return {
    type: RESET_TIMER_VALUE,
  };
};

export const startVideoCall = () => {
  return {
    type: START_VIDEO_CALL,
  };
};
export const endVideoCall = () => {
  return {
    type: END_VIDEO_CALL,
  };
};

export const startAudioCall = () => {
  return {
    type: START_AUDIO_CALL,
  };
};
export const endAudioCall = () => {
  return {
    type: END_AUDIO_CALL,
  };
};
export const noOfSecondsForPatient = (patientTimeInSeconds) => {
  return {
    type: NO_OF_SECONDS_FOR_PATIENT,
    payload: patientTimeInSeconds,
  };
};
export const patientReportTimeInSeconds = (patientReportTimeInSeconds) => {
  return {
    type: PATIENT_REPORT_TIME_IN_SECONDS,
    payload: patientReportTimeInSeconds,
  };
};
export const patientBasicDetailTimerResetFlag = (flag) => {
  return {
    type: BASIC_DETAIL_TIMER_REFRESH_FLAG,
    payload: flag,
  };
};
export const refreshManualTimeLogComponent = (flag) => {
  return {
    type: REFRESH_MANUAL_TIMELOG_COMPONENT,
    payload: flag,
  };
};
export const patientTimerRefrshFlag = (flag) => {
  return {
    type: TIMER_REFRESH_FLAG,
    payload: flag,
  };
};

export const updateIndividualPatientAccumulatedTime = (patientId, accumulatedTime) => {
  return {
    type: UPDATE_INDIVIDUAL_PATIENT_ACCUMULATED_TIME,
    payload: { patientId: patientId, accumulatedTime },
  };
};

export const turnOnPatientTimer = () => {
  return {
    type: TURN_ON_PATIENT_TIMER,
  };
};

export const turnOffPatientTimer = () => {
  return {
    type: TURN_OFF_PATIENT_TIMER,
  };
};

export const _setnoOfSecondsForPatient = (patients) => {
  let nestedObj = {};
  //
  patients.map((patient) => {
    nestedObj[patient._id] = {
      numberOfSecondsForPatient: patient.totalTime,
    };
  });
  return (dispatch) => {
    dispatch(noOfSecondsForPatient(nestedObj));
  };
};

export const _patientReportTimeInSeconds = (patients) => {
  let nestedObj = {};
  patients.map((patient) => {
    nestedObj[patient._id] = {
      numberOfSecondsForPatient: patient.numberOfSecondsForPatient,
    };
  });
  return (dispatch) => {
    dispatch(patientReportTimeInSeconds(nestedObj));
  };
};

export const _patientBasicDetailTimerResetFlag = (flag) => {
  return (dispatch) => {
    dispatch(patientBasicDetailTimerResetFlag(flag));
  };
};
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _patientTimerRefrshFlag = (flag) => {
  return (dispatch) => {
    dispatch(patientTimerRefrshFlag(flag));
  };
};
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _refreshManualTimeLogComponent = (flag) => {
  return (dispatch) => {
    dispatch(refreshManualTimeLogComponent(flag));
  };
};

// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _updateIndividualPatientAccumulatedTime = (patiendId, accumulatedTime) => {
  return (dispatch) => {
    dispatch(updateIndividualPatientAccumulatedTime(patiendId, accumulatedTime));
  };
};
// -> ASYNC SPECIAL FUNCTION, RETURN ANOTHER FUNCTION
// -> RECEIVE DISPATCH AS ARGUMENT
export const _specialTimerFlagCheckBeforeMakeAddendum = (timerFlag) => {
  return (dispatch) => {
    dispatch(specialTimerFlagCheckBeforeMakeAddendum(timerFlag));
  };
};

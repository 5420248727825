import React, { useRef } from 'react';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { postRequest, patchRequest } from 'src/crud/crud';
import { Stack, TextField, Box, MenuItem, InputAdornment, IconButton, LinearProgress, Grid } from '@mui/material';
import { TravelExplore } from '@mui/icons-material';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import { toast, ToastContainer } from 'react-toastify';
import css from './addNewPractice.module.css';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';

export const Asktwillio = ({ practiceId, onClose }) => {
  const [twillioNumbers, settwillioNumbers] = React.useState([]);
  const [numberLoadingError, setnumberLoadingError] = React.useState({ loading: false, error: false });
  const [loading, setloading] = React.useState({ loading: false, error: false });
  const inputRef = useRef(null);

  const { handleSubmit, control, getValues, setError } = useForm({
    mode: `onChange`,
    reValidateMode: `onChange`,
  });

  const askTwilioHandler = (name) => {
    const getTwillioNumber = getValues(`twiliioAreacode`);

    let integer = parseInt(getTwillioNumber, 10);
    let validArr = [
      205, 251, 256, 334, 659, 938, 907, 250, 480, 520, 602, 623, 928, 327, 479, 501, 870, 209, 213, 310, 323, 341, 369, 408, 415, 424, 442, 510, 530,
      559, 562, 619, 626, 627, 628, 650, 657, 661, 669, 707, 714, 747, 760, 764, 805, 818, 831, 858, 909, 916, 925, 935, 949, 951, 303, 719, 720, 970,
      203, 475, 860, 959, 302, 202, 239, 305, 321, 352, 386, 407, 561, 689, 727, 754, 772, 786, 813, 850, 863, 904, 941, 954, 229, 404, 470, 478, 678,
      706, 762, 770, 912, 808, 208, 217, 224, 309, 312, 331, 447, 464, 618, 630, 708, 730, 773, 779, 815, 847, 872, 219, 260, 317, 574, 765, 812, 319,
      515, 563, 641, 712, 316, 620, 785, 913, 270, 364, 502, 606, 859, 225, 318, 337, 504, 985, 207, 227, 240, 301, 410, 443, 667, 339, 351, 413, 508,
      617, 774, 781, 857, 978, 231, 248, 269, 313, 517, 586, 616, 679, 734, 810, 906, 947, 989, 218, 320, 507, 612, 651, 763, 952, 228, 601, 662, 769,
      314, 417, 557, 573, 636, 660, 816, 975, 406, 308, 402, 531, 702, 775, 603, 201, 551, 609, 732, 848, 856, 862, 908, 973, 505, 575, 212, 315, 347,
      516, 518, 585, 607, 631, 646, 716, 718, 845, 914, 917, 929, 252, 336, 704, 828, 910, 919, 980, 984, 701, 216, 234, 283, 330, 380, 419, 440, 513,
      567, 614, 740, 937, 405, 539, 580, 918, 458, 503, 541, 971, 215, 267, 272, 412, 445, 484, 570, 582, 610, 717, 724, 814, 835, 878, 401, 803, 843,
      864, 605, 423, 615, 731, 865, 901, 931, 210, 214, 254, 281, 325, 361, 409, 430, 432, 469, 512, 682, 713, 737, 806, 817, 830, 832, 903, 915, 936,
      940, 956, 972, 979, 385, 435, 801, 802, 276, 434, 540, 571, 703, 757, 804, 206, 253, 360, 425, 509, 564, 304, 681, 262, 274, 414, 534, 608, 715,
      920, 307,
    ];

    let hasCode = validArr.some((e) => e === integer);

    let payLoad = {
      twilioAreaCode: getTwillioNumber,
    };
    if (hasCode) {
      inputRef.current.focus();
      setnumberLoadingError({ error: false, loading: true });
      postRequest(`practices/list/twilioNumbers/${practiceId}`, payLoad)
        .then((res) => {
          settwillioNumbers(res?.data?.twilioNumbers);
          setnumberLoadingError({ error: false, loading: false });
        })
        .catch(() => {
          setnumberLoadingError({ error: true, loading: false });
        });
    } else {
      setError(name, { type: `custom`, message: `Oops try valid area code` });
    }
  };
  const onSubmitTwillio = (data) => {
    let payLoad = {
      twilioNumber: data?.twilioNumber,
    };
    setloading({ loading: true, error: false });
    patchRequest(`practices/twilio/buyNumber/${practiceId}`, payLoad)
      .then((res) => {
        setloading({ loading: false, error: false });
        toast.success(() => <>Number purchased successfully !</>, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        onClose(res);
      })
      .catch(() => setloading({ loading: false, error: false }));
  };
  let twillioNumbersOptions = twillioNumbers?.map((e, index) => (
    <MenuItem value={e} key={index + e}>
      {e}
    </MenuItem>
  ));
  return (
    <FormProvider>
      <ToastContainer />
      <form onSubmit={handleSubmit(onSubmitTwillio)}>
        <>
          <Grid sx={{ mt: 3 }} container rowSpacing={1.5} columnSpacing={1.5}>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Controller
                name="twiliioAreacode"
                control={control}
                rules={{
                  required: `*Required`,
                  maxLength: 3,
                  minLength: 2,
                }}
                render={({ field: { onChange, onBlur, value, name }, fieldState: { invalid, error } }) => (
                  <TextField
                    type={`number`}
                    size="small"
                    label="*Area Code"
                    fullWidth
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    error={invalid}
                    helperText={error?.type === `minLength` ? `Invalid` : error?.type === `maxLength` ? `Invalid` : error?.message}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => askTwilioHandler(name)}>
                            <TravelExplore />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Controller
                name="twilioNumber"
                control={control}
                rules={{
                  required: `*Required`,
                }}
                render={({ field: { onChange, onBlur, value }, fieldState: { invalid, error } }) => (
                  <TextField
                    inputRef={inputRef}
                    select
                    size="small"
                    label="*Select Number"
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    error={invalid}
                    helperText={error?.message}
                    fullWidth
                  >
                    {numberLoadingError.error ? (
                      <Box sx={{ color: `red`, textAlign: `center`, mt: 2, pb: 2 }}>
                        <Box sx={{ display: `inline-block`, mt: 0.2 }}>Sorry try again later an error has occured</Box>
                        {` `}
                        <Box sx={{ display: `inline-block` }}>
                          <GppMaybeOutlinedIcon sx={{ color: `red` }} fontSize="large" />
                        </Box>
                      </Box>
                    ) : numberLoadingError.loading ? (
                      <LinearProgress sx={{ mt: 2 }} />
                    ) : (
                      twillioNumbersOptions
                    )}
                  </TextField>
                )}
              />
            </Grid>
          </Grid>
          <Stack sx={{ mt: 1.5 }} direction="row" justifyContent="center">
            <button
              id={css.addProviderBtn}
              className="btn btn-outline-info btn-sm"
              disabled={loading?.loading}
              type="submit"
              onClick={handleSubmit(onSubmitTwillio)}
            >
              {loading?.error ? (
                <ErrorOutlineSharpIcon fontSize="small" />
              ) : loading?.loading ? (
                <span className="spinner-border spinner-border-sm text-primary"></span>
              ) : (
                <span>Submit</span>
              )}
            </button>
          </Stack>
        </>
      </form>
    </FormProvider>
  );
}

/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import css from './ListView.module.css';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import { useParams } from 'react-router-dom';
import { getRequest, COUNT_ALL_PHRASES } from 'src/crud/crud';
import { Paginator } from 'src/pages/common/components/pagination/Paginator';
import { Box, IconButton, Slide, Typography, Chip } from '@mui/material';
import { sortIconHandler, sortKeyGiver } from 'src/pages/home/modules/patient2/PatientOverview/PatientOverView';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ToolTipProvider } from '../Tooltip/ToolTipProvider';
import { CustomizedDialogs } from '../modal/CustomizeModal';
import { dynamicWriteCredential } from 'src/utils';

export const ListView = () => {
  const [phrases, setPhrase] = React.useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = React.useState(1);
  const [recordPerPage, setRecordPerPage] = React.useState(10);
  const [phraseLength, setPhrasesLength] = React.useState(0);
  const [sorting, setSorting] = useState({
    sortOrder: `ASC`,
    sortField: `count`,
  });
  const [openPhrase, setOpenPhrase] = useState(false);
  const [selectedPhrase, setSelectedPhrase] = useState({});
  const params = useParams();
  useEffect(() => {
    getAllphrases();
  }, [page, recordPerPage, params?.id, sorting]);
  const sortHandler = (key, order) => {
    setSorting((p) => ({
      ...p,
      sortField: key,
      sortOrder: order,
    }));
  };
  const getAllphrases = () => {
    let sortingObject = { ...sorting, sortOrder: sorting.sortOrder === `ASC` ? -1 : 1 };
    let practiceId = { practiceId: params?.id }
    let payLoad = {
      id: params?.id,
      page: page,
      recordPerPage: recordPerPage,
      countFlag: params?.id ? false : true,
      sorting: JSON.stringify(sortingObject),
      practice: JSON.stringify(practiceId),
    };

    setLoading(true);
    let query = COUNT_ALL_PHRASES + `?pageNumber=${payLoad?.page}&filter=${payLoad?.practice}&pageSize=${payLoad?.recordPerPage}&sorting=${payLoad?.sorting}`
    getRequest(query)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          setPhrase(response.data.phrases.phrases);
          setPhrasesLength(response.data.phrases.totalDocuments);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const tableRows = () => {
    return (
      <React.Fragment>
        {!loading ? (
          phrases?.map((item, index) => {
            item.text = dynamicWriteCredential(item?.text)
            return (
              <Slide key={item?._id} in={4} timeout={500} mountOnEnter unmountOnExit direction="right">
                <tr draggable="true" key={item?._id}>
                  <td scope="row">{index + 1}</td>
                  <td className="text-capitalize" title={item?.title}>
                    {item.title.length > 20 ? item.title.substring(0, 20) + `...` : item.title}
                  </td>
                  <ToolTipProvider
                    toolTipProps={{
                      arrow: true,
                      placement: `top`,
                      title: item?.text,
                    }} element={
                      <td >{item?.text?.length > 80 ? item?.text?.substring(0, 80) + `...` : item?.text}</td>
                    } />
                  <td className={css.customTd}>
                    <ToolTipProvider
                      toolTipProps={{
                        arrow: true,
                        placement: `top`,
                        title: `Click to view Phrase`,
                      }}
                      element={
                        <IconButton
                          onClick={() => {
                            setSelectedPhrase(item);
                            setOpenPhrase(true);
                          }}
                          sx={{ padding: `0px`, marginTop: `5px`, marginRight: `10px`, color: `#1699c5` }}
                          aria-label="view"
                        >
                          <VisibilityIcon />
                        </IconButton>
                      }
                    />

                    <Chip
                      title={`this phrase used ${item?.count} times`}
                      className="mt-2"
                      size="small"
                      label={item?.count}
                      color="primary"
                      variant="outlined"
                    />
                  </td>
                </tr>
              </Slide>
            )

          }


          )
        ) : (
          <tr className="text-center">
            <PuffSpinner />
          </tr>
        )}
      </React.Fragment>
    );
  }
  return (
    <div className="p-5">
      <div id={css.practiceContainer} style={{ position: `relative` }}>
        <div className="table-responsive" style={{ borderRadius: `10px 10px 10px 10px` }}>
          <table className="table table-striped">
            <thead style={{ borderBottom: `1px solid grey` }}>
              <tr style={{ backgroundColor: `white` }}>
                <td colSpan={10}>
                  <h4 className="text-dark">Smart Phrases</h4>
                  <div className="d-flex justify-content-between align-items-center"></div>
                </td>
              </tr>

              <tr>
                <th scope="col" className={css.customCol0}>
                  #ID
                </th>
                <th scope="col" className={css.customCol2} onClick={() => sortHandler(`title`, sortKeyGiver(sorting, `title`))}>
                  TITLE
                  {sortIconHandler(sorting, `title`)}
                </th>
                <th scope="col" className={css.customCol3} onClick={() => sortHandler(`text`, sortKeyGiver(sorting, `text`))}>
                  PHRASE
                  {sortIconHandler(sorting, `text`)}
                </th>
                <th scope="col" className={css.customCol4} onClick={() => sortHandler(`count`, sortKeyGiver(sorting, `count`))}>
                  USAGE COUNT
                  {sortIconHandler(sorting, `count`)}
                </th>
              </tr>
            </thead>
            <tbody id={css.body}>{tableRows()}</tbody>
          </table>
          <Paginator setPage={setPage} page={page} count={phraseLength} setRecordPerPage={setRecordPerPage} recordPerPage={recordPerPage} />
        </div>
      </div>
      {openPhrase && (
        <CustomizedDialogs
          title={`View Phrase`}
          open={openPhrase}
          setOpen={() => {
            setOpenPhrase(false);
            setSelectedPhrase({});
          }}
        >
          <Typography variant="h6" gutterBottom sx={{ color: `#888888` }}>
            {selectedPhrase?.title}
          </Typography>
          <Box>{selectedPhrase?.text}</Box>
        </CustomizedDialogs>
      )}
    </div>
  );
}



import React, { useState, useEffect, useCallback, useRef } from 'react';
import ReactQuill from 'react-quill';
import { cleanWordsFormatterVal } from 'src/utils';
import './wordsFormatters.css';
import { Box } from '@mui/material';
import { errorToast } from '../snackBar/toast';

export const WordsFormatter = ({ maxLength, hasOnChange, onBlur, placeHolder,
  setKeyValue, readyToSet, isTask, commentEmptyOnApiSuccess,
  setCommentEmptyOnApiSuccess, hasInitialValue }) => {

  const [value, setValue] = useState({ value: isTask ? hasInitialValue : ``, from: `` });
  const [maxLimitReached, setMaxLimitReached] = useState(false)
  const refCheck = useRef(0)
  const performAction = useCallback(() => {
    if (readyToSet) {
      const { value: exVal, from } = value
      setKeyValue(exVal, from);
    }
    if (hasInitialValue && refCheck.current === 0) {
      setValue({ value: hasInitialValue, from: `initialVal` });
      refCheck.current = refCheck.current + 1;
    }
    if (commentEmptyOnApiSuccess) {
      setValue({ value: ``, from: `commentEmptyOnApiSuccess` });
    }
  }, [readyToSet, value, commentEmptyOnApiSuccess, hasInitialValue]);

  useEffect(() => {
    performAction();
  }, [performAction]);

  const modules = {
    toolbar: [
      [{ header: `1` }, { header: `2` }, { font: [] }],
      [{ size: [] }],
      [`bold`, `italic`, `underline`, `strike`, `blockquote`],
      [{ list: `ordered` }, { list: `bullet` }, { indent: `-1` }, { indent: `+1` }],
      [`clean`],
    ],
    clipboard: {
      matchVisual: false,
    },
  };

  const maxLengthValidation = (changeVal) => {
    const doHasOnChange = () => {
      if (hasOnChange) hasOnChange(changeVal);
    };
    if (maxLength) {
      let isValid = cleanWordsFormatterVal(changeVal)?.length <= maxLength;
      if (maxLimitReached) {
        errorToast(`Maximum allowed characters ${maxLength}`)
        setMaxLimitReached(false)
        return false
      } else if (isValid) {
        setValue({ value: changeVal, from: `onChange` })
        doHasOnChange()
      } else {
        let slicedVal = changeVal.slice(0, 1800)
        setMaxLimitReached(true)
        setValue({ value: slicedVal, from: `onChange` });
        doHasOnChange()
      }
    } else setValue({ value: changeVal, from: `onChange` })
  }

  const onChangeHandler = (value) => {
    if (setCommentEmptyOnApiSuccess && commentEmptyOnApiSuccess) {
      setCommentEmptyOnApiSuccess(false);
    }
    maxLengthValidation(value)
  }

  return <div className={`parentScroll`}>
    <ReactQuill
      {...onBlur && {
        onBlur: () => {
          const filteredVal = cleanWordsFormatterVal(value)
          onBlur(filteredVal)
        }
      }} placeholder={placeHolder}

      bounds={`parentScroll`}
      scrollingContainer={`parentScroll`}
      modules={modules} theme="snow" value={value.value} onChange={(value) => onChangeHandler(value)} />
    {maxLength && maxLimitReached ? <Box sx={{ mt: .5, color: `red` }}>Max limit reached</Box> : null}
  </div>
};

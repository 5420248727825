import React from 'react'
import css from './roundDot.module.css'

export const RoundDot = ({ style }) => {

    return (
        <div><span {...(style ? { style: { ...style } } : {})}   {...(!style ? { className: `${css.dot} ${css.rounded}` } : { className: `${css.rounded}` })}   ></span></div>
    )
}


import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { MapUserRoles } from 'src/router/UserRoles/MapUserRoles';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import { loadingSetter } from 'src/utils';
import { Logo } from 'src/pages/common/components/Logo/Logo';
import { Box, Container } from '@mui/material';

export const SwitchPractice = ({ open, setOpen }) => {
  const [loadingState, setLoadingState] = useState({
    loading: false,
    error: false
  })
  const { loading } = loadingState
  const loadingHandler = (key, value) => {
    loadingSetter({
      setLoading: setLoadingState,
      key: key,
      value: value
    })
  };
  const { _id: userId, uniqueString, systemRoles } = useSelector((state) => state?.user?.user)

  return (
    <CustomizedDialogs title="Switch Practice" open={open} size="xl" setOpen={setOpen} fullWidth={true}>
      <Box sx={{ pb: 4 }}>
        <Box sx={{ display: `flex`, justifyContent: `center`, width: `100%`, pt: 5 }}>
          <Logo isFromRoles={true} />
        </Box>
        <Box sx={{ width: `100%`, mt: `22px` }}>
          <Container maxWidth="xl">
            <MapUserRoles isSwitch={true} uniqueString={uniqueString} _id={userId}
              loadingHandler={loadingHandler}
              systemRoles={systemRoles} />
          </Container>
        </Box>
      </Box>
      {loading && <PuffSpinner />}
    </CustomizedDialogs>
  );
};

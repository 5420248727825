import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StatsWidget } from './components/statsWidget/statsWidget';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AdherenceWidget } from './components/adherenceWidget/adherenceWidget';
import {
  getRequest, postRequest, DASHBOARD_ANALYTICS, GET_PATIENT_ONLINE_OFFLINE_STATUS,
  SMART_PHRASE_LIST,
} from '../../../../crud/crud';
import { AdministrationPractice } from '../../../home/modules/administration/practice/practice';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { momentWrapper } from 'src/momentWrapper';
import { GroupAdminDashboard } from './components/Group admin/GroupAdminDashboard';
import { SystemAdminDashboard } from './components/Systemadmin/SystemAdminDashboard';
import { Grid } from '@mui/material';
import { setSmartPhrases } from 'src/redux/practices/practiceActions';
import { generateInteger } from 'src/utils';

export const Dashboard = () => {
  const { user: parentUser, resmed } = useSelector((state) => state)
  const { user } = parentUser
  const { isRealTimeDataAvailable: realTimeDataAvailable, importNewPatientSuccessfully } = resmed
  const [totalPatients, setTotalPatients] = useState(0);
  const [adherenceArr, setAdherenceArr] = useState([]);
  const [onlinePatients, setOnlinePatients] = useState(0);
  const [offlinePatients, setOfflinePatients] = useState(0);
  // const [realTimeDataAvailableFlag, setRealTimeDataAvailableFlag] = useState(realTimeDataAvailable);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user?.isSystemAdministrator && !user?.isGroupAdmin) {
      let payload = {
        count: false,
      };
      let route = `${SMART_PHRASE_LIST}${payload?.id}?pageNumber=${payload?.page}&pageSize=${payload?.recordPerPage}&fieldName=${payload?.sortKey}&Sortorder=${payload?.sortOrder}`

      getRequest(route, payload)
        .then((response) => {
          if (response.status === 200) {
            dispatch(setSmartPhrases(response?.data?.phrases?.phrases));
          }
        })
        .catch((e) => {
          process.env.NODE_ENV === `development` && console.log(e);
        });
    }
    //
    if (!user?.isGroupAdmin && !user?.isSystemAdministrator) {
      if (user.isSuperAdmin || user.isProvider || user.isClinicalStaff || user.isTransportationAdmin) {
        // DASHBOARD ANALYTICS
        const endDate = momentWrapper(new Date()).subtract(1, `days`).set({ hours: 23, minutes: 59, seconds: 59 }).toISOString();
        const startDate = momentWrapper(new Date(endDate)).subtract(29, `days`).set({ hours: 0, minutes: 0, seconds: 0 }).toISOString();
        postRequest(DASHBOARD_ANALYTICS, {
          endDate: endDate,
          startDate: startDate,
        }).then((response) => {
          const prepareAnalytics = (res) => {
            if (res.data.dashboardAnalytics) {
              const { last3DaysStats, last30DaysStats } = res.data.dashboardAnalytics
              for (const key in last3DaysStats) {
                last3DaysStats[key] = generateInteger(last3DaysStats[key])
              }
              for (const key in last30DaysStats) {
                last30DaysStats[key] = generateInteger(last30DaysStats[key])
              }
              setAdherenceArr(res.data.dashboardAnalytics);
            }
          }
          prepareAnalytics(response)

        }).catch(() => {

        })

      }
    }
  }, [user, importNewPatientSuccessfully]);

  useEffect(() => {
    if (!user?.isGroupAdmin && !user?.isSystemAdministrator) {
      if (user.isSuperAdmin || user.isProvider || user.isClinicalStaff || user.isTransportationAdmin) {
        getRequest(GET_PATIENT_ONLINE_OFFLINE_STATUS).then((response) => {
          setTotalPatients(response?.data?.numberOfTotalPatients)
          setOnlinePatients(response.data?.numberOfOnlinePatients);
          setOfflinePatients(response.data?.numberOfOfflinePatients);
        }).catch(() => {

        })

      }
    }
  }, [importNewPatientSuccessfully]);

  // useEffect(() => {
  //   if (realTimeDataAvailable) {
  //     if (realTimeDataAvailable.apiStatus === `available`) {
  //       setRealTimeDataAvailableFlag(true);
  //     }
  //     if (realTimeDataAvailable.apiStatus === `unavailable`) {
  //       setRealTimeDataAvailableFlag(false);
  //     }
  //     if (realTimeDataAvailable.apiStatus === `disabled`) {
  //       setRealTimeDataAvailableFlag(false);
  //     }
  //   }
  // }, [realTimeDataAvailable]);

  // const liveDataToggleBtn = () => {
  //   setRealTimeDataAvailableFlag(!realTimeDataAvailableFlag);
  //   // EVENT EMIT - TO GET LIVE DATA STATUS
  //   if (realTimeDataAvailable?.apiStatus === `available`) {
  //     newSocket.emit(`changeResmedAPIStatus`, {
  //       apiStatus: `disabled`,
  //     });
  //   }

  //   if (realTimeDataAvailable?.apiStatus === `disabled` || realTimeDataAvailable?.apiStatus === `unavailable`) {
  //     newSocket.emit(`changeResmedAPIStatus`, {
  //       apiStatus: `available`,
  //     });
  //   }
  // };

  if (!user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff && !user.isTransportationAdmin && user.isSystemAdministrator && !user?.isGroupAdmin) {
    return <SystemAdminDashboard />;
  } else if (user?.isGroupAdmin && !user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff && !user.isTransportationAdmin && !user.isSystemAdministrator) {
    return <GroupAdminDashboard />;
  } else if (user.isSuperAdmin && !user.isProvider && !user.isClinicalStaff && !user.isTransportationAdmin && !user?.isGroupAdmin) {
    return (
      <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
        <Box sx={{ display: `flex`, justifyContent: `space-between`, flexDirection: { xs: `column`, sm: `row` }, gap: `10px` }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{ fontWeight: `normal`, textTransform: `capitalize`, fontSize: 24, textAlign: { xs: `center`, sm: `left` } }}
          >
            Welcome,{` `}
            <span style={{ fontWeight: `600` }}>
              {user?.firstName} {user?.lastName}
            </span>
          </Typography>
        </Box>
        <AdministrationPractice />
      </Box>
    );
  } else {
    return (
      <Box sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
        <Box sx={{ display: `flex`, justifyContent: `space-between`, flexDirection: { xs: `column`, sm: `row` }, gap: `10px` }}>
          <Typography
            variant="h1"
            component="h1"
            gutterBottom
            sx={{ fontWeight: `normal`, textTransform: `capitalize`, fontSize: 24, textAlign: { xs: `center`, sm: `left` } }}
          >
            Welcome,{` `}
            <span style={{ fontWeight: `600` }}>
              {user?.firstName} {user?.lastName}
            </span>
          </Typography>
        </Box>
        {
          user?.isProvider || user?.isClinicalStaff || user?.isTransportationAdmin ? (
            <Box sx={{ display: `flex`, gap: `60px`, flexDirection: `column` }}>
              <StatsWidget totalPatients={totalPatients} onlinePatients={onlinePatients} offlinePatients={offlinePatients}
                user={user}
              />
              <Grid container spacing={2} sx={{ display: `flex`, justifyContent: `center` }} >
                <Grid item xs={4} sm={4} md={6} lg={4} xl={4}>
                  <AdherenceWidget adherenceArr={adherenceArr} user={user} />
                </Grid>
                <Grid item xs={4} sm={4} md={6} lg={4} xl={4}>
                  <AdherenceWidget adherenceArr={adherenceArr} isLast3days={true} />
                </Grid>
              </Grid>
            </Box>
          ) : null
        }
      </Box >
    );
  }
}
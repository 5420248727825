import React, { useEffect, useState } from 'react'
import { base64FileNameFinder, convertToBase64 } from 'src/utils';
import { Box, IconButton, Zoom } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import { useSelector } from 'react-redux';
import { EDIT_GROUP_LOGO, patchRequest } from 'src/crud/crud';
import { ToastContainer } from 'react-toastify';
import { Bootspinner } from 'src/pages/common/components/spinner/bootspinner/Bootspinner';

export const EditGroupLogo = ({ perFormAction }) => {
    const [logoImage, setLogoImage] = useState({ img: false, error: false })
    const [loading, setLoading] = useState(false)
    const _practiceGroupId = useSelector((state) => state.practiceGroup.groupId)

    useEffect(() => {
        return () => {
            setLogoImage({ img: false, error: false })
        }
    }, [])
    const uploadGroupLogo = async (e) => {
        setLogoImage({ img: false, error: false })
        let { files } = e.target;
        let base64File = await convertToBase64(files[0], { size: false, validateWidth: 270, validateHeight: 100 });
        if (base64File) {
            setLogoImage((p) => ({ ...p, img: base64File }))
            let fileName = base64FileNameFinder(files[0]?.name)
            setLoading(true)
            patchRequest(`${EDIT_GROUP_LOGO}/${_practiceGroupId}`, { logo: base64File, logoName: fileName }).then((res) => {
                setLoading(false)
                perFormAction && perFormAction(res)
            }).catch(() => setLoading(false))
        } else {
            setLogoImage((p) => ({ ...p, error: `Image dimension should be 100*270 or less than 100*270` }))
        }
    }
    return (
        <Box sx={{ position: `relative` }}>
            <ToastContainer />
            <Box sx={{ width: `${logoImage?.img ? `270px` : `auto`}`, border: `1px solid ${logoImage?.img ? `red` : `white`}`, borderRadius: `10px`, height: `100px` }}>
                <Zoom in={logoImage?.img}>
                    <img style={{
                        width: `inherit`,
                        borderRadius: `inherit`,
                        height: `inherit`,
                        objectFit: `fill`
                    }} src={logoImage?.img ? logoImage?.img : null} alt="" />
                </Zoom>
            </Box>
            <Box sx={{ position: `absolute`, zIndex: 5, top: 0 }}>
                {loading ? <Bootspinner type={`text-primary`} /> : <IconButton aria-label="upload picture" component="label">
                    <input
                        onChange={uploadGroupLogo}
                        hidden
                        accept="image/*"
                        type="file"
                        onClick={(e) => (e.target.value = ``)}
                    />
                    <PhotoCamera />
                </IconButton>}

            </Box>
            <p style={{ color: `red` }}>{logoImage?.error && logoImage.error}</p>
        </Box>

    )
}
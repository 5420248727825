import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { postRequest, ADD_COMMENT_TO_SPECIFIC_TICKET } from 'src/crud/crud';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { WordsFormatter } from 'src/pages/common/components/WordsFormatter/WordsFormatter';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { PuffSpinner } from 'src/pages/common/components/spinner/puff/puff';
import { cleanWordsFormatterVal } from 'src/utils';

export const SaveComment = ({ issueDetail, commnetSavingFlag, setCommentSavingFlag, selectedTicketDetails }) => {
  const [commentEmptyOnApiSuccess, setCommentEmptyOnApiSuccess] = useState(false);
  const [comment, setComment] = useState(``);
  const { user } = useSelector((state) => state?.user);

  const savingCommentHandler = (ticketId) => {
    let filterValue = cleanWordsFormatterVal(comment);
    if (!filterValue) {
      errorToast(`Comment text is empty.`);
      return;
    }
    setCommentSavingFlag(true);
    postRequest(ADD_COMMENT_TO_SPECIFIC_TICKET + ticketId, { comment: comment })
      .then(() => {
        setCommentEmptyOnApiSuccess(true)
        selectedTicketDetails(ticketId);
        setComment(``);
        setCommentSavingFlag(false);
        // Red dot notification event emit
        newSocket.emit(`getAllTicketsOfUser`, {
          userId: user._id,
        });
      })
      .catch((err) => {
        setCommentSavingFlag(false);
        errorToast(err?.response?.data?.message ? err?.response?.data?.message : err.response?.data?.error);
      });
  };

  //   savingCommentHandler(issueDetail._id)
  return (
    <>
      {commnetSavingFlag && <PuffSpinner />}

      {issueDetail.issueStatus !== `closed` && <WordsFormatter
        commentEmptyOnApiSuccess={commentEmptyOnApiSuccess}
        setCommentEmptyOnApiSuccess={setCommentEmptyOnApiSuccess}
        placeHolder={`Add Comment`} setKeyValue={setComment} readyToSet={true} />}
      {issueDetail.issueStatus !== `closed` ? (
        <Box sx={{ display: `flex`, justifyContent: `flex-end`, mt: { xs: `30%`, sm: `15%`, md: `15%`, lg: `15%`, xl: `15%` } }}>
          <button className="btn btn-success" type="button" size="sm" disabled={commnetSavingFlag} onClick={() => savingCommentHandler(issueDetail?._id)}>
            Comment
          </button>
        </Box>
      ) : null}
    </>
  );
};

import React, { useState } from 'react';
import logo from '../../../assets/monitair-logo.svg';
import css from './resetPassword.module.css';
import {
  postRequest,
  RESET_PASSWORD_URL
} from '../../../crud/crud';
import { PulseSpinner } from '../../common/components/spinner/spinner';
import { Redirect } from 'react-router-dom';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { FooterWrapper } from 'src/pages/common/HOC/FooterWrapper';
import { Box } from '@mui/material';

export const NintyDaysResetPassword = (props) => {
  const secret = props.match.params.secret;
  const id = props.match.params.id;
  const [password, setPassword] = useState(``);
  const [confirmPass, setConfirmPass] = useState(``);
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState(``);
  const [loading, setLoading] = useState(false);

  const newPasswordHandler = (e) => {
    e.preventDefault();
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[.,#?!@$%^&*_]).{8,}$/;
    if (!regex.test(password) || !regex.test(confirmPass)) {
      setError(true);
      setPassword(``);
      setConfirmPass(``);
    } else if (password !== confirmPass) {
      alert(`Password mismatched`);
      setPassword(``);
      setConfirmPass(``);
    } else {
      setLoading(true);
      postRequest(RESET_PASSWORD_URL, {
        _id: id,
        secretToken: secret,
        resetPassword: confirmPass,
      })
        .then(() => {
          setLoading(false);
          setRedirect(`/login`);
        })
        .catch((err) => {
          setLoading(false);
          alert(err?.response?.data?.message);
        });
    }
  };

  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <FooterWrapper showOnlytLiscense={true}>
      <Box>
        <div id={css.container}>
          <img id={css.logo} src={logo} alt="Logo" />
          <div id={css.title}>
            <h5 id={css.titleText}>Enter a new password</h5>
          </div>
          <p id={css.bannerReset}>You've not reset your password since 90 days.Please reset your password.</p>
          <div id={error ? css.bannerResetWrong : css.bannerReset}>
            <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>Please enter a password that meets the following criteria:</p>
            <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-At least 8 characters.</p>
            <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-A mix of both uppercase and lowercase letters.</p>
            <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-A mix of letters and numbers.</p>
            <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-Include at least one special character (e.g., ! @ # ? ).</p>
          </div>
          <form id={css.form} onSubmit={newPasswordHandler}>
            <InputField size='small' type="password" placeholder="New Password" onChange={(e) => setPassword(e.target.value)} value={password} />
            <InputField size='small'
              type="password"
              sx={{ mt: 1 }}
              placeholder="Confirm the New Password"
              onChange={(e) => setConfirmPass(e.target.value)}
              value={confirmPass}
            />
            <div id={css.buttonHolder}>
              <button type="submit" id={css.button}>
                RESET PASSWORD
              </button>
            </div>
          </form>
        </div>
        {loading ? <PulseSpinner /> : null}
      </Box>
    </FooterWrapper>

  );
}
import { Box, Card, CardContent, Fade } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { CapitalizeFirstLetterComp } from 'src/pages/common/HOC/CapitalizeFirstLetterComp'
import { TruncateWraper } from 'src/pages/common/HOC/TruncateWraper'
import { capitalizeFirstLetter } from 'src/utils'
import { BadgePill } from '../../../BadgePill/BadgePill'
import { HtmlFormatter } from '../../../HtmlFormatter'
import NoLength from '../../../NoLength/NoLength'
import { RoundDot } from '../../../RoundDot/RoundDot'
import { PuffSpinner } from '../../../spinner/puff/puff'

const colorStyle = {
    color: `black`
}

const ReporterDesc = ({ isComment, task, isMissed }) => {
    const decideAssignedBy = () => {
        if (typeof task?.assignedBy === `string`) return task?.assignedBy
        else if (typeof task?.assignedBy === `object`) return `${task?.assignedBy?.firstName} ${task?.assignedBy?.lastName}`
        return ``
    }
    const decidehasOrHad = isMissed ? `had` : `has`
    return (<Box sx={{ fontSize: `10px`, color: `rgb(22, 153, 197)` }}>
        <Box sx={{ display: `inline`, fontWeight: `600`, mr: .5 }}>
            Reporter: <CapitalizeFirstLetterComp>
                {decideAssignedBy()}
            </CapitalizeFirstLetterComp>
        </Box>
        <Box sx={{ display: `block`, color: `black`, fontWeight: 600 }}>
            {isComment ? `A new comment has been added on this task!` : `${decidehasOrHad} assigned you a new task!`}
        </Box>
        {isMissed && !isComment && <Box sx={{ ...colorStyle, mt: .5 }}>
            <RoundDot color={`red`} style={{ fontSize: `6px`, verticalAlign: `middle` }} /> You have missed Task Alert for this task
        </Box>}

    </Box>)
}


export const TasksNotifications = ({ setOpenSpecificTask, setSelectedTask }) => {
    const { tasksManager: { taskNotificationArray, tasksLoading } } = useSelector((state) => state)
    return (
        <div style={{ height: 300, overflow: 'auto', }}>
            {tasksLoading?.loading ? <PuffSpinner /> : null}
            {taskNotificationArray?.length ? taskNotificationArray?.map((task, index) => (
                <Fade mountOnEnter unmountOnExit in={task ? true : false} >
                    <Card onClick={() => {
                        setOpenSpecificTask((p) => !p)
                        setSelectedTask(task)
                    }} key={index} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
                        <CardContent style={{ padding: `12px` }}>
                            <Box sx={{
                                fontWeight: `600`,
                                fontSize: `13px`,
                                color: `rgb(22, 153, 197)`,
                                wordWrap: `break-word`
                            }}>
                                <TruncateWraper tag='div' maxWidth='90%'>
                                    {task?.taskName}
                                </TruncateWraper>
                            </Box>

                            {task?.isNewCommentAdded ? <BadgePill type={task?.taskStatus === `opened` ? `badge-success` : `badge-danger`} >   {capitalizeFirstLetter(task?.taskStatus)}</BadgePill> : null}
                            {
                                !task?.isNewCommentAdded && <TruncateWraper tag='div'
                                    maxWidth={`85%`}
                                >
                                    <React.Fragment style={{
                                        fontSize: `10px`,
                                        WebkitLineClamp: `1`,
                                        color: `rgb(22, 153, 197)`,
                                        marginBottom: `8px`,
                                        wordWrap: `break-word`
                                    }}>
                                        <HtmlFormatter shouldTrunc={true} title={task?.taskDescription} />
                                    </React.Fragment>

                                </TruncateWraper>
                            }

                            <ReporterDesc task={task} isComment={task?.isNewCommentAdded ? true : false} isMissed={!task?.taskReminderNotificationReceived ? true : false} />

                        </CardContent >
                    </Card >
                </Fade >
            )) : <NoLength phrase={`No Tasks!`} style={{ p: 1 }} />
            }</div>
    )
}

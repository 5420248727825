import React, { useState, useEffect } from 'react';
import regexifyString from 'regexify-string';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export const LatestMessage = ({ text }) => {
    const { recentMessage } = useSelector((state) => state?.messenger)
    const { user: { user } } = useSelector((state) => state)
    const [message, setMessage] = useState(`. . .`);
    const history = useHistory();
    const moveToPatientModule = (patientId) => {
        history.push({ pathname: `/patient`, patientId });
    };
    useEffect(() => {
        if (text) {
            let msgObj = { msg: text };
            let msgAfterAddLinks = regexifyString({
                pattern: /@\[.+?\]\(.+?\)/g,
                decorator: (match) => {
                    if (user?.isTransportationAdmin) {
                        let userName = match.split(`[`)[1].split(`]`)[0];
                        return (
                            <button
                                className="userMessage"
                                style={{ color: `black`, border: `none`, outline: `none`, textDecoration: `none` }}
                            >
                                {userName}
                            </button>
                        );
                    } else {
                        let userName = match.split(`[`)[1].split(`]`)[0];
                        let userId = match.split(`(`)[1].split(`)`)[0];
                        return (
                            <button
                                className="userMessage"
                                style={{ color: `black`, border: `none`, outline: `none` }}
                                onClick={() => moveToPatientModule(userId)}
                                key={userId}
                            >
                                <i>{userName}</i>
                            </button>
                        );
                    }
                },
                input: msgObj.msg,
            });

            setMessage(msgAfterAddLinks.length > 60 ? `${msgAfterAddLinks.slice(0, 55)}. . .` : msgAfterAddLinks);
        } else {
            setMessage(recentMessage.length > 60 ? `${recentMessage.slice(0, 55)}. . .` : recentMessage);
        }
    }, [text, recentMessage]);

    return <>{message}</>;
}


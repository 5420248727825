import React, { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { _selectedUser } from '../../../../../../redux/messenger/messengerActions';
import { LatestMessage } from '../LatestMessage';
import css from './ConversationListItem.module.css';
import Avatar from 'react-avatar';

export const ConversationListItem = (props) => {
  const selectedUserRedux = useSelector((state) => state.messenger.selectedUser);
  const dispatch = useDispatch();
  const msgRef = useRef();

  let { receiverId, name, text, isOnline } = props.data;

  // Function update Selected User object in messenger Reducer
  const selectUserHandler = () => {
    dispatch(_selectedUser(receiverId));
  };

  return (
    <div
      style={selectedUserRedux === props?.data?.receiverId ? { backgroundColor: `#f4f4f8` } : null}
      className={css.conversationListItem}
      onClick={selectUserHandler}
    >
      <Avatar name={name} size="40" round="40px" className={css.conversationPhoto} />

      <div className={css.conversationInfo}>
        <h1 className={css.conversationTitle}>
          {name} {isOnline ? <span className="badge badge-pill badge-success">Online</span> : null}
        </h1>
        <p className={css.conversationSnippet} id="conversationSnippet" ref={msgRef}>
          <i>{<LatestMessage text={text} />}</i>
        </p>
      </div>
    </div>
  );
}


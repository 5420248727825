import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { PatientMessenger } from './components/PatientMessenger';
import { PracticeMessenger } from './components/PracticeMessenger';
import { Charting } from './components/Charting';
import { Grid } from '@mui/material';
import { capitalizeResmed, capitalizeRespironics, capitalizeFirstLetter, returnNull, handleAHI, manufacturerForReact_Health } from 'src/utils';
import css from '../PatientOver.module.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';
import { momentWrapper } from 'src/momentWrapper';
import { useHistory } from 'react-router-dom';
import { PressureDecider } from '../components/PressureDecider';
import { _selectedUser } from 'src/redux/messenger/messengerActions';
import { RoundDot } from 'src/pages/common/components/RoundDot/RoundDot';

export const daysParam = `/${momentWrapper(new Date()).daysInMonth()}days`;

export const ReportModal = () => {
  const { open, patientData } = useSelector((state) => state?.readyToSign);
  const {
    user: { user },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const maxWidthXSm = useMediaQuery(`(max-width:576px)`);
  const maxWidthSm = useMediaQuery(`(max-width:768px)`);
  const maxWidthMd = useMediaQuery(`(max-width:900px)`);
  const maxWidthLg = useMediaQuery(`(max-width:1200px)`);
  const maxWidthXl = useMediaQuery(`(max-width:1300px)`);
  const maxWidthXxl = useMediaQuery(`(max-width:1500px)`);
  const maxWidthXxxl = useMediaQuery(`(max-width:2200px)`);
  const widthGiver = () => {
    let width = ``;
    if (maxWidthXSm) {
      width = `300%`;
    } else if (maxWidthSm) {
      width = `300%`;
    } else if (maxWidthMd) {
      width = `200%`;
    } else if (maxWidthLg) {
      width = `180%`;
    } else if (maxWidthXl) {
      width = `150%`;
    } else if (maxWidthXxl) {
      width = `100%`;
    } else if (maxWidthXxxl) {
      width = `100%`;
    }
    return width;
  };
  let width = widthGiver();

  useEffect(() => {
    return () => {
      dispatch({
        type: `OPENINFOMODAL`,
        payLoad: {
          patientData: {},
          rootKeys: {
            open: false,
            patientId: ``,
          },
        },
      });
      dispatch(_selectedUser(``));
    };
  }, []);

  const moveToPatientModule = (patientId) => {
    history.push({ pathname: `/patient`, patientId });
  };

  // The ColorCodedAHI function is store in variable.
  let showColorCodedAHI = handleAHI(Number(patientData?.AHI));
  let dates = {
    endDate: momentWrapper(new Date()).endOf(`day`).subtract(1, `days`).format(`MM/DD/YYYY`),
    startDate: momentWrapper(new Date()).startOf(`day`).subtract(30, `days`).format(`MM/DD/YYYY`),
  };
  let { endDate, startDate } = dates;

  return (
    <CustomizedDialogs
      hasHeight={true}
      title={`Review Patient`}
      open={open}
      setOpen={() => {
        dispatch({
          type: `OPENINFOMODAL`,
          payLoad: {
            patientData: {},
            rootKeys: {
              open: false,
              patientId: ``,
            },
          },
        });
      }}
      size="xl"
      fullWidth={true}
    >
      <div className="table-responsive" style={{ width: `${width} !important` }}>
        <table style={{ width: `${width}` }} className={`table  table-lg table-borderless table-striped`}>
          <thead>
            <tr style={{ textAlign: `center`, verticalAlign: `middle` }}>
              <td className={css.tdLess}>Last Name</td>
              <td className={css.tdLess}>First Name</td>
              <td className={css.tdModerate}>Days On Device</td>
              <td className={css.tdLess}>Device Type</td>
              <td className={css.tdLess}>Manufacturer</td>
              <td className={css.tdLess}>Alert</td>
              <td className={css.tdLess}>30 Days</td>
              <td className={css.tdLess}>Prior 3 Days</td>
              <td className={css.td}>Last 3 Days</td>
              <td className={css.tdModerate}>Pressure 90th/95th %</td>
              <td className={css.tdLess}>AHI</td>
              <td className={css.tdLess}>Leak</td>
            </tr>
          </thead>
          <tbody>
            <tr className={css.tableRow}>
              {!user?.isTransportationAdmin ? (
                <td style={{ cursor: `pointer` }} onClick={() => moveToPatientModule(patientData?._id)}>
                  <ToolTipProvider
                    toolTipProps={{
                      title: `Click to get details`,
                      placement: `top`,
                      arrow: true,
                    }}
                    element={<span>{capitalizeFirstLetter(patientData?.lastName)}</span>}
                  />
                </td>
              ) : (
                <td className={css.verticallyCenter}>{capitalizeFirstLetter(patientData?.lastName)}</td>
              )}
              {!user?.isTransportationAdmin ? (
                <td style={{ cursor: `pointer` }} onClick={() => moveToPatientModule(patientData?._id)}>
                  <ToolTipProvider
                    toolTipProps={{
                      title: `Click to get details`,
                      placement: `top`,
                      arrow: true,
                    }}
                    element={<span>{capitalizeFirstLetter(patientData?.firstName)}</span>}
                  />
                </td>
              ) : (
                <td className={css.verticallyCenter}>{capitalizeFirstLetter(patientData?.firstName)}</td>
              )}

              <td>{patientData?.daysDeviceSetup}</td>
              <td>
                {patientData?.device?.manufacturer === `respironics` || patientData?.device?.manufacturer === `reactHealth`
                  ? patientData?.device?.type
                  : patientData?.device?.detail}
              </td>
              <td>
                {patientData?.device?.manufacturer && patientData?.device?.manufacturer === `respironics`
                  ? capitalizeRespironics()
                  : patientData?.device?.manufacturer === `reactHealth`
                  ? manufacturerForReact_Health()
                  : capitalizeResmed()}
              </td>
              <td className={css.tdLess}>
                {patientData?.adherenceAlerts?.last3Days || patientData?.adherenceAlerts?.last30Days ? (
                  <RoundDot
                    style={{
                      width: `7px`,
                      height: `7px`,
                      backgroundColor: `red`,
                    }}
                  />
                ) : (
                  `-`
                )}
              </td>
              <td>{patientData?.adherencePercentage?.last30Days}</td>
              <td>{patientData?.adherencePercentage?.prior3Days}</td>
              <td>{patientData?.adherencePercentage?.last3Days}</td>
              <td style={{ width: `4%` }}>
                <PressureDecider deviceMenuFacturer={patientData?.device?.manufacturer} pressure={patientData?.pressure} />
              </td>

              <td>
                <ToolTipProvider
                  toolTipProps={{
                    title: `Events/hour`,
                    placement: `top`,
                    arrow: true,
                  }}
                  element={
                    <span style={{ color: showColorCodedAHI }}>
                      {returnNull({
                        key: patientData?.AHI ? true : false,
                        successRetrun: patientData?.AHI,
                        faliure: `-`,
                      })}
                    </span>
                  }
                />
              </td>
              <td>
                <ToolTipProvider
                  toolTipProps={{
                    title: `L/min`,
                    placement: `top`,
                    arrow: true,
                  }}
                  element={
                    <div>
                      {returnNull({
                        key: patientData?.leak ? true : false,
                        successRetrun: patientData?.leak,
                        faliure: `0`,
                      })}
                    </div>
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Grid sx={{ mt: 2 }} container spacing={1}>
        <Grid sx={{ p: 0.5 }} xs={4} sm={4} md={4} lg={4} xl={4}>
          <PatientMessenger />
        </Grid>
        <Grid sx={{ p: 0.5 }} xs={4} sm={4} md={4} lg={4} xl={4}>
          <PracticeMessenger />
        </Grid>
        <Grid sx={{ p: 0.5 }} xs={4} sm={4} md={4} lg={4} xl={4}>
          <Charting />
        </Grid>
      </Grid>
    </CustomizedDialogs>
  );
};

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, MenuItem, Select, InputLabel, FormControl, FormHelperText } from '@mui/material';
import { useSelector } from 'react-redux';
import { GET_ALL_PATIENTS_WRT_USER, getRequest, GET_ALL_USERS } from 'src/crud/crud';
import LinearProgress from '@mui/material/LinearProgress';
import GppMaybeSharpIcon from '@mui/icons-material/GppMaybeSharp';
import { capitalizeFirstLetter } from 'src/utils';
import { SearchInput } from 'src/pages/common/components/LIstSubheader/SearchInput';

const Comp = ({ onBlur, setValue, isPatient, error, isValueComing, providerAssignedPatients }) => {
  const [listData, setListData] = useState(``);
  const [value, setValueState] = useState(``);
  const [searchValue, setsearchValue] = useState(``);
  const { _practiceId } = useSelector((state) => state?.user?.user);
  const isDefaultValSet = useRef(`no`);
  const isGoneForData = useRef(`no`);
  const decideRoute = isPatient ? `${GET_ALL_PATIENTS_WRT_USER}${providerAssignedPatients}` : `${GET_ALL_USERS}/${_practiceId}`;
  const setStates = (res, valueOrKey) => {
    if (res) setListData(res?.data?.[valueOrKey]);
    else setListData(valueOrKey);
  };
  const doIfPatient = () => {
    if (isPatient) {
      setValue(``);
      setValueState(``);
    }
  };
  const getData = () => {
    if (isGoneForData.current === `no`) {
      isGoneForData.current = `yes`;
      setListData(`loading`);
      getRequest(decideRoute)
        .then((res) => setStates(res, isPatient ? `patients` : `users`))
        .catch(() => {
          setListData(`error`);
          doIfPatient();
        });
    }
  };

  useEffect(() => {
    if (isGoneForData.current === `yes`) isGoneForData.current = `no`;
    if (providerAssignedPatients) getData();
  }, [providerAssignedPatients, isGoneForData]);

  useEffect(() => {
    const setIfValueComing = () => {
      if (isValueComing && isDefaultValSet.current === `no`) {
        setStates(false, isValueComing);
        setValueState(isValueComing?.[0]?._id);
        isDefaultValSet.current = `yes`;
      }
    };
    setIfValueComing();

    if (!isPatient) getData();
  }, [isValueComing, isDefaultValSet, isGoneForData, isPatient]);

  let filteredProviders = useMemo(() => {
    return (
      typeof listData === `object` &&
      listData.filter((e) => e?.firstName?.toLowerCase()?.includes(searchValue) || e?.lastName?.toLowerCase()?.includes(searchValue))
    );
  }, [listData, searchValue]);
  return (
    <Box>
      <FormControl fullWidth size="small">
        <InputLabel
          error={error}
          style={{
            left: `-2%`,
          }}
          id="ProvidersAnalytics"
        >
          {isPatient ? `Patient` : `Assigned to*`}
        </InputLabel>
        <Select
          size="small"
          label={isPatient ? `Patient` : `Assigned to`}
          name="providersInputName"
          id="providersInputName"
          value={value}
          {...(onBlur && {
            onBlur: (e) => {
              const {
                target: { value },
              } = e;
              onBlur(value);
            },
          })}
          error={error}
          // {...(!isValueComing
          //   ? {
          //       onFocus: () => {
          //         if (searchValue) {
          //           setsearchValue(``);
          //         }
          //         if (!isPatient || (providerAssignedPatients && isPatient)) {
          //           setListData(`loading`);
          //           getRequest(decideRoute)
          //             .then((res) => {
          //               isPatient ? setListData(res?.data?.patients) : setListData(res?.data?.users);
          //             })
          //             .catch(() => setListData(`error`));
          //         }
          //       },
          //     }
          //   : {})}
          onChange={(e) => {
            const {
              target: { value },
            } = e;
            setValue(e);
            setValueState(value);
          }}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: `300px`,
              },
            },
          }}
        >
          <SearchInput label={`Search a ${isPatient ? `Patient` : `User`}!`} keyValue={false} isObj={false} setSearchValue={setsearchValue} />
          {listData === `loading` && <LinearProgress sx={{ width: `100%` }} />}
          {listData === `error` && <GppMaybeSharpIcon sx={{ width: `100%`, color: `red` }} />}
          {typeof listData === `object` &&
            filteredProviders.map((e, i) => (
              <MenuItem sx={{ fontWeight: 600 }} onClick={() => setValue(e)} value={e?._id} key={i}>
                {capitalizeFirstLetter(e?.firstName + ` ` + e?.lastName)}
              </MenuItem>
            ))}
        </Select>
        {error && <FormHelperText sx={{ color: `red` }}>{error}</FormHelperText>}
      </FormControl>
    </Box>
  );
};

export const AskForAssignee = React.memo(Comp);

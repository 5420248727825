import React, { useState, useEffect, useRef } from 'react';
import css from './Compose.module.css';
import { momentWrapper } from 'src/momentWrapper';
import { SmartPhraseModal } from 'src/pages/common/components/smartphrasemodal/SmartPhraseModal';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import { useSelector } from 'react-redux';
import { MentionsInput, Mention } from 'react-mentions';
import defaultStyles from './defaultStyles';
import defaultMentionStyle from './defaultMentionStyle';
import { AutoWriteCredentials } from 'src/pages/common/components/AutoWriteCredentials/AutoWriteCredentials';
import { capitalizeFirstLetter, onKeyUpCredentialsHandler, shallowValueSetter } from 'src/utils';

const style = { width: `76%`, right: `18%`, bottom: `54px` };

const ComposeComp = (props) => {
  const {
    messenger: { individualUser: individualUsers },
  } = useSelector((state) => state);
  const inputRefs = useRef(null);
  const [newMsg, setNewMsg] = useState(``);
  const [patients, setPatient] = useState([]);
  const [searchKey, setSearchKey] = useState(``);
  const [charting, setCharting] = useState(``);
  const [phraseId, setPhraseId] = useState(null);
  const [phraseScope, setPhraseScope] = useState(null);
  const [shallowValue, setShallowValue] = useState(``);
  const [openTemplatesGate, setOpenTemplatesGate] = useState(false);
  //  for smartPhrase hide and show
  const [smartPhrase, setSmartPhrase] = useState(false);

  const short_verse = (verse) => {
    let result = [];
    verse.split(` `).map((word) => (word.charAt(0) != `` ? result.push(word.charAt(0)) : ``));
    return result.join(` `);
  };

  const findHashWord = (newNote) => {
    let hash;
    newNote.split(` `).map((word) => {
      if (word.charAt(0) === `#`) {
        hash = word;
      }
    });
    return hash;
  };
  useEffect(() => {
    if (short_verse(newMsg).includes(`#`)) {
      setSmartPhrase(true);
      setSearchKey(findHashWord(newMsg).replace(`#`, ``));
    } else {
      setSmartPhrase(false);
      setSearchKey(``);
      setCharting(newMsg);
    }
  }, [newMsg]);

  useEffect(() => {
    if (props?.listOfPatients) {
      setPatient([
        ...props.listOfPatients.map((patient) => {
          return {
            ...patient,
            id: patient?._id,
            display: `${capitalizeFirstLetter(patient?.firstName)} ${capitalizeFirstLetter(patient?.lastName)}`,
          };
        }),
      ]);
    }
  }, [props?.listOfPatients]);

  const onFocusHandler = () => {
    newSocket.emit(`changeChatReadStatusOnFocus`, {
      senderId: props.senderId,
      receiverId: props.selectedUser,
      practiceId: props.practiceId,
      _chatRoomId: individualUsers[props.selectedUser]?._chatRoomId,
    });
  };

  const commonObj = () => {
    return [
      {
        _receiverId: props.selectedUser,
        _senderId: props.senderId,
        _practiceId: props.practiceId,
        _socketId: props.socketId,
        // Conditional object creation based
        // `@[${props?.patientName}](${patientReportId})` is used as a Markdown mention format for `patientName`
        // `newMsg` is appended to the `message` property as a string
        // If `patientName` is not present in `props`, `newMsg` is used as the entire `message` property
        message: props?.patientName
          ? `Patient : ${`@[${props?.patientName}](${props?.patientId})`},
      ${newMsg}`
          : newMsg,
        _chatRoomId: props.chatId,
        phraseId: phraseId,
        phraseScope: phraseScope,
        date: momentWrapper().format(),
      },
    ];
  };

  //   some check for phrase selected using up and down key and send on enter button
  const sendMessageHandler = (key, isSendIcon) => {
    if (
      (key?.keyCode === 13 && !key.shiftKey && newMsg.trim().length > 0 && newMsg !== `#`) ||
      (key === 1300 && newMsg.trim().length > 0 && newMsg !== `#`) ||
      isSendIcon
    ) {
      key.preventDefault();
      let temp = commonObj();
      props.newMsgHandler(temp, () => setNewMsg(``));
      setNewMsg(``);
      setPhraseScope(null);
      setPhraseId(null);
      inputRefs.current.value = ``;
    }
  };

  const messageStateUpdate = (e) => {
    let { value } = e.target;
    shallowValueSetter({
      flag: openTemplatesGate,
      setValue: () => setShallowValue(value),
    });
    if (!e.keyCode != 13) {
      setNewMsg(value);
    }
  };

  const doSendMessage = newMsg.trim().length > 0 && newMsg !== `#` && true;

  const iconId = newMsg.trim().length > 0 && newMsg !== `#` ? css.toolbarButton : css.disabledIcon;


  return (
    <SmartPhraseModal
      slectedPhraseId="practiceMessengerPhrases"
      setNewNote={setNewMsg}
      newNote={newMsg}
      setPhraseId={setPhraseId}
      setPhraseScope={setPhraseScope}
      style={style}
      anchorEl={inputRefs.current}
    >
      <div className={props?.isFromView ? css.composeView : css.compose}>
        <div className={css.composeInputs}>
          <AutoWriteCredentials setShallowValue={setShallowValue} value={shallowValue} open={openTemplatesGate} setValue={setNewMsg} />

          <MentionsInput
            value={newMsg}
            onChange={messageStateUpdate}
            onKeyDown={(e) => sendMessageHandler(e, false)}
            onKeyUp={(e) => {
              onKeyUpCredentialsHandler({
                event: e,
                openTemplatesHandler: (value) => setOpenTemplatesGate(value),
              });
            }}
            onFocus={() => onFocusHandler()}
            placeholder="Type a Message..."
            allowSuggestionsAboveCursor={true}
            inputRef={inputRefs}
            disabled={!props.selectedUser}
            style={defaultStyles}
            className={css.composeInput}
          >
            <Mention type="user" trigger="@" data={patients} style={defaultMentionStyle} />
          </MentionsInput>


        </div>
        <div id={css.composeInputBtn}>
          <i
            className={`ion-md-send`}
            id={iconId}
            onClick={(e) => sendMessageHandler(e, doSendMessage)}
          />
        </div>
      </div>
    </SmartPhraseModal>
  );
};

export const Compose = React.memo(ComposeComp);

import React, { useEffect, useState, useRef } from 'react';
import './videoPublicClient.css';
import { useDispatch } from 'react-redux';
import { isSupported } from 'twilio-video';
import { isMobile } from './components/browser';
import { joinRoom } from './components/joinroom';
import { micLevel } from './components/miclevel';
import { selectMedia } from './components/selectmedia';
import { selectRoom } from './components/selectroom';
import { showError } from './components/showerror';
import { ScreenShare } from './components/screenshare';
import $ from 'jquery';
import { LocalMediaControls } from './components/localmediacontrols';
import { postRequest, PUBLIC_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL, getRequest } from '../../../crud/crud';
import { MobileView, BrowserView } from 'react-device-detect';
import logo from '../../../assets/monitair-logo.svg';
import Fab from '@mui/material/Fab';
import ScreenShareIcon from '@mui/icons-material/ScreenShare';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import VideocamIcon from '@mui/icons-material/Videocam';
import { ToolTipProvider } from '../components/Tooltip/ToolTipProvider';
import { momentWrapper } from 'src/momentWrapper';
import { authTokenHandler } from 'src/redux/action/authTokenHandler';
import { capitalizeFirstLetter } from 'src/utils';

// ConnectOptions settings for a video web application.
const connectOptions = {
  // Available only in Small Group or Group Rooms only. Please set "Room Type"
  // to "Group" or "Small Group" in your Twilio Console:
  // https://www.twilio.com/console/video/configure
  bandwidthProfile: {
    video: {
      dominantSpeakerPriority: `high`,
      mode: `collaboration`,
      renderDimensions: {
        high: { height: 720, width: 1280 },
        standard: { height: 90, width: 160 },
      },
    },
  },

  // Available only in Small Group or Group Rooms only. Please set "Room Type"
  // to "Group" or "Small Group" in your Twilio Console:
  // https://www.twilio.com/console/video/configure
  dominantSpeaker: true,

  // Comment this line to disable verbose logging.
  logLevel: `debug`,

  // Comment this line if you are playing music.
  maxAudioBitrate: 16000,

  // VP8 simulcast enables the media server in a Small Group or Group Room
  // to adapt your encoded video quality for each RemoteParticipant based on
  // their individual bandwidth constraints. This has no utility if you are
  // using Peer-to-Peer Rooms, so you can comment this line.
  preferredVideoCodecs: [{ codec: `VP8`, simulcast: true }],

  // Capture 720p video @ 24 fps.
  video: { height: 720, frameRate: 24, width: 1280 },
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile) {
  connectOptions.bandwidthProfile.video.maxSubscriptionBitrate = 2500000;
}

// On mobile browsers, there is the possibility of not getting any media even
// after the user has given permission, most likely due to some other app reserving
// the media device. So, we make sure users always test their media devices before
// joining the Room. For more best practices, please refer to the following guide:
// https://www.twilio.com/docs/video/build-js-video-application-recommendations-and-best-practices
const deviceIds = {
  audio: isMobile ? null : localStorage.getItem(`audioDeviceId`),
  video: isMobile ? null : localStorage.getItem(`videoDeviceId`),
};

export const VideoPublicClient = (props) => {
  let paramToken = props.match.params.token;
  const flag = 0;
  const [isTimeStampReceivedFromProvider, setIsTimeStampReceivedFromProvider] = useState(0);
  const [videoCamera, setVideoCamera] = useState(false);
  const [audioVolume, setAudioVolume] = useState(false);
  const $main = useRef(null);
  const $modals = useRef(null);
  const $selectMicModal = useRef(null);
  const $selectCameraModal = useRef(null);
  const $showErrorModal = useRef(null);
  const $joinRoomModal = useRef(null);
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(authTokenHandler(paramToken))
    if ($showErrorModal.current && isSupported) {
      selectMicrophone();
    } else {
      showError($showErrorModal.current, new Error(`This browser is not supported.`));
    }
    return () => {
      setIsTimeStampReceivedFromProvider(0);
    };
  }, [flag]);

  const toHHMMSS = (v) => {
    let sec_num = parseInt(v, 10); // don't forget the second param
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);
    let seconds = sec_num - hours * 3600 - minutes * 60;

    if (hours < 10) {
      hours = `0` + hours;
    }
    if (minutes < 10) {
      minutes = `0` + minutes;
    }
    if (seconds < 10) {
      seconds = `0` + seconds;
    }
    return hours + `:` + minutes + `:` + seconds;
  };

  /**
   * Select your microphone.
   */
  async function selectMicrophone() {
    if (deviceIds.audio === null) {
      try {
        deviceIds.audio = await selectMedia(`audio`, $selectMicModal.current, (stream) => {
          const $levelIndicator = $(`svg rect`, $selectMicModal.current);
          const maxLevel = Number($levelIndicator.attr(`height`));
          micLevel(stream, maxLevel, (level) => $levelIndicator.attr(`y`, maxLevel - level));
        });
      } catch (error) {
        showError($showErrorModal.current, error);
        return;
      }
    }
    return selectCamera();
  }

  /**
   * Select your camera.
   */
  async function selectCamera() {
    if (deviceIds.video === null) {
      try {
        deviceIds.video = await selectMedia(`video`, $selectCameraModal.current, (stream) => {
          const $video = $(`video`, $selectCameraModal.current);
          $video.get(0).srcObject = stream;
        });
      } catch (error) {
        showError($showErrorModal.current, error);
        return;
      }
    }
    return selectAndJoinRoom();
  }

  /**
   * Select your Room name, your screen name and join.
   * @param [error=null] - Error from the previous Room session, if any
   */
  async function selectAndJoinRoom(error = null) {
    const { data } = await postRequest(`url/decryptToken`, { token: paramToken });
    paramToken = data?.actualToken;
    dispatch(authTokenHandler(paramToken))

    // Capitalize Name of Patient.
    const patientCapitalizeName = capitalizeFirstLetter(data?.patientName)

    const formData = await selectRoom($joinRoomModal.current, error, patientCapitalizeName);

    if (!formData) {
      // User wants to change the camera and microphone.
      // So, show them the microphone selection modal."
      deviceIds.audio = null;
      deviceIds.video = null;
      return selectMicrophone();
    }

    const { identity } = formData;

    try {
      let url = PUBLIC_TWILIO_VIDEO_CALL_TOKEN_VERIFY_URL + identity
      // Fetch an AccessToken to join the Room.
      const response = await getRequest(url);

      // dateCreated is exist or not
      const dateCreated = await response.data?.dateCreated;

      if (dateCreated) {
        const diffInSeconds = momentWrapper(new Date()).diff(momentWrapper(dateCreated), `seconds`);

        setIsTimeStampReceivedFromProvider(diffInSeconds);
      } else {
        setIsTimeStampReceivedFromProvider((pre) => pre + 1);
      }
      // Extract the AccessToken from the Response.
      const token = await response.data.token;
      // Extract the RoomName from the Response.
      const roomName = await response.data.roomName;

      // Add the specified audio device ID to ConnectOptions.
      connectOptions.audio = { deviceId: { exact: deviceIds.audio } };

      // Add the specified Room name to ConnectOptions.
      connectOptions.name = roomName;

      // Add the specified video device ID to ConnectOptions.
      connectOptions.video.deviceId = { exact: deviceIds.video };

      // Join the Room.
      await joinRoom(token, connectOptions, $main.current);

      // After the video session, display the room selection modal.
      return selectAndJoinRoom();
    } catch (error) {
      return selectAndJoinRoom(error);
    }
  }

  // USE EFFECT
  useEffect(() => {
    if (isTimeStampReceivedFromProvider) {
      let intervalId = setInterval(() => {
        setIsTimeStampReceivedFromProvider((preState) => preState + 1);
      }, 1000);
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [isTimeStampReceivedFromProvider]);

  // JOINING CHAT HANDLER
  const joiningChatHandler = () => {
    $(`#join-room`).modal(`hide`);
  };

  return (
    <div id="main" ref={$main}>
      <nav className="navbar navbar-expand-sm navbar-light bg-white">
        <a className="navbar-brand" href="#">
          <img src={logo} width="200" height="40" className="d-inline-block align-top" alt="" />
          {/* <span style={{ margin: "0 10px" }}>Video Quick Start</span> */}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsible-content"
          aria-controls="collapsible-content"
          aria-expanded="false"
          aria-label="Toggle Navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="collapsible-content">
          <div className="d-flex w-75 justify-content-around"></div>
        </div>
      </nav>
      <div className="container-fluid">
        <div className="row" id="room">
          <div id="participants" className="col-xs-12 col-sm-3 col-md-2" style={{ textAlign: `center` }}></div>
          <div id="active-participant" className="col-xs-12 col-sm-9 col-md-10" style={{ textAlign: `center` }}>
            <div className="participant main" id="participentMainWindow">
              <video id="videopreview" autoPlay playsInline muted></video>
              <video id="screenpreview" autoPlay></video>

              <div id="controls">
                {/* IMPORTANT LINE :  videoBtnRef?.classList?.contains("muted") */}
                {!videoCamera ? (
                  <BrowserView>
                    <ToolTipProvider
                      toolTipProps={{
                        title: `Turn off camera`,
                      }}
                      element={
                        <div>
                          <Fab
                            size="small"
                            color="primary"
                            id="muteVideoBtn"
                            onClick={() => {
                              LocalMediaControls($main.current, `video`);
                              setVideoCamera((p) => !p);
                            }}
                          >
                            <VideocamIcon />
                          </Fab>
                        </div>
                      }
                    />
                  </BrowserView>
                ) : (
                  <BrowserView>
                    <ToolTipProvider
                      toolTipProps={{
                        title: `Turn on camera`,
                      }}
                      element={
                        <div>
                          <Fab
                            size="small"
                            color="primary"
                            id="muteVideoBtn"
                            onClick={() => {
                              LocalMediaControls($main.current, `video`);
                              setVideoCamera((p) => !p);
                            }}
                          >
                            <VideocamOffIcon />
                          </Fab>
                        </div>
                      }
                    />
                  </BrowserView>
                )}

                {!audioVolume ? (
                  <ToolTipProvider
                    toolTipProps={{
                      title: `Unmute Audio`,
                    }}
                    element={
                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          id="muteAudioBtn"
                          onClick={() => {
                            LocalMediaControls($main.current, `audio`);
                            setAudioVolume((p) => !p);
                          }}
                        >
                          <MicIcon />
                        </Fab>
                      </div>
                    }
                  />
                ) : (
                  <ToolTipProvider
                    toolTipProps={{
                      title: `Unmute Audio`,
                    }}
                    element={
                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          id="muteAudioBtn"
                          onClick={() => {
                            LocalMediaControls($main.current, `audio`);
                            setAudioVolume((p) => !p);
                          }}
                        >
                          <MicOffIcon />
                        </Fab>
                      </div>
                    }
                  />
                )}

                <ToolTipProvider
                  toolTipProps={{
                    title: `Leave room`,
                  }}
                  element={
                    <div>
                      <Fab color="secondary" id="leave-room">
                        <PhoneDisabledIcon />
                      </Fab>
                    </div>
                  }
                />
                <MobileView>
                  {!videoCamera ? (
                    <ToolTipProvider
                      toolTipProps={{
                        title: `Turn off camera`,
                      }}
                      element={
                        <div>
                          <Fab
                            size="small"
                            color="primary"
                            id="muteVideoBtn"
                            onClick={() => {
                              LocalMediaControls($main.current, `video`);
                              setVideoCamera((p) => !p);
                            }}
                          >
                            <VideocamIcon />
                          </Fab>
                        </div>
                      }
                    />
                  ) : (
                    <ToolTipProvider
                      toolTipProps={{
                        title: `Turn on camera`,
                      }}
                      element={
                        <div>
                          <Fab
                            size="small"
                            color="primary"
                            id="muteVideoBtn"
                            onClick={() => {
                              LocalMediaControls($main.current, `video`);
                              setVideoCamera((p) => !p);
                            }}
                          >
                            <VideocamOffIcon />
                          </Fab>
                        </div>
                      }
                    />
                  )}
                </MobileView>
                <BrowserView>
                  <ToolTipProvider
                    toolTipProps={{
                      title: `Screen share`,
                    }}
                    element={
                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          id="capturescreen"
                          onClick={() => {
                            ScreenShare($main.current, `start`);
                          }}
                        >
                          <ScreenShareIcon />
                        </Fab>
                      </div>
                    }
                  />
                </BrowserView>
                <BrowserView>
                  <ToolTipProvider
                    toolTipProps={{
                      title: `Stop screen share`,
                    }}
                    element={
                      <div>
                        <Fab
                          size="small"
                          color="primary"
                          id="stopscreencapture"
                          onClick={() => {
                            ScreenShare($main.current, `stop`);
                          }}
                        >
                          <StopScreenShareIcon />
                        </Fab>
                      </div>
                    }
                  />
                </BrowserView>
              </div>

              <h6 style={{ color: `black`, marginTop: `20px` }}>{toHHMMSS(isTimeStampReceivedFromProvider)}</h6>
            </div>
          </div>
        </div>
        <div ref={$modals} id="modals">
          <div
            className="modal fade"
            ref={$selectMicModal}
            id="select-mic"
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="select-mic-label"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="select-mic-label">
                    Microphone
                  </h5>
                </div>
                <div className="modal-body" style={{ textAlign: `center` }}>
                  <select style={{ width: `100%` }}></select>
                  <svg focusable="false" viewBox="0 0 100 100" aria-hidden="true" height="100" width="100" style={{ margin: `10px 0` }}>
                    <defs>
                      <clipPath id="level-indicator">
                        <rect x="0" y="100" width="100" height="100" />
                      </clipPath>
                    </defs>
                    <path
                      fill="rgb(220, 220, 220)"
                      d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
                    ></path>
                    <path
                      fill="rgb(220, 220, 220)"
                      d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
                    ></path>
                    <path
                      fill="#080"
                      clipPath="url(#level-indicator)"
                      d="m52 38v14c0 9.757-8.242 18-18 18h-8c-9.757 0-18-8.243-18-18v-14h-8v14c0 14.094 11.906 26 26 26v14h-17v8h42v-8h-17v-14c14.094 0 26-11.906 26-26v-14h-8z"
                    ></path>
                    <path
                      fill="#080"
                      clipPath="url(#level-indicator)"
                      d="m26 64h8c5.714 0 10.788-4.483 11.804-10h-11.887v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h12.083v-4h-12.083v-4h11.887c-1.016-5.517-6.09-10-11.804-10h-8c-6.393 0-12 5.607-12 12v40c0 6.393 5.607 12 12 12z"
                    ></path>
                  </svg>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="select-camera"
            ref={$selectCameraModal}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="select-camera-label"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="select-camera-label">
                    Camera
                  </h5>
                </div>
                <div className="modal-body" style={{ textAlign: `center` }}>
                  <select style={{ width: `100%` }}></select>
                  <video autoPlay muted playsInline style={{ margin: `10px 0`, width: `60%` }}></video>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary">
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="join-room"
            ref={$joinRoomModal}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="join-room-label"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="join-room-label">
                    Video Chat
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="alert alert-warning" role="alert"></div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-dark">
                    Change Microphone and Camera
                  </button>
                  <button type="button" onClick={() => joiningChatHandler()} className="btn btn-primary">
                    Join
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className="modal fade"
            id="show-error"
            ref={$showErrorModal}
            data-backdrop="static"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="show-error-label"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="show-error-label">
                    Error
                  </h5>
                </div>
                <div className="modal-body">
                  <div className="alert alert-warning" role="alert"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
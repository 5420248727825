import $ from 'jquery';
import Video from 'twilio-video';
import { room, localVideoTrack } from './joinroom';

let captureScreen = null;
let stopScreenCapture = null;
let videopreview = null;
let screenPreview = null;
// The LocalVideoTrack for your screen.
let screenTrack;

const toggleButtons = () => {
  captureScreen.is(':visible') ? captureScreen.show() : captureScreen.hide();
  stopScreenCapture.is(':visible') ? stopScreenCapture.show() : stopScreenCapture.hide();
}

export default async function ScreenShare($main, key) {
  screenPreview = $('#screenpreview', $main);
  stopScreenCapture = $('#stopScreenCapture', $main);
  captureScreen = $('#capturescreen', $main);
  videopreview = $('#videopreview', $main);

  if (key === 'start') {
    try {
      // Hide the "Video Screen".
      videopreview.hide();
      screenPreview.show();
      // Create and preview your local screen.
      screenTrack = await createScreenTrack(720, 1280);
      screenTrack.attach(screenPreview.get(0));
      room.localParticipant.unpublishTrack(localVideoTrack);
      room.localParticipant.publishTrack(screenTrack);
      // Show the "Capture Screen" button after screen capture stops.
      screenTrack.on('stopped', toggleButtons);
      // Show the "Stop Capture Screen" button.
      toggleButtons();
    } catch (e) {
      videopreview.show();
      screenPreview.hide();
      alert(e.message);
    }
  }

  if (key === 'stop') {
    // Stop capturing your screen.
    videopreview.show();
    screenPreview.hide();
    screenTrack.stop();
    room.localParticipant.unpublishTrack(screenTrack);
    room.localParticipant.publishTrack(localVideoTrack);
  }
}

/**
 * Create a LocalVideoTrack for your screen. You can then share it
 * with other Participants in the Room.
 * @param {number} height - Desired vertical resolution in pixels
 * @param {number} width - Desired horizontal resolution in pixels
 * @returns {Promise<LocalVideoTrack>}
 */
async function createScreenTrack(height, width) {
  if (typeof navigator === 'undefined' || !navigator.mediaDevices || !navigator.mediaDevices.getDisplayMedia) {
    return Promise.reject(new Error('getDisplayMedia is not supported'));
  }
  return navigator.mediaDevices
    .getDisplayMedia({
      video: {
        height: height,
        width: width,
      },
    })
    .then(function (stream) {
      //
      return new Video.LocalVideoTrack(stream.getVideoTracks()[0]);
    });
}

import React from 'react'

export const ConditionallyRenderWrapper = ({ children, con }) => {
    return (
        <>
            {con ? children : null}
        </>
    )
}


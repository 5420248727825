import {
  SET_USER, UPDATE_PROVIDER_TYPE, UPDATE_USER_NAME, SWITCH_PRACTICE,
  UPDATE_USER_SIGNATURE, SAVE_EXISTING_USER_DETAILS, UPDATE_PRACTICE_LOGO_PATH,
  UPDATE_APP_REL_COUNT,
} from './types';
import { store } from 'src/redux/store';


export const updateAppReclount = () => {
  return { type: UPDATE_APP_REL_COUNT };
};


export const setUserHandler = (data) => (dispatch) => {
  dispatch({
    type: SET_USER,
    payload: data,
  });
};

// Action creator function that returns an action object
export const updatePracticeLogoPath = (payLoad) => {
  return {
    // Describes the type of action being performed
    type: UPDATE_PRACTICE_LOGO_PATH,
    // The payload of the action that contains any necessary data
    payLoad,
  };
};

export const setProviderType = (payLoad) => {
  return {
    type: UPDATE_PROVIDER_TYPE,
    payLoad: payLoad,
  };
};

export const switchPractice = (payLoad) => {
  return {
    type: SWITCH_PRACTICE,
    payLoad: payLoad,
  };
};

export const setUserName = (name) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_NAME,
    payload: name,
  });
};

export const updateUserSignature = (payload) => {
  return (dispatch) => {
    const userAuth = store.getState()?.user?.user;
    userAuth[`defaultSignature`] = payload;
    dispatch({
      type: UPDATE_USER_SIGNATURE,
      payload: userAuth,
    });
  };
};

export const saveUserInforRegistration = (payLoad) => {
  return {
    type: SAVE_EXISTING_USER_DETAILS,
    payLoad: payLoad,
  };
};

import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';

export const MuiSelect = ({ label, controlProps, options, optionKey, optionValue, isSimple, selectProps }) => {
  const { labelStyle, error: selectError } = selectProps || false;
  const { id: controlId } = controlProps || false;
  return (
    <FormControl {...controlProps}>
      <InputLabel
        {...(selectError
          ? { sx: { color: `red`, left: `-2%`, ...(labelStyle ? { ...labelStyle } : {}) } }
          : { sx: { left: `-2%`, ...(labelStyle ? { ...labelStyle } : {}) } })}
        {...(controlId ? { id: controlId } : {})}
      >
        {label}
      </InputLabel>
      <Select {...selectProps}>
        {options?.map((option, index) => (
          <MenuItem key={option[optionValue + index]} value={isSimple ? option : option[optionValue]}>
            {isSimple ? option : option[optionKey]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

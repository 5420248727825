import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { ForgetPassword } from '../pages/auth/forgetPassword/forgetPassword';
import PatientForget from '../pages/auth/patientForgetPassword/patientForget';
import { ResetPassword } from '../pages/auth/resetPassword/resetPassword';
import { NintyDaysResetPassword } from '../pages/auth/resetPassword/nintyDaysResetPassword';
import { Login } from '../pages/auth/login/login';
import { Otp } from '../pages/auth/otp/otp';
import { Logout } from '../pages/auth/logout/logout';
import { VerifyEmail } from '../pages/auth/verify/verify';
import { ErrorPage } from '../pages/error/error';
import { SubscriptionError } from '../pages/subscriptionError';
import { VideoPublicClient } from '../pages/common/videoPublicClient/videoPublicClient';
import { PuffSpinner } from '../pages/common/components/spinner/puff/puff';
import { useSelector } from 'react-redux';
import { ResponsiveDrawer } from 'src/pages/common/components/wrapper/components/sidebar/sidebar';
import { SystemAdminRouter } from './systemAdmin';
import { SuperAdminRouter } from './superAdmin';
import { ProviderRouter } from './provider';
import { ClinicalStaffRouter } from './clinicalUser';
import { TransportationAdmin } from './TransportationAdmin';
import Signup from '../pages/auth/patient/signup';
import { setPracticeTimezone } from 'src/utils';
import { GroupAdmin } from './GroupAdmin';
import { JwtExpWrapper } from 'src/pages/common/HOC/JwtExpWrapper';
import { SocketWrapper } from 'src/pages/common/HOC/SocketWrapper';
import { TimerConfimation } from 'src/pages/home/modules/patient2/components/TimerConfiramtion/TimerConfimation';
import { IdleUserWrapper } from 'src/pages/common/HOC/IdleUserWrapper';
import { useUserConditionalRendering } from 'src/pages/common/hooks/useUserConditionalRendering';
import { TaskAlertWrapper as NotifyTaskAlerts } from 'src/pages/common/HOC/TaskAlertWrapper';
import { ToastContainer } from 'react-toastify';
import { SwitchPracticeWrapper } from 'src/pages/common/HOC/SwitchPracticeWrapper';


const SwitchToSubscriptionError = () => {
  return (
    <Switch>
      <Route exact path="/subscription-error">
        <SubscriptionError />
      </Route>
      <Route path="/">
        <Redirect to="subscription-error" />
      </Route>
    </Switch>
  );
};


const MainRouter = () => {
  const { user: parentUser, patient } = useSelector((state) => state);
  const { user } = parentUser;
  let patientId = patient?.patientData?.data?._id;
  const [show, setShow] = useState(false);


  const userRoutes = useUserConditionalRendering({
    superAdminAndProvider: <SuperAdminRouter user={user} />,
    superAdminAndClinicalStaff: <SuperAdminRouter user={user} />,
    superAdmin: <SuperAdminRouter user={user} />,
    provider: user?.stripeSubscriptionId ? <ProviderRouter /> : <SwitchToSubscriptionError />,
    clinicalStaff: user?.stripeSubscriptionId ? <ClinicalStaffRouter /> : <SwitchToSubscriptionError />,
    transporationAdmin: user?.stripeSubscriptionId ? <TransportationAdmin /> : <SwitchToSubscriptionError />,
    systemAdmin: <SystemAdminRouter />,
    groupAdmin: <GroupAdmin />,
  });

  useEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 3000);
  }, [user, patientId]);

  if (show) {
    return (
      <div>
        {user && <TimerConfimation />}
        <ToastContainer />
        <Switch>
          <Route exact path="/logout">
            <Logout />
          </Route>

          <Route exact path="/error">
            <ErrorPage />
          </Route>

          {!user && (
            <Route exact path="/login">
              <Login />
            </Route>
          )}

          {!user && (
            <Route exact path="/forget">
              <ForgetPassword />
            </Route>
          )}

          {!user && (
            <Route exact path="/resetPassword/:secret/:id">
              {(props) => <ResetPassword {...props} />}
            </Route>
          )}
          {!user && (
            <Route exact path="/ninetyDaysResetPassword/:secret/:id">
              {(props) => <NintyDaysResetPassword {...props} />}
            </Route>
          )}

          {!user && (
            <Route exact path="/users/verify/:secret/:id/:_practiceId/:isExisting">
              {(props) => <VerifyEmail {...props} />}
            </Route>
          )}

          {!user && (
            <Route exact path="/patient/join/:id">
              <Signup />
            </Route>
          )}
          {!user && (
            <Route exact path="/patient/reset/:secret/:id">
              <PatientForget />
            </Route>
          )}
          {!user && (
            <Route exact path="/otp">
              <Otp />
            </Route>
          )}
          {!user && (
            <Route exact path="/public/videoCall/:token">
              {(props) => <VideoPublicClient {...props} isTelemedicine={false} />}
            </Route>
          )}
          {!user && (
            <Route path="/">
              <Redirect to="/login" />
            </Route>
          )}

          {user && setPracticeTimezone(user?.isSystemAdministrator || user?.isGroupAdmin ? `US/Eastern` : user.timezone) && (
            <SwitchPracticeWrapper>
              <SocketWrapper>
                <NotifyTaskAlerts>
                  <JwtExpWrapper>
                    <IdleUserWrapper>
                      <ResponsiveDrawer user={user}>{userRoutes}</ResponsiveDrawer>
                    </IdleUserWrapper>
                  </JwtExpWrapper>
                </NotifyTaskAlerts>
              </SocketWrapper>
            </SwitchPracticeWrapper>

          )}
          <Redirect to="/error" />
        </Switch>
      </div>
    );
  } else {
    return <PuffSpinner />;
  }
};

export default withRouter(MainRouter);

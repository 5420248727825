import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PENDING_PROVIDERS_STATUS_UPDATE_URL, postRequest } from '../../../../../../crud/crud';
import { PulseSpinner } from '../../../../../common/components/spinner/spinner';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import Moment from 'react-moment';
import { findPendingStatus } from 'src/utils';
import { setPendingProviderCountHandler } from '../../../../../../redux/action/setPendingProviderCountHandler';

export const ViewProviderProfile = ({ id, data, setRefreshProviderListComp }) => {
  const [loading, setLoading] = useState(false);
  const close = useRef(null);
  const dispatch = useDispatch()
  const statusUpdateHandler = (id, status) => {
    setLoading(true);
    let _practiceId = findPendingStatus(data?.systemRoles)
    postRequest(PENDING_PROVIDERS_STATUS_UPDATE_URL + id, {
      _practiceId: _practiceId?._practiceId?._id,
      action: status,
    })
      .then((res) => {
        dispatch(setPendingProviderCountHandler(res.data.count))
        // Rerender component here
        setRefreshProviderListComp((p) => !p);
        setLoading(false);
        successToast(res.data.message);
        close.current.click();
      })
      .catch(() => {
        setLoading(false);
        // errorToast(`Unable to process your request.`);
      });
  };

  return (
    <>
      <div className="modal fade" id={id} tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle" style={{ color: `grey`, fontSize: `16px`, fontWeight: `bold` }}>
                {data ? data?.firstName + ` ` + data?.lastName : null}
              </h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <table className="table table-hover table-sm">
                <tbody>
                  <tr>
                    <th scope="row">Practice Name</th>
                    <td>{data?.systemRoles[0]?._practiceId?.practiceName ? data?.systemRoles[0]?._practiceId?.practiceName : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Group Name</th>
                    <td>{data?.systemRoles[0]?._practiceGroupId?.name ? data?.systemRoles[0]?._practiceGroupId?.name : `-`}</td>
                  </tr>
                  <tr>
                    <th scope="row">Full Name</th>
                    <td>{data ? data.firstName + ` ` + data.lastName : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Date of Birth</th>
                    <td>{data ? <Moment date={data.dateOfBirth} format="DD-MMM-YYYY" /> : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Email</th>
                    <td>{data ? data?.email : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Phone Number</th>
                    <td>{data ? data?.phoneNumber : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Type</th>
                    <td>{data ? data?.providerType : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">NPI</th>
                    <td>{data ? data.NPI : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Medical License</th>
                    <td>{data ? data?.medicalLicense : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">License State</th>
                    <td>{data ? data?.licenseState : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Added By</th>
                    <td>{data ? data?.systemRoles[0]._created_by?.firstName + ` ` + data?.systemRoles[0]._created_by?.lastName : null}</td>
                  </tr>
                  <tr>
                    <th scope="row">Date</th>
                    <td>{data ? <Moment date={data.createdAt} format="DD-MMM-YYYY" /> : null}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" ref={close} data-dismiss="modal">
                Close
              </button>
              <button type="button" className="btn btn-danger" onClick={() => statusUpdateHandler(data._id, `reject`)}>
                Reject
              </button>
              <button type="button" className="btn btn-success" onClick={() => statusUpdateHandler(data._id, `accept`)}>
                Approve
              </button>
            </div>
          </div>
        </div>
        {loading && <PulseSpinner />}
      </div>
    </>
  );
}





import { Box, IconButton, Zoom } from '@mui/material'
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { postRequest, SNOOZE_TASK } from 'src/crud/crud'
import { TaskAssigneeBar, ViewTask } from 'src/pages/home/modules/TaskManagement/ViewTask/ViewTask'
import { taskSnoozOptions, updateNotificationStatusTaskMang } from 'src/utils'
import { HtmlFormatter } from '../components/HtmlFormatter'
import { MuiSelect } from '../components/MuiSelect/MuiSelect'
import { errorToast, successToast } from '../components/snackBar/toast'
import { ConfirmationWrapper } from './ConfirmationWrapper'
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ToolTipProvider } from '../components/Tooltip/ToolTipProvider'
import { PuffSpinner } from '../components/spinner/puff/puff'
import { updateTaskALert, deleteFromTaskALerts } from 'src/redux/taskManager/taskManagerActions.actions'
const TaskContent = ({ setViewTask, loading,
    setSnoozeDuration,
    latestALert,
    openSnoozContent
}) => {
    const doIfEmptyValue = (val, cb) => {
        if (!val) errorToast(`Please select one snooze duration!`)
        else cb && cb()
    }
    return (
        <>
            {loading && <PuffSpinner />}
            <TaskAssigneeBar taskDetail={latestALert} />
            <Box sx={{ pt: 1 }}>
                {latestALert?.taskName}
            </Box>
            <Box sx={{ pb: 2, pt: 1, pr: 2, pl: 2 }}>
                <HtmlFormatter shouldTrunc={true} title={latestALert?.taskDescription} />
            </Box>
            <Zoom in={openSnoozContent} mountOnEnter unmountOnExit>
                <Box>
                    <MuiSelect controlProps={{
                        size: `small`,
                        fullWidth: true,
                        id: `taskSnoozeMenu`,
                    }}
                        label="*Snooze Options"
                        options={[...taskSnoozOptions]}
                        optionKey={`label`}
                        optionValue={`value`}
                        selectProps={{
                            label: `*Snooze Options`,
                            name: `taskSnoozeMenu`,
                            onBlur: ({ target: { value } }) => doIfEmptyValue(value, null),
                            onChange: (event) => {
                                const { value } = event.target
                                doIfEmptyValue(value, () => {
                                    setSnoozeDuration(value)
                                })
                            },
                        }}
                    />
                </Box>
            </Zoom>
            <Box sx={{ width: `100%`, textAlign: `end` }}>
                <ToolTipProvider toolTipProps={{
                    title: `View Task`,
                    placement: `top`,
                    arrow: true,
                }} element={<IconButton onClick={() => setViewTask((p) => !p)}><VisibilityIcon /></IconButton>} />
            </Box>
        </>
    )
}


const TaskALert = ({ setOpenSnoozContent, snoozeDuration, openSnoozContent,
    resetState, setViewTask, setSnoozeDuration }) => {
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const { taskAlerts } = useSelector((state) => state?.tasksManager)
    const alert = useMemo(() => {
        if (Array.isArray(taskAlerts) && taskAlerts?.length) {
            return taskAlerts?.[0] || {}
        }
        return {}
    }, [taskAlerts])


    useEffect(() => {
        if (Array.isArray(taskAlerts) && taskAlerts?.length && Object.keys(alert)?.length) {
            updateNotificationStatusTaskMang({
                ...alert, taskReminderNotificationReceived: false,
                taskCreationNotificationReceived: true
            })
        }
    }, [alert])

    return <ConfirmationWrapper
        maxWidth='md'
        fullWidth={true}
        open={Object.keys(alert)?.length}
        successFunc={() => {
            if (!openSnoozContent) setOpenSnoozContent(true)
            else if (openSnoozContent && snoozeDuration) {
                setLoading(true)
                postRequest(SNOOZE_TASK + alert?._id, {
                    snoozeStatus: `on`, snoozeDuration
                }).then(() => {
                    setLoading(false)
                    successToast(`Done`)
                    resetState(() => dispatch(deleteFromTaskALerts({ ...alert })))
                }).catch(() => setLoading(false))
            } else if (openSnoozContent && !snoozeDuration) {
                errorToast(`Please select a snooze duration.`)
            } else {
                setLoading(false)
                resetState()
            }


        }}
        cancelFunc={() => resetState(() => dispatch(deleteFromTaskALerts({ ...alert })))}
        successText={`Snooze`}
        cancelText="Dismiss"
        title={`Task Reminder!`}
        renderContent={<TaskContent loading={loading} setSnoozeDuration={setSnoozeDuration} setViewTask={setViewTask} latestALert={alert} openSnoozContent={openSnoozContent} resetState={resetState} />}
    >
        <></>
    </ConfirmationWrapper>
}




export const TaskAlertWrapper = ({ children }) => {

    const [viewTask, setViewTask] = useState(false)
    const [snoozeDuration, setSnoozeDuration] = useState(``)
    const [openSnoozContent, setOpenSnoozContent] = useState(false)
    const { taskAlerts } = useSelector((state) => state?.tasksManager)
    const latestALert = taskAlerts?.[0] || {}
    const { _id: taskId } = latestALert
    const dispatch = useDispatch()
    const updateTask = useCallback(() => {
        dispatch(updateTaskALert(taskId))
    }, [taskId])
    useEffect(() => {

        return () => {
            setOpenSnoozContent(false)
        }
    }, [taskId])
    useEffect(() => {
        updateTask()
    }, [updateTask])





    const resetState = (cb) => {
        cb && cb()
        setViewTask(false)
        setOpenSnoozContent(false)

    }

    return (<>
        {children}
        {viewTask && <ViewTask selectedTaskModalHandler={viewTask}
            setSelectedTaskModalHandler={setViewTask}
            selectedTask={latestALert}
            isFromALert={true} />}
        <TaskALert setOpenSnoozContent={setOpenSnoozContent} snoozeDuration={snoozeDuration} openSnoozContent={openSnoozContent}
            resetState={resetState} setViewTask={setViewTask} setSnoozeDuration={setSnoozeDuration} />

    </>

    )
}






import React, { useState, useEffect } from 'react';
import logo from '../../../assets/monitair-logo.svg';
import css from './verify.module.css';
import { PulseSpinner } from '../../common/components/spinner/spinner';
import {
  VERIFY_EMAIL_URL, getRequest, postRequest, VERIFY_USERNAME, SET_PASSWORD_URL
} from '../../../crud/crud';
import { useHistory } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { AcceptTermsPrivacyPolicy } from './acceptTermsPrivacyPolicy';
import { FooterWrapper } from 'src/pages/common/HOC/FooterWrapper';
import { Box } from '@mui/material';

export const VerifyEmail = (props) => {
  const secret = props.match.params.secret;
  const id = props.match.params.id;
  const practiceId = props.match.params._practiceId;
  const isExisting = props.match.params.isExisting === `true` ? true : false;
  const [pass, setPass] = useState(``);
  const [confirmPass, setConfirmPass] = useState(``);
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState(``);
  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userError, setUserError] = useState(``);
  const history = useHistory();
  const [modal, setModal] = useState(false);
  const [agreeWithTermsAndPolicy, setAgreeWithTermsAndPolicy] = useState(false);
  const [agreeError, setAgreeError] = useState(``);

  useEffect(() => {
    let isExistingRoute = isExisting ? `/true` : `/false`
    let route = VERIFY_EMAIL_URL + `/` + secret + `/` + id + `/` + practiceId + isExistingRoute
    getRequest(route)
      .then(() => {
        if (isExisting) {
          history.push(`/login`)
        } else {
          setVerified(true);
        }
      })
      .catch(() => {
        history.push(`/error`);
      });
  }, []);

  const newPasswordHandler = (e) => {
    e.preventDefault();
    error && setError(false);
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[.,#?!@$%^&*_]).{8,}$/;
    if (!pass || !userName || !confirmPass) {
      toast.error(() => <>Please enter all entries</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (!regex.test(pass) || !regex.test(confirmPass)) {
      toast.error(() => <>Please follow the rules described above for password!</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      setError(true);
    } else if (pass !== confirmPass) {
      toast.error(() => <>Password mismatched</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (userError) {
      toast.error(() => <>Please check your user name entry!</>, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    } else if (!agreeWithTermsAndPolicy) {
      setAgreeError(`Please accept privacy policies`)
    } else {
      setLoading(true);
      // _id: id,
      // secretToken: secret,
      // resetPassword: password,
      // userName: userName,
      postRequest(SET_PASSWORD_URL, { resetPassword: confirmPass, secretToken: secret, _id: id, userName: userName, _practiceId: practiceId })
        .then(() => {
          setLoading(false);
          history.push(`/login`);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  return (
    <FooterWrapper showOnlytLiscense={true}>
      <Box>
        {verified ? (
          <div id={css.container}>
            <ToastContainer />
            <img id={css.logo} src={logo} alt="Logo" />
            <div id={css.title}>
              <h5 id={css.titleText}>Enter Password</h5>
            </div>
            <div id={css.bannerVerified}>
              <p id={css.bannerVerifiedText}>Email Successfully Verified !</p>
            </div>
            <div id={error ? css.bannerResetWrong : css.bannerReset}>
              <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>Please enter a password that meets the following criteria:</p>
              <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-At least 8 characters.</p>
              <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-A mix of both uppercase and lowercase letters.</p>
              <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-A mix of letters and numbers.</p>
              <p id={error ? css.bannerResetTextWrong : css.bannerResetText}>-Include at least one special character (e.g., ! @ # ? ).</p>
            </div>

            <form id={css.form} onSubmit={newPasswordHandler}>
              <InputField size='small'
                type="text"
                placeholder="User Name"
                onChange={(e) => setUserName(e.target.value)}
                value={userName}
                onBlur={(e) => {
                  let { value } = e.target;
                  if (value?.length > 0) {
                    setLoading(true);
                    postRequest(VERIFY_USERNAME, { _id: id, userName: userName, secretToken: secret })
                      .then(() => {
                        setUserError(``);
                        setLoading(false);
                        toast.success(() => <>Available</>, {
                          position: toast.POSITION.BOTTOM_RIGHT,
                        });
                      })
                      .catch((e) => {
                        setLoading(false);
                        if (e?.response) {
                          if (e?.response?.status === 409) {
                            setUserError(`Sorry user name already exists!`);
                          } else {
                            setUserError(e?.response?.data?.message);
                          }
                        }
                      });
                  }
                }}
              />
              <p className={css.errorPara}>{userError ? userError : ``}</p>
              <InputField size='small' type="password"
                sx={{ mt: 1 }} placeholder="Password" onChange={(e) => setPass(e.target.value)} value={pass} />
              <InputField size='small'
                type="password"
                sx={{ mt: 1 }}
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPass(e.target.value)}
                value={confirmPass}
              />

              <div id={css.remember}>
                <label style={{ cursor: `pointer`, color: `blue` }} onClick={() => setModal(true)}>
                  Accept Terms of Use and Privacy Policy
                </label>
              </div>
              <AcceptTermsPrivacyPolicy
                modal={modal}
                setModal={() => setModal(false)}
                setAgreeWithTermsAndPolicy={setAgreeWithTermsAndPolicy}
              />
              {!agreeWithTermsAndPolicy && <div style={{ color: `red`, fontSize: `12px` }}>{agreeError}</div>}

              <div id={css.buttonHolder}>
                <button type="submit" id={css.button}>
                  SET PASSWORD
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div id={css.container}>
            <img id={css.logo} src={logo} alt="Logo" />
            <div>
              <h3>Please wait ...</h3>
            </div>
          </div>
        )}

        {verified ? null : <PulseSpinner />}
        {loading ? <PulseSpinner /> : null}

      </Box>
    </FooterWrapper>
  );
}
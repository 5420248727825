import {
  AUTH_TOKEN,
  SET_USER,
  LOGOUT,
  SWITCH_PRACTICE,
  AUTH_TOKEN_REFRESH_TOKEN,
  UPDATE_USER_NAME,
  UPDATE_PROVIDER_TYPE,
  UPDATE_USER_SIGNATURE,
  SAVE_EXISTING_USER_DETAILS,
  UPDATE_PRACTICE_LOGO_PATH,
  UPDATE_APP_REL_COUNT,
} from '../action/types';

const initialState = {
  appRelCount: 0,
  auth: ``,
  user: ``,
  hasSwitchedPractice: false,
  existingUser: ``,
  usStates: [],
  providerType: [],
  practiceSpeciality: [],
  practiceType: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_APP_REL_COUNT:
      return { ...state, appRelCount: state.appRelCount + 1 }
    case AUTH_TOKEN:
      state.auth = action.payload;
      return {
        ...state,
        usStates: [
          {
            name: `Alabama`,
            abbreviation: `AL`,
          },
          {
            name: `Alaska`,
            abbreviation: `AK`,
          },
          {
            name: `American Samoa`,
            abbreviation: `AS`,
          },
          {
            name: `Arizona`,
            abbreviation: `AZ`,
          },
          {
            name: `Arkansas`,
            abbreviation: `AR`,
          },
          {
            name: `California`,
            abbreviation: `CA`,
          },
          {
            name: `Colorado`,
            abbreviation: `CO`,
          },
          {
            name: `Connecticut`,
            abbreviation: `CT`,
          },
          {
            name: `Delaware`,
            abbreviation: `DE`,
          },
          {
            name: `District Of Columbia`,
            abbreviation: `DC`,
          },
          {
            name: `Federated States Of Micronesia`,
            abbreviation: `FM`,
          },
          {
            name: `Florida`,
            abbreviation: `FL`,
          },
          {
            name: `Georgia`,
            abbreviation: `GA`,
          },
          {
            name: `Guam`,
            abbreviation: `GU`,
          },
          {
            name: `Hawaii`,
            abbreviation: `HI`,
          },
          {
            name: `Idaho`,
            abbreviation: `ID`,
          },
          {
            name: `Illinois`,
            abbreviation: `IL`,
          },
          {
            name: `Indiana`,
            abbreviation: `IN`,
          },
          {
            name: `Iowa`,
            abbreviation: `IA`,
          },
          {
            name: `Kansas`,
            abbreviation: `KS`,
          },
          {
            name: `Kentucky`,
            abbreviation: `KY`,
          },
          {
            name: `Louisiana`,
            abbreviation: `LA`,
          },
          {
            name: `Maine`,
            abbreviation: `ME`,
          },
          {
            name: `Marshall Islands`,
            abbreviation: `MH`,
          },
          {
            name: `Maryland`,
            abbreviation: `MD`,
          },
          {
            name: `Massachusetts`,
            abbreviation: `MA`,
          },
          {
            name: `Michigan`,
            abbreviation: `MI`,
          },
          {
            name: `Minnesota`,
            abbreviation: `MN`,
          },
          {
            name: `Mississippi`,
            abbreviation: `MS`,
          },
          {
            name: `Missouri`,
            abbreviation: `MO`,
          },
          {
            name: `Montana`,
            abbreviation: `MT`,
          },
          {
            name: `Nebraska`,
            abbreviation: `NE`,
          },
          {
            name: `Nevada`,
            abbreviation: `NV`,
          },
          {
            name: `New Hampshire`,
            abbreviation: `NH`,
          },
          {
            name: `New Jersey`,
            abbreviation: `NJ`,
          },
          {
            name: `New Mexico`,
            abbreviation: `NM`,
          },
          {
            name: `New York`,
            abbreviation: `NY`,
          },
          {
            name: `North Carolina`,
            abbreviation: `NC`,
          },
          {
            name: `North Dakota`,
            abbreviation: `ND`,
          },
          {
            name: `Northern Mariana Islands`,
            abbreviation: `MP`,
          },
          {
            name: `Ohio`,
            abbreviation: `OH`,
          },
          {
            name: `Oklahoma`,
            abbreviation: `OK`,
          },
          {
            name: `Oregon`,
            abbreviation: `OR`,
          },
          {
            name: `Palau`,
            abbreviation: `PW`,
          },
          {
            name: `Pennsylvania`,
            abbreviation: `PA`,
          },
          {
            name: `Puerto Rico`,
            abbreviation: `PR`,
          },
          {
            name: `Rhode Island`,
            abbreviation: `RI`,
          },
          {
            name: `South Carolina`,
            abbreviation: `SC`,
          },
          {
            name: `South Dakota`,
            abbreviation: `SD`,
          },
          {
            name: `Tennessee`,
            abbreviation: `TN`,
          },
          {
            name: `Texas`,
            abbreviation: `TX`,
          },
          {
            name: `Utah`,
            abbreviation: `UT`,
          },
          {
            name: `Vermont`,
            abbreviation: `VT`,
          },
          {
            name: `Virgin Islands`,
            abbreviation: `VI`,
          },
          {
            name: `Virginia`,
            abbreviation: `VA`,
          },
          {
            name: `Washington`,
            abbreviation: `WA`,
          },
          {
            name: `West Virginia`,
            abbreviation: `WV`,
          },
          {
            name: `Wisconsin`,
            abbreviation: `WI`,
          },
          {
            name: `Wyoming`,
            abbreviation: `WY`,
          },
        ],
        providerType: [
          {
            name: `MD`,
          },
          {
            name: `DO`,
          },
          {
            name: `APRN`,
          },
          {
            name: `PA-C`,
          },
          {
            name: `RN`,
          },
          {
            name: `RPSGT`,
          },
          {
            name: `RST`,
          },
          {
            name: `RRT`,
          },
          {
            name: `CRT`,
          },
          {
            name: `CCSH`,
          },
          {
            name: `Other`,
          },
        ],
        practiceSpeciality: [
          {
            name: `Adolescent medicine`,
          },
          {
            name: `Allergy and Immunology`,
          },
          {
            name: `Anesthesiology`,
          },
          {
            name: `Cardiovascular Disease`,
          },
          {
            name: `Critical Care Medicine`,
          },
          {
            name: `Endocrinology, Diabetes and Metabolism`,
          },
          {
            name: `Epilepsy`,
          },
          {
            name: `Family Medicine`,
          },
          {
            name: `Internal Medicine`,
          },
          {
            name: `Geriatric Medicine`,
          },
          {
            name: `Hospice and Palliative Medicine`,
          },
          {
            name: `Neurocritical Care`,
          },
          {
            name: `Neurology`,
          },
          {
            name: `Neuromuscular Medicine`,
          },
          {
            name: `Otolaryngology (ENT)`,
          },
          {
            name: `Pain Medicine`,
          },
          {
            name: `Pediatrics`,
          },
          {
            name: `Pediatric Critical Care Medicine`,
          },
          {
            name: `Pediatric Pulmonology`,
          },
          {
            name: `Pediatric Sleep Medicine`,
          },
          {
            name: `Psychiatry`,
          },
          {
            name: `Pulmonary Disease`,
          },
          {
            name: `Sleep Medicine`,
          },
          {
            name: `Vascular neurology`,
          },
        ],
        practiceType: [
          {
            name: `Solo Practice`,
          },
          {
            name: `Group Practice`,
          },
          {
            name: `Hospital Owned`,
          },
          {
            name: `Federally Qualified Health Center`,
          },
          {
            name: `Academic Health Center`,
          },
        ],
      };
    case SET_USER:
      return {
        ...state,
        user: action.payload,
      };

    case UPDATE_PRACTICE_LOGO_PATH: // A case in a Redux reducer to handle updating a practice logo path
      const { practiceId, practiceLogo } = action.payLoad; // Destructuring of the `practiceId` and `practiceLogo` variables from the `action.payLoad` object
      const splitUrl = practiceLogo.split(`/`); // Splitting the `practiceLogo` URL into an array using the forward slash delimiter
      const publicIndex = splitUrl.indexOf(`public`); // Finding the index of the 'public' segment in the URL
      const practicesIndex = splitUrl.indexOf(`practices`); // Finding the index of the 'practices' segment in the URL
      const fileName = splitUrl[splitUrl.length - 1]; // Getting the last segment of the URL as the file name
      const newUrlOfPracticeImage = `${splitUrl[publicIndex]}/${splitUrl[practicesIndex]}/${splitUrl[practicesIndex + 1]}/${fileName}`; // Constructing a new URL for the practice image with the 'public' and 'practices' segments rearranged and the file name at the end

      return {
        ...state, // Creating a new object that copies all the properties of the previous state
        user: {
          // Updating the user property in the state
          ...state?.user, // Creating a new object that copies all the properties of the previous user object
          // Updating the user property inside the user object
          // Creating a new object that copies all the properties of the previous user object again
          systemRoles: state?.user?.systemRoles?.map((role) => {
            if (role?._practiceId?._id === practiceId) {
              return { ...role, _practiceId: { ...role?._practiceId, practiceLogoPath: newUrlOfPracticeImage } };
            } else {
              return { ...role };
            }
          }),
        },
      };
    case AUTH_TOKEN_REFRESH_TOKEN:
      return { ...state, auth: action.payLoad };
    case UPDATE_USER_SIGNATURE:
      return {
        ...state,
        user: action.payload,
      };
    case UPDATE_PROVIDER_TYPE:
      return { ...state, user: { ...state?.user, providerType: action.payLoad } };

    case UPDATE_USER_NAME:
      let _user = state.user;
      _user.firstName = action.payload.firstName;
      _user.lastName = action.payload.lastName;
      return {
        ...state,
        user: { ..._user },
      };
    case LOGOUT:
      return { usStates: [], providerType: [], practiceSpeciality: [], practiceType: [], auth: ``, user: `` };
    case SWITCH_PRACTICE:
      return { ...state, hasSwitchedPractice: action.payLoad };
    case SAVE_EXISTING_USER_DETAILS:
      return { ...state, existingUser: action.payLoad };
    default:
      return state;
  }
}

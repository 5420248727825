import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import css from './list-all-tickets.module.css';
import { AdvanceFilter } from './advanceFilter/advance-filter';
import { SysAdminViewIssue } from './sysAdminViewIssue/sysAdmin-view-issue';
import { PuffSpinner } from '../../../../../../../common/components/spinner/puff/puff';
import { capitalizeFirstLetter, cleanWordsFormatterVal } from 'src/utils';
import { getTicketsSysAdmin, updateAllTickets } from '../../../../../../../../redux/ticketsCenter/ticketsCenterActions';
import { Fade, Zoom } from '@mui/material';
import { momentWrapper } from 'src/momentWrapper';
import { HtmlFormatter } from '../../../../../HtmlFormatter/index';
import { TruncateWraper } from 'src/pages/common/HOC/TruncateWraper';
import { TableFooter } from 'src/pages/common/hooks/TableFooter';
import { ToolTipProvider } from 'src/pages/common/components/Tooltip/ToolTipProvider';

export const ListAllTickets = () => {
  const [searchFilters, setSearchFilters] = useState({});
  const [selectedIssue, setSelectedIssue] = useState(``);
  const [selectedIssueModalHandler, setSelectedIssueModalHandler] = useState(false);
  const [issueListFlag, setIssueListFlag] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const data = useSelector((state) => state?.ticketsCenter?.data);
  const totalRows = useSelector((state) => state?.ticketsCenter?.totalRows);
  const totalPage = useSelector((state) => state?.ticketsCenter?.totalPage);
  const loading = useSelector((state) => state?.ticketsCenter?.loading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (issueListFlag || !issueListFlag) {
      let prepareObj = Object.keys(searchFilters)?.length ? { searchFilters: { ...searchFilters } } : false;
      dispatch(getTicketsSysAdmin(rowsPerPage, currentPage, prepareObj));
    }
  }, [issueListFlag, rowsPerPage, currentPage, searchFilters]);

  const openSpecificIssueHandler = (issueId) => {
    setSelectedIssue(issueId);
    setSelectedIssueModalHandler(true);
    dispatch(updateAllTickets({ role: `system`, ticketId: issueId }));
  };
  return (
    <div id={css.main} style={{ position: `relative` }}>
      <AdvanceFilter setSearchFilters={setSearchFilters} setIssueListFlag={setIssueListFlag} />
      <div className="table-responsive">
        <table className="table table-sm table-borderless table-striped">
          <thead>
            <tr
              style={{
                width: `10%`,
                textAlign: `start`,
                verticalAlign: `middle`,
              }}
            >
              <th scope="col"></th>
              <th scope="col">TITLE</th>
              <th scope="col">TYPE</th>
              <th scope="col">PRIORITY</th>
              <th>STATUS</th>
              <th>PRACTICE NAME</th>
              <th>OPEN DATE</th>
              <th>CLOSE DATE</th>
              <th>CREATED BY</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {data?.length > 0 ? (
              data?.map((ticket) => {
                const truncateText = (text, maxLength) => {
                  if (text.length > maxLength) {
                    const truncated = text.substr(0, maxLength);
                    return truncated.substr(0, truncated.lastIndexOf(` `)) + `...`;
                  }
                  return text;
                };

                const maxLength = 100; // adjust the length.

                const formattedIssueTitle = truncateText(ticket.issueTitle.replace(/<\/?p>/g, ` `), maxLength);
                return (
                  <Zoom in={data?.length} mountOnEnter unmountOnExit timeout={500}>
                    <tr key={ticket._id} className="text-start">
                      <td>
                        <Fade in={!ticket?.readBySysAdmin} timeout={500}>
                          <span style={{ color: `red` }}>&#9679;</span>
                        </Fade>
                      </td>
                      <td>
                        <ToolTipProvider
                          toolTipProps={{
                            title: cleanWordsFormatterVal(ticket.issueTitle),
                            arrow: true,
                            placement: `top`,
                          }}
                          element={
                            <div>
                              <HtmlFormatter shouldTrunc={true} title={formattedIssueTitle} />
                            </div>
                          }
                        />
                      </td>
                      <td style={{ textTransform: `capitalize` }}>{ticket.issueType}</td>
                      <td style={{ textTransform: `capitalize` }}>
                        <span className={`badge badge-${ticket.issuePriority === `normal` ? `primary` : `info`}`}>{ticket.issuePriority}</span>
                      </td>
                      <td style={{ textTransform: `capitalize` }}>
                        <span className={`badge badge-${ticket.issueStatus === `opened` ? `success` : `danger`}`}>{ticket.issueStatus}</span>
                      </td>
                      <td className="text-truncate" style={{ maxWidth: `1.5vw` }}>
                        {ticket.practiceName}
                      </td>
                      <td>{momentWrapper(ticket.openedTicketDate).format(`MM/DD/YYYY`)}</td>
                      <td>{ticket?.issueStatus === `closed` ? <Moment date={ticket.closedTicketDate} format="MM/DD/YYYY" /> : `-`}</td>
                      <td style={{ textTransform: `capitalize` }}>
                        {capitalizeFirstLetter(ticket.created_by?.firstName)} {capitalizeFirstLetter(ticket.created_by?.lastName)}
                      </td>
                      <td>
                        <button
                          type="button"
                          className="text-white"
                          style={{ width: `3em`, backgroundColor: `#1699c5` }}
                          onClick={() => openSpecificIssueHandler(`${ticket._id}`)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  </Zoom>
                );
              })
            ) : (
              <tr>
                <td colSpan="9" style={{ textAlign: `center` }}>
                  No Record Found.
                </td>
              </tr>
            )}
          </tbody>
          <TableFooter
            onRowsChange={(e) => {
              dispatch({ type: `SET_ROWS`, payLoad: parseInt(e.target.value) });
              setCurrentPage(1);
            }}
            data={data}
            currentPage={currentPage}
            rowsPerPage={rowsPerPage}
            totalRows={totalRows}
            setRowsPerPage={setRowsPerPage}
            setCurrentPage={setCurrentPage}
            totalPage={totalPage}
            isPatientView={false}
            isTask={true}
          />
        </table>
        {loading?.loading && <PuffSpinner />}
        {selectedIssueModalHandler && (
          <SysAdminViewIssue
            selectedIssueId={selectedIssue}
            selectedIssueModalHandler={selectedIssueModalHandler}
            setSelectedIssueModalHandler={setSelectedIssueModalHandler}
            setIssueListFlag={setIssueListFlag}
          />
        )}
      </div>
    </div>
  );
};

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { makeStyles } from '@mui/styles';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import { Slide } from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const createData = (topic, resources) => {
  return { topic, resources };
}

const rows = [
  createData(`CMS Physician Fee Schedule`, `https://www.cms.gov/medicare/physician-fee-schedule/search`),
  createData(`CPT Code Information`, `https://www.ama-assn.org/practice-management/digital/ama-telehealth-policy-coding-payment`),
  createData(`ResMed`, `https://document.resmed.com/documents/articles/1013491r11_Remote_Patient_Monitoring_Guide-Amer_Eng.pdf`),
  createData(`React Health`, `https://www.reacthealth.com/`),
  createData(`Philips Respironics`, `https://www.usa.philips.com/healthcare/solutions/sleep`),
  createData(`MonitAir White Paper`, `https://faqresources.s3.us-east-2.amazonaws.com/w_paper.pdf`),
];

export const Resources = () => {
  const [isOpen, setisOpen] = React.useState(false);
  const containerRef = React.useRef(null);
  React.useEffect(() => {
    setisOpen(true);
    return () => {
      setisOpen(false);
    };
  }, []);


  const useStyles = makeStyles({
    root: {
      backgroundColor: `#1699C5`,
    },
  });

  const classes = useStyles();

  return (
    <Box ref={containerRef} sx={{ display: `flex`, gap: `30px`, flexDirection: `column`, padding: { xs: `30px 15px`, sm: `45px 30px` } }}>
      <Slide direction="up" timeout={500} in={isOpen}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="simple table">
            <TableHead className={classes.root}>
              <TableRow>
                <StyledTableCell align="left">#</StyledTableCell>
                <StyledTableCell align="left">TOPIC</StyledTableCell>
                <StyledTableCell align="left">RESOURCE</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left">{row.topic}</StyledTableCell>
                  <Link href={row.resources} target="_blank">
                    <StyledTableCell align="left">{row.resources}</StyledTableCell>
                  </Link>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Slide>
    </Box>
  );
}

// This is a JavaScript file containing code that imports various modules and defines some functions and components.
import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { EXTERNAL_DEVICE_CREDENTIALS_ADD, getRequest, LIST_ALL_PRACTICES, postRequest } from 'src/crud/crud';
import { PulseSpinner } from 'src/pages/common/components/spinner/spinner';
import { errorToast, successToast } from 'src/pages/common/components/snackBar/toast';
import { InputField } from 'src/pages/common/components/InputField/InputField';
import { MuiSelect } from 'src/pages/common/components/MuiSelect/MuiSelect';
import { REACT_HEALTH } from 'src/utils';

export const SystemAdminAddCredentials = (props) => {
  // Define initial state variables using the useState hook
  const [formData, setFormData] = useState({ friendlyName: ``, dmeUserName: ``, dmePassword: `` }); // Object to store form data
  const [loading, setLoading] = useState(false); // Flag to indicate whether data is being loaded or not
  const [optionsForPractices, setOptionsForPractices] = useState([]); // Array to store options for dropdown
  const [_practiceId, setPracticeId] = useState(``); // Selected practice ID
  const [errors, setErrors] = useState({
    // Object to store error messages
    externalDevice: ``,
  });

  const { friendlyName, dmeUserName, dmePassword } = formData;
  // function to handle the form submission
  const addExternalDevice = (event) => {
    // prevent the default form submission behavior
    event.preventDefault();
    if (!friendlyName || !dmeUserName || !dmePassword) errorToast(`Please fill all the field in order to add the provider credentials`);
    else {
      // set loading state to true
      setLoading(true);
      // create the payload object with the form data and selected practice ID
      let payload = {
        friendlyName: formData?.friendlyName,
        userName: formData?.dmeUserName,
        password: formData?.dmePassword,
        _practiceId: _practiceId,
        deviceManufacturer: REACT_HEALTH,
      };
      // make a post request to add the external device credentials
      postRequest(EXTERNAL_DEVICE_CREDENTIALS_ADD, payload)
        .then((response) => {
          // reset the form data and loading state
          setFormData({ friendlyName: ``, dmeUserName: ``, dmePassword: `` });
          setLoading(false);
          // call the function to get all credentials and close the add dialog
          props.getAllCredentials();
          props.setOpenAddDialog(false);
          // show success toast message
          successToast(`New ExternalDevice has been created`);
        })
        .catch((error) => {
          // set loading state to false in case of error
          setLoading(false);
        });
    }
  };

  const handleInputChange = (event) => {
    // Extract the name and value properties of the input field from the event target
    const { name, value } = event.target;
    // Update the formData object with the new value for the specified input field
    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  // Define a function that retrieves a list of practices
  const getAllListOfPractices = () => {
    setLoading(true); // Set loading to true while the request is being made
    getRequest(LIST_ALL_PRACTICES) // Make a GET request to retrieve a list of practices
      .then((response) => {
        // If the request is successful, update state with the retrieved data
        setLoading(false); // Set loading to false since the request is done
        setOptionsForPractices(response?.data?.practices); // Update the state variable optionsForPractices with the list of practices
      })
      .catch((error) => {
        // If the request fails, log the error and set loading to false
        setLoading(false);
      });
  };

  // Call getAllListOfPractices when the component mounts or when props.openAddDialog changes
  useEffect(() => {
    getAllListOfPractices();
  }, [props.openAddDialog]);

  // function to perform validation on blur event of input fields
  const validateOnBlur = (value, field) => {
    // helper function for setting error state
    const emptyValidation = () => {
      setErrors((prevState) => ({ ...prevState, [field]: value ? `` : `*Required` }));
    };
    // switch statement to check validation based on input field
    switch (field) {
      case `externalDevice`:
        emptyValidation();
        break;
      default:
        return true;
    }
  };

  return (
    <>
      {/* Renders a custom dialog */}
      <CustomizedDialogs
        title={
          <>
            {/* Renders a span element with the text "Add Provider Credentials" */}
            <span>{`Add Provider Credentials`}</span>
          </>
        }
        open={props.openAddDialog}
        setOpen={() => {
          props.setOpenAddDialog(false);
        }}
        size="md"
        fullWidth={true}
      >
        {/* Renders a form element with an onSubmit handler */}
        <form onSubmit={addExternalDevice}>
          {/* Renders a Grid container with column and row spacing */}
          <Grid container columnSpacing={1} rowSpacing={2}>
            {/* Renders a Grid item with size configurations */}
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <div className="form-group">
                {/* Renders an InputField component with label, name, value and onChange props */}
                <InputField
                  size="small"
                  fullWidth={true}
                  type="text"
                  className="form-control"
                  label="Friendly Name"
                  name="friendlyName"
                  value={formData.friendlyName}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>

            {/* Renders a Grid item with size configurations */}
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <div className="form-group">
                {/* Renders an InputField component with label, name, value and onChange props */}
                <InputField
                  size="small"
                  fullWidth={true}
                  type="text"
                  className="form-control"
                  label="*Client Id"
                  name="dmeUserName"
                  value={formData?.dmeUserName}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>

            {/* Renders a Grid item with size configurations */}
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              <div className="form-group">
                {/* Renders an InputField component with label, name, value and onChange props */}
                <InputField
                  size="small"
                  fullWidth={true}
                  type="text"
                  className="form-control"
                  label="*Client Secret"
                  name="dmePassword"
                  value={formData?.dmePassword}
                  onChange={handleInputChange}
                />
              </div>
            </Grid>

            {/* Renders a Grid item with size configurations */}
            <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
              {/* Renders a MuiSelect component with various props */}
              <MuiSelect
                controlProps={{
                  id: `_id`,
                  fullWidth: true,
                  size: `small`,
                  error: errors?.externalDevice ? true : false,
                }}
                label="*Practice"
                optionKey={`practiceName`}
                optionValue={`_id`}
                options={optionsForPractices}
                isSimple={false}
                selectProps={{
                  label: `*Practice`,
                  name: `practiceName`,
                  helperText: errors.externalDevice,
                  onBlur: ({ target: { value } }) => {
                    validateOnBlur(value, `externalDevice`);
                  },
                  // onBlur: (value) => validateOnBlur(value, `externalDevice`),
                  onChange: (event) => {
                    setPracticeId(event?.target?.value);
                  },
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: `284px`, // set the maximum height of the options
                        width: `284px`, // set the width of the options
                      },
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <button
            type="submit"
            className="btn btn-outline-primary"
            style={{
              borderRadius: `25px`,
              minHeight: `30px`,
              float: `right`,
              marginTop: `30px`,
            }}
            disabled={_practiceId ? false : true}
          >
            Add
          </button>

          {loading && <PulseSpinner />}
        </form>
      </CustomizedDialogs>
    </>
  );
};

import axios from 'axios';
import config from 'src/config.js';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { logoutHandlerS } from 'src/redux/action/logoutHandler';
import { store } from 'src/redux/store';
// config.SERVER_ADDRESSS

export const SERVER_ADDRESS = config.SERVER_ADDRESSS;


export const axiosClient = axios.create();
axiosClient.defaults.baseURL = SERVER_ADDRESS;
axiosClient.interceptors.request.use((config) => {
  const token = store?.getState().user?.auth;
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});
axiosClient.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.message !== `canceled`) {
      if (error?.response?.status == 401 && error?.response?.data?.isLoggedOut) {
        store.dispatch(logoutHandlerS());
      } else if (error?.response?.status == 413) {
        errorToast(`The maximum file size that you can upload is 10MB`);
      } else if (error?.response?.status == 409) {
        errorToast(error?.response?.data?.message ? error.response.data.message : `Sorry given entry already exists!`);
      } else {
        const decideError = () => {
          let errorMsg = ``;
          if (error?.response?.data?.message) {
            errorMsg = error?.response?.data?.message;
          } else if (error?.response?.data?.err) {
            errorMsg = error?.response?.data?.err;
          } else if (error?.response?.data.error) {
            errorMsg = error?.response?.data.error;
          } else if (error?.response?.data.error?.message) {
            errorMsg = error?.response?.data.error?.message;
          }
          return errorMsg;
        };

        let errorMessage = decideError();
        errorToast(errorMessage?.message ? errorMessage?.messag : errorMessage ? errorMessage : `error`);
      }
    }

    return Promise.reject(error);
  }
);

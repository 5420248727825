import React, { useState, useEffect, useCallback, useRef } from 'react';
import css from './notification.module.css';
import { successToast } from 'src/pages/common/components/snackBar/toast';
import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import { useHistory } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Avatar from 'react-avatar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  LIST_ALL_NOTIFICATIONS_URL,
  getRequest,
  UNREAD_NOTIFICATIONS_STATUS_UPDATE_URL,
  PENDING_PROVIDERS,
  ALL_UNREAD_MESSAGE_RED_DOT_SHOW,
  UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS,
  patchRequest,
  ALL_RISK_NOTIFICATION,
  ALL_UNREAD_PATIENT_NOTIFICATION,
  deleteRequest,
  ADD_PATIENT_MONITORING_TIME_LOG_URL,
  postRequest,
} from '../../../../../../crud/crud';
import { capitalizeFirstLetter, timeLogAddOnSwitchPatientAndSorting } from 'src/utils';
import Moment from 'react-moment';
import { setUnreadNotifyCountHandler } from '../../../../../../redux/action/setUnreadNotifyCountHandler';
import {
  removeSMSFromNotificationArray,
  emptySMSArrayObject,
  addSMSArrayObjectInitial,
  _addRiskArrayObjectInitial,
} from '../../../../../../redux/twilio/twilioActions';
import { addResmedRiskArrayObjectInitial, _alertPatientRiskChange, _removeRiskAlertPatient } from '../../../../../../redux/resmed/resmedActions';
import { newSocket } from 'src/pages/common/HOC/SocketWrapper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import { ViewTask } from 'src/pages/home/modules/TaskManagement/ViewTask/ViewTask';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { momentWrapper } from 'src/momentWrapper';
import { TasksNotifications } from './TasksNotifications';
import { saveInTaskNotifyArray } from 'src/redux/taskManager/taskManagerActions.actions';

const customTheme = createTheme({
  overrides: {
    MuiTab: {
      root: {
        maxWidth: `120px`,
        fontSize: `0.75rem`,
        minWidth: `80px`,
        '@media(minWidth: 600px)': {
          minWidth: `80px`,
        },
        MuiButtonBaseRoot: {
          size: `small`,
        },
      },
    },
  },
});

let row = null;

let notFlag = false;

export const NotificationDropDown = () => {
  const {
    rtData,
    twilio,
    resmed,
    patient,
    user: parentUser,
    tasksManager: { taskNotificationArray },
  } = useSelector((state) => state);
  const { notifyCount: count } = rtData;
  const { smsArrayForNotification } = twilio;
  const { alertPatientRiskChange } = resmed;
  const { auth } = parentUser;
  let selectedPatient = patient?.patientData?.data?._id;
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [notificationFlag, setNotificationFlag] = useState(false);
  const [value, setValue] = useState(0);
  const [collapseFlag, setCollapseFlag] = useState(false);
  const [listOfPendingProviders, setListOfPendingProviders] = useState([]);
  const [openSpecificTask, setOpenSpecificTask] = useState(false);
  const [selectedTask, setSelectedTask] = useState({});
  const user = useSelector((state) => state.user.user);
  const [listOfNewPatientNotifications, setListOfNewPatientNotifications] = useState([]);
  let history = useHistory();
  // GET LIST OF PENDING PROVIDERS

  const getListOfPendingProvidersHandler = useCallback(() => {
    if (user?.isSuperAdmin) {
      getRequest(PENDING_PROVIDERS)
        .then((res) => {
          setListOfPendingProviders(res?.data?.pendingProviders);
        })
        .catch(() => { });
    }
  }, []);
  useEffect(() => {
    getListOfPendingProvidersHandler();
  }, [getListOfPendingProvidersHandler]);

  const handleClickAway = () => {
    if (collapseFlag) {
      notFlag = true;
      setCollapseFlag((pre) => !pre);
    }
  };

  const handleHide = () => {
    setCollapseFlag((prev) => {
      if (!prev) setValue(0);
      return !prev;
    });

    user?.isSystemAdministrator && fetchNoticationHandler();
  };

  const renderCount = useRef(0);
  useEffect(() => {
    if (renderCount.current === 0) {
      if (!user?.isSystemAdministrator && !user?.isGroupAdmin) {
        dispatch(saveInTaskNotifyArray());
      }
      allUnreadMessageRedDotShow();
      unreadRiskNotification();
      getPatientNotifications();
      renderCount.current = renderCount.current + 1;
    }
  }, [user, renderCount]);

  useEffect(() => {
    if (newSocket && user) {
      // EVENT EMIT - TO GET LIST OnewSocketF USERS
      newSocket.emit(`getUnreadRequestsOfProviders`);

      newSocket.on(`newPatientNotification`, (data) => {
        setListOfNewPatientNotifications((oldListOfNewPatientNotifications) => {
          if (oldListOfNewPatientNotifications?.length !== 0) return [...oldListOfNewPatientNotifications, data];
          else return [data];
        });
      });

      newSocket.on(`deletePatientNotification`, (id) => {
        setListOfNewPatientNotifications((oldListOfNewPatientNotifications) => {
          return oldListOfNewPatientNotifications.filter((notify) => notify._id !== id);
        });
      });

      newSocket.on(`getUnreadRequestsOfProviders`, (data) => {
        dispatch(setUnreadNotifyCountHandler(data));
      });

      newSocket.on(`approvalRequestForProvider`, (provider) => {
        successToast(provider.description);
        fetchNoticationHandler();
      });

      newSocket.on(`newProviderApprovalNotification`, (provider) => {
        successToast(provider.message);
      });
    }

    return () => {
      newSocket.off(`newProviderApprovalNotification`);
      newSocket.off(`approvalRequestForProvider`);
      newSocket.off(`getUnreadRequestsOfProviders`);
      newSocket.off(`newPatientNotification`);
      newSocket.off(`deletePatientNotification`);
    };
  }, [newSocket]);

  useEffect(() => {
    return () => {
      dispatch(emptySMSArrayObject());
    };
  }, []);

  const getPatientNotifications = () => {
    getRequest(ALL_UNREAD_PATIENT_NOTIFICATION + user?._id)
      .then((res) => {
        setListOfNewPatientNotifications(res?.data?.notifications);
      })
      .catch(() => { });
  };

  const unreadRiskNotification = () => {
    getRequest(ALL_RISK_NOTIFICATION)
      .then((res) => {
        dispatch(addResmedRiskArrayObjectInitial(res?.data?.notifications));
      })
      .catch(() => { });
  };
  const fetchNoticationHandler = () => {
    getRequest(LIST_ALL_NOTIFICATIONS_URL)
      .then((res) => {
        if (res?.data?.notifications) {
          setData([...res.data.notifications]);
        }
      })
      .catch(() => { });
  };
  const allUnreadMessageRedDotShow = () => {
    getRequest(ALL_UNREAD_MESSAGE_RED_DOT_SHOW)
      .then((res) => {
        dispatch(addSMSArrayObjectInitial(res?.data?.messages));
      })
      .catch(() => { });
  };

  const markAsReadHandler = (id) => {
    setNotificationFlag(true);
    getRequest(UNREAD_NOTIFICATIONS_STATUS_UPDATE_URL + id)
      .then(() => {
        setNotificationFlag(false);

        newSocket.emit(`getUnreadRequestsOfProviders`);
        fetchNoticationHandler();

        //
      })
      .catch(() => {
        setNotificationFlag(false);
      });
  };

  // REDIRECT PATIENT HANDLER
  const redirectPatientHandler = (patientId) => {
    timeLogAddOnSwitchPatientAndSorting();
    if (selectedPatient === patientId) {
      dispatch(removeSMSFromNotificationArray(patientId));
      setCollapseFlag((prev) => !prev);
    } else {
      patchRequest(UPDATE_PATIENT_UNREAD_NOTIFICATION_STATUS + patientId)
        .then(() => {
          dispatch(removeSMSFromNotificationArray(patientId));
          setCollapseFlag((prev) => !prev);
          history.push({ pathname: `/patient`, patientId: patientId });
        })
        .catch(() => { });
    }
  };

  // RISK ALERT REDIRECT HANDLER
  const riskChangeAlertPatientRedirectHandler = (notification) => {
    if (selectedPatient === notification?.patient?._id) {
      dispatch(_removeRiskAlertPatient(notification?.patient?._id));
      setCollapseFlag((prev) => !prev);
    } else {
      deleteRequest(`notifications/rf/${notification?.patient?._id}`)
        .then(() => {
          dispatch(_removeRiskAlertPatient(notification?._id));
          setCollapseFlag((prev) => !prev);
          history.push({ pathname: `/patient`, patientId: notification?.patient?._id });
        })
        .catch(() => { });
    }
  };

  if (data?.length !== 0) {
    row = data.map((note) => {
      return (
        <tr
          key={note._id}
          onClick={() => (note.status !== `READ` ? markAsReadHandler(note._id) : null)}
          style={{
            borderBottom: `1px solid rgb(211, 209, 209)`,
            cursor: `${notificationFlag ? `unset` : `pointer`}`,
          }}
          id={note.status === `UNREAD` ? css.unreadNote : css.readNote}
        >
          <td>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <p style={{ margin: 0 }}>{`${note?.resourceId?.firstName} ${note?.resourceId?.lastName} (`}</p>
                <p style={{ margin: 0 }}>{<Moment date={note?.resourceId?.dateOfBirth} format="MM/DD/YY" />}</p>
                <p style={{ margin: 0 }}>{` )`}</p>
              </div>
              <p style={{ margin: 0 }}>{<Moment date={note.createdAt} format="MM/DD/YY" />}</p>
            </div>
            <div className="d-flex align-items-center">
              {note.status === `UNREAD` ? (
                <i
                  className="fa fa-circle mr-1"
                  style={{
                    color: `#1699C5`,
                    fontSize: `6px`,
                    verticalAlign: `middle`,
                  }}
                  aria-hidden="true"
                ></i>
              ) : null}
              <p style={{ margin: 0 }}>{note.description}</p>
            </div>
          </td>
        </tr>
      );
    });
  }

  const deleteNewPatientNotifications = (id, patientId, providerId, clinicalId) => {
    history.push({ pathname: `/patient`, patientId });
    const data = {
      notificationId: id,
      patientId: patientId,
      // userId:user?._id
      providerId: providerId,
      clinicalId: clinicalId,
    };
    newSocket.emit(`deleteNewPatientNotification`, data);
  };
  const isOnVideo = useSelector((state) => state.patient.isOnVideo);
  let isOnlySuperAdmin = user?.isSuperAdmin && !user.isProvider && !user.isClinicalStaff && !user.isTransportationAdmin ? true : false;
  return (
    <>
      {` `}
      {openSpecificTask && (
        <ViewTask
          isNotify={true}
          selectedTaskModalHandler={openSpecificTask}
          setSelectedTaskModalHandler={setOpenSpecificTask}
          selectedTask={selectedTask}
        />
      )}
      <ThemeProvider theme={customTheme}>
        <button disabled={isOnVideo} id={css.topBarBtnSmall} type="button" onClick={() => handleHide()}>
          {!user?.isSystemAdministrator &&
            (smsArrayForNotification?.length > 0 ||
              alertPatientRiskChange?.length > 0 ||
              listOfNewPatientNotifications?.length > 0 ||
              taskNotificationArray?.length > 0) ? (
            <span id={css.badge}>
              {smsArrayForNotification?.length +
                alertPatientRiskChange?.length +
                listOfNewPatientNotifications?.length +
                taskNotificationArray?.length}
            </span>
          ) : null}
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 40 43.993">
            <g id="bell_2_" data-name="bell (2)" transform="translate(2 2)">
              <path
                id="Path"
                d="M30,12A12,12,0,0,0,6,12C6,26,0,30,0,30H36s-6-4-6-18"
                fill="none"
                stroke={count > 0 ? `#1699c5` : `#6d7278`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="4"
              />
              <path
                id="Path-2"
                data-name="Path"
                d="M6.92,0A4,4,0,0,1,3.46,2,4,4,0,0,1,0,0"
                transform="translate(14.54 37.996)"
                fill="none"
                stroke={count > 0 ? `#1699c5` : `#6d7278`}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit="10"
                strokeWidth="4"
              />
            </g>
          </svg>
        </button>
        {isOnlySuperAdmin || user.isTransportationAdmin ? (
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => handleClickAway()}>
            <Collapse in={collapseFlag}>
              <div
                style={{
                  overflow: `inherit`,
                  position: `absolute`,
                  zIndex: `22`,
                  display: `flex`,
                  marginTop: `18%`,
                  marginLeft: `-9em`,
                  maxHeight: `300px`,
                  boxShadow: `0 2px 6px 0 #0000001a, 0 6px 20px 0 rgb(0 0 0 / 19%)`,
                }}
              >
                <Paper square style={{ width: `249px` }}>
                  <div
                    style={{
                      height: 46,
                      justifyContent: `space-between`,
                      display: `flex`,
                      alignItems: `center`,
                    }}
                  >
                    {value != 0 && isOnlySuperAdmin ? (
                      <ChevronLeftIcon
                        style={{ marginLeft: 10 }}
                        onClick={() => {
                          if (value == 0) {
                            setValue(0);
                          } else {
                            setValue((p) => p - 1);
                          }
                        }}
                      />
                    ) : (
                      <span style={{ marginLeft: 10 }}></span>
                    )}
                    {value === 0 && isOnlySuperAdmin ? (
                      <div style={{ textTransform: `capitalize` }} onClick={getListOfPendingProvidersHandler}>
                        <span style={{ fontSize: `small` }}>{`Pending Providers (${listOfPendingProviders.length})`}</span>
                      </div>
                    ) : null}
                    {value === 1 || user?.isTransportationAdmin ? (
                      <div>
                        <span style={{ fontSize: `small` }}>{`Tasks (${taskNotificationArray?.length})`}</span>
                      </div>
                    ) : null}
                    {value != 1 && isOnlySuperAdmin ? (
                      <ChevronRightIcon
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          if (value === 1) {
                            setValue();
                          } else {
                            setValue((p) => p + 1);
                          }
                        }}
                      />
                    ) : (
                      <span style={{ marginLeft: 10 }}></span>
                    )}
                  </div>
                  {value === 0 && isOnlySuperAdmin ? <PendingProviders listOfPendingProviders={listOfPendingProviders} /> : null}
                  {value === 1 || user?.isTransportationAdmin ? (
                    <TasksNotifications setOpenSpecificTask={setOpenSpecificTask} setSelectedTask={setSelectedTask} />
                  ) : null}{` `}
                </Paper>
              </div>
            </Collapse>
          </ClickAwayListener>
        ) : (
          <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={() => handleClickAway()}>
            <Collapse in={collapseFlag}>
              {!user?.isSystemAdministrator && (
                <div
                  style={{
                    overflow: `inherit`,
                    position: `absolute`,
                    zIndex: `22`,
                    display: `flex`,
                    marginTop: `18%`,
                    marginLeft: `-9em`,
                    maxHeight: `300px`,
                    boxShadow: `0 2px 6px 0 #0000001a, 0 6px 20px 0 rgb(0 0 0 / 19%)`,
                  }}
                >
                  <Paper square style={{ width: `249px` }}>
                    <div
                      style={{
                        height: 46,
                        justifyContent: `space-between`,
                        display: `flex`,
                        alignItems: `center`,
                      }}
                    >
                      {value != 0 ? (
                        <ChevronLeftIcon
                          style={{ marginLeft: 10 }}
                          onClick={() => {
                            if ((user?.isClinicalStaff && !user?.isSuperAdmin) || (user?.isProvider && !user?.isSuperAdmin)) {
                              value === 3 ? setValue(1) : setValue((p) => p - 1);
                            } else {
                              value === 0 ? setValue(0) : setValue((p) => p - 1);
                            }
                          }}
                        />
                      ) : (
                        <span style={{ marginLeft: 10 }}></span>
                      )}
                      {value == 0 ? (
                        <div style={{ textTransform: `capitalize` }}>
                          <span style={{ fontSize: `small` }}>{`Messages (${smsArrayForNotification?.length})`}</span>
                        </div>
                      ) : null}
                      {user?.isSuperAdmin && value == 2 ? (
                        <div style={{ textTransform: `capitalize` }} onClick={getListOfPendingProvidersHandler}>
                          <span style={{ fontSize: `small` }}>{`Pending Providers (${listOfPendingProviders.length})`}</span>
                        </div>
                      ) : null}
                      {value == 1 ? (
                        <div style={{ textTransform: `capitalize` }} onClick={getListOfPendingProvidersHandler}>
                          <span style={{ fontSize: `small` }}>{`New Patient (${listOfNewPatientNotifications?.length})`}</span>
                        </div>
                      ) : null}
                      {value === 3 ? (
                        <div>
                          <span style={{ fontSize: `small` }}>{`Tasks (${taskNotificationArray?.length})`}</span>
                        </div>
                      ) : null}
                      {value != 3 ? (
                        <ChevronRightIcon
                          style={{ marginRight: 10 }}
                          onClick={() => {
                            if (user?.isClinicalStaff && !user?.isSuperAdmin) {
                              value == 3 || value === 1 ? setValue(3) : setValue((p) => p + 1);
                            } else if (user?.isProvider && !user?.isSuperAdmin) {
                              value == 3 || value === 1 ? setValue(3) : setValue((p) => p + 1);
                            } else value == 3 ? setValue(3) : setValue((p) => p + 1);
                          }}
                        />
                      ) : (
                        <span style={{ marginLeft: 10 }}></span>
                      )}
                    </div>
                    {/* </Tabs> */}
                    {value == 0 ? (
                      <div style={{ overflowY: `auto`, height: smsArrayForNotification?.length ? `230px` : null }}>
                        {smsArrayForNotification?.length > 0 ? (
                          smsArrayForNotification?.map((message, index) => (
                            <Card key={index} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
                              <CardContent>
                                <div style={{ display: `flex` }}>
                                  <Avatar name={`${message?.firstName} ${message?.lastName}`} size="35" round="40px" />
                                  <div className="ml-2">
                                    <span
                                      className="text-capitalize text-primary"
                                      style={{
                                        cursor: `pointer`,
                                        fontWeight: `600`,
                                        fontSize: `12px`,
                                      }}
                                      onClick={() => redirectPatientHandler(message?.from)}
                                    >
                                      {message?.firstName} {message?.lastName}
                                    </span>
                                    <br />
                                    <span
                                      className="d-inline-block text-truncate"
                                      style={{
                                        fontWeight: `600`,
                                        fontSize: `11px`,
                                        maxWidth: `180px`,
                                      }}
                                    >
                                      Message : <i>{message?.message}</i>
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        fontSize: `11px`,
                                      }}
                                    >
                                      {momentWrapper(message?.date).format(`MM/DD/YYYY hh:mm:ss A`)}
                                    </span>
                                  </div>
                                </div>
                              </CardContent>
                            </Card>
                          ))
                        ) : (
                          <Card sx={{ boxShadow: `none` }}>
                            <CardContent style={{ padding: `12px` }}>
                              <p className="text-center">No Message Found</p>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    ) : null}
                    {value == 1 ? (
                      <div style={{ overflowY: `auto`, height: listOfNewPatientNotifications?.length ? `230px` : null }}>
                        {listOfNewPatientNotifications?.length > 0 ? (
                          listOfNewPatientNotifications.map((provider, index) => (
                            <Card key={index} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
                              <CardContent style={{ padding: `12px` }}>
                                <div
                                  onClick={() =>
                                    deleteNewPatientNotifications(provider?._id, provider?._patientId, provider?._providerId, provider?._clinicalId)
                                  }
                                >
                                  <span
                                    style={{
                                      cursor: `pointer`,
                                      fontWeight: `600`,
                                      fontSize: `13px`,
                                      color: `#1699c5`,
                                    }}
                                  >
                                    {capitalizeFirstLetter(provider?.patientName)}
                                  </span>
                                  <br />
                                  <span
                                    style={{
                                      fontSize: `11px`,
                                      color: `#000`,
                                    }}
                                  >
                                    {` `}
                                    Date Added : <Moment date={provider?.createdAt} format="MM/DD/YY" />
                                  </span>
                                </div>
                              </CardContent>
                            </Card>
                          ))
                        ) : (
                          <Card sx={{ boxShadow: `none` }}>
                            <CardContent style={{ padding: `12px` }}>
                              <p className="text-center">You don't have any notifications</p>
                            </CardContent>
                          </Card>
                        )}
                      </div>
                    ) : null}
                    {value == 2 ? <PendingProviders listOfPendingProviders={listOfPendingProviders} /> : null}
                    {value === 3 ? <TasksNotifications setOpenSpecificTask={setOpenSpecificTask} setSelectedTask={setSelectedTask} /> : null}
                  </Paper>
                </div>
              )}
            </Collapse>
          </ClickAwayListener>
        )}
      </ThemeProvider>
    </>
  );
};

const PendingProviders = ({ listOfPendingProviders }) => {
  return (
    <div style={{ overflowY: `auto`, height: listOfPendingProviders?.length ? `230px` : null }}>
      {listOfPendingProviders?.length > 0 ? (
        listOfPendingProviders.map((provider, index) => (
          <Card key={index} sx={{ boxShadow: `0 0px 2px #9c909085`, borderRadius: 0 }}>
            <CardContent style={{ padding: `12px` }}>
              <div>
                <span
                  className="text-capitalize"
                  style={{
                    fontWeight: `600`,
                    fontSize: `13px`,
                    color: `#1699c5`,
                  }}
                >
                  {capitalizeFirstLetter(provider?.firstName)} {capitalizeFirstLetter(provider?.lastName)}
                </span>
                <br />
                <span
                  className="text-capitalize"
                  style={{
                    fontSize: `11px`,
                    color: `rgb(133 170 183)`,
                  }}
                >
                  {` `}
                  Created by : {capitalizeFirstLetter(provider?._created_by?.firstName)} {capitalizeFirstLetter(provider?._created_by?.lastName)}
                </span>
              </div>
            </CardContent>
          </Card>
        ))
      ) : (
        <Card sx={{ boxShadow: `none` }}>
          <CardContent style={{ padding: `12px` }}>
            <p className="text-center">You don't have any notifications</p>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

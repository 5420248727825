import React from 'react';
import css from '../../../pages/home/modules/practices/components/listAllPractice/listAllPractice.module.css';

export const TableFooter = ({ onRowsChange = false, data, totalPage, currentPage, rowsPerPage, totalRows,
  setRowsPerPage, setCurrentPage, pagination, setPagination,
  isPatientView, hasStyle,
}) => {
  const disableNavigateBackBtn = () => {
    if (isPatientView) {
      return pagination?.currentPage === 1
    }
    return currentPage === 1
  }
  const disableNavigateAdvBtn = () => {
    if (isPatientView) {
      return pagination?.currentPage === pagination?.totalPage || !data?.length
    }
    return currentPage === totalPage || !data?.length
  }

  const renderIndications = () => {
    if (isPatientView) {
      return pagination?.currentPage * pagination?.rowsPerPage -
        pagination?.rowsPerPage +
        1 +
        `-` + (pagination?.currentPage * pagination?.rowsPerPage > pagination?.totalRows
          ? pagination?.totalRows
          : pagination?.currentPage * pagination?.rowsPerPage) +
        ` of ` +
        pagination?.totalRows
    }

    return currentPage * rowsPerPage -
      rowsPerPage +
      1 +
      `-` +
      (currentPage * rowsPerPage > totalRows ? totalRows : currentPage * rowsPerPage) +
      ` of ` +
      totalRows
  }

  const applyBtnid = () => {
    if (isPatientView) {
      return pagination?.currentPage !== 1 ? css.customPageinationButton : css.customPageinationButtonDisabled
    }
    return currentPage !== 1 ? css.customPageinationButton : css.customPageinationButtonDisabled
  }


  const applyBtn1Id = () => {
    if (isPatientView) {
      return data?.length && pagination?.currentPage !== pagination?.totalPage ? css.customPageinationButton : css.customPageinationButtonDisabled
    }
    return data?.length && currentPage !== totalPage ? css.customPageinationButton : css.customPageinationButtonDisabled
  }

  return (
    <tfoot>
      <tr style={{ backgroundColor: `white`, margin: `2% 0%` }}>
        <td colSpan="9">
          <div className="d-flex justify-content-end align-items-center" style={{ ...(hasStyle && { marginBottom: `30px`, marginTop: `30px`, marginRight: `30px` }) }}>
            <p id={css.customTableFooterText}>Rows per page:</p>
            <select
              value={isPatientView ? pagination.rowsPerPage : rowsPerPage}
              id={css.rowsPerPage}
              onChange={(e) => {
                if (onRowsChange) onRowsChange(e)
                let parsedValue = parseInt(e.target.value);
                if (isPatientView) {
                  setPagination((p) => ({ ...p, rowsPerPage: parsedValue }))
                } else {
                  if (parsedValue > totalRows) setCurrentPage(1)
                  setRowsPerPage(parsedValue)
                }
              }}
            >
              <option value={10}>10</option>
              <option value={25}>25</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </select>
            <p id={css.customTableFooterText}>
              {renderIndications()}
            </p>
            <button
              id={applyBtnid()}
              disabled={disableNavigateBackBtn()}
              onClick={() => (isPatientView ? setPagination((p) => ({ ...p, currentPage: 1 })) : setCurrentPage(1))}
            >
              <i className="fa fa-angle-double-left"></i>
            </button>
            <button
              id={applyBtnid()}
              disabled={disableNavigateBackBtn()}
              onClick={() => (isPatientView ? setPagination((p) => ({ ...p, currentPage: p.currentPage - 1 })) : setCurrentPage((val) => val - 1))}
            >
              <i className="fa fa-angle-left"></i>
            </button>
            <button
              id={applyBtn1Id()}
              disabled={disableNavigateAdvBtn()}
              onClick={() => (isPatientView ? setPagination((p) => ({ ...p, currentPage: p.currentPage + 1 })) : setCurrentPage((val) => val + 1))}
            >
              <i className="fa fa-angle-right"></i>
            </button>
            <button
              id={applyBtn1Id()}
              disabled={disableNavigateAdvBtn()}
              onClick={() => (isPatientView ? setPagination((p) => ({ ...p, currentPage: pagination?.totalPage })) : setCurrentPage(totalPage))}
            >
              <i className="fa fa-angle-double-right"></i>
            </button>
          </div>
        </td>
      </tr>
    </tfoot>
  );
}
import React, { useRef, useState } from 'react';
import { PatientVolume } from '../patient/patientVolume/PatientVolume';
import { Adherence } from '../patient/adherence/Adherence';
import { Grid, Slide } from '@mui/material';

export const PatientAnalytics = () => {
  const [clicked, setClicked] = useState(true);
  const containerRef = useRef(null);
  return (
    <React.Fragment ref={containerRef}>
      <div className="d-flex justify-content-between w-100 mt-4">
        {` `}
        <h4 style={{ fontWeight: 625 }}>Patient Analytics</h4>
        <button onClick={() => setClicked((p) => !p)}>
          {` `}
          <i className={clicked ? `fa fa-chevron-up` : `fa fa-chevron-down`}></i>
        </button>
      </div>
      <hr className="w-100 m-0" />
      <Slide in={clicked} direction="up" timeout={600} mountOnEnter unmountOnExit container={containerRef.current}>
        <Grid sx={{ mt: 1 }} container columnSpacing={1} rowSpacing={1}>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            {` `}
            <PatientVolume clicked={clicked} />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
            <Adherence clicked={clicked} />
          </Grid>
        </Grid>
      </Slide>
    </React.Fragment>
  );
}


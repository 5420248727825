import { Chip } from '@mui/material';
import React from 'react';

export const BadgePill = ({ children, type }) => {
  return <div className={`badge badge-pill ${type}`}>{children}</div>;
};
export const MuiPill = ({ children, pillProps }) => {
  return (
    <Chip label={children} {...(pillProps && { ...pillProps })} />
  )
}
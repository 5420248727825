import { AUTH_TOKEN, AUTH_TOKEN_REFRESH_TOKEN } from './types';

export const authTokenHandler = (token) => (dispatch) => {
  dispatch({
    type: AUTH_TOKEN,
    payload: token,
  });
};

export const setAuth = (payLoad) => {
  return {
    type: AUTH_TOKEN_REFRESH_TOKEN,
    payLoad: payLoad,
  }
};
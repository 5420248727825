import React from 'react';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { AddNewUser } from './addNewUser/addNewUser';
import { AskForConfirmation } from 'src/pages/common/components/AskForconfirmation/AskForConfirmation';
import { EmailAndLastNameCheck } from 'src/pages/common/components/EmailAndLastNameCheck/EmailAndLastNameCheck';
import { useDispatch, useSelector } from 'react-redux';
import { saveUserInforRegistration } from 'src/redux/action/setUserHandler';
import { extractUserAdditionalValues } from 'src/utils';
export const ParentAddUser = ({ addUserModal, setAddUserModal }) => {
  const dispatch = useDispatch();
  const existing = useSelector((state) => state.user?.existingUser);

  let ask = (
    <AskForConfirmation
      acceptElem={
        <EmailAndLastNameCheck
          action={async (values) => {
            let additionalValues = await extractUserAdditionalValues(values);
            if (additionalValues && Object.keys(additionalValues)?.length) {
              for (const key in additionalValues) {
                delete values[key];
              }
            }
            dispatch(
              saveUserInforRegistration({
                ...values,
                ...(additionalValues && Object.keys(additionalValues)?.length ? { additionalValues: { ...additionalValues } } : {}),
              })
            );
          }}
          succesElem={<AddNewUser setAddUserModal={setAddUserModal} isNew={false} />}
          isMultiple={true}
        />
      }
      rejectElem={<AddNewUser setAddUserModal={setAddUserModal} isNew={true} />}
      actionOnAccept={() => {
        return null;
      }}
      actionOnReject={() => {
        return null;
      }}
      confirmationProps={{
        containerStyles: { width: `100%`, mt: 0.5 },
        acceptText: `Existing`,
        rejectText: `New`,
        confirmationText: `Do you want to add a new user or an existing user ?`,
      }}
      navigationProps={{
        hasNavigation: true,
        iconBtnBlockStyles: { marginTop: `-13px`, marginLeft: `-14px` },
        action: () => {
          dispatch(saveUserInforRegistration(``));
        },
      }}
    />
  );
  const emptyExistingUser = () => {
    if (existing) {
      dispatch(saveUserInforRegistration(``));
    }
  }
  return (
    <CustomizedDialogs
      title="ADD NEW USER"
      open={addUserModal}
      setOpen={() => {
        emptyExistingUser()
        setAddUserModal(false)
      }}
      size="md"
      fullWidth={true}
      notShowDividers={false}
      showCloseButton={false}
    >
      {ask}
    </CustomizedDialogs>
  );
};
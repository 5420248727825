export const HANDLE_TIMER = `HANDLE_TIMER`; //Handle Time
export const LIST_ALL_PATIENTS = `LIST_ALL_PATIENTS`; //Get all patients
export const LIST_ALL_PATIENTS_LOADING = `LIST_ALL_PATIENTS_LOADING`; //Patients List Loading
export const LIST_ALL_PATIENTS_ERROR = `LIST_ALL_PATIENTS_ERROR`; //Patients List Error
export const SELECTED_PATIENT = `SELECTED_PATIENT`;
export const SELECTED_PATIENT_LOADING = `SELECTED_PATIENT_LOADING`;
export const SELECTED_PATIENT_ERROR = `SELECTED_PATIENT_ERROR`;
export const PATIENTS_FILTERS = `PATIENTS_FILTERS`;
export const LIST_ALL_PATIENTS_EMPTY = `LIST_ALL_PATIENTS_EMPTY`;
export const PATIENT_DATA_EMPTY = `PATIENT_DATA_EMPTY`;
export const INITIALIZE_START_END_TIME = `INITIALIZE_START_END_TIME`;
export const SET_VIDEO_CALL_STATUS = `SET_VIDEO_CALL_STATUS`;
export const HANDLE_SELECTED_TAB = `HANDLE_SELECTED_TAB`;
export const REPORT_LOADING = `REPORT_LOADING`;
export const HIDE_TIMER = `HIDE_TIMER`;
export const PATIENT_SWITCHING = `PATIENT_SWITCHING`;
export const SET_MESSAGES = `SET_MESSAGES`;
export const SET_MESSAGES_CURRENT_PAGE = `SET_MESSAGES_CURRENT_PAGE`;
export const SET_MESSAGES_HASMORE = `SET_MESSAGES_HASMORE`;
export const TXT_ERROR = `TXT_ERROR`;
export const SMSRESPONSE = `SMSRESPONSE`;
export const ALL_TIME_LOGS = `ALL_TIME_LOGS`;
export const ALL_TIME_LOGS_LOADING = `ALL_TIME_LOGS_LOADING`;

export const FIRST_RESPOSNE = `FIRST_RESPOSNE`;
export const SOCKET_RESPONSE = `SOCKET_RESPONSE`;
export const PATIENT_DATA_FLAG = `PATIENT_DATA_FLAG`;
export const COMMUNICATION_PATIENT = `COMMUNICATIONPATIENT`;
export const SET_NOTES = `SET_NOTES`;

export const SELECTED_PATIENT_UNUSE = `SELECTED_PATIENT_UNUSE`;

// Not in use right now
export const SELECTED_PATIENT_REPORT = `SELECTED_PATIENT_REPORT`;
//  for getting and listion smartPhrase

export const GET_SMART_PHRASE = `GET_SMART_PHRASE`;
//  for set currenTab of communication center
export const CURRENT_TAB = `CURRENT_TAB`;

import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import css from './viewAllPatients.module.css';
import { VIEW_ALL_PATIENTS, VIEW_ALL_PATIENTS_GROUP, getRequest, postRequest } from '../../../../../../../crud/crud';
import { PuffSpinner } from '../../../../../../common/components/spinner/puff/puff';
import { CustomizedDialogs } from 'src/pages/common/components/modal/CustomizeModal';
import { errorToast } from 'src/pages/common/components/snackBar/toast';
import { momentWrapper } from 'src/momentWrapper';

export const ViewAllPatients = ({ viewAllPatientModal, setViewAllPatientModal }) => {
  const { user: { user }, practice } = useSelector((state) => state)
  const [loading, setLoading] = useState(false);
  const [listOfPatients, setListOfPatients] = useState([]);

  // PAGINATION HOOKS
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalRows, setTotalRows] = useState(0);
  const [totalPage, setTotalPage] = useState(1);

  // SORT VALUES
  const [sortKey, setSortKey] = useState(`createdAt`);
  const [sortOrder, setSortOrder] = useState(1);

  let filters = {
    sort: JSON.stringify({ [sortKey]: sortOrder }),
    // sortField: sortKey,
    // sortOrder: sortOrder === -1 ? 'ASC' : sortOrder === 1 ? 'DESC' : null,
  };
  let sorting = {
    sortField: sortKey,
    sortOrder: sortOrder === -1 ? `ASC` : sortOrder === 1 ? `DESC` : null,
  };
  let payLoad = {
    pageNumber: currentPage,
    pageSize: rowsPerPage,
    ...sorting,
    ...(!user?.isGroupAdmin && { _practiceId: practice?._id }),
  };

  const queryParams = (queryObj) => {
    let queryString = ``;
    for (const item in queryObj) {
      if (item === `page` || item === `pageNumber`) {
        queryString += `?${item}=${queryObj[item]}`;
      } else if (item === `filters`) {
        queryString += `&${item}=${JSON.stringify(queryObj.filters)}`;
      } else {
        queryString += `&${item}=${queryObj[item]}`;
      }
    }

    return queryString;
  };
  // GET LIST OF ALL THE PATIENTS

  useEffect(() => {
    if (user?.isGroupAdmin) {
      let query = queryParams(payLoad);
      getRequest(VIEW_ALL_PATIENTS_GROUP + practice?._id + query)
        .then((response) => {
          setLoading(false);

          setListOfPatients(response?.data?.data);

          setTotalRows(response?.data?.pager?.totalRecords);
          if (Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.pageSize)) {
            setTotalPage(Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.pageSize));
          }
        })
        .catch(() => { });
    } else {
      setLoading(true);
      postRequest(VIEW_ALL_PATIENTS, payLoad)
        .then((response) => {
          setLoading(false);

          setListOfPatients(response?.data?.data);

          setTotalRows(response?.data?.pager?.totalRecords);
          if (Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.pageSize)) {
            setTotalPage(Math.ceil(response?.data?.pager?.totalRecords / response?.data?.pager?.pageSize));
          }
        })
        .catch((err) => {
          setLoading(false);

          errorToast(err?.response?.data?.message ? err?.response?.data?.message : err?.response?.data?.error);
        });
    }
  }, [rowsPerPage, sortKey, sortOrder, currentPage, practice._id, user]);

  const sortHandler = (key) => {
    setSortKey(key);
    if (sortOrder === -1) setSortOrder(1);
    else setSortOrder(-1);
  };

  return (
    <div>
      <CustomizedDialogs
        title="VIEW ALL PATIENTS"
        open={viewAllPatientModal}
        setOpen={() => setViewAllPatientModal(false)}
        size="md"
        fullWidth={true}
        notShowDividers={true}
        showCloseButton={false}
      >
        <div>
          {listOfPatients?.length > 0 ? (
            <table className="table table-striped">
              <thead>
                <tr>
                  {/* <th scope="col"></th> */}
                  <th scope="col" className={css.customCol1} onClick={() => sortHandler(`lastName`)}>
                    PATIENT LAST NAME
                    {sortKey === `lastName` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `lastName` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th scope="col">PATIENT FIRST NAME</th>
                  <th scope="col">DATE OF BIRTH</th>
                  <th
                    scope="col"
                    // className={css.customCol1}
                    onClick={() => sortHandler(`provider`)}
                  >
                    PROVIDER
                    {sortKey === `provider` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `provider` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th
                    scope="col"
                    // className={css.customCol1}
                    onClick={() => sortHandler(`clinicalUser`)}
                  >
                    CLINICAL STAFF
                    {sortKey === `clinicalUser` && sortOrder === 1 ? (
                      <i className="fa fa-sort-asc ml-2" />
                    ) : sortKey === `clinicalUser` && sortOrder === -1 ? (
                      <i className="fa fa-sort-desc ml-2" />
                    ) : (
                      <i className="fa fa-sort ml-2" />
                    )}
                  </th>
                  <th scope="col">STATUS</th>
                </tr>
              </thead>
              <tbody id={css.body}>
                {listOfPatients.map((patient) => {
                  return (
                    <tr key={patient?._id}>
                      <td className="text-capitalize">{patient?.lastName}</td>
                      <td className="text-capitalize">{patient?.firstName}</td>
                      <td>{momentWrapper(patient?.dateOfBirth, `YYYY-MM-DD`).format(`MM/DD/YYYY`)}</td>

                      <td style={{ textTransform: `capitalize` }}>
                        {!user?.isGroupAdmin ? (
                          <>
                            <span>{patient?.provider.firstName + ` ` + patient?.provider?.lastName}</span>
                          </>
                        ) : (
                          <>
                            <span>{patient?.provider?.name}</span>
                          </>
                        )}
                      </td>

                      <td style={{ textTransform: `capitalize` }}>
                        {!user?.isGroupAdmin ? (
                          <>
                            <span>{patient?.clinicalUser.firstName + ` ` + patient?.clinicalUser?.lastName}</span>
                          </>
                        ) : (
                          <>
                            <span>{patient?.clinicalUser?.name}</span>
                          </>
                        )}
                      </td>

                      <td style={{ textTransform: `uppercase` }}>{patient?.status}</td>
                    </tr>
                  );
                })}
              </tbody>
              <tfoot>
                <tr style={{ backgroundColor: `white`, margin: `2% 0%` }}>
                  <td colSpan="10">
                    <div className="d-flex justify-content-end align-items-center">
                      <p id={css.customTableFooterText}>Rows per page:</p>
                      <select id={css.rowsPerPage} onChange={(e) => setRowsPerPage(parseInt(e.target.value))}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                      <p id={css.customTableFooterText}>
                        {currentPage * rowsPerPage -
                          rowsPerPage +
                          1 +
                          `-` +
                          (currentPage * rowsPerPage > totalRows ? totalRows : currentPage * rowsPerPage) +
                          ` of ` +
                          totalRows}
                      </p>
                      <button
                        id={currentPage !== 1 ? css.customPageinationButton : css.customPageinationButtonDisabled}
                        disabled={currentPage !== 1 ? false : true}
                        onClick={() => setCurrentPage(1)}
                      >
                        <i className="fa fa-angle-double-left"></i>
                      </button>
                      <button
                        id={currentPage !== 1 ? css.customPageinationButton1 : css.customPageinationButton1Disabled}
                        disabled={currentPage !== 1 ? false : true}
                        onClick={() => setCurrentPage((val) => val - 1)}
                      >
                        <i className="fa fa-angle-left"></i>
                      </button>
                      <button
                        id={currentPage !== totalPage ? css.customPageinationButton1 : css.customPageinationButton1Disabled}
                        disabled={currentPage !== totalPage ? false : true}
                        onClick={() => setCurrentPage((val) => val + 1)}
                      >
                        <i className="fa fa-angle-right"></i>
                      </button>
                      <button
                        id={currentPage !== totalPage ? css.customPageinationButton : css.customPageinationButtonDisabled}
                        disabled={currentPage !== totalPage ? false : true}
                        onClick={() => setCurrentPage(totalPage)}
                      >
                        <i className="fa fa-angle-double-right"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          ) : (
            <div className="d-flex justify-content-center align-items-center" style={{ color: `rgb(193, 193, 193)` }}>
              Patients record not found.
            </div>
          )}
        </div>
        {loading && <PuffSpinner />}
      </CustomizedDialogs>
    </div>
  );
}


